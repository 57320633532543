import Redux, { AnyAction } from 'redux';

import PaymentApi from '../api/Payment';
import PublicPaymentApi from '../api/PublicPayment';
import { Payment } from '../model/Payment';
import { PaymentBody } from '../model/payload/PaymentBody';
import { PaymentMethod } from '../model/PaymentMethod';
import { PaymentResult } from '../model/PaymentResult';
import { GopayCharge, PublicGopayCharge } from '../model/payload/GopayCharge';
import { CardCharge } from '../model/payload/CardCharge';
import { PaymentCheck } from '../model/payload/PaymentCheck';
import { PaymentCheckStatus } from '../model/PaymentCheckStatus';

export const SET_MY_PAYMENT = `PAYMENT.SET_MY_PAYMENT`
export const SET_PAYMENT_METHOD = `PAYMENT.SET_PAYMENT_METHOD`
export const SET_PAYMENT_RESULT = `PAYMENT.SET_PAYMENT_RESULT`
export const SET_AMOUNT_PAYMENT = `PAYMENT.SET_AMOUNT_PAYMENT`
export const SET_ANONIM = `PAYMENT.SET_ANONIM`
export const SET_USER_FULL_NAME = `PAYMENT.SET_USER_FULL_NAME`
export const SET_USER_CONTACT = `PAYMENT.SET_USER_CONTACT`

export const setMyPayment: Redux.ActionCreator<Redux.AnyAction> = (payload: Payment[]) => {
    return { type: SET_MY_PAYMENT, payload: payload }
}

export const setPaymentMethod: Redux.ActionCreator<Redux.AnyAction> = (payload: PaymentMethod[]) => {
    return { type: SET_PAYMENT_METHOD, payload: payload }
}

export const setPaymentResult: Redux.ActionCreator<Redux.AnyAction> = (payload: PaymentResult) => {
    return { type: SET_PAYMENT_RESULT, payload: payload }
}

export const setAmountPayment: Redux.ActionCreator<Redux.AnyAction> = (payload: string) => {
    return { type: SET_AMOUNT_PAYMENT, payload: payload }
}

export const setAnonim: Redux.ActionCreator<Redux.AnyAction> = (payload: string) => {
    return { type: SET_ANONIM, payload: payload }
}

export const setDonatur: Redux.ActionCreator<Redux.AnyAction> = (payload: string) => {
    return { type: SET_USER_FULL_NAME, payload: payload }
}

export const setUserContact: Redux.ActionCreator<Redux.AnyAction> = (payload: string) => {
    return { type: SET_USER_CONTACT, payload: payload }
}

export const getPayments = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string) {
    try {
        const result: Payment[] = await PaymentApi.getPayments(user_id, starting_after, order_by, limit, status);
        return result
    } catch (err: any) {
        throw err
    }
};

export const getPaymentMethods = async function () {
    try {
        const result: PaymentMethod[] = await PaymentApi.getPaymentMethods();
        return result
    } catch (err: any) {
        throw err
    }
};
export const getPublicPaymentMethods = async function () {
    try {
        
        const result: PaymentMethod[] = await PublicPaymentApi.getPublicPaymentMethods();
        console.log(">>>>",result)
        return result
    } catch (err: any) {
        console.log(">>>>",123)
        throw err
    }
};

export const submitPayment = async function (payload: PaymentBody) {
    try {
        await PaymentApi.submitPayment(payload);
    } catch (err: any) {
        throw err
    }
}

export const chargeGopay = async function (payload: GopayCharge) {
    try {
        const result: PaymentResult = await PaymentApi.chargeGopay(payload);
        return result
    } catch (err: any) {
        throw err
    }
}

export const publicGopayCharge = async function (payload: PublicGopayCharge) {
    try {
        const result: PaymentResult = await PaymentApi.publicGopayCharge(payload);
        return result
    } catch (err: any) {
        throw err
    }
}

export const chargeCard = async function (payload: CardCharge) {
    try {
        const result: PaymentResult = await PaymentApi.chargeCard(payload);
        return result
    } catch (err: any) {
        throw err
    }
}

export const checkStatus = async function (payload: PaymentCheck) {
    try {
        const result: PaymentCheckStatus = await PaymentApi.checkStatus(payload);
        return result
    } catch (err: any) {
        throw err
    }
}
export const publicCheckStatus = async function (payload: PaymentCheck) {
    try {
        const result: PaymentCheckStatus = await PaymentApi.publicCheckStatus(payload);
        return result
    } catch (err: any) {
        throw err
    }
}

export interface PaymentState {
    myPayments: Payment[] | undefined
    paymentResult: Payment | undefined
    paymentMethods: PaymentMethod[] | undefined
    amountPayment: String
    isAnonim: boolean
    userFullName: String
    userContact: String
}

export const initialPaymentState: PaymentState = {
    myPayments: undefined,
    paymentMethods: undefined,
    paymentResult: undefined,
    userFullName: "",
    userContact: "",
    amountPayment: "",
    isAnonim: false
}

const paymentReducers = (state: PaymentState = initialPaymentState, action: any): PaymentState => {
    switch (action.type) {
        case SET_MY_PAYMENT:
            return {
                ...state,
                myPayments: action.payload
            }
        case SET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: action.payload
            }
        case SET_PAYMENT_RESULT:
            return {
                ...state,
                paymentResult: action.payload
            }
        case SET_AMOUNT_PAYMENT:
            return {
                ...state,
                amountPayment: action.payload
            }
        case SET_ANONIM:
            return {
                ...state,
                isAnonim: action.payload
            }
        case SET_USER_CONTACT:
            return {
                ...state,
                userContact: action.payload
            }
        case SET_USER_FULL_NAME:
            return {
                ...state,
                userFullName: action.payload
            }
        default: {
            return state;
        }
    }
}

export default paymentReducers
