import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { Project } from '../model/Project';
import { ListDescription } from '../model/ListDescription';
import { ProjectUserBody } from '../model/payload/ProjectUserBody';
import { ProjectPMTBody } from '../model/payload/ProjectPMTBody';

const projectConnectionWithAccessToken = JsonServerConnection(url, '/projects').withAccessToken;
const publicProjectConnectionWithAccessToken = JsonServerConnection(url, '/public-projects');

const getProjects = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string, name?: string) => {
    let finalQuery = "?"

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }
    if (name && name !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "name=" + name
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await projectConnectionWithAccessToken.get(finalQuery) as ListDescription<Project>
    return result.data;
};

const getPublicProjects = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string, name?: string) => {
    let finalQuery = "?"
    
    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }
    if (name && name !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "name=" + name
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }

    const result = await publicProjectConnectionWithAccessToken.get(finalQuery) as ListDescription<Project>
    return result.data;
};

const submitProject = async (payload: ProjectUserBody) => {
    await projectConnectionWithAccessToken.post("/me", payload)
}

const editProjectPMT = async (project_id: string, payload: ProjectPMTBody) => {
    await projectConnectionWithAccessToken.post("/pmt/" + project_id, payload)
}

const editProjectUser = async (project_id: string, payload: ProjectUserBody) => {
    await projectConnectionWithAccessToken.post("/me/" + project_id, payload)
}

export interface ProjectApi {
    getProjects: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string, name?: string) => Promise<Project[]>;
    getPublicProjects: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string, name?: string) => Promise<Project[]>;
    submitProject: (payload: ProjectUserBody) => void;
    editProjectPMT: (project_id: string, payload: ProjectPMTBody) => void;
    editProjectUser: (project_id: string, payload: ProjectUserBody) => void;
}

export default {
    getProjects,
    getPublicProjects,
    submitProject,
    editProjectPMT,
    editProjectUser
} as ProjectApi;
