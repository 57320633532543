import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
} from 'react-bootstrap';
import './style.css';
import { Dispatch } from 'redux';
import Navigation from '../../utils/Navigation';
import { getTranslate } from '../../utils/General';
import { hasBack } from '../../reducers/User';

export interface TNCPageProps {
    hasBack: boolean
}

export interface TNCPageAction {
    setHashBack(has: boolean): void
}

export interface TNCPageState {
    text1: string
    text2: string
    text3: string
}

class TNCPage extends React.Component<TNCPageProps & TNCPageAction, TNCPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            text1: getTranslate('Syarat dan Ketentuan'),
            text2: getTranslate('<p><a style="font-weight: bold;">indonesiagreen.com</a> (“Situs”) dikelola oleh PT KREASI ENERGI TERBARUKAN bersama dengan YAYASAN KREASI ENERGI TERBARUKAN (“Kami”). ') +
                getTranslate('Dengan mengunjungi dan/atau menggunakan platform indonesiagreen.com, maka baik pengunjung maupun pengguna (“Anda”) dinyatakan telah memahami dan menyepakati semua isi dalam syarat dan ketentuan di bawah ini.') +
                getTranslate('Apabila Anda sebagai pengguna situs tidak menyetujui salah satu,sebagian atau seluruh isi syarat dan ketentuan ini, maka Anda tidak diperkenankan untuk menggunakan layanan di situs kami.') +
                getTranslate('Kami menyediakan layanan Penggalangan Dana dalam jaringan (online crowdfunding) kepada pengguna baik melalui web maupun aplikasi mobile (“Platform”) berdasarkan … Kepada Yayasan Kreasi Energi Terbarukan untuk berbagai bentuk Penggalangan Dana.') +
                getTranslate('Kami berhak atas kebijakan untuk mengubah atau memodifikasi sebagian atau keseluruhan dari isi syarat dan ketentuan ini setiap saat, artinya aturan yang berlaku pada halaman ini dapat berubah sewaktu-waktu secara sepihak oleh kami, apabila hal tersebut terjadi maka kami akan mencoba memberikan pemberitahuan kepada seluruh pengguna situs, bisa melalui email, sosial media kami, maupun melalui situs ini secara langsung.') +
                getTranslate('Aturan yang baru akan mulai berlaku setelah pemberitahuan sudah terpublikasikan atau terkirim ke seluruh pengguna situs Indonesia Green (IGE). Kesepakatan di atas tidak berlaku apabila terdapat perubahan karena alasan hukum negara Indonesia, syarat dan ketentuan pada situs ini akan berubah menyesuaikan dengan peraturan pemerintah yang berlaku.</p>') +
                getTranslate('Pengguna situs sangat dianjurkan untuk membaca dengan seksama segala ketentuan di bawah ini karena akan berdampak kepada hak dan kewajiban sebagai pengguna situs ini. Pertanyaan lainnya seputar Indonesia Green (IGE) dapat dibaca di halaman FAQ atau anda menanyakan langsung kepada kami di support@indonesiagreen.com.'),
            text3: getTranslate('<p style="font-size: 24px; font-weight: bold;">Ketentuan Umum</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">A. Definisi</p><br />') +

                getTranslate('<p>Dalam Syarat dan Ketentuan ini yang dimaksud dengan:</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.1 Akun</a> adalah suatu pengaturan (arrangement) antara Penyedia Platform dengan Pengunjung Platform atau Pengguna Platform berdasarkan mana Pengunjung Platfrom atau Pengguna Platform diberikan akses oleh Penyedia Platform terhadap fitur-fitur Platform setelah melakukan pendaftaran data pribadi, nama pengguna (username) dan kata sandi (password).</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.2 BANI</a> adalah singkatan dari Badan Arbitrase Nasional Indonesia, suatu lembaga arbitrase yang berbentuk perkumpulan berbadan hukum</p>') +
                getTranslate('<p>melalui akta No.23 tanggal 14 Juni 2016, yang dibuat di hadapan Ny.Hj.Devi Kantini Rolaswati, SH, M.Kn, Notari di Jakarta,yang telah mendapat persetujuan dari Menteri Kehakiman dan Hak Asasi Manusia Republik Indonesia dengan surat keputusan No.AHU-0064837.AH.01.07.Tahun 2016, tanggal 20 Juni 2016, berikut perubahannya.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.3 Beneficiary</a> adalah:</p>') +
                getTranslate('<p>i.  Pihak yang merangkap sebagai Project Owner dan terdaftar dalam Platform; atau</p>') +
                getTranslate('<p>ii. Pihak lain selain Project Owner yang adalah individu, kelompok, badan usaha atau badan hukum, yang menerima manfaat (beneficiary) atas Dana dari suatu Project.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.4 Dana</a> adalah sumbangan atau donasi (donation) yang dinyatakan dalam mata uang Rupiah.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.5 Project</a> adalah suatu usaha Penggalangan Dana untuk maksud dan tujuan tertentu, termasuk, namun tidak terbatas pada, kategori bantuan energi terbarukan yang berdampak kepada kebutuhan masyarakat, baik dengan maupun tanpa janjian imbalan (reward).</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.6 Random Project</a> adalah Project yang dipilih secara acak (random Project) berdasarkan diskresi penuh Penyedia Platform untuk menerima hasil Penggalangan Dana suatu Project lainnya dalam keadaan-keadaan tertentu.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.7 Project Owner</a> adalah individu, kelompok, badan usaha atau badan hukum yang menggunakan fasilitas Penggalangan Dana pada Platform untuk sebuah Project tertentu dan bertanggung jawab atas Pelaksanaan Project yang bersangkutan.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.8 Donatur</a> adalah individu, kelompok, badan usaha maupun badan hukum yang melakukan pendaftaran ke Platform untuk mendukung Project dengan menyalurkan Dana.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">1.9 Fundraiser</a> adalah seseorang atau organisasi yang menyatakan dukungan terhadap suatu Project yang dibuat oleh Project Owner dalam bentuk pembuatan halaman Project baru yang terhubung dengan Project utama. Dana yang masuk dari halaman fundraiser akan masuk di Project utama.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.0 Konten</a> adalah segala jenis materi dan/atau muatan yang dibuat dan/atau diunggah ke dalam Platform secara mandiri oleh Pengguna Platform (user generated content) dan bukan oleh Penyedia Platform.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.1 Konten Yang Dilarang</a> adalah segala jenis materi dan/atau muatan yang melanggar ketentuan peraturan perundang-undangan.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.2 Laporan</a> adalah pemberitahuan yang disampaikan oleh Pengunjung Platform dan/atau Pengguna Platform kepada Penyedia Platform tentang telah atau sedang atau diduga akan terjadinya peristiwa pelanggaran Syarat dan Ketentuan.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.3 Mitra Pencairan Dana</a> adalah bank yang bekerjasama dengan Penyedia Platform dalam rangka memfasilitasi Pencairan Dana.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.4 Pelaksanaan Project</a> adalah tahap perwujudan atau realisasi dari maksud dan tujuan sebuah Project dengan menggunakan hasil Penggalangan Dana yang bersangkutan secara bertanggung jawab.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.5 Pencairan Dana</a> adalah tindakan Penyedia Platform mengalihkan (transfer) Dana yang terkumpul dalam:</p>') +
                getTranslate('<p>i. Rekening atas nama Donatur, kepada rekening asal Donatur; atau</p>') +
                getTranslate('<p>ii. Rekening atas nama Project Owner atau Penerima Manfaat, ke rekening tujuan yang telah ditunjuk Project Owner atau Penerima Manfaat pada saat pendaftaran yang dilakukan atas permohonan (request) Donatur, Project Owner atau Penerima Manfaat.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.6 Penggalangan Dana</a> adalah proses pengumpulan Dana dari masyarakat dalam rangka pembangunan proyek di bidang energi terbarukan berlandaskan sosial.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.7 Pengguna Platform</a> terdiri dari Project Owner, Project Owner dan/atau Beneficiary, baik secara langsung maupun tidak langsung, sebagaimana berlaku.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.8 Pengunjung Platform</a> adalah pihak yang mengakses, membuka, dan memperoleh informasi dari Platform.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">2.9 Platform</a> adalah wadah berupa aplikasi, situs internet dan/atau layanan lainnya berbasis internet yang digunakan untuk transaksi dan/atau fasilitasi Penggalangan Dana melalui sistem elektronik yang dikelola dan disediakan oleh PT KREASI ENERGI TERBARUKAN dan/atau YAYASAN KREASI ENERGI TERBARUKAN, termasuk, namun tidak terbatas pada situs indonesiagreen.com.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.0 Pengaduan</a> adalah Laporan yang disertai permintaan kepada Penyedia Platform untuk memeriksa Pengguna Platform yang telah atau sedang atau diduga melakukan pelanggaran Syarat dan Ketentuan.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.1 Penyedia Platform</a> adalah PT KREASI ENERGI TERBARUKAN dan YAYASAN KREASI ENERGI TERBARUKAN yang menyelenggarakan program Penggalangan Dana dalam jaringan (online crowdfunding) berdasarkan … tentang … Kepada Yayasan Kreasi Energi Terbarukan Di Jakarta Selatan tanggal ….</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.2 Syarat dan Ketentuan</a> adalah syarat dan ketentuan penggunaan Platform yang ditetapkan dan dapat diubah sewaktu-waktu oleh Penyedia Platform serta mengikat bagi Pengunjung Platform dan/atau Pengguna Platform.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.3 Update</a> adalah fitur yang terdapat pada halaman Project yang difungsikan untuk setiap Project Owner agar dapat memberikan pemberitahuan kepada seluruh donatur melalui email secara otomatis mengenai keadaan terbaru Project, penggunaan dana maupun hal lainnya.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.4 Verifikasi</a> adalah tindakan pemeriksaan kebenaran dan kelengkapan yang dilakukan oleh Penyedia Platform terhadap Akun, Konten dan/atau Pencairan Dana yang didaftarkan, diunggah dan/atau dimohonkan oleh Pengguna Platform, atau untuk keperluan lainnya berdasarkan diskresi penuh Penyedia Platform.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.5 Mitra Konsultan</a> adalah pihak ketiga yang mendampingi konsep maupun perencanaan dari proyek.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.6 Kontraktor</a> adalah pihak ketiga yang merealisasikan proyek yang diusulkan dan bertanggungjawab secara teknis, sumber daya, dan konsep.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">3.7 Project Management Team (PMT)</a> adalah kelompok pendamping proyek yang ditunjuk oleh IGE untuk melakukan verifikasi, analisa, investigasi dan evaluasi terhadap proyek-proyek yang sedang dijalankan oleh project owner.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">B. Biaya Operasional dan Biaya Tambahan</p><br />') +

                getTranslate('<p>1.	Dana yang diperoleh dari Project melalui Platform akan dikenakan biaya administrasi:</p>') +
                getTranslate('<p>i. Oleh Penyedia Platform sebesar 10% (sepuluh persen); </p>') +
                getTranslate('<p>ii. Oleh Mitra Pencairan Dana, yang besarannya masing-masing dapat dilihat dengan klik di sini; </p>') +
                getTranslate('<p>iii. Oleh Mitra Konsultan atau Kontraktor proyek;</p>') +
                getTranslate('<p>2.	Dalam hal terdapat pajak, retribusi dan/atau pungutan lainnya yang dikenakan oleh peraturan perundang-undangan yang berlaku terhadap Dana yang diperoleh dari Project, maka Penyedia Platform akan menetapkan biaya tambahan sesuai dengan besaran sebagaimana ditentukan peraturan perundang-undangan yang bersangkutan. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">C. Pengungkapan Secara Sukarela</p><br />') +

                getTranslate('<p>Segala pengungkapan (disclosure) oleh Pengunjung Platform atau  Pengguna Platform dalam bentuk pertanyaan, pernyataan, komentar,ide, kritik, saran atau informasi pada Platform, yang bukan merupakan pengungkapan yang disyaratkan atau diminta oleh Penyedia Platform, adalah pengungkapan yang dibuat secara sukarela (voluntary disclosure) dan sepenuhnya merupakan tanggung jawab dari Pengunjung Platform atau Pengguna Platform yang bersangkutan.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">D. Posisi Indonesia Green (IGE)</p><br />') +

                getTranslate('<p>indonesiagreen.com Bukanlah Broker/Lembaga Penyalur Amal/Lembaga Keuangan/Kreditor/Investasi. </p>') +
                getTranslate('<p>indonesiagreen.com merupakan platform untuk memfasilitasi transaksi donasi antara Project Owner dan donatur. Indonesia Green (IGE) tidak bertanggung jawab atas pelaksanaan, atau informasi yang disediakan oleh Project Owner, donatur, beneficiary atau pengguna lainnya. Indonesia Green (IGE) dengan ini melepaskan semua tanggung jawab dalam hal tersebut selama diizinkan oleh hukum yang berlaku di Indonesia.</p>') +
                getTranslate('<p>Semua informasi di dalam konten Project yang disediakan oleh Indonesia Green (IGE) merupakan bagian dari pemberitahuan, kami tidak menjamin keakuratan, kelengkapan, ketepatan waktu atau kebenaran dari konten yang dibuat oleh Project Owner.</p>') +
                getTranslate('<p>Maka, Anda mengetahui bahwa informasi dan konten yang ada pada halaman Project dalam situs kami merupakan risiko Anda sendiri.</p>') +
                getTranslate('<p>Indonesia Green (IGE) tidak menjamin bahwa setiap Project yang terdapat pada situs kami akan mendapatkan sejumlah donasi tertentu atau akan terpenuhi. Indonesia Green (IGE) secara tersirat maupun tersurat tidak selalu mendukung penyelenggaraan sebuah Project, kecuali terdapat perjanjian tertulis terlebih dahulu. Kami dengan tegas menolak kewajiban atau tanggung jawab atas kegagalan setiap Project atau total donasi yang Project Owner tetapkan tidak terpenuhi.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">E. Laporan dan Pengaduan</p><br />') +

                getTranslate('<p>1.1 Pengunjung Platform dan Pengguna Platform berhak untuk mengajukan Laporan dan/atau Pengaduan kepada Penyedia Platform mengenai dugaan dan/atau peristiwa pelanggaran Syarat dan Ketentuan ini, termasuk, namun tidak terbatas pada:</p><br />') +

                getTranslate('<p>i. Pengunjung Platform atau Pengguna Platform yang memasukkan data dan informasi yang tidak lengkap, tidak benar, menyesatkan dan/atau merupakan pemalsuan;</p>') +
                getTranslate('<p>ii. Pengunjung Platform atau Pengguna Platform yang memasukkan dan mengunggah Konten Yang Dilarang;</p>') +
                getTranslate('<p>iii. Project Owner yang menyalahgunakan Dana yang berasal dari Project; dan/atau</p>') +
                getTranslate('<p>iv. Project Owner yang tidak memenuhi atau hanya memenuhi sebagian dari Pelaksanaan Project, atau memenuhi Pelaksanaan Project tetapi tidak sesuai dengan yang dijanjikan oleh Project Owner melalui Platform.</p><br />') +

                getTranslate('<p>1.2  Konten Yang Dilarang sebagaimana disebut dalam huruf a butir ii di atas termasuk, namun tidak terbatas pada:</p>') +
                getTranslate('<p>i.   Konten negatif (pornografi, perjudian, kekerasan);</p>') +
                getTranslate('<p>ii.  Konten dengan materi kebencian;</p>') +
                getTranslate('<p>iii. Konten gambar grafis yang menimbulkan kengerian;</p>') +
                getTranslate('<p>iv. Konten yang melanggar hak kekayaan intelektual;</p>') +
                getTranslate('<p>v.  Konten jasa peretasan (hacking dan/atau cracking) dan/atau menyediakan akses tanpa hak atau melawan hukum atas sistem elektronik</p>') +
                getTranslate('<p>vi. Konten penyediaan dan/atau akses terhadap narkoba, zat adiktif, dan psikotropika</p>') +
                getTranslate('<p>vii. Konten dengan materi ketidakjujuran, kecurangan atau menyesatkan;</p>') +
                getTranslate('<p>viii.Konten yang mendukung perdagangan manusia (human trafficking); dan/atau</p>') +
                getTranslate('<p>ix. Konten penyediaan dan/atau akses terhadap rokok</p>') +
                getTranslate('<p>x.  Konten dengan muatan Politik Praktis, yaitu konten pada setiap Project yang mengandung muatan ajakan untuk memilih individu atau partai politik tertentu, baik yang dilakukan oleh individu maupun badan hukum lainnya. </p><br />') +

                getTranslate('<p>1.3 Laporan dan Pengaduan diajukan oleh Pengunjung Platform atau Pengguna Platform kepada Penyedia Platform dengan cara mengisi</p>') +
                getTranslate('<p>formulir laporan pada halaman Project dari Project Owner yang bersangkutan.</p><br />') +

                getTranslate('<p>1.4 Dengan mengajukan Laporan dan Pengaduan, Pengunjung Platform atau Pengguna Platform menyatakan sepakat dan bersedia untuk dipanggil sebagai saksi untuk dimintakan keterangannya dalam rangka pemeriksaan, termasuk, namun tidak terbatas pada, menghadap ke Penyedia Platform, instansi terkait, aparat penegak hukum, dan/atau pengadilan.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">F. Penonaktifan, Pemutusan dan Penghapusan Akun</p><br />') +

                getTranslate('<p>1.1 Pengunjung Platform atau Pengguna Platform berhak untuk mengajukan permohonan penonaktifan dan/atau pemutusan dan penghapusan Akun yang terdaftar atas namanya pada Platform kepada Penyedia Platform dengan mengirim email ke <a style="font-weight: bold;">support@indonesiagreen.com</a> disertai alasan-alasannya.</p><br />') +

                getTranslate('<p>1.2 Penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengunjung Platform atau Pengguna Platform dilakukan dengan memperhatikan kewajiban Penyedia Platform untuk melakukan penyimpanan data pribadi sesuai batas waktu yang ditentukan dalam</p>') +
                getTranslate('<p>peraturan perundang-undangan yang berlaku sejak tanggal penonaktifan dan/atau pemutusan dan penghapusan Akun tersebut. </p><br />') +

                getTranslate('<p>1.3 Penyedia Platform berhak untuk melakukan penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengunjung Platform atau Pengguna Platform apabila terbukti melanggar Syarat dan Ketentuan ini.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">G. Batasan Penyimpanan Data</p><br />') +

                getTranslate('<p>Anda mengetahui bahwa situs memiliki batasan dalam penyediaan layanan kepada seluruh pengguna situs, termasuk batasan jangka waktu data atau konten lainnya yang disimpan oleh server Indonesia Green (IGE) atas nama Anda. Karena server kami memiliki kapasitas maksimal untuk menyimpan data seluruh pengguna. Maka dari alasan tersebut, Anda setuju dan memahami bahwa kami berhak untuk mengakhiri akun atau Project atas nama Anda yang sudah tidak aktif dalam jangka waktu tertentu, sebelum melakukan hal tersebut kami akan berusaha memberikan pemberitahuan terlebih dahulu kepada Anda via email. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">H. Syarat Pengguna Situs</p><br />') +

                getTranslate('<p>1.1 Berusia 17 tahun atau di antara usia 13 sampai 16 disertai pengawasan orang tua;</p><br />') +

                getTranslate('<p>1.2 Pengunjung situs ini wajib menyatakan diri sebagai seseorang yang cakap di mata hukum sehingga dapat bertanggung jawab atas segala tindakan ataupun kelalaian apabila melanggar syarat dan ketentuan ini;</p><br />') +

                getTranslate('<p>1.3 Tidak diperkenankan bagi pengunjung situs untuk melakukan tindakan yang dapat melanggar ketentuan privasi seperti yang diatur dalam kebijakan privasi pada situs ini. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">I. Notifikasi</p><br />') +

                getTranslate('<p>Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging) yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik tersebut. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">J. Konten Publik</p><br />') +

                getTranslate('<p>Anda mengetahui bahwa setiap informasi yang Anda tampilkan pada konten atau layanan kami, dapat diakses oleh publik, seperti nama, nomer telepon, email dan sosial media. Hal tersebut kami lakukan agar terdapat keterbukaan informasi bagi pengguna situs agar tidak dapat menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">Ketentuan Penggalangan Dana (Fundraising)</p><br />') +

                getTranslate('<p>1.1 Indonesia Green (IGE) tidak memberikan ganti rugi dalam bentuk apapun atas penggalangan dana yang dilakukan pada situs kami.</p><br />') +

                getTranslate('<p>1.2 Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa terdapat Project yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan Indonesia Green (IGE) untuk melaporkan kepada kami dan ikut serta membantu menyelesaikan perkara tersebut.</p><br />') +

                getTranslate('<p>1.3 Terdapat beberapa contoh jenis fundraising yang wajib dipahami oleh Anda:</p>') +
                getTranslate('<p>i. Kasus A: Apabila jumlah donasi tercapai dan waktu donasi selesai, maka project owner dapat mencairkan donasi.</p>') +
                getTranslate('<p>ii. Kasus B: Apabila jumlah donasi tidak tercapai namun waktu donasi selesai, maka IGE koordinasi dengan project owner dengan pilihan melanjutkan proyek dengan dana yang difundraise atau menambah waktu fundraising.</p>') +
                getTranslate('<p>iii. Kasus C: Apabila jumlah donasi tercapai dan waktu donasi belum selesai, maka fundraising dianggap selesai atau menunggu hingga waktu donasi selesai.</p>') +
                getTranslate('<p>iv. Kasus D: Apabila jumlah donasi tidak tercapai dan waktu donasi belum selesai, maka fundraising dilanjutkan hingga waktu selesai.</p>') +
                getTranslate('<p>v. Kasus E: Apabila jumlah donasi tercapai dan waktu donasi selesai, namun donatur ingin donasi ke proyek tersebut, maka donasi akan tetap disalurkan ke proyek tersebut.</p><br />') +

                getTranslate('<p>1.4 Fundraising yang tidak mencapai kesepakatan waktu atau jumlah yang disepakati, maka donasi akan masuk ke kas IGE.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">Ketentuan Untuk Project Owner</p><br />') +

                getTranslate('<p>Peringatan : Anda setuju bahwa Indonesia Green (IGE) tidak bertanggung jawab atau berkewajiban atas penghapusan atau kegagalan Anda dalam menyimpan data atau konten pada akun atau Project Anda. Selain itu, Anda berkewajiban menjaga kerahasiaan sandi serta keamanan akun Anda sendiri. Kami menyarankan Anda untuk senantiasa menjaga kerahasiaan akun Anda dari pihak lain, memastikan bahwa Anda selalu ‘log out’ setiap selesai menggunakan layanan pada situs Indonesia Green (IGE). Apabila di kemudian hari Anda mendapati bahwa akun Anda disalahgunakan atau diakses oleh pihak lain tanpa persetujuan Anda, segera hubungi kami melalui email:support@indonesiagreen.com agar kami dapat memproses akun Anda.</p>') +
                getTranslate('<p>Melalui persetujuan Anda kami dapat melakukan beberapa tindakan untuk melakukan pencegahan penyalahgunaan akun seperti : pembekuan sementara akun, penghapusan akun atau pemblokiran akun. Kami tidak bertanggung jawab untuk setiap kerugian atau kerusakan yang diakibatkan dari kegagalan Anda untuk mematuhi aturan pada bagian ini.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">A. Syarat Menjadi Project Owner</p><br />') +

                getTranslate('<p>1.1 Wajib memiliki badan Hukum, adalah merupakan organisasi/komunitas/yayasan yang memiliki (Nomor Pokok Wajib Pajak) NPWP dan Akta Notaris (Bukti Hukum) tentang pendirian organisasi/komunitas/yayasan yang bersangkutan beserta KTP perseorangan yang mewakilkan berbadan hukum tersebut yang terdaftar di dalam platform;</p><br />') +

                getTranslate('<p>1.2 Bersedia melakukan serangkaian proses verifikasi akun dan kelengkapan lainnya apabila diperlukan;</p><br />') +

                getTranslate('<p>1.3 Project Owner menjamin bahwa setiap informasi yang diberikan kepada situs Indonesia Green (IGE) merupakan data yang benar dan dapat dipertanggung jawabkan.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">B. Kewajiban Project Owner</p><br />') +

                getTranslate('<p>Project Owner wajib untuk:</p><br />') +

                getTranslate('<p>1.1 Menjamin dan menyatakan bahwa segala informasi yang dimasukkan dan diunggah oleh Project Owner ke Platform, termasuk, namun tidak terbatas pada:</p>') +
                getTranslate('<p>i.   Hubungan antara Project Owner dengan Penerima Manfaat;</p>') +
                getTranslate('<p>ii.  Status sebagai bukan pihak yang sedang terlibat dalam proses hukum pidana maupun perdata; dan</p>') +
                getTranslate('<p>iii. Status sebagai bukan penyelenggara negara sebagaimana dimaksud dalam Undang-Undang Nomor 28 Tahun 1999 tentang Penyelenggaraan Negara yang Bersih dan Bebas Dari Korupsi, adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;</p><br />') +

                getTranslate('<p>1.2 Menjamin dan menyatakan bahwa semua Konten yang dimasukkan dan diunggah oleh Project Owner ke Platform, tidak memuat Konten Yang Dilarang;</p><br />') +

                getTranslate('<p>1.3 Menunjuk 1 (satu) rekening bank dari salah satu Mitra Pencairan Dana sebagai rekening tujuan dalam rangka Pencairan Dana yang sifatnya tidak dapat diubah, kecuali dalam keadaan darurat, dimana Project Owner dapat mengajukan permohonan perubahan data kepada Penyedia Platform dengan mengirim email ke support@indonesiagreen.com disertai alasan-alasannya;</p><br />') +

                getTranslate('<p>1.4 Dalam hal tujuan Project berbentuk proyek:</p>') +
                getTranslate('<p>i.   Menyatakan bahwa proyek tersebut merupakan proyek yang tidak melanggar perundang-undangan yang berlaku;</p>') +
                getTranslate('<p>ii.  Menyatakan bahwa telah memperoleh izin tertentu, termasuk apabila bersumber dari pihak lain, sebagaimana diatur dalam peraturan perundang-undangan yang berlaku;</p>') +
                getTranslate('<p>iii. Menyatakan bahwa proyek tersebut bukan bertujuan untuk komersial;</p>') +
                getTranslate('<p>iv. Menyiapkan segala perencanaan yang sesuai, termasuk dengan standar kualitas dan keahlian sumber daya manusia yang sesuai dengan proyek yang diusulkan; dan</p><br />') +

                getTranslate('<p>1.5 Sepakat dan bersedia untuk membebaskan Penyedia Platform dari setiap gugatan maupun tuntutan hukum, dan untuk mengganti segala kerugian yang mungkin dialami oleh Penyedia Platform di kemudian hari atas, namun tidak terbatas pada, hal-hal berikut:</p>') +
                getTranslate('<p>i.Pelaksanaan Project yang tidak dipenuhi, dipenuhi sebagian atau yang dipenuhi tetapi tidak sesuai dengan yang dijanjikan oleh Project Owner melalui Platform;</p>') +
                getTranslate('<p>ii.Penggelapan dan/atau penyalahgunaan Dana oleh Project Owner; dan</p>') +
                getTranslate('<p>iii.Segala perbuatan melanggar hukum lainnya yang terjadi baik pada saat, selama maupun setelah masa Project dan/atau Pelaksanaan Project. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">C. Larangan Pencantuman Rekening Pribadi Maupun Pihak Lain</p><br />') +

                getTranslate('<p>1.1 Project Owner dilarang mencantumkan rekening pribadi dari Project Owner pada halaman Project di Platform. </p><br />') +

                getTranslate('<p>1.2 Dalam hal Project Owner menerima donasi luar jaringan dari Donatur, dan bukan melalui Dompet pada Platform, maka Project Owner sepenuhnya bertanggung jawab atas hubungan hukum yang timbul antara Project Owner dan Donatur yang bersangkutan.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">D. Kerjasama Khusus</p><br />') +

                getTranslate('<p>1.1 Project Owner dapat mengadakan kerjasama khusus dengan Penyedia Platform untuk Project tertentu yang dituangkan dalam perjanjian terpisah yang mengatur hak dan kewajiban para pihak.</p><br />') +

                getTranslate('<p>1.2 Penyedia Platform memiliki diskresi penuh untuk menyepakati atau menolak kerjasama khusus yang diusulkan oleh Project Owner.</p><br />') +

                getTranslate('<p>1.3 Anda dapat menghubungi kami melalui support@indonesiagreen.com untuk mendapatkan informasi lebih lanjut.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">E. Pelaksanaan Project</p><br />') +

                getTranslate('<p>Dalam Pelaksanaan Project, Project Owner berkewajiban untuk:</p><br />') +

                getTranslate('<p>1.1 Melaksanakan apa yang telah dijanjikan dalam Project melalui Platform, termasuk memenuhi secara tuntas imbalan kepada Donatur dalam hal suatu imbalan telah dijanjikan;</p><br />') +

                getTranslate('<p>1.2 Memberikan laporan Pelaksanaan Project yang transparan, kredibel dan dilengkapi dengan dokumentasi dan bukti pendukung yang layak kepada Donatur dan Pengelola Platform melalui Platform;</p><br />') +

                getTranslate('<p>1.3 Dalam hal ada perjanjian kerjasama khusus antara Project Owner dengan Penyedia Platform, menjalankan kewajibannya sebagaimana dimuat pada perjanjian kerjasama khusus tersebut; </p><br />') +

                getTranslate('<p>1.4 Bertanggungjawab untuk mengkalkulasi dan memproyeksi dampak dari proyek yang telah berjalan; </p><br />') +

                getTranslate('<p>1.5 Implementasi skema pembiayaan perawatan mandiri proyek terhadap beneficiary; dan</p><br />') +

                getTranslate('<p>1.6 Apabila proyek yang telah berjalan namun dengan suatu hal tertentu proyek diputuskan untuk tidak dilanjutkan oleh project owner, maka donasi yang telah disalurkan kepada project owner wajib dikembalikan sebesar 100%.</p><br />') +

                getTranslate('<p>1.7 Dalam hal Pelaksanaan Project tidak dapat dipenuhi sesuai dengan yang telah dijanjikan dan/atau tidak dapat dipenuhi sama sekali karena suatu keadaan memaksa, segera mengajukan laporan kepada Penyedia Platform dengan mengirim email ke support@indonesiagreen.com untuk ditindaklanjuti. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">F. Penunjukkan dan Pemberian Kuasa Kepada Penyedia Platform</p><br />') +

                getTranslate('<p>Project Owner setuju dan sepakat untuk menunjuk dan memberi kuasa kepada Penyedia Platform untuk bertindak untuk dan atas nama Project Owner untuk melakukan, antara lain, hal-hal berikut:</p><br />') +

                getTranslate('<p>1.1 Menjadi perantara eksklusif antara Project Owner dengan Donatur dan/atau penyebar informasi dari Project yang dilakukan Project Owner;</p><br />') +

                getTranslate('<p>1.2 Menyimpan dan mengelola Dana dari Donatur yang telah masuk rekening atas nama Project Owner;</p><br />') +

                getTranslate('<p>1.3 Dalam hal terdapat perjanjian kerjasama khusus antara Project Owner dengan Penyedia Platform, maka Project Owner dan Penyedia Platform akan bertindak sebagaimana yang telah diperjanjikan dalam perjanjian khusus tersebut; dan</p><br />') +

                getTranslate('<p>1.4 Segala hal yang diperlukan untuk pelaksanaan kuasa di atas, termasuk, namun tidak terbatas pada, menandatangani setiap dokumen yang diperlukan, melakukan surat-menyurat dengan dan menghadap kepada instansi yang berwenang, notaris dan/atau pejabat publik lainnya.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">G. Penolakan dan Penundaan Verifikasi</p><br />') +

                getTranslate('<p>Penyedia Platform memiliki kewenangan penuh untuk menolak dan/atau menunda melakukan Verifikasi terhadap Akun dan/atau Pencairan Dana apabila Project Owner:</p><br />') +

                getTranslate('<p>1.1 Belum menyampaikan data-data yang cukup sehubungan dengan pendaftaran Akun dan/atau Pencairan Dana kepada Penyedia Platform; atau</p><br />') +

                getTranslate('<p>1.2 Diduga telah melanggar atau berpotensi melanggar Syarat dan Ketentuan.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">H. Penutupan Project</p><br />') +

                getTranslate('<p>1.1 Penyedia Platform memiliki kewenangan penuh untuk menutup Project apabila Project Owner ditemukan telah melanggar Syarat dan Ketentuan.</p><br />') +

                getTranslate('<p>1.2 Dalam hal Penyedia Platform menutup sebuah Project, Dana yang telah masuk ke dalam Dompet dari Project Owner bersangkutan akan disalurkan kepada Project Acak.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">I. Ketentuan Project Owner</p><br />') +

                getTranslate('<p>1.1 Project Owner menjamin dan menyatakan bahwa semua konten yang diberikan kepada situs Indonesia Green (IGE) baik tulisan, foto dan video yang diterangkan dalam deskripsi adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;</p><br />') +

                getTranslate('<p>1.2 Setiap penggalangan Dana merupakan tanggung jawab penuh dari pihak Project Owner dan atau fundraiser;</p><br />') +

                getTranslate('<p>1.3 Penggalangan Dana dilarang untuk dilakukan apabila mengandung unsur: </p>') +
                getTranslate('<p>i.   Pornografi, konten dewasa dan mengandung unsur seksualitas;</p>') +
                getTranslate('<p>ii.  Konten yang menurut kami, teridentifikasi mendukung kegiatan terorisme, menebar kebencian, kekerasan, pelecehan, bullying, diskriminasi, atau intoleransi berkaitan dengan ras, etnis, afiliasi agama atau kegiatan yang akan menurut kami akan menimbulkan perpecahan di kemudian hari;</p>') +
                getTranslate('<p>iii. Konten atau Project yang palsu, menyesatkan, tidak akurat, tidak jujur, Project dengan target yang tidak realistis dan Project yang meniru (imitation) Project pihak lain di Indonesia Green (IGE) maupun penggalangan Dana di luar situs Indonesia Green (IGE) tanpa persetujuan pihak lain tersebut;</p>') +
                getTranslate('<p>iv. Konten berkaitan dengan benda tajam dan berbahaya seperti: pisau, bahan peledak, amunisi, senjata api dan lain lain;</p>') +
                getTranslate('<p>v.  Perdagangan manusia, ekploitasi manusia dan suap;</p>') +
                getTranslate('<p>vi. Terdapat musik, film, perangkat lunak atau materi lain di dalam konten Project yang tidak berlisensi atau berizin resmi dari pemegang hak;</p>') +
                getTranslate('<p>vii. Berkaitan dengan narkotika dan zat berbahaya lainnya;</p>') +
                getTranslate('<p>viii.Berkaitan dengan perjudian, pertaruhan, togel, lotere atau undian;</p>') +
                getTranslate('<p>ix.  Kegiatan yang dapat merusak lingkungan dan habibat alam;</p>') +
                getTranslate('<p>x.   Mengumpulkan atau menggalang Dana untuk tujuan apapun selain seperti yang dijelaskan dalam deskripsi Project;</p>') +
                getTranslate('<p>xi.  Konten atau aktivitas yang menurut Indonesia Green (IGE) tidak dapat diterima.</p><br />') +

                getTranslate('<p>1.4 Dalam hal tujuan Project berbentuk proyek, maka Project Owner menyatakan bahwa proyek tersebut merupakan proyek yang tidak melanggar perundang-undangan yang berlaku; Menyatakan bahwa telah memperoleh izin tertentu, termasuk apabila bersumber dari pihak lain, sebagaimana diatur dalam peraturan perundang-undangan yang berlaku; Menyatakan bahwa proyek tersebut bukan bertujuan untuk komersial; Menyiapkan segala perencanaan yang sesuai, termasuk dengan standar kualitas dan keahlian sumber daya manusia yang sesuai dengan proyek yang diusulkan</p>') +
                getTranslate('<p>.</p>') +
                getTranslate('<p>1.5 Indonesia Green (IGE) tidak bertanggung jawab atas penyalahgunaan, penggunaan tanpa izin, pelanggaran Hak Kekayaan Intelektual yang dilakukan oleh Project Owner;</p><br />') +

                getTranslate('<p>1.6 Project Owner dilarang mencantumkan nomor rekening pribadi atau lainnya dalam Project dengan alasan apapun, Indonesia Green (IGE) berhak untuk menghapus nomor rekening tersebut apabila ketentuan ini dilanggar;</p><br />') +

                getTranslate('<p>1.7 Indonesia Green (IGE) berwenang melakukan sensor/menghapus gambar yang kami anggap mengganggu atau tidak pantas untuk diperlihatkan kepada publik;</p><br />') +

                getTranslate('<p>1.8 Donasi yang diterima dapat dicairkan setelah akun dan rekening bank terverifikasi dalam waktu paling cepat 3x24 jam sejak donasi diterima, apabila ada keterlambatan, silakan hubungi support@indonesiagreen.com;</p><br />') +

                getTranslate('<p>1.9 Setiap Dana yang masuk ke rekening merupakan donasi bersih yang telah dipotong biaya administrasi;</p><br />') +

                getTranslate('<p>2.0 Terkhusus untuk donatur yang melakukan donasi menggunakan Credit Card, nominal donasi akan terpotong oleh biaya administrasi dan biaya potongan dari Credit Card secara otomatis;</p><br />') +

                getTranslate('<p>2.1 Donasi yang terdapat pada Project Owner dapat dicairkan dengan klik “Pencairan Dana” pada menu yang terdapat di dashboard;</p><br />') +

                getTranslate('<p>2.2 Project Owner bertanggung jawab atas apa yang telah ditulis dan dijanjikan pada halaman Project;</p><br />') +

                getTranslate('<p>2.3 Setiap proses pencairan Dana akan dikenakan biaya administrasi dari mitra pencairan (disbursement) Indonesia Green (IGE). Detail biaya tersebut dapat dilihat dengan klik di sini;</p><br />') +

                getTranslate('<p>2.4 Bahwa apabila terdapat pajak dan/atau retribusi dan/atau pungutan legal lain terhadap donasi yang diberikan, maka Indonesia Green (IGE) dapat menetapkan biaya tambahan pada setiap Project sesuai dengan besaran yang berlaku;</p><br />') +

                getTranslate('<p>2.5 Project Owner berkewajiban untuk memenuhi imbalan yang telah dijanjikan kepada donatur;</p><br />') +

                getTranslate('<p>2.6 Project Owner berkewajiban untuk memberikan laporan pelaksanaan atau perkembangan Project secara transparan melalui fitur “Update” pada situs Indonesia Green (IGE) untuk diketahui oleh publik dan donatur pada setiap halaman Project yang dibuat;</p><br />') +

                getTranslate('<p>2.7 Apabila anda sebagai Project Owner tidak/belum melakukan update pada halaman Project setelah melakukan pencairan pertama, maka kami berhak untuk menahan pencairan Anda sampai klausul (kesepakatan) dari kami terpenuhi;</p><br />') +

                getTranslate('<p>2.8 Project Owner wajib memberikan keterangan atau laporan melalui situs Indonesia Green (IGE) apabila pelaksanaan Project tidak sesuai dengan rencana dan/atau apabila terdapat reward/imbalan yang tidak dapat dipenuhi kepada Donatur;</p><br />') +

                getTranslate('<p>2.9 Apabila terdapat perjanjian kerjasama khusus antara Project Owner dengan Indonesia Green (IGE), maka kedua belah pihak wajib menjalankan kewajibannya sebagaimana yang telah tertera pada perjanjian kerjasama tersebut;</p><br />') +

                getTranslate('<p>3.0 Project Owner bertanggung jawab sepenuhnya terhadap pelaksanaan Project, penggunaan Dana donasi, dan hal-hal lain yang terkait dengan Project miliknya. Oleh karena itu, Project Owner menyatakan dan bersedia mengganti segala kerugian yang dialami oleh Indonesia Green (IGE) apabila hal tersebut terjadi di kemudian hari, termasuk membebaskan Indonesia Green (IGE) dari setiap tuntutan hukum yang timbul pada permasalahan yang disebabkan dan diakibatkan oleh Project Owner.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">Verifikasi Akun Project Owner</p><br />') +

                getTranslate('<p>Setiap Project Owner harus melakukan verifikasi akun untuk melakukan pencairan Dana. Verifikasi akun perlu dilakukan agar kami memiliki data terkait orang/lembaga yang bertanggung jawab atas sebuah Project.  Proses verifikasi akun Project Owner akan kami selesaikan dalam kurun waktu maksimal 2 hari kerja. Proses verifikasi akun hanya dapat dilakukan secara online melalui situs Indonesia Green (IGE).</p>') +
                getTranslate('<p>Pada saat proses verifikasi akun, kami akan meminta beberapa data dan berkas sesuai dengan akun Project Owner, apakah Organisasi/Yayasan. Adapun data dan berkas yang diminta saat proses verifikasi adalah sebagai berikut:</p><br />') +

                getTranslate('<p>a. Akun Organisasi/Yayasan : Scan NPWP atas nama Organisasi/Yayasan, Akta pendirian, kartu identitas pemegang akun, nomor rekening atas nama Organisasi/Yayasan, nomor telepon pemegang akun dan media sosial. Nomor rekening dan NPWP yang dilampirkan harus atas nama yayasan. KTP perseorangan yang mewakilkan berbadan hukum tersebut yang terdaftar di dalam platform. Apabila diperlukan, Indonesia Green (IGE) berhak melakukan proses verifikasi lebih lanjut berupa permintaan berkas pendukung tambahan, wawancara online, kunjungan, dan bentuk verifikasi lain sesuai dengan jenis dan kategori Project. Kami berhak menolak verifikasi akun Anda apabila belum memenuhi standar yang telah ditetapkan Indonesia Green (IGE). </p>') +
                getTranslate('<p><a style="font-weight: bold;">Indonesia Green (IGE) berhak melakukan investigasi terhadap sebuah atau beberapa Project yang dianggap patut untuk ditelusuri lebih lanjut. Maka dari itu, Anda setuju untuk bekerjasama dengan baik serta memberikan informasi dengan benar dan sedetail mungkin.</a></p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">A. Pencairan Dana</p><br />') +

                getTranslate('<p>Anda sebagai Project Owner dapat melakukan pencairan Dana terhadap donasi telah terkumpul pada setiap Project anda kapanpun setelah akun dan rekening Anda terverifikasi. </p>') +
                getTranslate('<p>Pada proses verifikasi rekening Anda cukup melakukannya sekali untuk setiap halaman Project. Namun, hal ini mengakibatkan Anda harus melakukan verifikasi rekening di setiap Project yang Anda buat, apabila Anda memiliki beberapa Project dalam satu akun.</p>') +
                getTranslate('<p>Adapun syarat untuk melakukan verifikasi rekening, yaitu: </p><br />') +

                getTranslate('<p>1.1 Sudah melakukan verifikasi Akun;</p>') +
                getTranslate('<p>1.2 Project sudah memiliki minimal satu donatur.</p><br />') +

                getTranslate('<p>Anda dapat memilih ke mana uang donasi akan dicairkan apabila memenuhi kriteria yang telah kami tentukan, yaitu:</p><br />') +

                getTranslate('<p>i.   Rekening Project Owner sendiri;</p>') +
                getTranslate('<p>ii.  Rekening pihak ketiga. Yang dimaksud dengan pihak ketiga yaitu beneficiary, keluarga beneficiary, atau yayasan penyalur donasi. Pada ketentuan ini, Project Owner harus menyertakan alasan mengalihkan pencairan ke pihak ketiga dan melampirkan scan identitas atas nama yang bersangkutan. Khusus pencairan ke Organisasi/Yayasan,  Project Owner perlu menambahkan lampiran scan NPWP atas nama Organisasi/Yayasan yang bersangkutan.</p>') +
                getTranslate('<p>iii. Rekening mitra penyalur Indonesia Green (IGE). Anda cukup menghubungi kami untuk memproses pilihan ini.  </p><br />') +

                getTranslate('<p>Dana donasi yang terdapat dalam Project dapat dialihkan sewaktu-sewaktu kepada pihak lain dengan melibatkan kesepakatan antara Project Owner dan Indonesia Green (IGE), hal tersebut dapat terjadi apabila:</p>') +
                getTranslate('<p>i.  Beneficiary yang hendak mendapatkan manfaat atau bantuan dari Project telah meninggal dunia;</p>') +
                getTranslate('<p>ii.  Beneficiary yang hendak mendapatkan manfaat atau menolak mendapatkan santunan;</p>') +
                getTranslate('<p>iii. Dana donasi melebihi target yang direncanakan, sebagian Dana donasi yang lebih tersebut dapat diberikan kepada pihak lain;</p>') +
                getTranslate('<p>iv. Project Owner terbukti menggunakan sebagian atau seluruh Dana donasi tanpa persetujuan beneficiary yang bersangkutan;</p>') +
                getTranslate('<p>v.  Status Project Owner sedang menjalani proses hukum dengan pihak yang berwajib;</p>') +
                getTranslate('<p>vi. Kondisi tertentu yang menurut Indonesia Green (IGE) diperlukan pengalihan Dana donasi.</p><br />') +

                getTranslate('<p><a style="font-weight: bold;">Peringatan:</a> Indonesia Green (IGE) berhak menolak verifikasi rekening, menyarankan pilihan verifikasi rekening dan mengubah secara sepihak pilihan rekening yang sudah terverifikasi.</p>') +
                getTranslate('<p>Penolakan, saran, atau perubahan verifikasi rekening menjadi hak prerogatif Indonesia Green (IGE). Perlu kami sampaikan kembali bahwa Anda sebagai Project Owner bertanggung jawab atas amanat yang Anda sampaikan sendiri sesuai dengan yang tertera pada konten Project.</p>') +
                getTranslate('<p>Setelah donasi kami salurkan kepada pihak Project Owner, Anda wajib memberikan laporan penggunaan Dana yang nantinya akan terlihat pada fitur update di situs kami. Kami tidak bertanggung jawab atas segala hal yang terjadi terhadap uang donasi yang telah dicairkan kepada Project Owner. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">B. Kewenangan Indonesia Green (IGE) terhadap Project Owner</p><br />') +

                getTranslate('<p>1.1 Indonesia Green (IGE) berhak untuk melakukan edit/hapus deskripsi Project dan deskripsi update apabila pihak Project Owner:</p>') +
                getTranslate('<p>i.   Mencantumkan no rekening selain daripada atas nama YAYASAN INDONESIA GREEN (IGE);</p>') +
                getTranslate('<p>ii.  Terbukti melakukan pencatutan nama pihak ke 3 tanpa seizin dan sepengetahuan yang bersangkutan baik disadari oleh kami maupun laporan;</p>') +
                getTranslate('<p>iii.  Menurut kami mengandung unsur hate speech kepada pihak ke 3 maupun kepada Indonesia Green (IGE);</p><br />') +

                getTranslate('<p>1.2 Indonesia Green (IGE) berhak untuk mengalihkan pencairan donasi milik akun Project Owner secara sepihak apabila Project Owner:</p>') +
                getTranslate('<p>i. Terbukti melakukan penipuan disertai bukti bukti yang kami dapatkan melalui investigasi maupun laporan dari publik;</p>') +
                getTranslate('<p>ii. Permintaan Project Owner sendiri;</p>') +
                getTranslate('<p>iii. Project Owner telah meninggal dunia.</p><br />') +

                getTranslate('<p>1.3 Indonesia Green (IGE) berhak menolak/menunda verifikasi akun atau membekukan akun dan pencairan donasi apabila pihak Project Owner:</p>') +
                getTranslate('<p>i.  Belum mengirimkan data-data yang diperlukan oleh indonesiagreen.com;</p>') +
                getTranslate('<p>ii. Apabila dana yang terkumpul harus diberikan secara langsung kepada beneficiary sesuai dengan ketentuan yang berlaku;</p>') +
                getTranslate('<p>iii. Apabila kami memiliki alasan dan bukti bahwa Project Owner melanggar ketentuan layanan pada situs kami;</p>') +
                getTranslate('<p>iv. Apabila diperlukan dalam rangka untuk memenuhi perintah pengadilan, surat perintah, keputusan pemerintah atau ketentuan hukum yang berlaku di Indonesia.</p><br />') +

                getTranslate('<p>1.4 Indonesia Green (IGE) berhak untuk melakukan verifikasi, analisa, investigasi, dan evaluasi terhadap proyek yang sedang dijalankan oleh project owner melalui atau tanpa Project Management Team (PMT).</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">Donatur</p>') +
                getTranslate('<p>Semua sumbangan dari donatur merupakan risiko dari donatur itu sendiri. Situs kami tidak menjamin bahwa setiap Project yang berada dalam situs kami bebas dari penipuan dan penyalahgunaan. Apabila di kemudian hari Anda meyakini terdapat konten penipuan dan penyalahgunaan dana atau kecurigaan lainnya pada Project di situs kami, Anda bisa melaporkan hal tersebut dengan cara ‘klik’ tombol laporkan yang tersedia pada setiap Project.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">A. Kewajiban menjadi Donatur </p><br />') +

                getTranslate('<p>Donatur wajib: </p><br />') +

                getTranslate('<p>1.1 Bertanggung jawab penuh atas donasi yang diberikan, untuk itu donatur wajib mencermati segala informasi mengenai ide dan/atau Project penggalangan Dana yang dimuat di dalam situs sebelum memberikan dukungan;</p><br />') +

                getTranslate('<p>1.2 Tidak memberikan informasi tambahan yang palsu dan/atau menyesatkan atas segala halaman, tautan, dan berbagai bentuk media lainnya dari Project suatu penggalangan Dana di indonesiagreen.com;</p><br />') +

                getTranslate('<p>1.3 Bertanggung jawab atas donasi yang telah disalurkan dan menyadari konsekuensi bahwa Project Owner berkemungkinan tidak dapat melaksanakan secara sebagian atau sepenuhnya atas Project dan/atau reward/imbalan yang telah dijanjikan sebelumnya pada Project tersebut;</p><br />') +

                getTranslate('<p>1.4 Donatur menyadari bahwa Indonesia Green (IGE) hanya bertanggung jawab sebatas penyaluran donasi kepada Project Owner;</p><br />') +

                getTranslate('<p>1.5 Donatur wajib mencermati setiap kali hendak melakukan donasi terhadap Project, kami tidak menyarankan donatur untuk berdonasi kepada suatu Project yang mengandung unsur ketidakjelasan, kebohongan, mencurigakan, penipuan dan kegiatan yang dilarang oleh negara. Indonesia Green (IGE) tidak bertanggung jawab atas kelalaian donatur atas hal yang sudah kami peringatkan di atas;</p><br />') +

                getTranslate('<p>1.6 Sepakat untuk tidak mempermasalahkan dan/atau menuntut secara hukum Pihak Indonesia Green (IGE) atas penyalahgunaan donasi yang dilakukan oleh Pihak Project Owner;</p><br />') +

                getTranslate('<p>1.7 Memahami bahwa berdonasi menggunakan Credit Card akan dikenakan biaya administrasi dari partner Credit Card Indonesia Green (IGE). Jumlah nominal donasi akan secara otomatis terpotong dengan biaya administrasi;</p><br />') +

                getTranslate('<p>1.8 Donatur bersedia apabila sewaktu-waktu dihubungi oleh Indonesia Green (IGE) walaupun tercatat sebagai anonim (anonymous donation) untuk keperluan tertentu;</p><br />') +

                getTranslate('<p>1.9 Tidak melakukan pencucian uang (money laundry) dan/atau menggunakan uang yang berasal dari sumber yang tidak sah secara hukum dalam mendonasikan uangnya untuk mendukung ide dan/atau Project penggalangan Dana yang ada di dalam situs. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">B. Ketentuan Donatur</p><br /><br />') +

                getTranslate('<p>Donatur memahami bahwa:</p><br />') +

                getTranslate('<p>1.1 Setiap donasi yang masuk ke dalam suatu Project akan terpotong biaya administrasi sebesar 10%;</p><br />') +

                getTranslate('<p>1.2 Penyedia Platform memfasilitasi penyaluran Dana melalui Platform kepada Project Owner dan/atau Penerima Manfaat;</p><br />') +

                getTranslate('<p>1.3 Dana yang diterima akan masuk ke rekening atas nama Project Owner paling lambat 3 (tiga) x 24 (dua puluh empat) jam sejak Dana yang dialihkan oleh Donatur telah dilakukan Verifikasi oleh Penyedia Platform;</p><br />') +

                getTranslate('<p>1.4 Fundraising project telah dilakukan namun diberhentikan saat proses fundraising karena keputusan dari keadaan ataupun adanya pelanggaran ketentuan yang telah dipertimbangkan oleh pihak IGE, maka donasi yang telah diberikan oleh donatur akan tetap berada di kas IGE.</p><br />') +

                getTranslate('<p>1.5 Tanpa mengurangi hak penolakan dan/atau penundaan Verifikasi Akun, Konten dan/atau Pencairan Dana dari Penyedia Platform, Dana yang masuk ke dalam Dompet atas nama Project Owner sepenuhnya menjadi hak dari Penerima Manfaat, yang harus dipertanggungjawabkan dan hanya dapat digunakan untuk Pelaksanaan Project.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">C. Kontak Narahubung</p><br />') +

                getTranslate('<p>Donatur dapat memanfaatkan kontak narahubung yang dimuat pada tiap halaman Project untuk mengetahui detail suatu Project dan/atau Pelaksanaan Project.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">D. Donasi Anonim dan Donasi Luar Jaringan</p><br /><br />') +

                getTranslate('<p>1.1 Donatur memahami dengan berdonasi secara anonim (anonymous donation), maka nama dan alamat email Donatur tidak akan terlihat dan/atau timbul di halaman Project yang bersangkutan, namun kami tetap dapat melihat nama dan alamat email donatur secara lengkap.</p><br />') +

                getTranslate('<p>1.2 Donatur yang membuat donasi luar jaringan (offline donation) dengan cara mengalihkan Dana langsung kepada rekening pribadi Project Owner, dan bukan melalui Platform, sepakat untuk melepaskan dan membebaskan Penyedia Platform dari segala tanggung jawab atas kerugian yang mungkin dialaminya sehubungan dengan donasi luar jaringan tersebut.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">E. Random Project</p><br /><br />') +

                getTranslate('<p>Donatur sepakat dalam hal:</p><br />') +

                getTranslate('<p>1.1 Donatur membuat donasi dengan cara mengalihkan Dana ke Platform, sehingga dicatat sebagai donasi umum; dan/atau</p><br />') +

                getTranslate('<p>1.2 Sebuah Project ditutup oleh Penyedia Platform sebab Project Owner bersangkutan melanggar, diduga melanggar atau berpotensi melanggar Syarat dan Ketentuan, maka Penyedia Platform berhak untuk menyalurkan Dana yang telah masuk Platform kepada Project Acak.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">F. Refund</p><br />') +

                getTranslate('<p>Indonesia Green (IGE) tidak dapat melakukan pengembalian uang donatur, maka akan didonasikan kembali di sebuah atau beberapa Project di situs Indonesia Green (IGE):</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">G. Hukum Kekayaan Intelektual</p><br />') +

                getTranslate('<p>Semua Hak Kekayaan Intelektual dalam situs ini dimiliki oleh Indonesia Green (IGE). Semua informasi dan bahan yang tertera pada situs kami seperti : logo, ikon, kode html dan kode lainnya dalam situs web ini dilarang untuk dimodifikasi, direproduksi atau diubah dengan cara apapun di luar wilayah situs ini tanpa izin yang dinyatakan oleh Indonesia Green (IGE).</p>') +
                getTranslate('<p>Apabila Anda melanggar hak-hak ini, kami berhak untuk membuat gugatan perdata untuk jumlah keseluruhan kerusakan atau kerugian yang diderita. Pelanggaran terhadap poin ini bisa termasuk dalam tindak pidana.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">H. Pengecualian dan Pelepasan Tanggungjawab</p><br />') +

                getTranslate('<p>1.1 Indonesia Green (IGE) tidak bertanggung jawab atas segala hal yang dijanjikan pihak Project Owner galang Dana terhadap beneficiary atau donatur apabila terjadi sengketa di antara mereka. Indonesia Green (IGE) tidak memberikan garansi atas apa yang terjadi di kemudian hari.</p><br />') +

                getTranslate('<p>1.2 Indonesia Green (IGE) tidak bertanggung jawab atas ketidakpuasan Anda apabila Project Owner dan atau beneficiary atas penggunaan donasi yang telah digalangkan pada situs kami atau situs yang didukung oleh Indonesia Green (IGE).</p><br />') +

                getTranslate('<p>1.3 Indonesia Green (IGE) tidak bertanggung jawab atas apa yang terjadi apabila donasi telah diberikan kepada Project Owner dan atau beneficiary seperti pencurian, penggelapan atau tindakan apapun yang menyebabkan kehilangan Dana donasi.</p><br />') +

                getTranslate('<p>1.4 Dalam keadaan apapun, Pengguna Platform akan membayar kerugian Indonesia Green (IGE) dan/atau menghindarkan Indonesia Green (IGE) (termasuk petugas, direktur, karyawan, agen, dan lainnya) dari setiap biaya kerugian apapun, kehilangan, pengeluaran atau kerusakan yang berasal dari tuntutan atau klaim pihak ketiga yang timbul dari pelanggaran Pengguna Platform terhadap Aturan Penggunaan Indonesia Green (IGE), dan/atau pelanggaran terhadap hak dari pihak ketiga.</p><br />') +

                getTranslate('<p>1.5 <a style="font-weight: bold;">INDONESIA GREEN (IGE) TIDAK MEMBERIKAN GANTI RUGI DALAM BENTUK APAPUN ATAS PENGGALANGAN DANA YANG DILAKUKAN PADA SITUS KAMI.</a></p><br />') +

                getTranslate('<p>1.6 Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa terdapat Project yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan Indonesia Green (IGE) untuk melaporkan kepada kami dan ikut serta membantu menyelesaikan perkara tersebut.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">I. Hak Cipta dan Merek Dagang</p><br />') +

                getTranslate('<p>Semua fitur yang terdapat di dalam situs ini adalah milik PT KREASI ENERGI TERBARUKAN bersama dengan YAYASAN KREASI ENERGI TERBARUKAN dan dilindungi oleh ketentuan hukum Indonesia yang terdapat dalam Undang-undang HKI (Hak Kekayaan Intelektual). Oleh karena itu, pengguna situs tidak diperkenankan untuk menyalahgunakan fitur yang terdapat dalam situs ini untuk kepentingan pribadi, kolektif maupun komersil.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">J. Hukum yang Mengatur dan Yurisdiksi</p><br />') +

                getTranslate('<p>Syarat dan Ketentuan ini diatur, ditafsirkan dan dilaksanakan berdasarkan hukum Negara Kesatuan Republik Indonesia dan Pengunjung  maupun Pengguna Platform menundukkan diri pada yurisdiksi Badan Abritase Nasional Indonesia (BANI).</p>') +
                getTranslate('<p><a style="font-weight: bold;">Setiap orang dengan sengaja, dan tanpa hak menyebarkan berita bohong dan menyesatkan yang mengakibatkan kerugian konsumen dalam Transaksi Elektronik akan terjerat ketentuan pidana yang tertulis pada Pasal 28 Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik (UU ITE). </a></p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">K. Sanksi</p><br />') +

                getTranslate('<p>Apabila pengguna situs terbukti melanggar syarat dan ketentuan yang telah dibuat baik secara sengaja maupun tidak dilakukan secara sebagian maupun secara keseluruhan, maka: </p><br />') +

                getTranslate('<p>1.1 Kami akan memberitahu/notifikasi melalui pesan/email maupun telpon kepada pengguna mengenai ketentuan apa yang dilanggar;</p><br />') +

                getTranslate('<p>1.2 Kami akan memberikan sanksi sesuai dengan pelanggaran yang dilakukan oleh pengguna situs. </p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">L. Penyelesaian Sengketa dan Keadaan Terpaksa</p><br />') +

                getTranslate('<p>1.1 Dalam hal terdapat suatu perselisihan atau sengketa yang timbul dari atau sehubungan dengan penafsiran atau pelaksanaan dari Syarat dan Ketentuan ini, Pengunjung Platform maupun Pengguna Platform sepakat untuk menggunakan segala upaya untuk menyelesaikan perselisihan atau sengketa tersebut melalui musyawarah untuk mufakat.</p><br />') +

                getTranslate('<p>1.2 Semua sengketa yang timbul dari dan/atau sehubungan dengan Syarat dan Ketentuan ini dan/atau pelaksanaan Syarat dan Ketentuan ini, yang tidak dapat diselesaikan secara musyawarah, baik mengenai cidera janji maupun perbuatan melawan hukum, termasuk</p>') +
                getTranslate('<p>mengenai pengakhiran dan/atau keabsahan Syarat dan Ketentuan ini, akan diselesaikan dan diputus melalui BANI di wilayah setempat. Meskipun terdapat suatu perselisihan atau sengketa, Pengunjung Platform atau Pengguna Platform harus tetap memenuhi kewajibannya berdasarkan Syarat dan Ketentuan ini, tanpa mengurangi keberlakuan keputusan arbitrase final.</p><br />') +

                getTranslate('<p>1.3 Anda memahami dan mengerti bahwa Platform kami dapat berhenti beroperasi dikarenakan kejadian di luar kemampuan manusia dan atau tidak dapat dihindarkan seperti terjadi peperangan, kerusuhan, kebakaran, bencana alam, permogokan dan bencana lainnya yang dinyatakan oleh instansi berwenang.</p><br />') +

                getTranslate('<p style="font-size: 18px; font-weight: bold;">Penutup</p>') +
                getTranslate('<p>Demikian syarat dan ketentuan penggunaan situs Indonesia Green (IGE), dengan menggunakan situs ini maka pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam syarat dan ketentuan ini. Atas perhatian dan kesepakatan Anda, kami sampaikan terimakasih.</p><br />')
        }
    }

    onBackHome() {
        this.props.setHashBack(false)
        Navigation.to('/dashboard')
    }

    render() {
        return (
            <div>
                <Row style={{ backgroundColor: '#fafffd' }} id="body">
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        {this.props.hasBack &&
                            <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', alignItems: 'center' }}>
                                <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image style={{
                                        width: '23px',
                                        height: '14px', objectFit: 'contain'
                                    }} src={require("../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                    <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali')}</div>
                                </div>
                            </Col>
                        }

                        <Col style={{ margin: '0px', padding: '0px', marginTop: '56px' }}>
                            <div>
                                <div style={{ fontSize: '36px', color: '#333333', fontWeight: 'bold' }} dangerouslySetInnerHTML={{
                                    __html: this.state.text1
                                }} />
                            </div>
                            <div>
                                <div style={{ fontSize: '14px', color: '#4b4b4b', marginTop: '24px' }} dangerouslySetInnerHTML={{
                                    __html: this.state.text2
                                }} />
                            </div>
                            <div>
                                <div style={{ fontSize: '14px', color: '#4b4b4b', marginTop: '30px' }} dangerouslySetInnerHTML={{
                                    __html: this.state.text3
                                }} />
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        hasBack: state.user.hasBack
    } as TNCPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        setHashBack: (has: boolean) => dispatch(hasBack(has))
    } as TNCPageAction;
}

export default connect(stateToProps, dispatchToProps)(TNCPage);
