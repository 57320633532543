import React from 'react';
import {
    InputGroup,
    Button,
    Image,
    Row,
    Col,
    ProgressBar,
    Card,
    Spinner,
} from 'react-bootstrap';
import '../listProject/style.css';
import { Project } from '../../model/Project';
import { toCurrency } from '../../utils/Currency';
import { toTitleCase, getTranslate } from '../../utils/General';
import InfiniteScroll from 'react-infinite-scroll-component';
import { manyDaysBetween, convertToIDDateString } from '../../utils/Date';


export interface ListProjectProps {
    data: Project[]
    hasMoreItems: boolean
    onItemClick(project: Project): void
    onLoadMore(projectId: string): void
}

export interface ListProjectAction {

}

export interface ListProjectState {

}

class ListProjectComponent extends React.Component<ListProjectProps & ListProjectAction, ListProjectState> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    getPercentage(project: Project): number {
        const value = Math.round((parseInt(project.current_amount) / parseInt(project.target_amount)) * 100)
        return value
    }

    projectList() {
        return this.props.data.map((item) => {
            return (
                <Col className="col-project-container" key={item.id} style={{ margin: '0px', padding: '0px' }}>
                    <Col className="col-list-project" onClick={() => this.props.onItemClick(item)} style={{
                        backgroundColor: 'white',
                        position: 'relative',
                        padding: '32px',
                        wordBreak: 'break-all',
                        cursor: 'pointer', borderRadius: '4px',
                    }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            justifyContent: 'flex-start',
                        }}>
                            <div className="div-img-title">
                                <Image className="img-list-project" src={item.picture_url ? item.picture_url : require('../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                <div className="mobile-show img-title-mobile" style={{ fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(item.name)}</div>
                            </div>

                            <Col className="col-detail-project" style={{ margin: '0px', padding: '0px', marginLeft: '24px' }}>
                                <div className="desktop-show" style={{ fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(item.name)}</div>
                                <div style={{ marginBottom: '8px' }}>
                                    <ProgressBar
                                        style={{ borderRadius: '7px', height: '8px' }}
                                        now={this.getPercentage(item)} />
                                </div>
                                <div style={{ marginBottom: '8px', fontSize: '18px', color: '#333333' }}>{ item.status == "open funding" ? toCurrency(item.current_amount):""}</div>
                                <div style={{ marginBottom: '8px', fontSize: '12px', color: '#333333' }}>{getTranslate('terkumpul dari target ') + toCurrency(item.target_amount)}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image style={{ width: '16px', height: '16px', objectFit: 'contain' }} src={require('../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} />
                                    <div style={{ fontSize: '12px', color: '#808080', marginLeft: '8px' }}>{item.close_funding_date ? convertToIDDateString(item.close_funding_date) : '-'}</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px',
                    }} />
                </Col>
            )
        })
    }

    render() {
        return (
            <InfiniteScroll
                scrollThreshold={0.5}
                dataLength={this.props.data.length}
                next={() => this.props.onLoadMore(this.props.data[this.props.data.length - 1].id)}
                hasMore={this.props.hasMoreItems}
                loader={
                    <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                        <Spinner size="sm" animation="border" variant="secondary" />
                    </Row>
                }>
                {this.projectList()}
            </InfiniteScroll>

        )
    }
}

export default ListProjectComponent