import React from 'react';
import {
    Image,
    Navbar,
    Nav,
    Form,
    NavDropdown,
    Row,
    Col,
    Alert,
} from 'react-bootstrap';
import { AppState } from '../../AppState';
import { UserState, setUser, me } from '../../reducers/User';
import { connect } from 'react-redux';
import './style.css'
import authHelper from '../../utils/AuthHelper';
import { getInitialName, toTitleCase, getTranslate } from '../../utils/General';
import { User } from '../../model/User';
import { Dispatch } from 'redux';
import Navigation from '../../utils/Navigation';
import store from '../../store';

export interface navbarProps {
    user?: UserState
}

export interface navbarAction {
    me(): any
    setUser(user?: User): void
}

export interface navbarState {
    sessionTimeout: boolean
}

class NavbarComponent extends React.Component<navbarProps & navbarAction, navbarState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sessionTimeout: false
        }
    }

    async loadData() {
        try {
            const user = await this.props.me()
            this.props.setUser(user)
            this.setState({ sessionTimeout: false })
        } catch (e: any) {
            if (e.error_code && e.error_code === 401) {
                this.setState({ sessionTimeout: true })
                setTimeout(() => {
                    this.clearData()
                    Navigation.to('/')
                    this.setState({ sessionTimeout: false })
                }, 3000);
            }
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async clearData() {
        await authHelper.clearToken()
        await store.persistor.purge()
            .then(() => {
                return store.persistor.flush()
            })
            .then(() => {
                store.persistor.pause()
                this.props.setUser(undefined)
            })
    }

    logout() {
        this.clearData()
    }

    onProfileClick() {
        Navigation.to('/dashboard/my-profile')
    }

    onPMTDashboardClick() {
        Navigation.to('/pmt-dashboard')
    }

    onUserManagementClick() {
        Navigation.to('/user-management')
    }

    getProfilePict = (user?: User) => {
        if (user?.picture_url) {
            return (
                <Image style={{
                    width: '64px',
                    height: '64px', objectFit: 'cover'
                }} roundedCircle src={user?.picture_url} />
            )
        } else {
            return getInitialName(user?.name || getTranslate('anonim'))
        }
    }

    render() {
        return (
            <Navbar bg="transparent" expand="lg">
                <Navbar.Brand style={{ marginTop: '18px', marginBottom: '18px' }} href="/dashboard" >
                    <Image
                        style={{ height: '59px', objectFit: 'contain' }}
                        src={require('../../../src/assets/images/logo/logo.png')}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse style={{ marginTop: '16px', marginBottom: '16px' }} id="basic-navbar-nav">
                    <Nav className="mr-auto" />
                    <Form inline>
                        <Nav className="mr-auto">
                            <Row style={{
                                margin: '0px', padding: '0px', fontSize: '20px', fontWeight: 'bold', color: '#333333',
                                width: '68px', alignItems: 'center', textAlign: 'center', justifyContent: 'center',
                                height: '68px', borderRadius: '34px', backgroundColor: '#d8d8d8', marginRight: '11px'
                            }}>
                                {this.getProfilePict(this.props.user?.user)}
                            </Row>
                            <NavDropdown style={{
                                margin: '0px', padding: '0px', color: '#ffffff', fontSize: '16px',
                                fontWeight: 'bold', alignSelf: 'center'
                            }} title={this.props.user?.user && this.props.user?.user?.name ? this.props.user?.user?.name : getTranslate('anonim')}
                                id={"basic-nav-dropdown"}>
                                <NavDropdown.Item style={{
                                    width: '220px', margin: '0px', borderTopRightRadius: '4px',
                                    padding: '16px', borderTopLeftRadius: '4px'
                                }} id="navItem" onClick={() => this.onProfileClick()}>
                                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <Image style={{ width: '14px', height: '14px', marginRight: '8px', objectFit: 'contain' }} src={require('../../../src/assets/images/ic-actions-user-profile-grey/ic-actions-user-profile-grey.png')} />
                                        <div style={{ color: '#333333', fontSize: '16px' }}>
                                            {getTranslate('Profile')}
                                        </div>
                                    </Row>
                                </NavDropdown.Item>
                                {this.props.user?.user?.access_level === 'admin' &&
                                    <div>
                                        <div style={{ backgroundColor: '#d8d8d8', height: '1px' }} />
                                        <NavDropdown.Item style={{
                                            width: '220px', margin: '0px',
                                            padding: '16px'
                                        }} id="navItem" onClick={() => this.onUserManagementClick()}>
                                            <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                                <Image style={{ width: '14px', height: '14px', marginRight: '8px', objectFit: 'contain' }} src={require('../../../src/assets/images/ic-statistics-workflow/ic-statistics-workflow.png')} />
                                                <div style={{ color: '#333333', fontSize: '16px' }}>
                                                    {getTranslate('User Management')}
                                                </div>
                                            </Row>
                                        </NavDropdown.Item>
                                    </div>
                                }
                                {this.props.user?.user?.access_level !== 'general' &&
                                    <div>
                                        <div style={{ backgroundColor: '#d8d8d8', height: '1px' }} />
                                        <NavDropdown.Item style={{
                                            width: '220px', margin: '0px',
                                            padding: '16px'
                                        }} id="navItem" onClick={() => this.onPMTDashboardClick()}>
                                            <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                                <Image style={{ width: '14px', height: '14px', marginRight: '8px', objectFit: 'contain' }} src={require('../../../src/assets/images/ic-contact-browser/ic-contact-browser.png')} />
                                                <div style={{ color: '#333333', fontSize: '16px' }}>
                                                    {getTranslate('Dashboard PMT')}
                                                </div>
                                            </Row>
                                        </NavDropdown.Item>
                                    </div>
                                }
                                <div style={{ backgroundColor: '#d8d8d8', height: '1px' }} />
                                <NavDropdown.Item style={{
                                    width: '220px', margin: '0px', borderBottomLeftRadius: '4px',
                                    padding: '16px', borderBottomRightRadius: '4px'
                                }} id="navItem" href={'/'} onClick={() => this.logout()}>
                                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <Image style={{ width: '14px', height: '14px', marginRight: '8px', objectFit: 'contain' }} src={require('../../../src/assets/images/logout/logout.png')} />
                                        <div style={{ color: '#333333', fontSize: '16px' }}>
                                            {getTranslate('Keluar')}
                                        </div>
                                    </Row>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Form>
                </Navbar.Collapse>
                {this.state.sessionTimeout &&
                    <Alert style={{
                        padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                        marginLeft: '350px', marginRight: '350px',
                        position: 'absolute', top: 0, marginTop: '23px',
                        backgroundColor: 'rgba(255, 0, 0, 0.3)'
                    }}>
                        <div style={{ fontSize: '16px', color: '#ff0000', textAlign: 'center' }}>
                            {getTranslate('Sesi telah berakhir')}
                        </div>
                    </Alert>
                }
            </Navbar>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user
    } as navbarProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        me: () => me(),
        setUser: (user?: User) => dispatch(setUser(user))
    } as navbarAction;
}

export default connect(stateToProps, dispatchToProps)(NavbarComponent);
