import { applyMiddleware, compose } from 'redux';
import userReducers, { initialUserState } from './reducers/User'
import { AppState, RouteState } from "./AppState"
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { routerMiddleware, connectRouter } from 'connected-react-router';
import projectReducers, { initialProjectState } from "./reducers/Project"
import summaryReducers, { initialSummaryState } from "./reducers/Summary"
import donationReducers, { initialDonationState } from "./reducers/Donation"
import paymentReducers, { initialPaymentState } from "./reducers/Payment"
import mediaReducers, { initialMediaState } from "./reducers/Media"
import progressReducers, { initialProgressState } from "./reducers/Progress"
import { createBrowserHistory } from 'history'
import { createStore } from 'redux';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import authReducers, { initialAuthState } from './reducers/Auth';

export const history = createBrowserHistory()

const reducers = combineReducers<AppState & RouteState>({
    auth: authReducers,
    user: userReducers,
    project: projectReducers,
    summary: summaryReducers,
    donation: donationReducers,
    payment: paymentReducers,
    media: mediaReducers,
    progress: progressReducers,
    router: connectRouter(history)
})

const initialState: AppState = {
    auth: initialAuthState,
    user: initialUserState,
    project: initialProjectState,
    summary: initialSummaryState,
    donation: initialDonationState,
    payment: initialPaymentState,
    media: initialMediaState,
    progress: initialProgressState
}

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const configureStore = () => {
    const composeEnhancers = compose
    let store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(thunk, routerMiddleware(history))));
    let persistor = persistStore(store)
    return { store, persistor }
}

export const getState = function (): AppState & RouteState {
    return configureStore().store.getState()
};

export default configureStore()
