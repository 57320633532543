import 'moment/locale/id';

import moment from 'moment';
import { DateRange } from 'moment-range';
import momentTimezone from 'moment-timezone';
import { string } from 'prop-types';
import { getTranslate } from './General';

/**
 * Converts date string to date object
 * @param {string} dateString - date represent in string format e.g 2018-01-20
 * @returns {Date} date - a date object
 */
export function dateStringToDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
}

/**
 * Converts date string to epoch time in milis
 * @param {string} dateString - date represent in string format e.g 2018-01-20
 * @returns {number} epochTime
 */
export function dateStringToTimeInMilis(dateString: string): number {
    const date: Date = dateStringToDate(dateString);
    return date.getMilliseconds();
}

/**
 * Converts date object to date string with day accuracy
 * @param {Date} date
 * @returns {string} dateString with format YYYY-MM-DD
 */
export function dateToDateString(date: Date): string {
    return date.toISOString().slice(0, 10);
}

export function toIsoString(date: string): string {
    const d: Date = moment(date).locale('id').toDate();
    return d.toISOString();
}

/**
 * Gets range date from a given start date
 * @param {string} from - a date string as a pivot or start date e.g 2018-07-10
 * @param {number} range - an expected range
 * @param {boolean} inRange - a mode of range, if true then the range will be in between `from` value
 *                             if false, the range dates will be started from `from` value
 * @returns {string[]} dates - array of date string
 */
export function getXDaysRange(from: string, range: number, inRange: boolean = true): string[] {
    const result: string[] = [];
    const pivotDay = moment(dateStringToDate(from));
    const startDay = inRange ? moment(pivotDay).subtract(Math.ceil(range / 2), 'days') : pivotDay;
    for (let i = 1; i <= range; i++) {
        const addNum = inRange ? i : i - 1;
        const incrementtedDay = moment(startDay).add(addNum, 'days');
        result.push(incrementtedDay.format('YYYY-MM-DD'));
    }
    return result;
}

/**
 * Gets all dates between given two dates
 * @param {string} from - date string e.g 2018-07-01
 * @param {string} to - date string e.g 2018-07-10
 * @returns {string[]} dates - array of date string
 */
export function getDatesBetween(from: string, to: string): string[] {
    const startDay = moment(dateStringToDate(from));
    const endDay = moment(dateStringToDate(to));
    const diffDay = startDay.diff(endDay, 'days');
    const isFlipped: boolean = diffDay > 0;

    return getXDaysRange(isFlipped ? to : from, Math.abs(diffDay) + 1, false);
}

/**
 * Checks if date string is valid with spesific format
 * @param {string} dateString - with format YYY-MM-DD (2018-07-10)
 * @param {string} format - example date format YYYY-MM-DD | DD/MM/YYYY
 * @returns {boolean} true or false
 */
export function isDateStringFormatValid(dateString: string, format: string = 'YYYY-MM-DD'): boolean {
    return moment(dateString, format, true).isValid();
}

export function convertToIDDateString(dateString: string): string {
    const date: Date = moment(dateString).toDate();
    return moment(date).locale('id').format('dddd, DD MMMM YYYY');
}

export function convertToIDDateTimeString(dateString: string): string {
    const date: Date = moment(dateString).toDate();
    return moment(date).locale('id').format('dddd, DD MMM YYYY hh:mm');
}
export function convertToIDTimeString(dateString: string): string {
    const date: Date = moment(dateString).toDate();
    return moment(date).locale('id').format('hh:mm');
}

export function getFullYearOfDateString(dateString: string): string {
    return dateString.slice(0, 10);
}

export function changeStringDateFormat(stringDate: string, format: string): string {
    const date: Date = moment(stringDate).toDate();
    return moment(date).locale('id').format(format);
}

export function toDate(stringDate: string): Date {
    return moment(stringDate).locale('id').toDate();
}

export function isDateInRange(checkedDate: string, fromDate: string, toDate: string): boolean {
    const checked: number = moment(new Date(checkedDate)).valueOf();
    const from: number = moment(new Date(fromDate)).valueOf();
    const to: number = moment(new Date(toDate)).valueOf() + (60 * 60 * 24 * 1000);
    return checked >= from && checked < to;
}

export function getFirstAndLastDateOfCurrentMonth(date?: string): DateRange {
    if (!date) {
        return new DateRange(moment().startOf('month'), moment().endOf('month'));
    }
    return new DateRange(moment(new Date(date)).startOf('month'), moment(new Date(date)).endOf('month'));
}

export function createMomentDateRange(start: string, end: string): DateRange {
    return new DateRange(moment(start).startOf('day'), moment(end).endOf('day'));
}

export function createDateRange(start: string, end: string): DateRange {
    return new DateRange(moment(start), moment(end));
}

export function getDateOfLastXDays(days: number = 1): string {
    return moment().locale('id').subtract(days, 'days').startOf('day').toISOString();
}

export function getEndOfCurrentDay() {
    return moment().locale('id').endOf('day').toISOString();
}

export function getCurrentISODate(): string {
    return moment().locale('id').toISOString();
}

export function getCurrentTimeInMillis(): number {
    return moment().locale('id').valueOf();
}

/**
 * Gets all the weeks range within the month
 * @param {number} year
 * @param {number} month - start from 0 (january)
 */
export function getWeeksWithinMonth(year: number, month: number): DateRange[] {
    const startDate = moment([year, month]);

    const fisrtDay = moment(startDate).startOf('month');
    const endDay = moment(startDate).endOf('month');

    const monthRange = new DateRange(fisrtDay, endDay);

    const iterableMoment: Iterable<moment.Moment> = monthRange.by('days');
    const az = iterableMoment[Symbol.iterator]();
    const weeks: number[] = [];
    while (true) {
        const value = az.next();
        if (value.done) break;
        const m: moment.Moment = value.value;
        if (weeks.indexOf(m.week()) < 0) {
            weeks.push(m.week());
        }
    }

    const weeksRange: DateRange[] = [];
    weeks.forEach((weekNum: number) => {
        const firstWeekDay = moment().week(weekNum).day(1);
        const lastWeekDay = moment().week(weekNum).day(7);
        const weekRange = new DateRange(firstWeekDay, lastWeekDay);
        weeksRange.push(weekRange);
    });

    return weeksRange;
}

export function isDateLessThan(checkedDate: string, comparedDate: string): boolean {
    const lastOfDay: moment.Moment = moment(new Date(checkedDate)).endOf('day');
    const startOfDay: moment.Moment = moment(new Date(comparedDate)).startOf('day');
    const x = lastOfDay.diff(startOfDay, 'seconds');
    return x < 0;
}

export function toLocalTime(utcTime: string): string {
    const localTime = moment(utcTime).locale('id').toDate();
    return moment(localTime).locale('id').toDate().toISOString();
}

export function getTimeZoneOffset(): number {
    return moment().locale('id').utcOffset();
}

export function toSFDateFormatSimple(date: string): string {
    const localTime = moment(date).locale('id').toDate();
    return moment(localTime).locale('id').format('DD MMM YYYY');
}

export function toSFDateFormat(date: string, forChecker: boolean = false): string {
    const localTime = moment(date).locale('id').toDate();
    if (forChecker)
        return moment(localTime).locale('id').format('DD/MM/YY HH:mm:ss');
    return moment(localTime).locale('id').format('ddd, DD MMM YYYY');
}

export function toSFTimeFormat(date: string): string {
    const localTime = moment(date).toDate();
    const m = moment(localTime);
    return m.format('HH:mm');
    // return m.tz(moment.tz.guess()).format('HH:mm z') || m.format('HH:mm');
}

export function toSFDateFormatFull(date: string): string {
    const localTime = moment(date).toDate();
    const m = momentTimezone(localTime);
    return m.tz(moment.tz.guess()).format('ddd, DD MMM YYYY HH:mm z') || m.format('ddd, DD MMM YYYY HH:mm');
}

// tslint:disable-next-line:max-line-length
export function returnDaysBetween(startTimeInMillis: number, currentTimeInMillis: number = getCurrentTimeInMillis()): number {
    const duration = moment.duration((startTimeInMillis - currentTimeInMillis), 'milliseconds');
    const days: number = duration.asDays();
    return days;
}

export function manyDaysBetween(firstDate: string, secondDate: string = getCurrentISODate()): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDateFormat = new Date(firstDate);
    const secondDateFormat = new Date(secondDate);

    const diffDays = Math.round(Math.abs((firstDateFormat.valueOf() - secondDateFormat.valueOf()) / oneDay));
    return diffDays
}

export function convertDateToMillis(date: string): number {
    const localTime = toLocalTime(date);
    return moment(localTime).valueOf();
}

export function humanDate(dateString: string): string {
    let date = moment(toLocalTime(dateString)).locale('id');
    return date.fromNow();
}