import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
    Card,
    Accordion,
    Button,
    FormControl,
    InputGroup,
    FormLabel,
    ProgressBar,
    Alert
} from 'react-bootstrap';
import './style.css';
import NavbarComponent from '../../component/navbar';
import { Editor } from '@tinymce/tinymce-react';
import { ProjectUserBody } from '../../model/payload/ProjectUserBody';
import { Dispatch } from 'redux';
import { submitProject } from '../../reducers/Project';
import { Organization } from '../../model/Organization';
import { getOrganization, setOrganization, submitOrganization, editOrganization, editProfile } from '../../reducers/User';
import { User } from '../../model/User';
import { OrganizationBody } from '../../model/payload/OrganizationBody';
import { setUploadedMedia, getUploadedMedia, clearMedia } from '../../reducers/Media';
import { Media } from '../../model/Media';
import { actualSize, bytesToSize, getFileExtensions, validateEmail, validateUrl, removeAllSpecialChar, getTranslate } from '../../utils/General';
import Navigation from '../../utils/Navigation';
import NumberFormat from 'react-number-format';

export interface SubmissionPageProps {
    user: User,
    media: Media[],
    trackProjectPage: boolean
}

export interface SubmissionPageAction {
    submitProject(paylaod: ProjectUserBody): void
    mediaUpload(data: FormData, id_user: string, file_type: string): any
    setMediaUpload(medias: Media[]): void
    clearMedia(): void
}

export interface SubmissionPageState {
    //project details
    name: string,
    description: string,
    address_project: string,
    target_amount?: number,
    electrical_power?: number,
    electrical_target?: number,
    pv_location: string,
    is_pv_battery?: boolean,
    is_experience_contractor?: boolean,
    is_started_project?: boolean,
    creator_is_owner?: boolean,
    doc: string[],

    loading: boolean,
    loadingUploadImage: boolean,
    showAlert: Boolean,
    isError: string,

    imageFile: string
    imageFormData?: FormData
}

class SubmissionPage extends React.Component<SubmissionPageProps & SubmissionPageAction, SubmissionPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            //project details
            name: "",
            description: "",
            address_project: "",
            target_amount: undefined,
            electrical_power: undefined,
            electrical_target: undefined,
            pv_location: "",
            is_pv_battery: undefined,
            is_experience_contractor: undefined,
            is_started_project: undefined,
            creator_is_owner: undefined,
            doc: [],

            loading: false,
            loadingUploadImage: false,
            showAlert: false,
            isError: "",
            imageFile: ""
        }
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    onBackHome() {
        if (this.props.trackProjectPage) {
            Navigation.to('/dashboard/my-project')
        } else {
            Navigation.to('/dashboard')
        }
    }

    componentWillUnmount() {
        this.props.clearMedia()
    }

    async onSubmitProject() {
        this.setState({ loading: true })
        const s = this.state

        const submitDocs: string[] = []
        if (this.props.media && this.props.media.length > 0) {
            this.props.media.forEach(pro => {
                submitDocs.push(pro.data)
            })
        }

        let payload: ProjectUserBody = {
            name: removeAllSpecialChar(s.name),
            description: removeAllSpecialChar(s.description),
            target_amount: s.target_amount ? s.target_amount : 0,
            address: removeAllSpecialChar(s.address_project),
            current_electricity: s.electrical_power ? s.electrical_power : 0,
            electricity_request: s.electrical_target ? s.electrical_target : 0,
            electricity_placement: removeAllSpecialChar(s.pv_location),
            has_electricity_battery: s.is_pv_battery || false,
            has_exp_contractor: s.is_experience_contractor || false,
            has_started: s.is_started_project || false,
            creator_is_owner: s.creator_is_owner || false,
            submitted_doc: submitDocs
        };
        try {
            await this.props.submitProject(payload)
            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ loading: false, showAlert: false })
                this.onBackHome()
            }, 1000);
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    handleEditorChange(e: any) {
        this.setState({ description: e.target.getContent() });
    }

    async upload(data: FormData) {
        this.setState({ loadingUploadImage: true })
        try {
            const media: Media = await this.props.mediaUpload(data, this.props.user.id, 'project_pict')
            const medias = this.props.media

            medias.push(media);
            this.setState({ loadingUploadImage: false });
            this.props.setMediaUpload(medias);
            
        } catch (e: any) {
            this.setState({ loadingUploadImage: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    onClickUploadPictureUrl = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);
            this.setState({
                imageFile: URL.createObjectURL(event.target.files[0]),
                imageFormData: data
            });
        }
    }

    onClickUpload = (event: any) => {
        let file = event.target.files[0];
        
        if (file) {
            console.log(actualSize(parseFloat(file.size)))
            if (file.size >= 5000000) {
              this.setState({
                loadingUploadImage: false,
                isError: getTranslate("File size cannot upload more than 5MB"),
                showAlert: true,
              });
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              return false;
            }

            let data = new FormData();
            data.append('file_data', file);
            this.upload(data)
        }
    }

    validationButton = () => {
        const s = this.state
        if (s.name && s.description && s.address_project && s.target_amount && s.electrical_power && s.electrical_target &&
            s.pv_location && s.is_pv_battery !== undefined && s.is_experience_contractor !== undefined && s.is_started_project !== undefined && s.creator_is_owner !== undefined
        ) {
            return false
        }
        return true
    }

    onRemoveMedia = (indexMedia: number) => {
        let medias = this.props.media

        if (medias.length <= 1) {
            medias = []
        } else {
            medias.forEach((item: Media, index: number) => {
                if (indexMedia === index) {
                    medias.splice(medias.indexOf(item), 1);
                }
            })
        }

        this.props.setMediaUpload(medias)
    }

    renderMediaList() {
        return this.props.media.map((item, index) => {
            return (
                <Col key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{ margin: '0px', padding: '0px' }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            width: '64px', height: '64px', marginRight: '16px',
                            backgroundColor: '#cccccc', borderRadius: '4px',
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            {validateUrl(item.data) && (getFileExtensions(item.data.split('.')) === 'PNG' || getFileExtensions(item.data.split('.')) === 'JPG' || getFileExtensions(item.data.split('.')) === 'JPEG') ?
                                <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item.data} />
                                :
                                <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(item.data.split('.'))}</div>
                            }
                        </Row>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{item.data}</div>
                            <div style={{ color: '#808080', fontSize: '12px', fontWeight: 'normal' }}>{item.size && item.size !== '-' ? bytesToSize(parseInt(item.size)) : '-'}</div>
                        </Col>

                        <Image onClick={() => this.onRemoveMedia(index)} style={{ width: '24px', height: '24px', cursor: 'pointer' }} src={require("../../../src/assets/images/ic-common-action-close-gray/ic-common-action-close-gray.png")} />
                    </Row>

                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px', marginTop: '16px'
                    }} />
                </Col>
            )
        })
    }

    renderProjectLayout = () => {
        return (
            <Col className="card-submission" style={{
                margin: '0px', padding: '24px', backgroundColor: 'white',
                marginTop: '40px',
                cursor: 'default',
                paddingLeft: '32px', paddingRight: '32px',
                borderColor: 'transparent',
                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
            }}>
                <input type="file" id="image-file"
                    style={{ display: "none" }}
                    onChange={this.onClickUploadPictureUrl}
                    accept="image/*" />
                <div className="card-label-title" style={{ color: '#333333', fontSize: '24px', fontWeight: 'bold' }}>
                    {getTranslate('Detail Pengajuan Proyek')}
                </div>
                <div className="garis" style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.15)',
                    marginLeft: '-32px', marginRight: '-32px',
                    height: '1px', marginTop: '24px', marginBottom: '24px'
                }} />
                <Col style={{ margin: '0px', padding: '0px' }}>
                    <FormLabel>{getTranslate('Nama Proyek')}</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <FormControl placeholder={getTranslate("Contoh: ") + "Pemasangan Turbin di Kawasan Ciwidey"}
                            defaultValue={this.state.name}
                            onChange={(event: any) => this.setState({ name: event.target.value })}
                        />
                    </InputGroup>
                </Col>
                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <FormLabel>{getTranslate('Deskripsi Proyek')}</FormLabel>
                        <Editor
                            initialValue={this.state.description}
                            init={{
                                height: 400,
                                menubar: true,
                                plugins: 'link image code',
                                toolbar: 'undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat| image'
                            }}
                            onChange={this.handleEditorChange} />
                    </Col>
                </Row>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Jumlah Pengajuan Dana Proyek')} (Rp)</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <NumberFormat
                            value={this.state.target_amount}
                            placeholder={getTranslate("Contoh: ") + "500.000"}
                            id="number_format"
                            thousandSeparator={true}
                            onValueChange={(event: any) => this.setState({ target_amount: event.value })} />
                    </InputGroup>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Alamat Proyek')}</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <FormControl
                            defaultValue={this.state.address_project}
                            placeholder={getTranslate("Contoh: ") + "Jl. Dr. Ide Gde Agung No. 9, Setiabudi, Kuningan, Jakarta Selatan"} as="textarea"
                            onChange={(event: any) => this.setState({ address_project: event.target.value })}
                        />
                    </InputGroup>
                </Col>
                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                        <FormLabel>{getTranslate('Daya Listrik yang telah Terpasang')} (Watt)</FormLabel>
                        <InputGroup style={{ margin: '0px', padding: '0px' }}>
                            <FormControl type="number" placeholder={getTranslate("Contoh: ") + "5000"}
                                defaultValue={this.state.electrical_power}
                                onChange={(event: any) => this.setState({ electrical_power: event.target.value })}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                        <FormLabel>{getTranslate('Jumlah Pengajuan Daya PV')} (kWp)</FormLabel>
                        <InputGroup style={{ margin: '0px', padding: '0px' }}>
                            <FormControl type="number" placeholder={getTranslate("Contoh: ") + "1200"}
                                defaultValue={this.state.electrical_target}
                                onChange={(event: any) => this.setState({ electrical_target: event.target.value })}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Dimana PV akan dipasang?')}</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <FormControl
                            defaultValue={this.state.pv_location}
                            placeholder={getTranslate("Contoh: ") + "atap, lapangan, bukit, dll."}
                            as="textarea"
                            onChange={(event: any) => this.setState({ pv_location: event.target.value })}
                        />
                    </InputGroup>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah akan menggunakan PV Baterai?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.is_pv_battery === true ?
                                true : undefined} name="is_pv_battery" value="Ya"
                                onClick={() => this.setState({ is_pv_battery: true })}
                            />
                            <label>{getTranslate('Ya')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.is_pv_battery === false ?
                                true : undefined} name="is_pv_battery" value="Tidak"
                                onClick={() => this.setState({ is_pv_battery: false })}
                            />
                            <label>{getTranslate('Tidak')}</label>
                        </div>
                    </Row>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah proyek ini telah memiliki kontraktor berpengalaman?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.is_experience_contractor === true ?
                                true : undefined} name="is_experience_contractor" value="Ya"
                                onClick={() => this.setState({ is_experience_contractor: true })}
                            />
                            <label>{getTranslate('Ya')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.is_experience_contractor === false ?
                                true : undefined} name="is_experience_contractor" value="Tidak"
                                onClick={() => this.setState({ is_experience_contractor: false })}
                            />
                            <label>{getTranslate('Tidak')}</label>
                        </div>
                    </Row>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah proyek ini sudah dimulai?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.is_started_project === true ?
                                true : undefined} name="is_started_project" value="Ya"
                                onClick={() => this.setState({ is_started_project: true })}
                            />
                            <label>{getTranslate('Sudah')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.is_started_project === false ?
                                true : undefined} name="is_started_project" value="Tidak"
                                onClick={() => this.setState({ is_started_project: false })}
                            />
                            <label>{getTranslate('Belum')}</label>
                        </div>
                    </Row>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah Anda bertindak sebagai perwakilan langsung / tim dari proyek ini?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.creator_is_owner === true ?
                                true : undefined} name="creator_is_owner" value="Ya"
                                onClick={() => this.setState({ creator_is_owner: true })}
                            />
                            <label>{getTranslate('Ya')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.creator_is_owner === false ?
                                true : undefined} name="creator_is_owner" value="Tidak"
                                onClick={() => this.setState({ creator_is_owner: false })}
                            />
                            <label>{getTranslate('Tidak')}</label>
                        </div>
                    </Row>
                </Col>
                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <FormLabel>{getTranslate('Upload proposal / gambar proyek jika ada')}</FormLabel>
                        <div className="uploader">
                            <div style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>{getTranslate('Drag & drop')}</div>
                            <div style={{ color: '#808080', fontSize: '16px', marginTop: '4px' }}>{getTranslate('atau')}
                                <a style={{ color: '#1aa59a' }}>{getTranslate(' browse ')}</a>{getTranslate('your files')}</div>
                            <div style={{ color: '#d8d8d8', fontSize: '14px', marginTop: '10px' }}>(format: doc, pdf, xls, image)</div>
                            <input
                                type="file" name="project-file"
                                onChange={this.onClickUpload} id="filePhoto"
                                accept=".doc, .docx, .pdf, .xls, .xlsx, image/*" />
                        </div>
                    </Col>
                </Row>
                {((this.props.media && this.props.media.length > 0) || this.state.loadingUploadImage) &&
                    <Col style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                        {this.state.loadingUploadImage &&
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div style={{
                                    color: '#d8d8d8', fontSize: '14px',
                                    fontWeight: 'bold', marginBottom: '8px'
                                }}>
                                    {getTranslate('Uploading...')}
                                </div>
                                <ProgressBar
                                    animated
                                    style={{ borderRadius: '7px', height: '8px' }}
                                    now={100} />
                            </Col>
                        }
                        {this.renderMediaList()}
                    </Col>
                }
            </Col>
        )
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>
                <Row id="body">
                    <Col className="submission-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{this.props.trackProjectPage ? getTranslate('Kembali ke Detail Proyek') : getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>
                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div className="font-mobile-20" style={{ fontSize: '36px', color: '#333333', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{getTranslate('Project Submission')}</div>
                            {this.renderProjectLayout()}

                            <Row className="submit-button-row" style={{ margin: '0px', padding: '0px', marginTop: '24px', justifyContent: 'flex-end' }}>
                                <Button style={{
                                    fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                    color: this.validationButton() ? '#808080' : 'white',
                                    borderColor: this.validationButton() ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.validationButton() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }}
                                    className={this.state.loading ? "ui primary loading button" : ""}
                                    disabled={this.validationButton()}
                                    onClick={() => this.onSubmitProject()}>
                                    {getTranslate('Submit Project')} </Button>
                            </Row>
                        </Col>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', paddingLeft: '32px', paddingRight: '32px',
                                margin: '23px 0 2px 350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Proyek berhasil dibuat')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        media: state.media.medias,
        trackProjectPage: state.project.trackProjectPage
    } as SubmissionPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        submitProject: (payload: ProjectUserBody) => submitProject(payload),
        mediaUpload: (data: FormData, id_user: string, file_type: string) => getUploadedMedia(data, id_user, file_type),
        setMediaUpload: (media: Media[]) => dispatch(setUploadedMedia(media)),
        clearMedia: () => dispatch(clearMedia())
    } as SubmissionPageAction;
}

export default connect(stateToProps, dispatchToProps)(SubmissionPage);
