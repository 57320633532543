import { Error } from "../model/Error";
import { getTranslate } from "./General";

export const ErrorMap = (response: Error) => {
    return response;
};

export const NoConnectionError = () => {
    return getTranslate('Tidak ada koneksi');
};
