import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
    Button,
    Card,
    FormControl,
    Alert,
    ProgressBar,
    Spinner,
    Modal,
    InputGroup,
} from 'react-bootstrap';
import './style.css';
import ListProjectComponent from '../../component/listProject';
import { UserState, setUser, me, setAllUser, getUsers, setOrganization, getOrganization, setOrganizationName, getOrganizationName, idCardApproved, idCardRejected, generalDeleted, setSelectedUser, pmtDeleted } from '../../reducers/User';
import NavbarComponent from '../../component/navbar';
import { User } from '../../model/User';
import { Dispatch } from 'redux';
import { Project } from '../../model/Project';
import { getProjects, setListProject, trackDonationPage, setSelectedProject, trackProjectPage } from '../../reducers/Project';
import { SummaryDashboard } from '../../model/SummaryDashboard';
import { getSummary, setSummaryAction, setPMTSummaryAction, getPMTSummary } from '../../reducers/Summary';

import { PaymentMethod } from '../../model/PaymentMethod';
import { getPaymentMethods, setPaymentMethod } from '../../reducers/Payment';
import { Organization } from '../../model/Organization';
import { Donation } from '../../model/Donation';
import { setDonation, getDonations, setDonationByProject } from '../../reducers/Donation';
import ListProjectPMTComponent from '../../component/listProjectPMT';
import { toCurrency } from '../../utils/Currency';
import { SummaryPMTDashboard } from '../../model/SummaryPMTDashboard';
import PieChart, { PieChartData } from 'react-minimal-pie-chart';
import { OrganizationName } from '../../model/OrganizationName';
import Navigation from '../../utils/Navigation';
import { clearMedia } from '../../reducers/Media';
import { Progress } from '../../model/Progress';
import { setProgressByProject } from '../../reducers/Progress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { mappingAccessLevelUser, mappingStatusUser, getTranslate } from '../../utils/General';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

export interface UserManagementProps {
    user: User,
    allUsers: User[]
}

export interface UserManagementAction {
    getUsers(sortBy: string, startingAfter?: string, name?: string): any
    setUsers(user: User[]): void
    setSelectedUser(user: User): void
    idCardApproved(user: User): any
    idCardRejected(user: User): any
    generalDeleted(user: User): any
    pmtDeleted(user: User): any
}

export interface UserManagementState {
    isLoading: boolean,
    showAlert: Boolean,
    isError: string,
    searchUser: string,
    hasMoreItems: boolean,
    showModalStatus: boolean,
    modalStatus: 'KTP' | 'ORGANIZATION',
    showModalInfo: boolean,
    selectedUser: User | undefined,

    updatedUserStatus?: boolean
    apiCallState: 'DEFAULT' | 'SEARCH' | 'VERIFY' | 'DELETE'
}

class UserManagement extends React.Component<UserManagementProps & UserManagementAction, UserManagementState>{
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            showAlert: false,
            isError: '',
            searchUser: '',
            hasMoreItems: true,
            showModalStatus: false,
            showModalInfo: false,
            selectedUser: undefined,
            updatedUserStatus: undefined,
            apiCallState: 'DEFAULT',
            modalStatus: 'KTP'
        }
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData() {
        try {
            const users = await this.props.getUsers("name_asc")
            this.props.setUsers(users)
        } catch (e: any) {
            console.error(e)
        }
    }

    async searchUser() {
        try {
            this.setState({ isLoading: true })
            const users = await this.props.getUsers("name_asc", "", this.state.searchUser)
            if (users && users.length > 0) {
                this.props.setUsers(users)
            } else {
                this.props.setUsers([])
            }
            this.setState({ apiCallState: 'SEARCH', hasMoreItems: true, showAlert: true })
            setTimeout(() => {
                this.setState({ isLoading: false, showAlert: false })
                if (!this.state.searchUser || this.state.searchUser === '') {
                    this.setState({ apiCallState: 'DEFAULT' })
                }
            }, 1000);
        } catch (e: any) {
            this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
                if (!this.state.searchUser || this.state.searchUser === '') {
                    this.setState({ apiCallState: 'DEFAULT' })
                }
            }, 3000);
        }
    }

    onSearchUser = () => {
        if (this.state.searchUser) {
            this.searchUser()
        } else {
            this.setState({ hasMoreItems: true })
            this.loadData()
        }
    }

    onAddUser = () => {
        Navigation.to('/user-management/add')
    }

    onVerifyData = (user: User) => {
        this.setState({ selectedUser: user, showModalStatus: true })
    }

    async updateStatusUser(user: User, status: string) {
        try {
            this.setState({ isLoading: true })
            if (status === 'approved') {
                await this.props.idCardApproved(user)
            } else {
                await this.props.idCardRejected(user)
            }
            await this.loadData()
            this.setState({ apiCallState: 'VERIFY', showAlert: true, showModalStatus: false, hasMoreItems: true })
            setTimeout(() => {
                this.setState({ isLoading: false, showAlert: false, apiCallState: 'DEFAULT' })
            }, 1000);
        } catch (e: any) {
            this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "", apiCallState: 'DEFAULT' })
            }, 3000);
        }
    }

    async deleteUser(user: User) {
        try {
            this.setState({ isLoading: true })
            if (user.access_level === 'general') {
                await this.props.generalDeleted(user)
            } else {
                await this.props.pmtDeleted(user)
            }
            await this.loadData()
            this.setState({ apiCallState: 'DELETE', showAlert: true, showModalStatus: false, hasMoreItems: true })
            setTimeout(() => {
                this.setState({ isLoading: false, showAlert: false, apiCallState: 'DEFAULT' })
            }, 1000);
        } catch (e: any) {
            this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "", apiCallState: 'DEFAULT' })
            }, 3000);
        }
    }

    onUpdateStatus = () => {
        if (this.state.selectedUser) {
            const user = this.state.selectedUser
            if (this.state.updatedUserStatus === true) {
                user.id_card_status = 'approved'
            } else {
                user.id_card_status = 'rejected'
            }

            this.updateStatusUser(user, user?.id_card_status)
        }
    }

    disableBtn = () => {
        if (this.state.searchUser) {
            return false
        } else {
            return true
        }
    }

    getBackgroundItemList = (index: number) => {
        if (index % 2) {
            return '#fffdfd'
        } else {
            return '#daf0ef'
        }
    }

    async onLoadMore(starting_after?: string, name?: string) {
        try {
            // this.setState({ hasMoreItems: true })
            const users = await this.props.getUsers("name_asc", starting_after, name)
            if (users && users.length > 0) {
                const currentUsers = this.props.allUsers.concat(...users)
                this.props.setUsers(currentUsers)
                this.setState({ hasMoreItems: true })
            } else {
                this.setState({ hasMoreItems: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItems: false })
        }
    }

    onClickUserMenu = (user: User, position: number) => {
        switch (position) {
            case 1:
                if (user.id_card) {
                    window.open(user.id_card, "_blank")
                } else {
                    this.setState({ showModalInfo: true, modalStatus: 'KTP' })
                }
                break
            case 2:
                if (user.organization_id) {
                    this.props.setSelectedUser(user)
                    Navigation.to('/user-management/view-organization')
                } else {
                    this.setState({ showModalInfo: true, modalStatus: 'ORGANIZATION' })
                }
                break
            case 3:
                this.deleteUser(user)
                break
        }
    }

    userList = () => {
        return this.props.allUsers.map((item, index) => {
            return (
                <Row key={index} style={{
                    margin: '0px', padding: '8px', marginLeft: '50px', marginRight: '50px', alignItems: 'center',
                    paddingLeft: '16px', paddingRight: '16px', backgroundColor: this.getBackgroundItemList(index)
                }}>
                    <div style={{ fontSize: '14px', display: 'flex', color: '#4b4b4b', width: '50px', wordBreak: 'break-all', marginRight: '16px' }}>{index + 1}</div>
                    <div style={{ fontSize: '14px', display: 'flex', color: '#4b4b4b', width: '150px', wordBreak: 'break-all', marginRight: '16px' }}>{item.name}</div>
                    <div style={{ fontSize: '14px', display: 'flex', color: '#4b4b4b', width: '150px', wordBreak: 'break-all', marginRight: '16px' }}>{item.is_social_media_register ? item.email : item.phone}</div>
                    <div style={{ fontSize: '14px', display: 'flex', color: '#4b4b4b', width: '100px', wordBreak: 'break-all', marginRight: '16px' }}>{mappingAccessLevelUser(item.access_level)}</div>
                    <div style={{ fontSize: '14px', display: 'flex', color: '#4b4b4b', width: '100px', wordBreak: 'break-all', marginRight: '16px' }}>{mappingStatusUser(item.id_card_status)}</div>
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <Row style={{ margin: '0px', padding: '0px' }}>
                            <Button style={{
                                fontWeight: 'bold',
                                color: '#5bc69a',
                                borderColor: '#5bc69a',
                                backgroundColor: 'white', opacity: '1',
                                visibility: ((item.access_level === "general" && !item.id_card_status) || item.id_card_status === 'pending' || item.id_card_status === 'rejected') ? 'visible' : 'hidden'
                            }}
                                onClick={() => this.onVerifyData(item)}>{getTranslate('Status Data')}</Button>
                            {item.access_level !== "admin" &&
                                <ContextMenuTrigger holdToDisplay={0} id={index + "_menu_trigger"}>
                                    <Button style={{
                                        color: '#5bc69a', padding: '0px', marginLeft: '16px',
                                        letterSpacing: 'unset', alignSelf: 'center', textAlign: 'center',
                                        fontWeight: 'bold', width: '28px', height: '28px',
                                        borderColor: '#5bc69a', borderRadius: '14px',
                                        backgroundColor: 'white', opacity: '1', zIndex: 0
                                    }}>{"•••"}</Button>
                                </ContextMenuTrigger>
                            }
                            <ContextMenu id={index + "_menu_trigger"}>
                                <MenuItem onClick={() => this.onClickUserMenu(item, 1)}>
                                    <Col style={{
                                        marginLeft: '24px',
                                        margin: '0dp', padding: '8px', backgroundColor: 'white', borderColor: 'rgba(0, 0, 0, 0.11)', borderStyle: 'solid',
                                        borderTopLeftRadius: '4px', borderTopRightRadius: '4px', borderWidth: '1px', cursor: 'pointer', paddingLeft: '16px', paddingRight: '16px'
                                    }}>
                                        <div style={{ fontSize: '16px', color: '#333333' }}>{getTranslate('Lihat KTP')}</div>
                                    </Col>
                                </MenuItem>
                                <MenuItem onClick={() => this.onClickUserMenu(item, 2)}>
                                    <Col style={{
                                        marginLeft: '24px',
                                        margin: '0dp', padding: '8px', borderColor: 'rgba(0, 0, 0, 0.11)', paddingLeft: '16px', paddingRight: '16px',
                                        backgroundColor: 'white', borderWidth: '1px', cursor: 'pointer', borderStyle: 'solid',
                                        borderBottomLeftRadius: ((item.access_level === "pmt" && this.props.user.access_level !== "pmt") || item.access_level === "general") ? '0px' : '4px',
                                        borderBottomRightRadius: ((item.access_level === "pmt" && this.props.user.access_level !== "pmt") || item.access_level === "general") ? '0px' : '4px'
                                    }}>
                                        <div style={{ fontSize: '16px', color: '#333333' }}>{getTranslate('Lihat Organisasi')}</div>
                                    </Col>
                                </MenuItem>
                                <MenuItem divider />
                                {((item.access_level === "pmt" && this.props.user.access_level !== "pmt") || item.access_level === "general") &&
                                    <MenuItem onClick={() => this.onClickUserMenu(item, 3)}>
                                        <Col style={{
                                            marginLeft: '24px',
                                            margin: '0dp', padding: '8px', backgroundColor: 'white', borderColor: 'rgba(0, 0, 0, 0.11)', borderStyle: 'solid',
                                            borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px', borderWidth: '1px', cursor: 'pointer',
                                            paddingLeft: '16px', paddingRight: '16px'
                                        }}>
                                            <div style={{ fontSize: '16px', color: '#1aa59a' }}>{getTranslate('Hapus User')}</div>
                                        </Col>
                                    </MenuItem>
                                }
                            </ContextMenu>
                        </Row>
                    </Col>
                </Row>
            )
        })
    }

    getAlertSuccess = () => {
        if (this.state.apiCallState === 'VERIFY') {
            return getTranslate('Berhasil verifikasi user')
        } else if (this.state.apiCallState === 'DELETE') {
            return getTranslate('Berhasil menghapus user')
        } else {
            return getTranslate('Cari user berhasil')
        }
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="user-management-container" style={{ margin: '0px', padding: '0px' }}>
                        <Row className="margin-top-16 margin-bottom-16" style={{ justifyContent: 'space-between', margin: '0px', padding: '0px', marginBottom: '56px', alignItems: 'center', marginTop: '64px' }}>
                            <div className="font-mobile-20" style={{ fontSize: '36px', color: '##333333', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{getTranslate('User Management')}</div>
                        </Row>
                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <Row style={{ margin: '0px', padding: '0px', marginBottom: '16px', flex: 1 }}>
                                <Col className="margin-bottom-16" style={{ margin: '0px', padding: '0px' }}>
                                    <div className="font-mobile-16" style={{ fontSize: '24px', fontWeight: 'bold' }}>{getTranslate('Daftar User')}</div>
                                </Col>
                                <Row className="w-100-mobile" style={{ margin: '0px', padding: '0px' }}>
                                    <Col className="margin-0" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <InputGroup>
                                            <FormControl
                                                style={{ width: '380px', paddingRight: '40px' }}
                                                placeholder={getTranslate("Cari user…")}
                                                aria-describedby="inputGroupPrepend"
                                                defaultValue={this.state.searchUser}
                                                onChange={(event: any) => this.setState({ searchUser: event.target.value })}
                                            />
                                            <Image style={{
                                                zIndex: 4, width: '18px', height: '18px', alignSelf: 'center',
                                                marginRight: '16px', cursor: 'pointer',
                                                marginLeft: '-32px'
                                            }}
                                                onClick={() => this.onSearchUser()}
                                                src={require("../../../src/assets/images/ic-actions-search/ic-actions-search.png")} />
                                        </InputGroup>
                                    </Col>
                                    <Col className="desktop-show" style={{ margin: '0px', padding: '0px' }}>
                                        <Button style={{
                                            fontWeight: 'bold',
                                            color: 'white',
                                            borderColor: '#5bc69a', paddingLeft: '32px', paddingRight: '32px',
                                            backgroundColor: '#5bc69a', opacity: '1'
                                        }} onClick={() => this.onAddUser()} block>{getTranslate('Add New User')}</Button>
                                    </Col>
                                </Row>
                                <Col className="mobile-show" style={{ margin: '0px', padding: '0px' }}>
                                    <Button style={{
                                        fontWeight: 'bold',
                                        color: 'white',
                                        borderColor: '#5bc69a', paddingLeft: '32px', paddingRight: '32px',
                                        backgroundColor: '#5bc69a', opacity: '1'
                                    }} onClick={() => this.onAddUser()} block>{getTranslate('Add New User')}</Button>
                                </Col>
                            </Row>

                            <Card style={{
                                margin: '0px', padding: '20px', paddingLeft: '80dp', paddingRight: '80dp',
                                paddingTop: '40px', paddingBottom: '40px', borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px', cursor: 'default'
                            }}>
                                {this.props.allUsers && this.props.allUsers.length > 0 ?
                                    <Col style={{ margin: '0px', padding: '0px' }}>
                                        <Row className="desktop-show-flex" style={{
                                            margin: '0px', padding: '8px', marginLeft: '50px', marginRight: '50px',
                                            paddingLeft: '16px', paddingRight: '16px', alignItems: 'center'
                                        }}>
                                            <div style={{ fontSize: '14px', color: '#4b4b4b', fontWeight: 'bold', width: '50px', wordBreak: 'break-all', marginRight: '16px' }}>No.</div>
                                            <div style={{ fontSize: '14px', color: '#4b4b4b', fontWeight: 'bold', width: '150px', wordBreak: 'break-all', marginRight: '16px' }}>{getTranslate('Nama')}</div>
                                            <div style={{ fontSize: '14px', color: '#4b4b4b', fontWeight: 'bold', width: '150px', wordBreak: 'break-all', marginRight: '16px' }}>{getTranslate('Login With')}</div>
                                            <div style={{ fontSize: '14px', color: '#4b4b4b', fontWeight: 'bold', width: '100px', wordBreak: 'break-all', marginRight: '16px' }}>{getTranslate('Akses Level')}</div>
                                            <div style={{ fontSize: '14px', color: '#4b4b4b', fontWeight: 'bold', width: '100px', wordBreak: 'break-all', marginRight: '16px' }}>{getTranslate('Status User')}</div>
                                            <Col style={{ margin: '0px', padding: '0px' }} lg={4} />
                                        </Row>
                                        <div style={{
                                            marginLeft: '50px', marginRight: '50px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.15)',
                                            height: '1px', marginTop: '8px'
                                        }} />
                                        <InfiniteScroll
                                            scrollThreshold={0.5}
                                            dataLength={this.props.allUsers.length}
                                            next={() => this.onLoadMore(this.props.allUsers[this.props.allUsers.length - 1].id, this.state.searchUser)}
                                            hasMore={this.state.hasMoreItems}
                                            loader={
                                                <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                                                    <Spinner size="sm" animation="border" variant="secondary" />
                                                </Row>
                                            }>
                                            {this.userList()}
                                        </InfiniteScroll>
                                    </Col>
                                    :
                                    <Col style={{ margin: '0px', padding: '0px' }}>
                                        <Col style={{
                                            margin: '0px', padding: '8px', alignItems: 'center',
                                            paddingLeft: '16px', paddingRight: '16px', textAlign: 'center'
                                        }}>
                                            <Image
                                                src={this.state.apiCallState === 'SEARCH' ?
                                                    require("../../../src/assets/images/personal-data/personal-data.png")
                                                    :
                                                    require("../../../src/assets/images/user-profile/user-profile.png")} />
                                            <div style={{ fontSize: '16px', color: '#808080', marginTop: '40px' }}>
                                                {this.state.apiCallState === 'SEARCH' ? getTranslate('User tidak ditemukan.') : getTranslate('Belum ada user')}
                                            </div>
                                        </Col>
                                    </Col>
                                }
                            </Card>
                        </Col>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : this.getAlertSuccess()}
                                </div>
                            </Alert>
                        }

                        <Modal
                            show={this.state.showModalStatus}
                            centered
                        >
                            <Modal.Header closeButton onClick={() => this.setState({ showModalStatus: false, updatedUserStatus: undefined })} style={{
                                padding: '24px'
                            }}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <div style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#333333',
                                    }}>{getTranslate('Status Data')}</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{
                                padding: '24px'
                            }}>
                                <Row style={{ margin: '0px', padding: '0px' }}>
                                    <Col style={{ margin: '0px', padding: '0px' }}>
                                        <div style={{ display: 'flex' }} className="ui radio checkbox">
                                            <input type="radio" defaultChecked={this.state.updatedUserStatus === true ?
                                                true : undefined} name="is_pv_battery" value="Approved"
                                                onClick={() => this.setState({ updatedUserStatus: true })}
                                            />
                                            <label>{getTranslate('Approved')}</label>
                                        </div>

                                        {(!this.state.selectedUser || this.state.selectedUser.id_card_status !== 'rejected') &&
                                            <div style={{ display: 'flex', marginTop: '8px' }} className="ui radio checkbox">
                                                <input type="radio" defaultChecked={this.state.updatedUserStatus === false ?
                                                    true : undefined} name="is_pv_battery" value="Rejected"
                                                    onClick={() => this.setState({ updatedUserStatus: false })}
                                                />
                                                <label>{getTranslate('Rejected')}</label>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer style={{
                                padding: '24px'
                            }}>
                                <Button style={{
                                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                    color: this.state.updatedUserStatus === undefined ? '#808080' : 'white',
                                    borderColor: this.state.updatedUserStatus === undefined ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.state.updatedUserStatus === undefined ? '#cccccc' : '#5bc69a', opacity: '1'
                                }} disabled={this.state.updatedUserStatus === undefined ? true : false}
                                    onClick={() => this.onUpdateStatus()} block
                                    className={this.state.isLoading ? "ui primary loading button" : ""}
                                >{getTranslate('Verifikasi')}</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.showModalInfo}
                            centered
                        >
                            <Modal.Header closeButton onClick={() => this.setState({ showModalInfo: false })} style={{
                                padding: '24px'
                            }}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <div style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#333333',
                                    }}>{this.state.modalStatus === 'KTP' ? getTranslate('KTP') : getTranslate('Organisasi')}</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{
                                padding: '24px'
                            }}>
                                <div style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    color: '#333333',
                                }}>
                                    {this.state.modalStatus === 'KTP' ? getTranslate('User ini tidak memiliki KTP') : getTranslate('User ini tidak memiliki organisasi')}
                                </div>
                            </Modal.Body>
                            <Modal.Footer style={{
                                padding: '24px'
                            }}>
                                <Button style={{
                                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                    color: 'white',
                                    borderColor: '#5bc69a',
                                    backgroundColor: '#5bc69a', opacity: '1'
                                }}
                                    onClick={() => this.setState({ showModalInfo: false })} block
                                >{getTranslate('Tutup')}</Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        allUsers: state.user.allUser
    } as UserManagementProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        setUsers: (user: User[]) => dispatch(setAllUser(user)),
        setSelectedUser: (user: User) => dispatch(setSelectedUser(user)),
        getUsers: (sortBy: string, startingAfter?: string, name?: string) => getUsers(undefined, startingAfter, sortBy, undefined, undefined, undefined, name),
        idCardApproved: (user: User) => idCardApproved(user),
        idCardRejected: (user: User) => idCardRejected(user),
        generalDeleted: (user: User) => generalDeleted(user),
        pmtDeleted: (user: User) => pmtDeleted(user)
    } as UserManagementAction;
}

export default connect(stateToProps, dispatchToProps)(UserManagement);
