/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Navbar,
    Nav,
    Image,
    Row,
    Col,
    Alert,
} from 'react-bootstrap';
import '../Login/style.css';
import LoginComponent from '../../component/login/login';
import RegisterComponent from '../../component/register/register';
import ForgotComponent from '../../component/forgot/forgot';
import { Authenticate } from '../../model/payload/Authenticate';
import { Forgot } from '../../model/payload/Forgot';
import { UserState, setUser, me, register, hasBack } from '../../reducers/User';
import { authenticate } from '../../reducers/Auth';
import { setToken } from '../../reducers/Auth';
import { Token } from '../../model/Token';
import { Register } from '../../model/payload/Register';
import Navigation from '../../utils/Navigation';
import { removeAllSpecialChar, getTranslate } from '../../utils/General';
import languageHelper from '../../utils/LanguageHelper';
import { Project } from '../../model/Project';
import { trackDonationPage } from '../../reducers/Project';



export interface LoginProps {
    userState: UserState
    selectedProject: Project
    loading: boolean
    amount: string
    isAnonim: boolean
}

export interface LoginAction {
    // setLoading(it: boolean): any
    register(payload: Register): any
    authenticate(payload: Authenticate): any
    setToken(token: Token): void
    setHashBack(has: boolean): void
}

export interface LoginState {
    current: String
    showAlert: Boolean
    loading: boolean
    isError: string
    isEnglish: boolean
}

class Login extends React.Component<LoginProps & LoginAction, LoginState>{

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            showAlert: false,
            current: "login",
            isError: "",
            isEnglish: false
        }
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({ isEnglish: languageHelper.getLanguage() })
    }

    async register(name: string, phone: string, password: string) {
        const payload: Register = {
            name,
            phone: phone,
            password: removeAllSpecialChar(password),
            is_social_media_register: false
        }
        try {
            // this.props.setLoading(true)
            this.setState({ loading: true })
            await this.props.register(payload)
            this.setState({ showAlert: true, loading: false, })
            setTimeout(() => {
                this.setState({ showAlert: false })
            }, 5000);
        } catch (err: any) {
            this.setState({ loading: false, isError: err.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async registerSocialMedia(email: string, password: string, name?: string, pictureUrl?: string) {
        const payload: Register = {
            name: name ? removeAllSpecialChar(name) : '',
            picture_url: pictureUrl ? removeAllSpecialChar(pictureUrl) : '',
            email: email ? removeAllSpecialChar(email) : '',
            password: password ? removeAllSpecialChar(password) : '',
            is_social_media_register: true
        }
        try {
            // this.props.setLoading(true)
            this.setState({ loading: true })
            await this.props.register(payload)
            this.setState({ showAlert: true, loading: false })
            setTimeout(() => {
                this.setState({ showAlert: false })
            }, 5000);
        } catch (err: any) {
            this.setState({ loading: false, isError: err.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async login(phone: string, password: string) {
        // console.log(this.props)
        // return
        const payload: Authenticate = {
            phone: phone,
            password: removeAllSpecialChar(password)
        }
        try {

           
            this.setState({ loading: true })
            const token = await this.props.authenticate(payload)
            this.props.setToken(token)
            this.setState({ loading: false })

            if(this.props.selectedProject) {
                trackDonationPage(true)
                Navigation.to('/dashboard/detail/donate')
            } else {
                Navigation.to('/dashboard')
            }
        } catch (err: any) {
            this.setState({ loading: false, isError: err.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }
    async forgot(phone: string) {
        const payload: Forgot = {
            phone: phone
        }
        try {
            this.setState({ loading: true })
            const token = await this.props.authenticate(payload)
            this.props.setToken(token)
            this.setState({ loading: false })
            Navigation.to('/dashboard')
        } catch (err: any) {
            this.setState({ loading: false, isError: err.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async loginSocialMedia(email: string, password: string) {
        const payload: Authenticate = {
            email: email ? removeAllSpecialChar(email) : '',
            password: password ? removeAllSpecialChar(password) : ''
        }
        try {
            this.setState({ loading: true })
            const token = await this.props.authenticate(payload)
            this.props.setToken(token)
            this.setState({ loading: false })
            Navigation.to('/dashboard')
        } catch (err: any) {
            this.setState({ loading: false, isError: getTranslate("user not found"), showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    onPrivacyPolicyClick = () => {
        this.props.setHashBack(true)
        Navigation.to('/privacy-policy')
    }

    onTNCClick = () => {
        this.props.setHashBack(true)
        Navigation.to('/tnc')
    }

    onChangeLanguage = (b: boolean) => {
        this.setState({ isEnglish: b })
        languageHelper.setLanguage(b)
    }

    render() {
        return (
            <div id="login-page" style={{backgroundColor: '#d6ffef'}}>
                <Row className="row-login" style={{ justifyContent: 'space-between', height: '114.5vh' }}>
                    <Image className="img-overlay-login" src={require('../../../src/assets/images/bg_login/bg_login.png')} />
                    <Col className="leftSideLogin" style={{ margin: '0px', padding: '0px', backgroundColor: 'rgba(26, 165, 154, 0.8)', width: '50%' }}>
                        {/* ini bagian atas kiri */}
                        <Row style={{
                            margin: '40px', padding: '0px', position: 'absolute',
                            marginLeft: '50px', top: '0'
                        }}>
                            <Image className="logo-green-login" src={require('../../../src/assets/images/logo/logo.png')} />
                        </Row>
                        {/* ini bagian bawah kiri */}
                        <Row style={{ margin: '40px', padding: '0px', position: 'static', marginTop:'70%', bottom: '0', justifyContent: 'flex-end' }}>
                            <p style={{ fontWeight: 'bold', fontSize: '48px', color: '#99fffa', marginBottom: '40px' }}>
                                {getTranslate('Bersama Membangun Indonesia Hijau')}
                            </p>
                            <div className="p-login-overlay" style={{ fontSize: '16px', color: 'white', marginBottom: '16px' }}>
                                {getTranslate('“We raised more than $1M USD for the installation of solar panel in a remote school. Indonesia Green ran Facebook ads from day one, and raised more than $500k through tracked links. We highly recommend Indonesia Green”')}
                            </div>
                            <Row style={{
                                margin: '0px', padding: '0px',
                                float: 'right'
                            }}>
                            </Row>
                        </Row>
                    </Col>

                    <Col className="rightSideLogin" style={{ margin: '0px', padding: '0px', backgroundColor: '#d6ffef', width: '50%' }}>
                        <Row className="language-container" style={{
                            margin: '0px', padding: '0px', cursor: 'pointer',
                            position: 'absolute', top: 0, left: 0, right: 0, justifyContent: 'flex-end',
                            marginLeft: '96px', marginRight: '96px', marginTop: '40px'
                        }} onClick={() => {
                            this.state.isEnglish ? this.onChangeLanguage(false) : this.onChangeLanguage(true)
                        }}>
                            <Image style={{ objectFit: 'contain' }} src={require('../../../src/assets/images/globe/globe.png')} />
                            <div style={{ color: '#4b4b4b', fontWeight: 'bold', fontSize: '14px', marginLeft: '8px' }}>
                                {this.state.isEnglish ? 'English' : 'Indonesia'}
                            </div>
                        </Row>
                        {this.state.showAlert === true &&
                            <div id="alert">
                                <Alert style={{
                                    position: 'absolute', top: 0, left: 0, right: 0,
                                    marginLeft: '96px', marginRight: '96px', marginBottom: '40px',
                                    alignItems: 'center', backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : '#0cca97',
                                    marginTop: '16px', padding: '16px', paddingLeft: '32px', paddingRight: '32px'
                                }} >
                                    <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : 'white', textAlign: 'center' }}>
                                        {this.state.isError ? this.state.isError : getTranslate('Anda berhasil mendaftar. Silakan ')}
                                        {!this.state.isError && <a style={{ fontWeight: 'bold', fontSize: '16px', color: '#99fffa' }} onClick={() => this.setState({ current: 'login' })}>
                                            {getTranslate('Login')}
                                        </a>
                                        }
                                    </div>
                                </Alert>
                            </div>
                        }
                        <Col style={{ position: 'absolute' }} className="tree" />
                        {
                            this.state.current === "login" ?
                                <LoginComponent
                                    loading={this.state.loading}
                                    onLoginClick={
                                        (phone, password) => this.login(phone, password)
                                    }
                                    onLoginClickSocialMedia={
                                        (email, password) => this.loginSocialMedia(email, password)
                                    }
                                    onRegisterClick={() => this.setState({ current: "register" })}
                                    onForgotPassword={() => this.setState({ current: "forgot" })}
                                    onPrivacyPolicyClick={this.onPrivacyPolicyClick}
                                    onTNCClick={this.onTNCClick}
                                />
                                : (this.state.current === "register" ) ?
                                    <RegisterComponent
                                        loading={this.state.loading}
                                        onRegisterClick={
                                            (name, phone, password) => this.register(name, phone, password)
                                        }
                                        onRegisterClickSocialMedia={
                                            (email, password, name, pictureUrl) => this.registerSocialMedia(email, password, name, pictureUrl)
                                        }
                                        onLoginClick={() => this.setState({ current: "login" })}
                                        onPrivacyPolicyClick={this.onPrivacyPolicyClick}
                                        onTNCClick={this.onTNCClick}
                                    />
                                    :
                                    <ForgotComponent
                                        loading={this.state.loading}
                                        
                                        onLoginClick={() => this.setState({ current: "login" })}
                                        onForgotClick={(phone) => this.forgot(phone)}
                                        onPrivacyPolicyClick={this.onPrivacyPolicyClick}
                                        onTNCClick={this.onTNCClick}
                                    />
                        }
                    </Col>
                </Row>
            </div>

        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        userState: state.user,
        selectedProject: state.project.selectedProject,

    } as LoginProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        // setLoading: (it: boolean) => setLoading(it),
        register: (payload: Register) => register(payload),
        authenticate: (payload: Authenticate) => authenticate(payload),
        setToken: (token: Token) => dispatch(setToken(token)),
        setHashBack: (has: boolean) => dispatch(hasBack(has)),
        trackDonationPage: (a: boolean) => dispatch(trackDonationPage(a)),
    } as LoginAction;
}

export default connect(stateToProps, dispatchToProps)(Login);
