import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../../AppState';
import {
    Image,
    Row,
    Col,
    Card,
    Accordion,
    Button,
    FormControl,
    InputGroup,
    FormLabel,
    ProgressBar,
    Alert,
    Badge,
    Spinner
} from 'react-bootstrap';
import './style.css';

import NavbarComponent from '../../../component/navbar';
import ListMyProjectComponent from '../../../component/listMyProject';
import { Project } from '../../../model/Project';
import { User } from '../../../model/User';
import { getProjects, setMyProject, setSelectedProject, trackProjectPage, editProjectPMT } from '../../../reducers/Project';
import { Dispatch } from 'redux';
import { Organization } from '../../../model/Organization';
import { Media } from '../../../model/Media';
import { getOrganization, setOrganization } from '../../../reducers/User';
import { setUploadedMedia, getUploadedMedia, clearMedia } from '../../../reducers/Media';
import { getStatusColor, toTitleCase, getFileExtensions, bytesToSize, validateEmail, validateUrl, removeAllSpecialChar, getInitialName, getTranslate, projectStatusMapper, paymentMethodMapper } from '../../../utils/General';
import { Editor } from '@tinymce/tinymce-react';
import { toCurrency } from '../../../utils/Currency';
import { convertToIDDateString, dateStringToDate, changeStringDateFormat, toDate, toLocalTime, humanDate } from '../../../utils/Date';
import DatePicker from 'react-date-picker';
import { ProjectPMTBody } from '../../../model/payload/ProjectPMTBody';
import Navigation from '../../../utils/Navigation';
import { Donation } from '../../../model/Donation';
import { Progress } from '../../../model/Progress';
import { getDonations, setDonationByProject } from '../../../reducers/Donation';
import { getProgress, setProgressByProject } from '../../../reducers/Progress';
import { getSummaryDonation, getSummaryProgress } from '../../../reducers/Summary';
import { SummaryDonation } from '../../../model/SummaryDonation';
import { SummaryProgress } from '../../../model/SummaryProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import NumberFormat from 'react-number-format';
import Truncate from 'react-truncate';

export interface PMTDashboardDetailPageProps {
    user: User,
    project: Project,
    media: Media[],
    allUser: User[],
    donation: Donation[],
    progress: Progress[],
}

export interface PMTDashboardDetailPageAction {
    editProjectPMT(project_id: string, payload: ProjectPMTBody): any
    setMediaUpload(medias: Media[]): void
    mediaUpload(data: FormData, id_user: string, file_type: string): any
    clearMedia(): void
    setDonationByProject(donation: Donation[]): void
    setProgressByProject(progress: Progress[]): void
    getDonations(project_id: string, sortBy: string, starting_after?: string, fromDate?: string, toDate?: string): any
    getProgress(project_id: string, sortBy: string, starting_after?: string): any
    getSummaryDonation(project_id: string): any
    getSummaryProgress(project_id: string): any
    trackProjectPage(a: boolean): void
}

export interface PMTDashboardDetailPageState {
    project_status: 'pending' | 'rejected' | 'open funding' | 'close funding' | 'project started' | 'project ended' | 'no-pending' | '',
    open_funding_date: Date | undefined,
    close_funding_date: Date | undefined,
    project_started_date: Date | undefined,
    project_ended_date: Date | undefined,
    loading: boolean,
    loadingUploadImage: boolean,
    showAlert: Boolean,
    isError: string

    //project details
    name: string,
    description: string,
    address_project: string,
    target_amount?: number,
    electrical_power?: number,
    electrical_target?: number,
    pv_location: string,
    is_pv_battery?: boolean,
    is_experience_contractor?: boolean,
    is_started_project?: boolean,
    creator_is_owner?: boolean,
    doc: string[],

    imageFile: string
    imageFormData?: FormData

    hasMoreItemsDonation: boolean
    hasMoreItemsProgress: boolean
    donationCount: number
    progressCount: number
    textExpandedProgress: boolean
    indexExpanded: number

    from_date: Date | undefined,
    to_date: Date | undefined,
}

class PMTDashboardDetailPage extends React.Component<PMTDashboardDetailPageProps & PMTDashboardDetailPageAction, PMTDashboardDetailPageState>{
    private fileUploader: any | null
    constructor(props: any) {
        super(props);
        this.state = {
            project_status: "",
            open_funding_date: undefined,
            close_funding_date: undefined,
            project_started_date: undefined,
            project_ended_date: undefined,
            loading: false,
            showAlert: false,
            isError: "",

            //project details
            name: "",
            description: "",
            address_project: "",
            target_amount: undefined,
            electrical_power: undefined,
            electrical_target: undefined,
            pv_location: "",
            is_pv_battery: undefined,
            is_experience_contractor: undefined,
            is_started_project: undefined,
            creator_is_owner: undefined,
            doc: [],

            loadingUploadImage: false,
            imageFile: "",

            hasMoreItemsDonation: true,
            hasMoreItemsProgress: true,
            donationCount: 0,
            progressCount: 0,
            textExpandedProgress: false,
            indexExpanded: -1,
            from_date: undefined,
            to_date: undefined
        }
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    async setProject() {
        const p = this.props.project
        if (this.props.project) {
            this.setState({
                project_status: p.status,
                open_funding_date: p.open_funding_date ? new Date(toLocalTime(p.open_funding_date)) : undefined,
                close_funding_date: p.close_funding_date ? new Date(toLocalTime(p.close_funding_date)) : undefined,
                project_started_date: p.project_started_date ? new Date(toLocalTime(p.project_started_date)) : undefined,
                project_ended_date: p.project_ended_date ? new Date(toLocalTime(p.project_ended_date)) : undefined,
                name: p.name,
                description: p.description,
                address_project: p.address,
                target_amount: p.target_amount ? parseInt(p.target_amount) : 0,
                electrical_power: p.current_electricity ? parseFloat(p.current_electricity) : 0,
                electrical_target: p.electricity_request ? parseFloat(p.electricity_request) : 0,
                pv_location: p.electricity_placement,
                is_pv_battery: p.has_electricity_battery,
                is_experience_contractor: p.has_exp_contractor,
                is_started_project: p.has_started,
                creator_is_owner: p.creator_is_owner,
                doc: p.submitted_doc,
            })

            if (p.submitted_doc && p.submitted_doc.length > 0) {
                const medias: Media[] = []
                p.submitted_doc.forEach(pro => {
                    medias.push({
                        data: pro,
                        size: '-'
                    })
                })
                this.props.setMediaUpload(medias)
            }

            const donations: Donation[] = await this.props.getDonations(this.props.project.id, "updated_at_desc")
            const progress: Progress[] = await this.props.getProgress(this.props.project.id, "updated_at_desc")
            const donationCount: SummaryDonation = await this.props.getSummaryDonation(this.props.project.id)
            const progressCount: SummaryProgress = await this.props.getSummaryProgress(this.props.project.id)

            // const filteredDonation: FilteredDonation = filteredDonations(donations)
            if (donations && donations.length > 0)
                this.props.setDonationByProject(donations)
            this.setState({
                donationCount: (donationCount.count),
                progressCount: progressCount.count
            })
            if (progress && progress.length > 0)
                this.props.setProgressByProject(progress)
        }
    }

    async onLoadMoreDonation(starting_after?: string) {
        try {
            this.setState({ hasMoreItemsDonation: true })
            let donations: Donation[] = []
            if (this.state.from_date && this.state.to_date) {
                donations = await this.props.getDonations(this.props.project.id, "updated_at_desc", starting_after,
                    this.state.from_date.toISOString(), this.state.to_date.toISOString())
            } else {
                donations = await this.props.getDonations(this.props.project.id, "updated_at_desc", starting_after)
            }
            if (donations && donations.length > 0) {
                const currentDonations = this.props.donation.concat(...donations)
                this.props.setDonationByProject(currentDonations)
                this.setState({ hasMoreItemsDonation: false })
            } else {
                this.setState({ hasMoreItemsDonation: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItemsDonation: false })
        }
    }

    async onLoadMoreProgress(starting_after?: string) {
        try {
            this.setState({ hasMoreItemsProgress: true })
            const progress = await this.props.getProgress(this.props.project.id, "updated_at_desc", starting_after)
            if (progress && progress.length > 0) {
                const currentProgress = this.props.progress.concat(...progress)
                this.props.setProgressByProject(currentProgress)
                this.setState({ hasMoreItemsProgress: false })
            } else {
                this.setState({ hasMoreItemsProgress: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItemsProgress: false })
        }
    }

    handleEditorChange(e: any) {
        this.setState({ description: e.target.getContent() });
    }

    componentDidMount() {
        this.setProject()
    }

    componentWillUnmount() {
        this.props.clearMedia()
        this.props.setDonationByProject([])
        this.props.setProgressByProject([])
    }

    onBackHome() {
        Navigation.to('/pmt-dashboard')
    }

    validationButton = () => {
        const s = this.state
        if ((s.name && (s.name !== this.props.project.name)) ||
            (s.description && (s.description !== this.props.project.description)) ||
            (s.address_project && (s.address_project !== this.props.project.address)) ||
            (s.target_amount && (("" + s.target_amount) !== this.props.project.target_amount)) ||
            (s.electrical_power && (("" + s.electrical_power) !== this.props.project.current_electricity)) ||
            (s.electrical_target && (("" + s.electrical_target) !== this.props.project.electricity_request)) ||
            (s.pv_location && (s.pv_location !== this.props.project.electricity_placement)) ||
            (s.is_pv_battery !== undefined && (s.is_pv_battery !== this.props.project.has_electricity_battery)) ||
            (s.is_experience_contractor !== undefined && (s.is_experience_contractor !== this.props.project.has_exp_contractor)) ||
            (s.is_started_project !== undefined && (s.is_started_project !== this.props.project.has_started)) ||
            (s.creator_is_owner !== undefined && (s.creator_is_owner !== this.props.project.creator_is_owner)) ||
            this.state.imageFormData ||
            (s.project_status && s.project_status !== this.props.project.status) ||
            (s.open_funding_date || s.close_funding_date || s.project_started_date || s.project_ended_date) ||
            (this.props.project.submitted_doc && this.props.project.submitted_doc.length !== this.props.media.length)) {
            return false
        } else {
            return true
        }
    }

    async onEditProject() {
        this.setState({ loading: true })
        const s = this.state

        const submitDocs: string[] = []
        if (this.props.media && this.props.media.length > 0) {
            this.props.media.forEach(pro => {
                submitDocs.push(pro.data)
            })
        }

        let payload: ProjectPMTBody = {
            status: s.project_status,
            open_funding_date: s.open_funding_date ? s.open_funding_date.toISOString() : '',
            close_funding_date: s.close_funding_date ? s.close_funding_date.toISOString() : '',
            project_started_date: s.project_started_date ? s.project_started_date.toISOString() : '',
            project_ended_date: s.project_ended_date ? s.project_ended_date.toISOString() : '',
            name: removeAllSpecialChar(s.name),
            description: removeAllSpecialChar(s.description),
            target_amount: s.target_amount ? s.target_amount : 0,
            address: removeAllSpecialChar(s.address_project),
            current_electricity: s.electrical_power ? s.electrical_power : 0,
            electricity_request: s.electrical_target ? s.electrical_target : 0,
            electricity_placement: removeAllSpecialChar(s.pv_location),
            has_electricity_battery: s.is_pv_battery || false,
            has_exp_contractor: s.is_experience_contractor || false,
            has_started: s.is_started_project || false,
            creator_is_owner: s.creator_is_owner || false,
            submitted_doc: submitDocs
        };
        try {
            if (this.state.imageFormData) {
                const media: Media = await this.props.mediaUpload(this.state.imageFormData, this.props.user.id, 'project_pict')
                payload.picture_url = media.data
            }

            await this.props.editProjectPMT(this.props.project.id, payload)
            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ loading: false, showAlert: false })
                this.onBackHome()
            }, 1000);
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    clickUpdateProject = () => {
        this.onEditProject()
    }

    async upload(data: FormData) {
        this.setState({ loadingUploadImage: true })
        try {
            const media: Media = await this.props.mediaUpload(data, this.props.user.id, 'project_doc')
            const medias = this.props.media
            medias.push(media)
            this.setState({ loadingUploadImage: false })
            this.props.setMediaUpload(medias)
        } catch (e: any) {
            this.setState({ loadingUploadImage: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    onClickUploadPictureUrl = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);
            this.setState({
                imageFile: URL.createObjectURL(event.target.files[0]),
                imageFormData: data
            });
        }
    }

    onClickUpload = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            if (file.size >= 5000000) {
              this.setState({
                loadingUploadImage: false,
                isError: getTranslate("File size cannot upload more than 5MB"),
                showAlert: true,
              });
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              return false;
            }

            let data = new FormData();
            data.append('file_data', file);
            this.upload(data)
        }
    }

    getPictureUrl = (project: Project) => {
        if (this.state.imageFile) {
            return (<Image style={{
                width: '320px',
                height: '187px',
                objectFit: 'cover', borderRadius: '4px'
            }} src={this.state.imageFile} />)
        } else {
            if (project.picture_url) {
                return (<Image style={{
                    width: '320px',
                    height: '187px',
                    objectFit: 'cover', borderRadius: '4px'
                }} src={project.picture_url} />)
            } else {
                return (<Image style={{
                    width: '320px',
                    height: '187px',
                    objectFit: 'cover', borderRadius: '4px'
                }} src={require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />)
            }
        }
    }

    addProgress = () => {
        this.props.trackProjectPage(true)
        Navigation.to('/add-progress')
    }

    projectStatusView() {
        const projectStatus: string[] = []

        if (this.props.project.status === 'pending') {
            projectStatus.push('rejected')
            projectStatus.push('open funding')
        } else if (this.props.project.status === 'rejected') {
            projectStatus.push('pending')
            projectStatus.push('open funding')
        } else if (this.props.project.status === 'open funding') {
            projectStatus.push('close funding')
        } else if (this.props.project.status === 'close funding') {
            projectStatus.push('project started')
        } else if (this.props.project.status === 'project started') {
            projectStatus.push('project ended')
        }

        return projectStatus.map((item, index) => {
            return (
                <option key={index} value={item}>{projectStatusMapper(item)}</option>
            )
        })
    }

    onClickProgressMedia = (link: string) => {
        window.open(link, "_blank")
    }

    onExpandStoryProgress = (index: number) => {
        this.setState({ textExpandedProgress: true, indexExpanded: index })
    }

    renderProgressMediaList(mediaList: string[]) {
        return mediaList.map((item, index) => {
            return (
                <Row key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{
                        margin: '0px', padding: '0px',
                        width: '64px', height: '64px', marginRight: '16px',
                        backgroundColor: '#cccccc', borderRadius: '4px',
                        alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
                    }} onClick={() => this.onClickProgressMedia(item)}>
                        {validateUrl(item) && (getFileExtensions(item.split('.')) === 'PNG' || getFileExtensions(item.split('.')) === 'JPG' || getFileExtensions(item.split('.')) === 'JPEG') ?
                            <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item} />
                            :
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(item.split('.'))}</div>
                        }
                    </Row>
                </Row>
            )
        })
    }

    listProgress = () => {
        if (this.props.progress && this.props.progress.length > 0)
            return this.props.progress.map((item, index) => {
                return (
                    <div key={item.id}>
                        <Row style={{ margin: '0px', padding: '0px', marginLeft: '82px', marginRight: '82px', paddingBottom: '16px' }} >
                            <div style={{ marginTop: '6px', marginBottom: '18px', marginRight: '8px' }}>
                                <div style={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '3px',
                                    backgroundColor: '#5bc69a',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }} />
                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    backgroundColor: '#5bc69a',
                                    marginTop: '8px',
                                    marginLeft: '2.5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: 'center-horizontal'
                                }} />
                            </div>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <div style={{
                                        color: '#5bc69a',
                                        fontSize: '14px',
                                    }}>{convertToIDDateString(item.updated_at)}</div>
                                    <div style={{
                                        marginTop: '4px',
                                        color: '#808080',
                                        fontSize: '10px'
                                    }}>{getTranslate('oleh ') + item.user_name + ', ' +
                                        ((this.props.project.user_id === item.user_id) ? getTranslate('Penyelenggara') : 'PMT')}</div>
                                    <div style={{
                                        marginTop: '8px',
                                        color: '#4b4b4b',
                                        fontSize: '14px'
                                    }}>
                                        <div style={{ fontWeight: 'bold' }}>{item.progress_name}</div>
                                        <div>
                                            <Truncate
                                                lines={this.state.indexExpanded !== index ? undefined : (!this.state.textExpandedProgress && 4)}
                                                ellipsis={(
                                                    <a>
                                                        <a>...</a>
                                                        <div style={{ marginTop: '8px', fontSize: '12px', fontWeight: 'bold', color: '#1aa59a', cursor: 'pointer' }} onClick={() => this.onExpandStoryProgress(index)}>{getTranslate('Baca Selengkapnya')}</div>
                                                    </a>
                                                )}
                                            >
                                                <p dangerouslySetInnerHTML={{ __html: item.progress_desc }} />
                                            </Truncate>
                                        </div>
                                    </div>
                                    {item.amount && item.amount !== "0" &&
                                        <div style={{
                                            marginTop: '8px',
                                            color: '#4b4b4b',
                                            fontSize: '12px'
                                        }}>{getTranslate("Jumlah Amount: ") + toCurrency(item.amount)}</div>
                                    }
                                </Col>
                                <Row style={{ margin: '0px', padding: '0px' }}>
                                    {item.invoice_doc && item.invoice_doc.length > 0 && this.renderProgressMediaList(item.invoice_doc)}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )
            })
    }

    getProfilePict = (item: Donation) => {
        if (item.is_anonim || !item.user_name) {
            return (
                <Image style={{
                    width: '16px',
                    height: '16px', objectFit: 'contain'
                }} src={require("../../../../src/assets/images/ic-user-2-copy/ic-user-2-copy.png")} />
            )
        } else {
            if (item.user_picture_url) {
                return (
                    <Image style={{
                        width: '32px',
                        height: '32px', objectFit: 'cover'
                    }} roundedCircle src={item.user_picture_url} />
                )
            } else {
                return getInitialName(item.user_name || getTranslate('anonim'))
            }
        }
    }

    listDonations = () => {
        if (this.props.donation && this.props.donation.length > 0)
            return this.props.donation.map((item) => {
                return (
                    <div key={item.id}>
                        <Row className="card-donation-list-item" style={{ margin: '0px', padding: '0px', paddingTop: '24px', paddingBottom: '24px', marginLeft: '82px', marginRight: '82px' }}>
                            <Row style={{
                                margin: '0px', padding: '0px',
                                width: '32px',
                                height: '32px',
                                borderRadius: '16px',
                                backgroundColor: '#d8d8d8',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                color: '#4b4b4b',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                marginRight: '16px'
                            }}> {this.getProfilePict(item)} </Row>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div style={{
                                    color: '#5bc69a',
                                    fontSize: '14px',
                                }}>{item.is_anonim ? (item.user_name.charAt(0) + "*****" +
                                    item.user_name.slice(-1)) :
                                    (item.user_name ?
                                        item.user_name : getTranslate('anonim'))}</div>
                                <div style={{
                                    marginTop: '4px',
                                    color: '#4b4b4b',
                                    fontSize: '12px'
                                }}>{getTranslate('Donasi ') + toCurrency(item.amount)}</div>
                                <div style={{
                                    marginTop: '4px',
                                    color: '#808080',
                                    fontSize: '10px'
                                }}>{"via " + paymentMethodMapper(item.payment_method) + " • " + humanDate(item.updated_at)}</div>
                            </Col>
                        </Row>

                        <div className="garis-donation-item" style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            height: '1px',
                            marginLeft: '82px', marginRight: '82px'
                        }} />
                    </div>
                )
            })
    }

    onRemoveMedia = (indexMedia: number) => {
        let medias = this.props.media

        if (medias.length <= 1) {
            medias = []
        } else {
            medias.forEach((item: Media, index: number) => {
                if (indexMedia === index) {
                    medias.splice(medias.indexOf(item), 1);
                }
            })
        }

        this.props.setMediaUpload(medias)
    }

    async filterDonationDate() {
        if (this.state.from_date && this.state.to_date) {
            const donations: Donation[] = await this.props.getDonations(this.props.project.id, "updated_at_desc", "",
                this.state.from_date.toISOString(), this.state.to_date.toISOString())
            this.props.setDonationByProject(donations)
        } else if (!this.state.from_date && !this.state.to_date) {
            const donations: Donation[] = await this.props.getDonations(this.props.project.id, "updated_at_desc")
            this.props.setDonationByProject(donations)
        }
    }

    onFilterDonationDate = (isFromDate: boolean, date: Date | undefined) => {
        if (isFromDate) {
            this.setState({ from_date: date })
        } else {
            this.setState({ to_date: date })
        }

        setTimeout(() => {
            this.filterDonationDate()
        }, 1000);
    }

    renderMediaList() {
        return this.props.media.map((item, index) => {
            return (
                <Col key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{ margin: '0px', padding: '0px' }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            width: '64px', height: '64px', marginRight: '16px',
                            backgroundColor: '#cccccc', borderRadius: '4px',
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            {validateUrl(item.data) && (getFileExtensions(item.data.split('.')) === 'PNG' || getFileExtensions(item.data.split('.')) === 'JPG' || getFileExtensions(item.data.split('.')) === 'JPEG') ?
                                <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item.data} />
                                :
                                <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(item.data.split('.'))}</div>
                            }
                        </Row>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{item.data}</div>
                            <div style={{ color: '#808080', fontSize: '12px', fontWeight: 'normal' }}>{item.size && item.size !== '-' ? bytesToSize(parseInt(item.size)) : '-'}</div>
                        </Col>

                        <Image onClick={() => this.onRemoveMedia(index)} style={{ width: '24px', height: '24px', cursor: 'pointer' }} src={require("../../../../src/assets/images/ic-common-action-close-gray/ic-common-action-close-gray.png")} />
                    </Row>

                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px', marginTop: '16px'
                    }} />
                </Col>
            )
        })
    }

    renderProjectLayout = () => {
        return (
            <Col className="card-submission" style={{
                margin: '0px', padding: '0px',
                width: '100%',
                wordBreak: 'break-all',
                backgroundColor: 'white',
                cursor: 'default',
                borderColor: 'transparent', marginBottom: '24px',
                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
            }}>
                <Col style={{ margin: '0px', padding: '0px' }}>
                    <input type="file" id="image-file"
                        ref={(ref) => this.fileUploader = ref}
                        style={{ display: "none" }}
                        onChange={this.onClickUploadPictureUrl}
                        accept="image/*" />
                    <div className="card-label-title" style={{
                        paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                        fontSize: '24px', color: '#333333', fontWeight: 'bold'
                    }}>
                        {getTranslate('Detail Pengajuan Proyek')}
                    </div>
                    <div className="garis" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />

                    <Col className="padding-0" style={{
                        margin: '0px', padding: '0px',
                        paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                    }}>
                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <FormLabel>{getTranslate('Nama Proyek')}</FormLabel>
                            <InputGroup style={{ margin: '0px', padding: '0px' }}>
                                <FormControl placeholder={getTranslate("Contoh: Pemasangan Turbin di Kawasan Ciwidey")}
                                    defaultValue={this.state.name}
                                    onChange={(event: any) => this.setState({ name: event.target.value })}
                                />
                            </InputGroup>
                        </Col>
                        <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <FormLabel>{getTranslate('Deskripsi Proyek')}</FormLabel>
                                <Editor
                                    initialValue={this.state.description}
                                    init={{
                                        height: 400,
                                        menubar: true,
                                        plugins: 'link image code',
                                        toolbar: 'undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat| image'
                                    }}
                                    onChange={this.handleEditorChange} />
                            </Col>
                        </Row>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Jumlah Pengajuan Dana Proyek')} (Rp)</FormLabel>
                            <InputGroup style={{ margin: '0px', padding: '0px' }}>
                                <NumberFormat
                                    value={this.state.target_amount}
                                    placeholder={getTranslate("Contoh: ") + "500.000"}
                                    id="number_format"
                                    thousandSeparator={true}
                                    onValueChange={(event: any) => this.setState({ target_amount: event.value })} />
                            </InputGroup>
                        </Col>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Alamat Proyek')}</FormLabel>
                            <InputGroup style={{ margin: '0px', padding: '0px' }}>
                                <FormControl
                                    defaultValue={this.state.address_project}
                                    placeholder={getTranslate("Contoh: ") + "Jl. Dr. Ide Gde Agung No. 9, Setiabudi, Kuningan, Jakarta Selatan"} as="textarea"
                                    onChange={(event: any) => this.setState({ address_project: event.target.value })}
                                />
                            </InputGroup>
                        </Col>
                        <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                <FormLabel>{getTranslate('Daya Listrik yang telah Terpasang')} (Watt)</FormLabel>
                                <InputGroup style={{ margin: '0px', padding: '0px' }}>
                                    <NumberFormat
                                        value={this.state.electrical_power}
                                        placeholder={getTranslate("Contoh: ") + "500.000"}
                                        id="number_format"
                                        thousandSeparator={true}
                                        onValueChange={(event: any) => this.setState({ electrical_power: event.value })} />
                                </InputGroup>
                            </Col>
                            <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                <FormLabel>{getTranslate('Jumlah Pengajuan Daya PV')} (kWp)</FormLabel>
                                <InputGroup style={{ margin: '0px', padding: '0px' }}>
                                    <NumberFormat
                                        value={this.state.electrical_target}
                                        placeholder={getTranslate("Contoh: ") + "1200"}
                                        id="number_format"
                                        thousandSeparator={true}
                                        onValueChange={(event: any) => this.setState({ electrical_target: event.value })} />

                                    
                                </InputGroup>
                            </Col>
                        </Row>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Dimana PV akan dipasang?')}</FormLabel>
                            <InputGroup style={{ margin: '0px', padding: '0px' }}>
                                <FormControl
                                    defaultValue={this.state.pv_location}
                                    placeholder={getTranslate("Contoh: ") + "atap, lapangan, bukit, dll."}
                                    as="textarea"
                                    onChange={(event: any) => this.setState({ pv_location: event.target.value })}
                                />
                            </InputGroup>
                        </Col>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Apakah akan menggunakan PV Baterai?')}</FormLabel>
                            <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                <div className="ui radio checkbox"
                                    style={{
                                        marginRight: '16px'
                                    }}>
                                    <input type="radio" defaultChecked={this.state.is_pv_battery === true ?
                                        true : undefined} name="is_pv_battery" value="Ya"
                                        onClick={() => this.setState({ is_pv_battery: true })}
                                    />
                                    <label>{getTranslate('Ya')}</label>
                                </div>
                                <div className="ui radio checkbox">
                                    <input type="radio" defaultChecked={this.state.is_pv_battery === false ?
                                        true : undefined} name="is_pv_battery" value="Tidak"
                                        onClick={() => this.setState({ is_pv_battery: false })}
                                    />
                                    <label>{getTranslate('Tidak')}</label>
                                </div>
                            </Row>
                        </Col>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Apakah proyek ini telah memiliki kontraktor berpengalaman?')}</FormLabel>
                            <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                <div className="ui radio checkbox"
                                    style={{
                                        marginRight: '16px'
                                    }}>
                                    <input type="radio" defaultChecked={this.state.is_experience_contractor === true ?
                                        true : undefined} name="is_experience_contractor" value="Ya"
                                        onClick={() => this.setState({ is_experience_contractor: true })}
                                    />
                                    <label>{getTranslate('Ya')}</label>
                                </div>
                                <div className="ui radio checkbox">
                                    <input type="radio" defaultChecked={this.state.is_experience_contractor === false ?
                                        true : undefined} name="is_experience_contractor" value="Tidak"
                                        onClick={() => this.setState({ is_experience_contractor: false })}
                                    />
                                    <label>{getTranslate('Tidak')}</label>
                                </div>
                            </Row>
                        </Col>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Apakah proyek ini sudah dimulai?')}</FormLabel>
                            <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                <div className="ui radio checkbox"
                                    style={{
                                        marginRight: '16px'
                                    }}>
                                    <input type="radio" defaultChecked={this.state.is_started_project === true ?
                                        true : undefined} name="is_started_project" value="Ya"
                                        onClick={() => this.setState({ is_started_project: true })}
                                    />
                                    <label>{getTranslate('Sudah')}</label>
                                </div>
                                <div className="ui radio checkbox">
                                    <input type="radio" defaultChecked={this.state.is_started_project === false ?
                                        true : undefined} name="is_started_project" value="Tidak"
                                        onClick={() => this.setState({ is_started_project: false })}
                                    />
                                    <label>{getTranslate('Belum')}</label>
                                </div>
                            </Row>
                        </Col>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <FormLabel>{getTranslate('Apakah Anda bertindak sebagai perwakilan langsung / tim dari proyek ini?')}</FormLabel>
                            <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                <div className="ui radio checkbox"
                                    style={{
                                        marginRight: '16px'
                                    }}>
                                    <input type="radio" defaultChecked={this.state.creator_is_owner === true ?
                                        true : undefined} name="creator_is_owner" value="Ya"
                                        onClick={() => this.setState({ creator_is_owner: true })}
                                    />
                                    <label>{getTranslate('Ya')}</label>
                                </div>
                                <div className="ui radio checkbox">
                                    <input type="radio" defaultChecked={this.state.creator_is_owner === false ?
                                        true : undefined} name="creator_is_owner" value="Tidak"
                                        onClick={() => this.setState({ creator_is_owner: false })}
                                    />
                                    <label>{getTranslate('Tidak')}</label>
                                </div>
                            </Row>
                        </Col>
                        <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <FormLabel>{getTranslate('Upload proposal / gambar proyek jika ada')}</FormLabel>
                                <div className="uploader">
                                    <div style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>{getTranslate('Drag & drop')}</div>
                                    <div style={{ color: '#808080', fontSize: '16px', marginTop: '4px' }}>{getTranslate('atau')}
                                        <a style={{ color: '#1aa59a' }}>{getTranslate(' browse ')}</a>{getTranslate('your files')}</div>
                                    <div style={{ color: '#d8d8d8', fontSize: '14px', marginTop: '10px' }}>(format: doc, pdf, xls, image)</div>
                                    <input
                                        type="file" name="project-file"
                                        onChange={this.onClickUpload} id="filePhoto"
                                        accept=".doc, .docx, .pdf, .xls, .xlsx, image/*" />
                                </div>
                            </Col>
                        </Row>
                        {((this.props.media && this.props.media.length > 0) || this.state.loadingUploadImage) &&
                            <Col style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                                {this.state.loadingUploadImage &&
                                    <Col style={{ margin: '0px', padding: '0px' }}>
                                        <div style={{
                                            color: '#d8d8d8', fontSize: '14px',
                                            fontWeight: 'bold', marginBottom: '8px'
                                        }}>
                                            {getTranslate('Uploading...')}
                                        </div>
                                        <ProgressBar
                                            animated
                                            style={{ borderRadius: '7px', height: '8px' }}
                                            now={100} />
                                    </Col>
                                }
                                {this.renderMediaList()}
                            </Col>
                        }
                    </Col>
                </Col>
            </Col>
        )
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="dashboard-detail-container" style={{ margin: '0px', padding: '0px' }} >
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div className="font-mobile-20 margin-bottom-16" style={{ fontSize: '36px', color: '#333333', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', marginBottom: '40px' }}>{getTranslate('Detail Proyek')}</div>
                            <Row className="image-edit-container" style={{
                                margin: '0px', padding: '0px',
                                justifyContent: 'flex-start', alignItems: 'end',
                                wordBreak: 'break-all', marginBottom: '24px'
                            }}>
                                <Row style={{
                                    margin: '0px', padding: '0px',
                                    width: '320px',
                                    height: '187px',
                                    borderRadius: '4px',
                                    backgroundColor: '#d8d8d8',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    color: '#4b4b4b',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginRight: '32px'
                                }} onClick={() => this.fileUploader?.click()}>
                                    <div style={{
                                        position: 'absolute', width: 'inherit',
                                        height: 'inherit', backgroundColor: 'rgba(0, 0, 0, 0.40)',
                                        borderRadius: '4px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        display: 'flex'
                                    }}>
                                        <Image style={{ alignSelf: 'center', objectFit: 'contain' }} src={require('../../../assets/images/ic-devices-camera/ic-devices-camera.png')} />
                                    </div>
                                    {this.getPictureUrl(this.props.project)}
                                </Row>
                                <Col className="label-gambar" style={{ margin: '0px', padding: '0px', marginLeft: '24px' }}>
                                    <Row style={{
                                        margin: '0px', padding: '0px', justifyContent: 'space-between',
                                        alignItems: 'center', wordBreak: 'break-all'
                                    }}>
                                        <Col className="margin-0" style={{ margin: '0px', padding: '0px', marginRight: '190px', wordBreak: 'break-word' }}>
                                            <div style={{ fontSize: '24px', color: '#333333' }} className="titleMyProject">{toTitleCase(this.props.project.name)}</div>
                                            <div className="descMyProject" style={{ fontSize: '16px', color: '#808080', marginTop: '8px' }}>{this.props.project.organization && this.props.project.organization.name ?
                                                this.props.project.organization.name : '-'}</div>
                                        </Col>
                                        <Badge className="desktop-show" style={{
                                            top: 0, right: 0, position: 'absolute',
                                            backgroundColor: getStatusColor(this.props.project?.status), color: 'white',
                                            fontSize: '14px', padding: '8px', paddingLeft: '24px', paddingRight: '24px'
                                        }} pill>{projectStatusMapper(this.props.project?.status)}</Badge>
                                    </Row>
                                    <Badge className="mobile-show badge-mobile" style={{
                                            right: 0, position: 'absolute',
                                            backgroundColor: getStatusColor(this.props.project?.status), color: 'white',
                                            fontSize: '14px', padding: '8px', paddingLeft: '24px', paddingRight: '24px'
                                        }} pill>{projectStatusMapper(this.props.project?.status)}</Badge>
                                </Col>
                            </Row>

                            <Col className="card-submission" style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent', marginBottom: '24px',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Row className="padding-0" style={{
                                    margin: '0px', padding: '0px', justifyContent: 'space-between',
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                }}>
                                    <Row className="block-mobile" style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <div style={{
                                            fontSize: '18px', color: '#333333', marginRight: '16px'
                                        }}>{getTranslate('Current Amount: ')}</div>
                                        <div style={{
                                            fontSize: '24px', color: '#1aa59a', fontWeight: 'bold'
                                        }}>{toCurrency(this.props.project.current_amount)}</div>
                                    </Row>

                                    <Row className="block-mobile" style={{ margin: '0px', padding: '0px', flexFlow: 'row', wordBreak: 'keep-all', alignItems: 'center' }}>
                                        <div style={{
                                            fontSize: '16px', color: '#333333', marginRight: '16px'
                                        }}>{'Status: '}</div>
                                        <select onChange={(e: any) => this.setState({ project_status: e.target.value })}
                                            className="custom-select" id="inputGroupSelect01" value={this.state.project_status}>
                                            <option disabled value={this.state.project_status}>{projectStatusMapper(this.state.project_status)}</option>
                                            {this.projectStatusView()}
                                        </select>
                                    </Row>
                                </Row>
                            </Col>

                            {this.renderProjectLayout()}

                            <Col className="card-submission" style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent', marginBottom: '24px',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <div className="padding-0" style={{
                                        paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                        fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                    }}>{getTranslate('Information')}</div>
                                    <div className="garis" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                    <Col className="padding-0" style={{
                                        margin: '0px', padding: '0px',
                                        paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                    }}>
                                        <Row style={{ margin: '0px', padding: '0px' }}>
                                            <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                                <FormLabel>{getTranslate('Open Funding Date')}</FormLabel>
                                                <Col style={{ margin: '0px', padding: '0px', display: 'grid' }}>
                                                    <DatePicker
                                                        format={"dd/MM/yyyy"}
                                                        clearIcon={null}
                                                        calendarIcon={
                                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                                src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} />
                                                        }
                                                        locale={getTranslate("id-ID")}
                                                        minDate={new Date()}
                                                        onChange={(date: any) => this.setState({ open_funding_date: date })}
                                                        value={this.state.open_funding_date}
                                                    />
                                                </Col>
                                            </Col>
                                            <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                                <FormLabel>{getTranslate('Close Funding Date')}</FormLabel>
                                                <Col style={{ margin: '0px', padding: '0px', display: 'grid' }}>
                                                    <DatePicker
                                                        format={"dd/MM/yyyy"}
                                                        clearIcon={null}
                                                        calendarIcon={
                                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                                src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} />
                                                        }
                                                        locale={getTranslate("id-ID")}
                                                        minDate={new Date()}
                                                        onChange={(date: any) => this.setState({ close_funding_date: date })}
                                                        value={this.state.close_funding_date}
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                            <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                                <FormLabel>{getTranslate('Project Started Date')}</FormLabel>
                                                <Col style={{ margin: '0px', padding: '0px', display: 'grid' }}>
                                                    <DatePicker
                                                        format={"dd/MM/yyyy"}
                                                        clearIcon={null}
                                                        calendarIcon={
                                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                                src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} />
                                                        }
                                                        locale={getTranslate("id-ID")}
                                                        onChange={(date: any) => this.setState({ project_started_date: date })}
                                                        value={this.state.project_started_date}
                                                    />
                                                </Col>
                                            </Col>
                                            <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                                <FormLabel>{getTranslate('Project Ended Date')}</FormLabel>
                                                <Col style={{ margin: '0px', padding: '0px', display: 'grid' }}>
                                                    <DatePicker
                                                        format={"dd/MM/yyyy"}
                                                        clearIcon={null}
                                                        calendarIcon={
                                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                                src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} />
                                                        }
                                                        locale={getTranslate("id-ID")}
                                                        onChange={(date: any) => this.setState({ project_ended_date: date })}
                                                        value={this.state.project_ended_date}
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>

                            <Col className="card-submission" style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent', marginBottom: '24px',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Col className="progress-container" style={{ margin: '0px', padding: '0px' }}>
                                    <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between' }}>
                                        <div className="progress-title" style={{
                                            paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                            fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                        }}>{'Progress (' + this.state.progressCount + ')'}</div>
                                        <Button style={{
                                            fontWeight: 'bold', marginLeft: '8px',
                                            color: 'white', marginRight: '82px', paddingLeft: '32px', paddingRight: '32px',
                                            borderColor: '#5bc69a', alignSelf: 'center',
                                            backgroundColor: '#5bc69a', opacity: '1'
                                        }}
                                            onClick={() => this.addProgress()}>
                                            {getTranslate('Add New Progress')}</Button>
                                    </Row>
                                    <div className="garis" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px', marginBottom: '24px' }} />
                                    {this.props.progress && this.props.progress.length > 0 &&
                                        <InfiniteScroll
                                            scrollThreshold={0.5}
                                            dataLength={this.props.progress.length}
                                            next={() => this.onLoadMoreProgress(this.props.progress[this.props.progress.length - 1].id)}
                                            hasMore={this.state.hasMoreItemsProgress}
                                            loader={
                                                <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                                                    <Spinner size="sm" animation="border" variant="secondary" />
                                                </Row>
                                            }>
                                            {this.listProgress()}
                                        </InfiniteScroll>
                                    }
                                    <div style={{ marginBottom: '8px' }} />
                                </Col>
                            </Col>

                            <Col className="card-submission" style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent', marginBottom: '24px',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Col className="progress-container" style={{ margin: '0px', padding: '0px' }}>
                                    <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between' }}>
                                        <div className="progress-title" style={{
                                            paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                            fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                        }}>{getTranslate('Donasi ') + '(' + this.state.donationCount + ')'}</div>
                                        <Row className="datepicker-container" style={{
                                            margin: '0px', padding: '0px', display: 'block', color: '#4b4b4b',
                                            alignSelf: 'center', marginRight: '82px', backgroundColor: '#e4f7f6'
                                        }}>
                                            <DatePicker
                                                format={"dd MMM yyyy"}
                                                // clearIcon={null}
                                                calendarIcon={
                                                    <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                        src={require('../../../../src/assets/images/ic-actions-calendar-black/ic-actions-calendar-black.png')} />
                                                }
                                                locale={getTranslate("id-ID")}
                                                maxDate={new Date()}
                                                onChange={(date: any) => this.onFilterDonationDate(true, date)}
                                                value={this.state.from_date}
                                            />
                                            <DatePicker
                                                format={"dd MMM yyyy"}
                                                // clearIcon={null}
                                                calendarIcon={
                                                    <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                                        src={require('../../../../src/assets/images/ic-actions-calendar-black/ic-actions-calendar-black.png')} />
                                                }
                                                locale={getTranslate("id-ID")}
                                                maxDate={new Date()}
                                                onChange={(date: any) => this.onFilterDonationDate(false, date)}
                                                value={this.state.to_date}
                                            />
                                        </Row>
                                    </Row>
                                    <div className="garis" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                    {this.props.donation && this.props.donation.length > 0 &&
                                        <InfiniteScroll
                                            scrollThreshold={0.5}
                                            dataLength={this.props.donation.length}
                                            next={() => this.onLoadMoreDonation(this.props.donation[this.props.donation.length - 1].id)}
                                            hasMore={this.state.hasMoreItemsDonation}
                                            loader={
                                                <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                                                    <Spinner size="sm" animation="border" variant="secondary" />
                                                </Row>
                                            }>
                                            {this.listDonations()}
                                        </InfiniteScroll>
                                    }
                                </Col>
                            </Col>

                            <Row className="submit-button-row" style={{ margin: '0px', padding: '0px', justifyContent: 'flex-end' }}>
                                <Button style={{
                                    fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                    color: this.validationButton() ? '#808080' : 'white',
                                    borderColor: this.validationButton() ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.validationButton() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }}
                                    className={this.state.loading ? "ui primary loading button" : ""}
                                    disabled={this.validationButton()}
                                    onClick={() => this.clickUpdateProject()}>
                                    {getTranslate('Update Project')}</Button>
                            </Row>
                        </Col>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', paddingLeft: '32px', paddingRight: '32px',
                                margin: '23px 0 2px 350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Proyek berhasil diubah')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        media: state.media.medias,
        project: state.project.selectedProject,
        donation: state.donation.donationsByProject,
        progress: state.progress.progressByProject,
        allUser: state.user.allUser,
    } as PMTDashboardDetailPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        editProjectPMT: (project_id: string, payload: ProjectPMTBody) => editProjectPMT(project_id, payload),
        setMediaUpload: (media: Media[]) => dispatch(setUploadedMedia(media)),
        mediaUpload: (data: FormData, id_user: string, file_type: string) => getUploadedMedia(data, id_user, file_type),
        clearMedia: () => dispatch(clearMedia()),
        getDonations: (project_id: string, sortBy: string, starting_after?: string, fromDate?: string, toDate?: string) => getDonations(undefined, starting_after, sortBy, undefined, project_id, undefined, 'succeed', fromDate, toDate),
        getProgress: (project_id: string, sortBy: string, starting_after?: string) => getProgress(undefined, starting_after, sortBy, undefined, project_id),
        setDonationByProject: (donations: Donation[]) => dispatch(setDonationByProject(donations)),
        setProgressByProject: (progress: Progress[]) => dispatch(setProgressByProject(progress)),
        getSummaryDonation: (project_id: string) => getSummaryDonation(project_id),
        getSummaryProgress: (project_id: string) => getSummaryProgress(project_id),
        trackProjectPage: (a: boolean) => dispatch(trackProjectPage(a)),
    } as PMTDashboardDetailPageAction;
}

export default connect(stateToProps, dispatchToProps)(PMTDashboardDetailPage);
