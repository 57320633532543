const LANGUAGE_CHOICE = 'language_choice';

class LanguageHelper {

    public setLanguage(b: boolean): void {
        return localStorage.setItem(LANGUAGE_CHOICE, String(b));
    }

    public getLanguage(): boolean {
        const stringItem = localStorage.getItem(LANGUAGE_CHOICE);
        if (stringItem && stringItem === 'true') {
            return true
        } else {
            return false
        }
    }

    public clearLanguage(): void {
        return localStorage.removeItem(LANGUAGE_CHOICE);
    }
}

const languageHelper: LanguageHelper = new LanguageHelper();
export default languageHelper;
