import React from 'react';
import {
    InputGroup,
    Button,
    Image,
    Row,
    Col,
    FormControl,
} from 'react-bootstrap';
import './style.css';
import { getTranslate } from '../../utils/General';
import PhoneInput from 'react-phone-number-input';

export interface ForgotProps {
    loading: boolean
    onLoginClick(): void
    onForgotClick(phone: string): void
    onPrivacyPolicyClick(): void
    onTNCClick(): void
}

export interface ForgotAction {

}

export interface ForgotState {
    passwordToggleImage: string
    confirmPasswordToggleImage: string
    isDisabled: Boolean
    buttonPassType: string
    buttonConfirmPassType: string
    phone: string
    password: string
    confirmPass: string
    name: string
}

class ForgotComponent extends React.Component<ForgotProps & ForgotAction, ForgotState> {
    constructor(props: any) {
        super(props);
        this.state = {
            passwordToggleImage: "images/ic-actions-closed-view/ic-actions-closed-view.png",
            confirmPasswordToggleImage: "images/ic-actions-closed-view/ic-actions-closed-view.png",
            isDisabled: true,
            buttonPassType: 'password',
            buttonConfirmPassType: 'password',
            phone: '',
            password: '',
            confirmPass: '',
            name: ''
        }
    }

    togglePass() {
        if (this.state.buttonPassType === "password") {
            this.setState({
                buttonPassType: "text",
                passwordToggleImage: 'images/ic-actions-view/ic-actions-view.png',

            })
        } else {
            this.setState({
                buttonPassType: "password",
                passwordToggleImage: 'images/ic-actions-closed-view/ic-actions-closed-view.png',
            })
        }
    }

    toggleConfirmPass() {
        if (this.state.buttonConfirmPassType === "password") {
            this.setState({
                buttonConfirmPassType: "text",
                confirmPasswordToggleImage: 'images/ic-actions-view/ic-actions-view.png',
            })
        } else {
            this.setState({
                buttonConfirmPassType: "password",
                confirmPasswordToggleImage: 'images/ic-actions-closed-view/ic-actions-closed-view.png',
            })
        }
    }

    validationButton() {
        if ( this.state.phone !== "" ) {
            this.setState({ isDisabled: false })
        } else {
            this.setState({ isDisabled: true })
        }
    }

    setPhone(event: any) {
        this.setState({
            phone: event
        }, () => {
            this.validationButton()
        })
    }


    render() {
        return (
            <div className="login-form-container" style={{
                margin: '0px', padding: '0px',
                marginTop: '100px',
                marginLeft: '96px',
                marginRight: '96px',
            }}>
                <div className="label-masuk" style={{ fontSize: '24px', marginBottom: '40px', fontWeight: 'bold' }}>{getTranslate('Lupa password')}</div>

                <Col style={{ margin: '0px', padding: '0px', marginTop: '50px', marginBottom: '50px' }}>
                    <InputGroup style={{ marginBottom: '8px' }}>
                        <PhoneInput
                            style={{ flex: 1 }}
                            international
                            defaultCountry="ID"
                            value={""}
                            placeholder={getTranslate("No Whatsapp")}
                            onChange={(event: any) => this.setPhone(event)} />
                    </InputGroup>
                    
                    <Button style={{
                        fontWeight: 'bold',
                        color: this.state.isDisabled ? '#808080' : 'white',
                        borderColor: this.state.isDisabled ? '#cccccc' : '#5bc69a', paddingLeft: '32px', paddingRight: '32px',
                        backgroundColor: this.state.isDisabled ? '#cccccc' : '#5bc69a', opacity: '1'
                    }} block disabled={this.state.isDisabled ? true : false}
                        onClick={() => this.props.onForgotClick(this.state.phone)}
                        className={this.props.loading ? "ui primary loading button" : ""}
                    >{getTranslate('Kirim')}</Button>

                    <div style={{ color: '#4b4b4b', fontSize: '14px' }} className="registerText"> {getTranslate('Sudah punya akun?')} &nbsp;
                        <a onClick={() => this.props.onLoginClick()} style={{ fontWeight: 'bold', color: '#1aa59a', fontSize: '14px' }}>
                            {getTranslate('Login')}
                        </a>
                    </div>
                    <div style={{ color: '#4b4b4b', fontSize: '14px', marginTop: '56px' }} className="registerText"> {getTranslate('Dengan mendaftar berarti Anda menyetujui')} &nbsp;
                        <a onClick={() => this.props.onTNCClick()} style={{ fontWeight: 'bold', color: '#207561', fontSize: '14px' }}>
                            {getTranslate('Syarat dan Ketentuan')} &nbsp;
                        </a>
                        <a style={{ color: '#4b4b4b', fontSize: '14px' }}>
                            {getTranslate('dan')} &nbsp;
                        </a>
                        <a onClick={() => this.props.onPrivacyPolicyClick()} style={{ fontWeight: 'bold', color: '#207561', fontSize: '14px' }}>
                            {getTranslate('Kebijakan Privasi')}
                        </a>
                    </div>
                </Col>
            </div>
        )
    }
}

export default ForgotComponent