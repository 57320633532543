import { getState, history } from '../store';

const Navigation = {
    to: (path: string) => {
        history.push(path);
    },
    isCurrentPath: (path: string) => {
        const location = getState().router.location;
        if (location) {
            const locationPathName = location.pathname;
            const isStartingWith = locationPathName.startsWith(path);
            const followedBy = locationPathName.substring(path.length);
            const isCorrectFollowedBy = followedBy.length === 0 || followedBy.startsWith('/') || followedBy.startsWith('?');
            return isStartingWith && isCorrectFollowedBy;
        }
        return false;
    },
    getCurrentPath: () => {
        const location = getState().router.location;
        if (location) {
            return location.pathname;
        }
        return null;
    }
};

export default Navigation;
