import Redux, { AnyAction } from 'redux';

import { Media } from '../model/Media';
import MediaApi from '../api/Media';

export const SET_UPLOADED_MEDIA = `MEDIA.SET_UPLOADED_MEDIA`
export const CLEAR_MEDIA = `MEDIA.CLEAR_MEDIA`

export const setUploadedMedia: Redux.ActionCreator<Redux.AnyAction> = (payload: Media[]) => {
    return { type: SET_UPLOADED_MEDIA, payload: payload }
}

export const clearMedia: Redux.ActionCreator<Redux.AnyAction> = () => {
    return { type: CLEAR_MEDIA }
}

export const getUploadedMedia = async function (data: FormData, user_id?: string, file_type?: string) {
    try {
        const result: Media = await MediaApi.upload(data, user_id, file_type);
        return result
    } catch (err: any) {
        throw err
    }
};

export interface MediaState {
    medias: Media[]
}

export const initialMediaState: MediaState = {
    medias: []
}

const mediaReducers = (state: MediaState = initialMediaState, action: any): MediaState => {
    switch (action.type) {
        case SET_UPLOADED_MEDIA:
            return {
                ...state,
                medias: action.payload
            }
        case CLEAR_MEDIA:
            return initialMediaState
        default: {
            return state;
        }
    }
}

export default mediaReducers
