import Redux, { AnyAction } from 'redux';
import { Authenticate } from '../model/payload/Authenticate';
import { Register } from '../model/payload/Register';
import UserApi from '../api/User';
import { Token } from '../model/Token';
import { User } from '../model/User';
import authHelper from '../utils/AuthHelper';
import { OrganizationBody } from '../model/payload/OrganizationBody';
import { Organization } from '../model/Organization';
import { CheckPassBody } from '../model/payload/CheckPassBody';
import { NewPasswordBody } from '../model/payload/NewPasswordBody';
import { OrganizationName } from '../model/OrganizationName';
import { UserBody } from '../model/payload/UserBody';

export const SET_USER = `USER.SET_USER`
export const SET_SELECTED_USER = `USER.SET_SELECTED_USER`
export const SET_ALL_USER = `USER.SET_ALL_USER`
export const SET_ORGANIZATION = `USER.SET_ORGANIZATION`
export const SET_ORGANIZATION_NAME = `USER.SET_ORGANIZATION_NAME`
export const SET_EDITED_PAGE = `USER.SET_EDITED_PAGE`
export const HAS_BACK = `USER.HAS_BACK`

export const setSelectedUser: Redux.ActionCreator<Redux.AnyAction> = (user: User) => {
    return { type: SET_SELECTED_USER, payload: user }
}
export const setUser: Redux.ActionCreator<Redux.AnyAction> = (user: User) => {
    return { type: SET_USER, payload: user }
}
export const setAllUser: Redux.ActionCreator<Redux.AnyAction> = (user: User[]) => {
    return { type: SET_ALL_USER, payload: user }
}

export const setOrganization: Redux.ActionCreator<Redux.AnyAction> = (payload: Organization) => {
    return { type: SET_ORGANIZATION, payload: payload }
}

export const setOrganizationName: Redux.ActionCreator<Redux.AnyAction> = (payload: OrganizationName[]) => {
    return { type: SET_ORGANIZATION_NAME, payload: payload }
}

export const setEditedPage: Redux.ActionCreator<Redux.AnyAction> = (payload: String) => {
    return { type: SET_EDITED_PAGE, payload: payload }
}

export const hasBack: Redux.ActionCreator<Redux.AnyAction> = (payload: boolean) => {
    return { type: HAS_BACK, payload: payload }
}

export const register = async function (payload: Register) {
    try {
        await UserApi.register(payload);
    } catch (err: any) {
        throw err
    }
};
export const me = async function () {
    try {
        const result = await UserApi.me();
        return result
    } catch (err: any) {
        throw err
    }
};

export const getUsers = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, access_level?: string, id_card_status?: string, name?: string) {
    try {
        const result = await UserApi.getUsers(user_id, starting_after, order_by, limit, access_level, id_card_status, name);
        return result
    } catch (err: any) {
        throw err
    }
};

export const getOrganization = async function (id_user: string) {
    try {
        const result = await UserApi.getOrganization(id_user);
        return result
    } catch (err: any) {
        throw err
    }
};

export const getOrganizationName = async function () {
    try {
        const result = await UserApi.getOrganizationName();
        return result
    } catch (err: any) {
        throw err
    }
};

export const submitOrganization = async function (payload: OrganizationBody) {
    try {
        await UserApi.submitOrganization(payload);
    } catch (err: any) {
        throw err
    }
};

export const editOrganization = async function (organization_id: string, payload: OrganizationBody) {
    try {
        await UserApi.editOrganization(organization_id, payload);
    } catch (err: any) {
        throw err
    }
};

export const editProfile = async function (payload: User) {
    try {
        await UserApi.editProfile(payload);
    } catch (e: any) {
        throw e
    }
}

export const checkPass = async function (payload: CheckPassBody) {
    try {
        const result = await UserApi.changePasswordCheck(payload);
        return result
    } catch (e: any) {
        throw e
    }
}

export const changePassword = async function (payload: NewPasswordBody) {
    try {
        await UserApi.editPassword(payload)
    } catch (e: any) {
        throw e
    }
}

export const idCardApproved = async function (user: User) {
    try {
        await UserApi.idCardApproved(user)
    } catch (e: any) {
        throw e
    }
}

export const idCardRejected = async function (user: User) {
    try {
        await UserApi.idCardRejected(user)
    } catch (e: any) {
        throw e
    }
}

export const generalDeleted = async function (user: User) {
    try {
        await UserApi.generalDeleted(user)
    } catch (e: any) {
        throw e
    }
}

export const pmtDeleted = async function (user: User) {
    try {
        await UserApi.pmtDeleted(user)
    } catch (e: any) {
        throw e
    }
}

export const addUser = async function (user: UserBody) {
    try {
        await UserApi.addUser(user)
    } catch (e: any) {
        throw e
    }
}

export interface UserState {
    user: User | undefined
    organization: Organization | undefined
    organizationName: OrganizationName[]
    editPage: String | undefined
    allUser: User[]
    selectedUser: User | undefined
    hasBack: boolean
}

export const initialUserState: UserState = {
    user: undefined,
    organization: undefined,
    editPage: undefined,
    allUser: [],
    organizationName: [],
    selectedUser: undefined,
    hasBack: false
}

const userReducers = (state: UserState = initialUserState, action: any): UserState => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload
            }
        case SET_ALL_USER:
            return {
                ...state,
                allUser: action.payload
            }
        case SET_ORGANIZATION:
            return {
                ...state,
                organization: action.payload
            }
        case SET_ORGANIZATION_NAME:
            return {
                ...state,
                organizationName: action.payload
            }
        case SET_EDITED_PAGE:
            return {
                ...state,
                editPage: action.payload
            }
        case HAS_BACK:
            return {
                ...state,
                hasBack: action.payload
            }
        default: {
            return state;
        }
    }
}

export default userReducers
