import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { Register } from '../model/payload/Register';
import { Authenticate } from '../model/payload/Authenticate';
import { Token } from '../model/Token';
import { User } from '../model/User';
import { OrganizationBody } from '../model/payload/OrganizationBody';
import { Organization } from '../model/Organization';
import { CheckPassBody } from '../model/payload/CheckPassBody';
import { NewPasswordBody } from '../model/payload/NewPasswordBody';
import { ListDescription } from '../model/ListDescription';
import { CheckPass } from '../model/CheckPass';
import { OrganizationName } from '../model/OrganizationName';
import { UserBody } from '../model/payload/UserBody';

const userConnection = JsonServerConnection(url, '/users');
const userConnectionWithAccessToken = JsonServerConnection(url, '/users').withAccessToken;

const register = async (payload: Register) => {
    return await userConnection.post('/register', payload);
};

const authenticate = async (payload: Authenticate) => {
    return await userConnection.post('/authenticate', payload);
};

const me = async () => {
    return await userConnectionWithAccessToken.get('/me');
};

const getUsers = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, access_level?: string, id_card_status?: string, name?: string) => {
    let finalQuery = "?"

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (access_level && access_level !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "access_level=" + access_level
    }
    if (id_card_status && id_card_status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "id_card_status=" + id_card_status
    }
    if (name && name !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "name=" + name
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await userConnectionWithAccessToken.get(finalQuery) as ListDescription<User>
    return result.data;
}

const getOrganization = async (id_user: string) => {
    return await userConnectionWithAccessToken.get("/organization/" + id_user)
}

const getOrganizationName = async () => {
    const result = await userConnectionWithAccessToken.get("/organization_name") as ListDescription<OrganizationName>
    return result.data
}

const submitOrganization = async (payload: OrganizationBody) => {
    return await userConnectionWithAccessToken.post("/organization", payload)
}

const editOrganization = async (id_organizaton: string, payload: OrganizationBody) => {
    return await userConnectionWithAccessToken.post("/organization/" + id_organizaton, payload)
}

const editProfile = async (payload: User) => {
    return await userConnectionWithAccessToken.post("/me/" + payload.id, payload)
}

const changePasswordCheck = async (payload: CheckPassBody) => {
    const result = await userConnectionWithAccessToken.post("/me/change_password_check", payload) as CheckPass
    return result;
}

const editPassword = async (payload: NewPasswordBody) => {
    return await userConnectionWithAccessToken.post("/me/change_password", payload);
}

const idCardApproved = async (user: User) => {
    return await userConnectionWithAccessToken.post("/pmt/" + user.id + "/id_card_approved");
}

const idCardRejected = async (user: User) => {
    return await userConnectionWithAccessToken.post("/pmt/" + user.id + "/id_card_rejected");
}

const generalDeleted = async (user: User) => {
    return await userConnectionWithAccessToken.delete("/pmt/" + user.id + "/general_deleted");
}

const pmtDeleted = async (user: User) => {
    return await userConnectionWithAccessToken.delete("/admin/" + user.id + "/pmt_deleted");
}

const addUser = async (user: UserBody) => {
    return await userConnectionWithAccessToken.post("/admin/create_user", user);
}

export interface UserApi {
    register: (payload: Register) => Promise<any>;
    authenticate: (payload: Authenticate) => Promise<Token>;
    me: () => Promise<User>;
    getOrganization: (id_user: string) => Promise<Organization>;
    getOrganizationName: () => Promise<OrganizationName[]>;
    submitOrganization: (payload: OrganizationBody) => Promise<any>;
    editOrganization: (id_organization: string, payload: OrganizationBody) => Promise<any>
    editProfile: (payload: User) => Promise<any>
    changePasswordCheck: (payload: CheckPassBody) => Promise<CheckPass>
    editPassword: (payload: NewPasswordBody) => Promise<any>
    getUsers: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, access_level?: string, id_card_status?: string, name?: string) => Promise<User[]>
    idCardApproved: (user: User) => Promise<any>
    idCardRejected: (user: User) => Promise<any>
    generalDeleted: (user: User) => Promise<any>
    pmtDeleted: (user: User) => Promise<any>
    addUser: (user: UserBody) => Promise<any>
}

export default {
    register,
    authenticate,
    me,
    getOrganization,
    getOrganizationName,
    submitOrganization,
    editOrganization,
    editProfile,
    changePasswordCheck,
    editPassword,
    getUsers,
    idCardApproved,
    idCardRejected,
    generalDeleted,
    pmtDeleted,
    addUser
} as UserApi;
