import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { SummaryDashboard } from '../model/SummaryDashboard';
import { SummaryDonation } from '../model/SummaryDonation';
import { SummaryProgress } from '../model/SummaryProgress';
import { SummaryPMTDashboard } from '../model/SummaryPMTDashboard';

const summaryConnectionWithAccessToken = JsonServerConnection(url, '/summary').withAccessToken;

const getSummary = async () => {
    let finalQuery = "/dashboard"
    return await summaryConnectionWithAccessToken.get(finalQuery)
};

const getPMTSummary = async () => {
    let finalQuery = "/pmt/dashboard"
    return await summaryConnectionWithAccessToken.get(finalQuery)
};

const getSummaryDonation = async (project_id?: string, user_id?: string) => {
    let finalQuery = "?"

    if (project_id && project_id !== "") {
        finalQuery += "project_id=" + project_id
    }
    if (user_id && user_id !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "user_id=" + user_id
    }
    return await summaryConnectionWithAccessToken.get('/donation/project' + finalQuery)
};

const getPublicSummaryDonation = async (project_id?: string, user_id?: string) => {
    let finalQuery = "?"

    if (project_id && project_id !== "") {
        finalQuery += "project_id=" + project_id
    }
    if (user_id && user_id !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "user_id=" + user_id
    }
    return await summaryConnectionWithAccessToken.get('/donation/public-project' + finalQuery)
};

const getSummaryProgress = async (project_id?: string) => {
    let finalQuery = "?"

    if (project_id && project_id !== "") {
        finalQuery += "project_id=" + project_id
    }
    return await summaryConnectionWithAccessToken.get('/progress' + finalQuery)
};
const getPublicSummaryProgress = async (project_id?: string) => {
    let finalQuery = "?"

    if (project_id && project_id !== "") {
        finalQuery += "project_id=" + project_id
    }
    return await summaryConnectionWithAccessToken.get('/public-progress' + finalQuery)
};

export interface SummaryApi {
    getSummary: () => Promise<SummaryDashboard>;
    getPMTSummary: () => Promise<SummaryPMTDashboard>;
    getSummaryDonation: (project_id?: string, user_id?: string) => Promise<SummaryDonation>;
    getPublicSummaryDonation: (project_id?: string, user_id?: string) => Promise<SummaryDonation>;
    getSummaryProgress: (project_id?: string) => Promise<SummaryProgress>;
    getPublicSummaryProgress: (project_id?: string) => Promise<SummaryProgress>;
}

export default {
    getSummary,
    getPMTSummary,
    getSummaryDonation,
    getPublicSummaryDonation,
    getSummaryProgress,
    getPublicSummaryProgress
} as SummaryApi;
