import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import store, { history } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { withRouter } from 'react-router';
import registerServiceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';

import './styles/css/style.css'

const NonBlockApp = withRouter<any>(App);

ReactDOM.render(
    <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
            <ConnectedRouter history={history}>
                <NonBlockApp />
            </ConnectedRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();
