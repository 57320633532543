/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Navbar,
    Nav,
    Image,
    Row,
    Col,
    Alert,
    Card,
    Button,
    ListGroup,
    FormControl,
    FormLabel,
    InputGroup,
} from 'react-bootstrap';
import '../Login/style.css';
import NavbarComponent from '../../component/navbar';
import { Authenticate } from '../../model/payload/Authenticate';
import { UserState, setEditedPage, getOrganization, setOrganization } from '../../reducers/User';
import { authenticate } from '../../reducers/Auth';
import './style.css';
import { Organization } from '../../model/Organization';
import { User } from '../../model/User';
import { getInitialName, toTitleCase, mappingAccessLevelUser, mappingStatusUser, getStatusColor, getTranslate } from '../../utils/General';
import { setUser } from '../../reducers/User';
import { me } from '../../reducers/User';
import Navigation from '../../utils/Navigation';

export interface ProfileProps {
    user: UserState
    organization: Organization
    loading: boolean
}

export interface ProfileAction {
    setEditedPage(payload: string): void
    getOrganization(id_user: string): any
    setOrganization(payload: Organization): void
}

export interface ProfileState {
    name: string
    email: string
    phone: string

    //organization details
    organization_name: string,
    organization_email: string,
    category?: 'yayasan' | 'perusahaan' | 'ngo' | 'pemerintahan' | 'pendidikan' | 'other' | '',
    address: string,
    organization_phone: string,
    website: string,
    is_vice_organization_1?: boolean,
}

class ProfilePage extends React.Component<ProfileProps & ProfileAction, ProfileState>{

    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            email: "",
            phone: "",

            //organization details
            organization_name: "",
            organization_email: "",
            category: "",
            address: "",
            organization_phone: "",
            website: "",
            is_vice_organization_1: undefined,
        }
    }

    async loadData() {
        try {
            const organization = await this.props.getOrganization(this.props.user.user?.organization_id + "")
            this.props.setOrganization(organization)
            this.setOrganization()
        } catch (e: any) {
            console.error(e)
        }
    }

    setOrganization = () => {
        const o = this.props.organization
        if (this.props.organization) {
            this.setState({
                organization_name: o.name || "",
                organization_email: o.email || "",
                category: o.category || "",
                address: o.address || "",
                organization_phone: o.phone || "",
                website: o.website || "",
                is_vice_organization_1: this.props.user.user?.is_organization_owner,
            })
        }
    }

    componentDidMount() {
        this.loadData();
    }

    onBackHome() {
        Navigation.to('/dashboard')
    }

    onClickEditProfile(payload: string) {
        this.props.setEditedPage(payload)
        Navigation.to('/dashboard/my-profile/edit-profile')
    }

    getProfilePict = (user?: User) => {
        if (user?.picture_url) {
            return (
                <Image style={{
                    width: '136px',
                    height: '136px', objectFit: 'cover'
                }} roundedCircle src={user?.picture_url} />
            )
        } else {
            return getInitialName(user?.name || getTranslate('anonim'))
        }
    }

    render() {
        const PROFILE = "profile"
        const PASSWORD = "password"
        const ORGANIZATION = "organization"
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>

                        <div className="div-profile" style={{
                            color: '#333333', fontSize: '36px',
                            fontWeight: 'bold', marginBottom: '40px'
                        }}>{getTranslate('Profile')}</div>
                        <Col className="div-info-akun" style={{
                            padding: '32px',
                            backgroundColor: 'white',
                            cursor: 'default',
                            borderColor: 'transparent',
                            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                        }}>
                            <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                    <div className="div-text-info" style={{
                                        color: '#333333', fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}>{getTranslate('Informasi Akun')}</div>
                                </Row>
                                <Col style={{ margin: '0px', padding: '0px', textAlign: 'end', alignSelf: 'center' }}>
                                    <Button className="btn-ubah-akun" style={{
                                        fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                        backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                                    }} onClick={() => this.onClickEditProfile(PROFILE)}
                                    >{getTranslate('Ubah Akun')}</Button>
                                </Col>
                            </Row>
                            <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                            <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                    <Row className="img-profile-container" style={{
                                        margin: '0px', padding: '0px',
                                        width: '136px',
                                        height: '136px',
                                        borderRadius: '68px',
                                        backgroundColor: '#d8d8d8',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        color: '#4b4b4b',
                                        fontSize: '32px',
                                        fontWeight: 'bold',
                                        marginRight: '32px'
                                    }}>
                                        {this.getProfilePict(this.props.user.user)}
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <Row style={{ margin: '0px', padding: '0px' }}>
                                            <div className="div-name" style={{ color: '#333333', fontSize: '24px', fontWeight: 'bold' }}>{this.props.user.user?.name ? this.props.user.user?.name : getTranslate('anonim')}</div>
                                            <Col className="desktop-show" style={{
                                                margin: '0px', padding: '3px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center',
                                                borderRadius: '16px', backgroundColor: '#50aa9f', marginLeft: '16px', display: 'flex', alignSelf: 'center'
                                            }}>
                                                <div style={{ color: 'white', fontSize: '10px' }}>{
                                                    mappingAccessLevelUser(this.props.user.user?.access_level!!)}</div>
                                            </Col>
                                        </Row>
                                        <div className="div-verified" style={{
                                            color: getStatusColor(this.props.user.user?.id_card_status),
                                            fontSize: '12px', marginTop: '8px'
                                        }}>{mappingStatusUser(this.props.user.user?.id_card_status!!)}</div>
                                        <Row className="mobile-show" style={{ margin: '0px', padding: '0px' }}>
                                            <div className="user-level-mobile">
                                                <div style={{ color: 'white', fontSize: '10px' }}>{
                                                    mappingAccessLevelUser(this.props.user.user?.access_level!!)}</div>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                            <Row className="div-name-email-form" style={{ margin: '0px', padding: '0px', marginTop: '32px' }}>
                                <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                    <FormLabel id="formLabel">{getTranslate('Nama')}</FormLabel>
                                    <InputGroup>
                                        <FormControl
                                            value={this.props.user.user?.name} disabled
                                            onChange={(event: any) => this.setState({ name: event.target.value })}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col style={{ margin: '0px', padding: '0px', marginRight: '8px', marginLeft: '8px' }}>
                                    <FormLabel id="formLabel">Email</FormLabel>
                                    <InputGroup>
                                        <FormControl type="email"
                                            value={this.props.user.user?.email} disabled
                                            onChange={(event: any) => this.setState({ email: event.target.value })}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                    <FormLabel id="formLabel">{getTranslate('Phone')}</FormLabel>
                                    <InputGroup>
                                        <FormControl type="tel"
                                            value={this.props.user.user?.phone} disabled
                                            onChange={(event: any) => this.setState({ phone: event.target.value })}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Col className="col-ktp" style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                <FormLabel>{getTranslate('KTP')}</FormLabel>
                                <FormControl
                                    placeholder=" "
                                    defaultValue={this.props.user?.user ? this.props.user.user.id_card : " "}
                                    disabled
                                />
                            </Col>
                        </Col>

                        <Col className="div-info-org" style={{
                            backgroundColor: 'white',
                            padding: '32px', marginTop: '24px',
                            cursor: 'default',
                            borderColor: 'transparent',
                            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                        }}>
                            <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                    <div className="div-text-info" style={{
                                        color: '#333333', fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}>{getTranslate('Profile Organisasi')}</div>
                                </Row>
                                <Col style={{ margin: '0px', padding: '0px', textAlign: 'end', alignSelf: 'center' }}>
                                    <Button className="btn-ubah-akun" style={{
                                        fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                        backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                                    }} onClick={() => this.onClickEditProfile(ORGANIZATION)}
                                    >{getTranslate('Ubah Organisasi')}</Button>
                                </Col>
                            </Row>
                            <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                            <Col className="div-name-email-form" style={{ margin: '0px', padding: '0px' }}>
                                <Row style={{ margin: '0px', padding: '0px' }}>
                                    <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <FormLabel>{getTranslate('Nama Organisasi')}</FormLabel>
                                        <FormControl placeholder=""
                                            defaultValue={this.state.organization_name}
                                            disabled
                                        />
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl placeholder=""
                                            defaultValue={this.state.organization_email}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                    <FormLabel>{getTranslate('Alamat')}</FormLabel>
                                    <FormControl placeholder="" as="textarea"
                                        defaultValue={this.state.address}
                                        disabled
                                    />
                                </Col>
                                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                    <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <FormLabel>{getTranslate('Phone')}</FormLabel>
                                        <FormControl type="tel" placeholder=""
                                            defaultValue={this.state.organization_phone}
                                            disabled
                                        />
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }} lg={6}>
                                        <FormLabel>{getTranslate('Kategori Organisasi')}</FormLabel>
                                        <select
                                            disabled
                                            className="custom-select" id="inputGroupSelect01" value={this.state.category}>
                                            <option disabled value="">-</option>
                                            <option value="yayasan">{getTranslate('Yayasan')}</option>
                                            <option value="perusahaan">{getTranslate('Perusahaan')}</option>
                                            <option value="ngo">{getTranslate('Ngo')}</option>
                                            <option value="pemerintahan">{getTranslate('Pemerintahan')}</option>
                                            <option value="pendidikan">{getTranslate('Pendidikan')}</option>
                                        </select>
                                    </Col>
                                </Row>
                                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                    <FormLabel>{getTranslate('Blog/Website')}</FormLabel>
                                    <FormControl
                                        placeholder="https://"
                                        defaultValue={this.state.website}
                                        disabled
                                    />
                                </Col>
                                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                    <FormLabel>{getTranslate('Apakah Anda bertindak sebagai perwakilan langsung dari organisasi ini?')}</FormLabel>
                                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                        <div className="ui radio checkbox"
                                            style={{
                                                marginRight: '16px'
                                            }}>
                                            <input defaultChecked={this.state.is_vice_organization_1 === true ?
                                                true : undefined} type="radio" name="is_vice_organization_1" value="Ya"
                                                disabled
                                            />
                                            <label>{getTranslate('Ya')}</label>
                                        </div>
                                        <div className="ui radio checkbox">
                                            <input type="radio" defaultChecked={this.state.is_vice_organization_1 === false ?
                                                true : undefined} name="is_vice_organization_1" value="Tidak"
                                                disabled
                                            />
                                            <label>{getTranslate('Tidak')}</label>
                                        </div>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>

                        <Col className="div-info-pass" style={{
                            backgroundColor: 'white',
                            padding: '32px', marginTop: '24px',
                            cursor: 'default',
                            borderColor: 'transparent',
                            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                        }}>
                            <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                    <div className="div-text-info" style={{
                                        color: '#333333', fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}>{getTranslate('Setting Password')}</div>
                                </Row>
                                <Col style={{ margin: '0px', padding: '0px', textAlign: 'end', alignSelf: 'center' }}>
                                    <Button className="btn-ubah-akun" style={{
                                        fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                        color: this.props.user.user?.is_social_media_register ? '#808080' : 'white',
                                        borderColor: this.props.user.user?.is_social_media_register ? '#cccccc' : '#5bc69a',
                                        backgroundColor: this.props.user.user?.is_social_media_register ? '#cccccc' : '#5bc69a', opacity: '1'
                                    }} disabled={this.props.user.user?.is_social_media_register}
                                        onClick={() => this.onClickEditProfile(PASSWORD)}
                                    >{getTranslate('Ubah Password')}</Button>
                                </Col>
                            </Row>
                            <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                            <Row style={{ margin: '0px', padding: '0px' }}>
                                <Col style={{ margin: '0px', padding: '0px' }} lg={6}>
                                    <FormLabel id="formLabel">{getTranslate('Password')}</FormLabel>
                                    <InputGroup>
                                        <FormControl
                                            value="*********" type="password" disabled
                                            onChange={(event: any) => this.setState({ name: event.target.value })}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div >
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user,
        organization: state.user.organization
    } as ProfileProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        setEditedPage: (payload: String) => dispatch(setEditedPage(payload)),
        getOrganization: (id_user: string) => getOrganization(id_user),
        setOrganization: (payload: Organization) => dispatch(setOrganization(payload))
    } as ProfileAction;
}

export default connect(stateToProps, dispatchToProps)(ProfilePage);
