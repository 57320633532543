/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../AppState';
import {
    Navbar,
    Nav,
    Image,
    Row,
    Col,
    Alert,
    Card,
    Button,
    ListGroup,
    FormControl,
    FormLabel,
    InputGroup,
    ProgressBar,
} from 'react-bootstrap';
import '../../Login/style.css';
import NavbarComponent from '../../../component/navbar';
import { Authenticate } from '../../../model/payload/Authenticate';
import { UserState, setEditedPage, getOrganization, setOrganization, addUser } from '../../../reducers/User';
import { authenticate } from '../../../reducers/Auth';
import './style.css';
import { Organization } from '../../../model/Organization';
import { User } from '../../../model/User';
import { getInitialName, toTitleCase, mappingAccessLevelUser, mappingStatusUser, bytesToSize, getTranslate } from '../../../utils/General';
import { setUser } from '../../../reducers/User';
import { me } from '../../../reducers/User';
import Navigation from '../../../utils/Navigation';
import { validateUrl } from '../../../utils/General';
import { getFileExtensions } from '../../../utils/General';
import { Media } from '../../../model/Media';
import { clearMedia } from '../../../reducers/Media';
import { setUploadedMedia } from '../../../reducers/Media';
import { getUploadedMedia } from '../../../reducers/Media';
import { ProgressBody } from '../../../model/payload/ProgressBody';
import { submitProgress } from '../../../reducers/Progress';
import { Project } from '../../../model/Project';
import { submitProgressPmt } from '../../../reducers/Progress';
import { UserBody } from '../../../model/payload/UserBody';

export interface ViewOrganizationProps {
    user: User
    organization: Organization
}

export interface ViewOrganizationAction {
    getOrganization(id_user: string): any
    setOrganization(payload: Organization): void
}

export interface ViewOrganizationState {
    //organization details
    organization_name: string,
    organization_email: string,
    category?: 'yayasan' | 'perusahaan' | 'ngo' | 'pemerintahan' | 'pendidikan' | 'other' | '',
    address: string,
    organization_phone: string,
    website: string,
    is_vice_organization_1?: boolean,
}

class ViewOrganization extends React.Component<ViewOrganizationProps & ViewOrganizationAction, ViewOrganizationState>{

    constructor(props: any) {
        super(props);
        this.state = {
            //organization details
            organization_name: "",
            organization_email: "",
            category: "",
            address: "",
            organization_phone: "",
            website: "",
            is_vice_organization_1: undefined,
        }
    }

    onBackHome() {
        Navigation.to('/user-management')
    }

    async loadData() {
        try {
            const organization = await this.props.getOrganization(this.props.user?.organization_id + "")
            this.props.setOrganization(organization)
            this.setOrganization()
        } catch (e: any) {
            console.error(e)
        }
    }

    setOrganization = () => {
        const o = this.props.organization
        if (this.props.organization) {
            this.setState({
                organization_name: o.name || "",
                organization_email: o.email || "",
                category: o.category || "",
                address: o.address || "",
                organization_phone: o.phone || "",
                website: o.website || "",
                is_vice_organization_1: this.props.user?.is_organization_owner,
            })
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke User Management')}</div>
                            </div>
                        </Col>

                        <Col style={{
                            margin: '0px', padding: '0px',
                            width: '100%',
                            wordBreak: 'break-all',
                            backgroundColor: 'white',
                            cursor: 'default',
                            borderColor: 'transparent', marginBottom: '24px',
                            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                        }}>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div style={{
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                    fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                }}>{getTranslate('Profile Organisasi ') + this.props.user.name}</div>
                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                <Col style={{
                                    margin: '0px', padding: '0px',
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                }}>
                                    <Row style={{ margin: '0px', padding: '0px' }}>
                                        <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Nama Organisasi')}</FormLabel>
                                            <FormControl placeholder=""
                                                defaultValue={this.state.organization_name}
                                                disabled
                                            />
                                        </Col>
                                        <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                            <FormLabel>Email</FormLabel>
                                            <FormControl placeholder=""
                                                defaultValue={this.state.organization_email}
                                                disabled
                                            />
                                        </Col>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Alamat')}</FormLabel>
                                        <FormControl placeholder="" as="textarea"
                                            defaultValue={this.state.address}
                                            disabled
                                        />
                                    </Col>
                                    <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Phone')}</FormLabel>
                                            <FormControl type="tel" placeholder=""
                                                defaultValue={this.state.organization_phone}
                                                disabled
                                            />
                                        </Col>
                                        <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }} lg={6}>
                                            <FormLabel>{getTranslate('Kategori Organisasi')}</FormLabel>
                                            <select
                                                disabled
                                                className="custom-select" id="inputGroupSelect01" value={this.state.category}>
                                                <option disabled value="">-</option>
                                                <option value="yayasan">{getTranslate('Yayasan')}</option>
                                                <option value="perusahaan">{getTranslate('Perusahaan')}</option>
                                                <option value="ngo">{getTranslate('Ngo')}</option>
                                                <option value="pemerintahan">{getTranslate('Pemerintahan')}</option>
                                                <option value="pendidikan">{getTranslate('Pendidikan')}</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Blog/Website')}</FormLabel>
                                        <FormControl
                                            placeholder="https://"
                                            defaultValue={this.state.website}
                                            disabled
                                        />
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Apakah Anda bertindak sebagai perwakilan langsung dari organisasi ini?')}</FormLabel>
                                        <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                            <div className="ui radio checkbox"
                                                style={{
                                                    marginRight: '16px'
                                                }}>
                                                <input defaultChecked={this.state.is_vice_organization_1 === true ?
                                                    true : undefined} type="radio" name="is_vice_organization_1" value="Ya"
                                                    disabled
                                                />
                                                <label>{getTranslate('Ya')}</label>
                                            </div>
                                            <div className="ui radio checkbox">
                                                <input type="radio" defaultChecked={this.state.is_vice_organization_1 === false ?
                                                    true : undefined} name="is_vice_organization_1" value="Tidak"
                                                    disabled
                                                />
                                                <label>{getTranslate('Tidak')}</label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div >
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.selectedUser,
        organization: state.user.organization
    } as ViewOrganizationProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        getOrganization: (id_user: string) => getOrganization(id_user),
        setOrganization: (payload: Organization) => dispatch(setOrganization(payload))
    } as ViewOrganizationAction;
}

export default connect(stateToProps, dispatchToProps)(ViewOrganization);
