import { Project } from "../model/Project";
import { Donation } from "../model/Donation";
import { Payment } from "../model/Payment";
import { User } from "../model/User";
import { FilteredDonation } from "../model/FilteredDonation";
import languageHelper from './LanguageHelper';

export function getInitialName(name: string): string {
    const matches = name.match(/\b(\w)/g); // ['J','S','O','N']
    if (matches) {
        let temp = matches.join('');
        if (temp.length > 3)
            temp = temp.substring(0, 3);
        return temp;
    }

    return '';
}

// export function getProjectDetailFromDonation(donation: Donation, projects: Project[]): Project | undefined {
//     var result = undefined
//     projects?.forEach(item => {
//         if (item.id === donation.project_id) {
//             result = item
//         }
//     })
//     return result;
// }

// export function getPaymentDetailFromDonation(donation: Donation, payments: Payment[]): Payment | undefined {
//     var result = undefined
//     payments?.forEach(item => {
//         if (item.id === donation.payment_id) {
//             result = item
//         }
//     })
//     return result;
// }

// export function getUserDetailFromId(users: User[], userId: string): User | undefined {
//     var result = undefined
//     users?.forEach(item => {
//         if (item.id === userId) {
//             result = item
//         }
//     })
//     return result;
// }

export function getProjectDetailFromId(projects: Project[], projectId: string): Project | undefined {
    var result = undefined
    projects?.forEach(item => {
        if (item.id === projectId) {
            result = item
        }
    })
    return result;
}

export function toTitleCase(str?: string) {
    if (!str)
        return ""
    return str?.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

export function paymentStatusMapper(str?: string): string {
    switch (str) {
        case "succeed":
            return getTranslate("Succeed")
        case "failed":
            return getTranslate("Failed")
        case "pending":
            return getTranslate("Pending")
        default:
            return str ? str : ''
    }
}

export function projectStatusMapper(str?: string): string {
    switch (str) {
        case "open funding":
            return getTranslate("Open Funding")
        case "close funding":
            return getTranslate("Close Funding")
        case "project started":
            return getTranslate("Project Started")
        case "project ended":
            return getTranslate("Project Ended")
        case "pending":
            return getTranslate("Pending")
        case "rejected":
            return getTranslate("Rejected")
        default:
            return str ? str : ''
    }
}

export function paymentMethodMapper(str?: string): string {
    switch (str) {
        case "ovo":
            return getTranslate("Ovo")
        case "gopay":
            return getTranslate("Gopay")
        case "debit card":
            return getTranslate("Debit Card")
        case "credit card":
            return getTranslate("Credit Debit Card")
        default:
            return str ? str : ''
    }
}

export function getStatusColor(status?: string) {
    if (status === undefined) {
        return '#808080'
    }

    if (status === 'rejected' || status === 'failed') {
        return '#f73859'
    } else if (status === 'open funding') {
        return '#ff7657'
    } else if (status === 'close funding') {
        return '#2c365d'
    } else if (status === 'project started') {
        return '#1aa59a'
    } else if (status === 'project ended') {
        return '#4b4b4b'
    } else if (status === 'succeed' || status === 'approved') {
        return '#5bc69a'
    } else {
        return '#aaaaaa'
    }
}

export function bytesToSize(bytes: number) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
}

export function actualSize(bytes: number) {
  if (bytes === 0) return "0 Byte";
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i));
}

export function getFileExtensions(strings: String[]) {
    return strings[strings.length - 1].toUpperCase();
}

export function validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateUrl(url: string) {
    var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (!re.test(url)) {
        return false;
    }
    return true
}

export function removeAllSpecialChar(str: string): string {
    if (!str)
        return ""
    var result = str.replace(/[']/g, "\'\'")
    return result
}

export function filteredDonations(donations: Donation[]): FilteredDonation {
    if (donations && donations.length > 0) {
        var temp: Donation[] = []
        var count = 0

        donations?.forEach(item => {
            if (temp.length <= 0) {
                temp.push(item)
            } else if (temp.find(el => el.user_id !== item.user_id)) {
                temp.push(item)
            } else {
                count += 1
                var elementPos = temp.map(function (x, index) { return x.user_id; }).indexOf(item.user_id);
                temp[elementPos].amount = "" + (parseInt(temp[elementPos].amount) + parseInt(item.amount))
            }
        })
        return {
            donation: temp,
            minusCount: count
        };
    }
    return {
        donation: [],
        minusCount: 0
    };
}

export function mappingStatusUser(status: string): string {
    if (status && status.toLowerCase() === 'approved') {
        return getTranslate('Verified')
    } else if (status && status.toLowerCase() === 'rejected') {
        return getTranslate('Rejected')
    } else {
        return getTranslate('Unverified')
    }
}

export function mappingAccessLevelUser(level: string): string {
    if (level.toLowerCase() === 'admin') {
        return getTranslate('Admin User')
    } else if (level.toLowerCase() === 'pmt') {
        return getTranslate('PMT User')
    } else {
        return getTranslate('General User')
    }
}

export function getTranslate(text: string): string {
    const lg = languageHelper.getLanguage()
    switch (text) {
        case "user not found":
            if (lg) {
                return "user not found"
            } else {
                return "pengguna tidak ditemukan"
            }
        case "Bersama Membangun Indonesia Hijau":
            if (lg) {
                return "Building the Country through Renewable Energy"
            } else {
                return "Membangun Negeri melalui Energi Baru Terbarukan"
            }
        case "“We raised more than $1M USD for the installation of solar panel in a remote school. Indonesia Green ran Facebook ads from day one, and raised more than $500k through tracked links. We highly recommend Indonesia Green”":
            if (lg) {
                return 'Indonesiagreen.com (IGE) took the initiative to help achieve the use of renewable energy in the nationwide through safe and legal donation-based crowd-funding (crowd-funding). Let’s contribute!'
            } else {
                return 'Indonesiagreen.com (IGE) berinisiatif untuk membantu tercapainya penggunaan energi baru terbarukan di masyarakat melalui crowd-funding (urun-dana) berbasis donasi yang aman dan legal. Yuk, ikut kontribusi!'
            }
        case "Login":
            if (lg) {
                return "Login"
            } else {
                return "Masuk"
            }
        case "Anda berhasil mendaftar. Silakan ":
            if (lg) {
                return "You have successfully registered. Please "
            } else {
                return "Anda berhasil mendaftar. Silakan "
            }
        case "Masuk ke akun dengan":
            if (lg) {
                return "Log into account with"
            } else {
                return "Masuk ke akun dengan"
            }
        case "atau":
            if (lg) {
                return "or"
            } else {
                return "atau"
            }
        case "Daftar":
            if (lg) {
                return "Sign up"
            } else {
                return "Daftar"
            }
        case "Belum punya akun?":
            if (lg) {
                return "Don't have an account?"
            } else {
                return "Belum punya akun?"
            }
        case "Kirim":
            if (lg) {
                return "Send"
            } else {
                return "Kirim"
            }
        case "Lupa password":
            if (lg) {
                return "Forgot password"
            } else {
                return "Lupa password"
            }
        case "Atau":
            if (lg) {
                return "Or"
            } else {
                return "Atau"
            }
        case "Dengan login ke Indonesia Green berarti Anda menyetujui":
            if (lg) {
                return 'By logging into Indonesia Green you agree Terms and conditions and Privacy policy. Need help? Email us to supports@indonesiagreen.com'
            } else {
                return 'Dengan login ke Indonesia Green berarti Anda menyetujui Syarat dan Ketentuan dan KebijakanPrivasi. Butuh bantuan? Email kami ke supports@indonesiagreen.com'
            }
        case "Tentang Kami":
            if (lg) {
                return 'About Us'
            } else {
                return 'Tentang Kami'
            }
        case "Hubungi Kami":
            if (lg) {
                return 'Contact Us'
            } else {
                return 'Hubungi Kami'
            }
        case "FAQ":
            if (lg) {
                return 'FAQ'
            } else {
                return 'FAQ'
            }
        case "Blog":
            if (lg) {
                return 'Blog'
            } else {
                return 'Blog'
            }
        case "Syarat dan Ketentuan":
            if (lg) {
                return "Terms and conditions"
            } else {
                return "Syarat dan Ketentuan"
            }
        case "Kebijakan Privasi":
            if (lg) {
                return "Privacy policy"
            } else {
                return "Kebijakan Privasi"
            }
        case "dan":
            if (lg) {
                return "and"
            } else {
                return "dan"
            }
        case "Daftar akun baru dengan":
            if (lg) {
                return "Register a new account with"
            } else {
                return "Daftar akun baru dengan"
            }
        case "Sudah punya akun?":
            if (lg) {
                return "Already have an account?"
            } else {
                return "Sudah punya akun?"
            }
        case "Dengan mendaftar berarti Anda menyetujui":
            if (lg) {
                return "By registering you agree"
            } else {
                return "Dengan mendaftar berarti Anda menyetujui"
            }
        case "Nama":
            if (lg) {
                return "Name"
            } else {
                return "Nama"
            }
        case "Password":
            if (lg) {
                return "Password"
            } else {
                return "Kata Sandi"
            }
        case "Confirm Password":
            if (lg) {
                return "Confirm Password"
            } else {
                return "Konfirmasi Kata Sandi"
            }
        case "No Whatsapp":
            if (lg) {
                return "Whatsapp Number"
            } else {
                return "No Whatsapp"
            }
        case "anonim":
            if (lg) {
                return "anonymous"
            } else {
                return "anonim"
            }
        case "Profile":
            if (lg) {
                return "Profile"
            } else {
                return "Profil"
            }
        case "User Management":
            if (lg) {
                return "User Management"
            } else {
                return "Manajemen Pengguna"
            }
        case "Dashboard PMT":
            if (lg) {
                return "Dashboard PMT"
            } else {
                return "PMT Dasbor"
            }
        case "Keluar":
            if (lg) {
                return "Logout"
            } else {
                return "Keluar"
            }
        case "Sesi telah berakhir":
            if (lg) {
                return "The session has ended"
            } else {
                return "Sesi telah berakhir"
            }
        case "oleh":
            if (lg) {
                return "by"
            } else {
                return "oleh"
            }
        case "Penyelenggara":
            if (lg) {
                return "Organizer"
            } else {
                return "Penyelenggara"
            }
        case "Baca Selengkapnya":
            if (lg) {
                return "Read More"
            } else {
                return "Baca Selengkapnya"
            }
        case "Kembali ke Detail Proyek":
            if (lg) {
                return "Back to Project Details"
            } else {
                return "Kembali ke Detail Proyek"
            }
        case "Add New Progress":
            if (lg) {
                return "Add New Progress"
            } else {
                return "Tambah Progress Baru"
            }
        case "Jumlah amount dalam progress (opsional)":
            if (lg) {
                return "Amount in progress (optional)"
            } else {
                return "Jumlah dalam progress (opsional)"
            }
        case "Nama progress":
            if (lg) {
                return "Progress name"
            } else {
                return "Nama progress"
            }
        case "Deskripsi progress":
            if (lg) {
                return "Progress Description"
            } else {
                return "Deskripsi progress"
            }
        case "Upload bukti transfer":
            if (lg) {
                return "Upload proof of transfer"
            } else {
                return "Unggah bukti transfer"
            }
        case "Drag & drop":
            if (lg) {
                return "Drag & drop"
            } else {
                return "Seret & taruh"
            }
        case "your files":
            if (lg) {
                return "your files"
            } else {
                return "file kamu"
            }
        case " browse ":
            if (lg) {
                return " browse "
            } else {
                return " cari "
            }
        case "Simpan":
            if (lg) {
                return "Save"
            } else {
                return "Simpan"
            }
        case "Progress berhasil ditambahkan":
            if (lg) {
                return "Progress added successfully"
            } else {
                return "Progress berhasil ditambahkan"
            }
        case "Belum ada proyek":
            if (lg) {
                return "There are no projects yet"
            } else {
                return "Belum ada proyek"
            }
        case "Indonesia Green Project":
            if (lg) {
                return "Indonesia Green Project"
            } else {
                return "Proyek Indonesia Green"
            }
        case "Create New Project":
            if (lg) {
                return "Create New Project"
            } else {
                return "Buat Proyek Baru"
            }
        case "My Project":
            if (lg) {
                return "My Project"
            } else {
                return "Proyek Saya"
            }
        case "My Donation":
            if (lg) {
                return "My Donation"
            } else {
                return "Donasi Saya"
            }
        case "All Projects":
            if (lg) {
                return "All Projects"
            } else {
                return "Semua Proyek"
            }
        case "Unverified User":
            if (lg) {
                return "Unverified User"
            } else {
                return "Pengguna Tidak Terverifikasi"
            }
        case "Kembali ke Metode Pembayaran":
            if (lg) {
                return "Back to Payment Method"
            } else {
                return "Kembali ke Metode Pembayaran"
            }
        case "Sedang memuat QR...":
            if (lg) {
                return "Loading QR..."
            } else {
                return "Sedang memuat QR..."
            }
        case "Card Details":
            if (lg) {
                return "Card Details"
            } else {
                return "Detail Kartu"
            }
        case "Card Number":
            if (lg) {
                return "Card No"
            } else {
                return "Card No"
            }
        case "Valid Through":
            if (lg) {
                return "Valid Through"
            } else {
                return "Berlaku Sampai"
            }
        case "Bayar":
            if (lg) {
                return "Pay"
            } else {
                return "Bayar"
            }
        case "Jenis Pembayaran ini tidak tersedia":
            if (lg) {
                return "This type of payment is not available"
            } else {
                return "Jenis Pembayaran ini tidak tersedia"
            }
        case "Data yang dimasukan salah":
            if (lg) {
                return "The data entered is incorrect"
            } else {
                return "Data yang dimasukan salah"
            }
        case "Halaman akan otomatis di perbarui saat melakukan pembayaran via Gopay":
            if (lg) {
                return "This page will be automatically updated when payments accepted via Gopay"
            } else {
                return "Halaman akan otomatis di perbarui saat melakukan pembayaran via Gopay"
            }
        case "Status profile anda ditolak oleh admin, silakan melakukan verifikasi ulang dengan melakukan update data profile anda dan selanjutnya data anda akan di verifikasi ulang oleh admin.":
            if (lg) {
                return "Your profile status is rejected by the admin, please re-verify by updating your profile data and then your data will be re-verified by the admin."
            } else {
                return "Status profil anda ditolak oleh admin, silahkan melakukan verifikasi ulang dengan melakukan update data profile anda dan selanjutnya data anda akan di verifikasi ulang oleh admin."
            }
        case "Status profile anda masih belum terverifikasi oleh admin, silakan menunggu sekitar 2x24 jam untuk proses verifikasi.":
            if (lg) {
                return "Your profile status is still not verified by admin, please wait about 2x24 hours for the verification process."
            } else {
                return "Status profil anda masih belum terverifikasi oleh admin, silakan menunggu sekitar 2x24 jam untuk proses verifikasi."
            }
        case "Anda perlu verifikasi akun untuk membuat project dengan mengupload KTP di bagian menu profile dan selanjutnya data anda akan di verifikasi oleh admin.":
            if (lg) {
                return "You need to verify your account to create a project by uploading your KTP in the profile menu and then your data will be verified by the admin."
            } else {
                return "Anda perlu verifikasi akun untuk membuat project dengan mengupload KTP di bagian menu profile dan selanjutnya data anda akan di verifikasi oleh admin."
            }
        case "Ok, saya mengerti":
            if (lg) {
                return "Ok, I get it"
            } else {
                return "Ok, saya mengerti"
            }
        case "oleh ":
            if (lg) {
                return "by "
            } else {
                return "oleh "
            }
        case "Jumlah Amount: ":
            if (lg) {
                return "Amount: "
            } else {
                return "Jumlah: "
            }
        case "Donasi ":
            if (lg) {
                return "Donation "
            } else {
                return "Donasi "
            }
        case "Kembali ke Beranda":
            if (lg) {
                return "Return to Homepage"
            } else {
                return "Kembali ke Beranda"
            }
        case "terkumpul dari target ":
            if (lg) {
                return "collected from the target "
            } else {
                return "terkumpul dari target "
            }
        case " batas waktu telah berakhir":
            if (lg) {
                return " funding ended"
            } else {
                return " batas waktu telah berakhir";
            }
        case " hari tersisa":
            if (lg) {
                return " days left"
            } else {
                return " hari tersisa"
            }
        case "Donasi Sekarang":
            if (lg) {
                return "Donate Now"
            } else {
                return "Donasi Sekarang"
            }
        case "Cerita":
            if (lg) {
                return "Story"
            } else {
                return "Cerita"
            }
        case "Masukkan Nilai Donasi":
            if (lg) {
                return "Enter the Donation Value"
            } else {
                return "Masukkan Nilai Donasi"
            }
        case "Nominal donasi minimal ":
            if (lg) {
                return "Minimum donation amount "
            } else {
                return "Nominal donasi minimal "
            }
        case "Pilih Metode Pembayaran":
            if (lg) {
                return "Choose Payment Method"
            } else {
                return "Pilih Metode Pembayaran"
            }
        case "Sembunyikan nama saya (anonim)":
            if (lg) {
                return "Hide my name (anonymous)"
            } else {
                return "Sembunyikan nama saya (anonim)"
            }
        case "Lanjut Pembayaran":
            if (lg) {
                return "Continue Payment"
            } else {
                return "Lanjut Pembayaran"
            }
        case "Berhasil donasi":
            if (lg) {
                return "Donation successful"
            } else {
                return "Berhasil donasi"
            }
        case "Belum ada donasi":
            if (lg) {
                return "There are no donations yet"
            } else {
                return "Belum ada donasi"
            }
        case "Mulai Kontribusi":
            if (lg) {
                return "Start Contribution"
            } else {
                return "Mulai Kontribusi"
            }
        case "TOP DONATION":
            if (lg) {
                return "TOP DONATION"
            } else {
                return "TOP DONASI"
            }
        case "Nama Proyek":
            if (lg) {
                return "Project Name"
            } else {
                return "Nama Proyek"
            }
        case "Amount":
            if (lg) {
                return "Amount"
            } else {
                return "Jumlah"
            }
        case "Daftar Proyek":
            if (lg) {
                return "List of Projects"
            } else {
                return "Daftar Proyek"
            }
        case "Nama Proyek…":
            if (lg) {
                return "Project Name…"
            } else {
                return "Nama Proyek…"
            }
        case "Status Proyek":
            if (lg) {
                return "Project Status"
            } else {
                return "Status Proyek"
            }
        case "Semua Status":
            if (lg) {
                return "All Statuses"
            } else {
                return "Semua Status"
            }
        case "Nama Organisasi":
            if (lg) {
                return "Organization Name"
            } else {
                return "Nama Organisasi"
            }
        case "Semua Organisasi":
            if (lg) {
                return "All Organizations"
            } else {
                return "Semua Organisasi"
            }
        case "Terapkan Filter":
            if (lg) {
                return "Apply Filters"
            } else {
                return "Terapkan Filter"
            }
        case "Filter proyek berhasil":
            if (lg) {
                return "Project filter successful"
            } else {
                return "Filter proyek berhasil"
            }
        case "Detail Pengajuan Proyek":
            if (lg) {
                return "Project Submission Details"
            } else {
                return "Detail Pengajuan Proyek"
            }
        case "Contoh: Pemasangan Turbin di Kawasan Ciwidey":
            if (lg) {
                return "Example: Turbine Installation in Ciwidey Area"
            } else {
                return "Contoh: Pemasangan Turbin di Kawasan Ciwidey"
            }
        case "Deskripsi Proyek":
            if (lg) {
                return "Project Description"
            } else {
                return "Deskripsi Proyek"
            }
        case "Jumlah Pengajuan Dana Proyek":
            if (lg) {
                return "Total Project Fund Submission"
            } else {
                return "Jumlah Pengajuan Dana Proyek"
            }
        case "Contoh: ":
            if (lg) {
                return "Example: "
            } else {
                return "Contoh: "
            }
        case "Alamat Proyek":
            if (lg) {
                return "Project Address"
            } else {
                return "Alamat Proyek"
            }
        case "Daya Listrik yang telah Terpasang":
            if (lg) {
                return "Installed Electric Power"
            } else {
                return "Daya Listrik yang telah Terpasang"
            }
        case "Jumlah Pengajuan Daya PV":
            if (lg) {
                return "Number of PV Power Submissions"
            } else {
                return "Jumlah Pengajuan Daya PV"
            }
        case "Dimana PV akan dipasang?":
            if (lg) {
                return "Where will the PV be installed?"
            } else {
                return "Dimana PV akan dipasang?"
            }
        case "Apakah akan menggunakan PV Baterai?":
            if (lg) {
                return "Will you use a PV battery?"
            } else {
                return "Apakah akan menggunakan PV Baterai?"
            }
        case "Ya":
            if (lg) {
                return "Yes"
            } else {
                return "Ya"
            }
        case "Tidak":
            if (lg) {
                return "No"
            } else {
                return "Tidak"
            }
        case "Apakah proyek ini telah memiliki kontraktor berpengalaman?":
            if (lg) {
                return "Does this project have experienced contractors?"
            } else {
                return "Apakah proyek ini telah memiliki kontraktor berpengalaman?"
            }
        case "Apakah proyek ini sudah dimulai?":
            if (lg) {
                return "Has this project started?"
            } else {
                return "Apakah proyek ini sudah dimulai?"
            }
        case "Sudah":
            if (lg) {
                return "Yes, It Has"
            } else {
                return "Sudah"
            }
        case "Belum":
            if (lg) {
                return "Not yet"
            } else {
                return "Belum"
            }
        case "Apakah Anda bertindak sebagai perwakilan langsung / tim dari proyek ini?":
            if (lg) {
                return "Are you acting as a direct / team representative of this project?"
            } else {
                return "Apakah Anda bertindak sebagai perwakilan langsung / tim dari proyek ini?"
            }
        case "Upload proposal / gambar proyek jika ada":
            if (lg) {
                return "Upload project proposals / photos if available"
            } else {
                return "Upload proposal / gambar proyek jika ada"
            }
        case "Uploading...":
            if (lg) {
                return "Uploading..."
            } else {
                return "Mengunggah..."
            }
        case "Detail Proyek":
            if (lg) {
                return "Project Details"
            } else {
                return "Detail Proyek"
            }
        case "Current Amount: ":
            if (lg) {
                return "Current Amount: "
            } else {
                return "Jumlah Saat Ini: "
            }
        case "Information":
            if (lg) {
                return "Information"
            } else {
                return "Informasi"
            }
        case "Open Funding Date":
            if (lg) {
                return "Open Funding Date"
            } else {
                return "Tanggal Pendanaan Terbuka"
            }
        case "Close Funding Date":
            if (lg) {
                return "Close Funding Date"
            } else {
                return "Tanggal Pendanaan Ditutup"
            }
        case "Project Started Date":
            if (lg) {
                return "Project Started Date"
            } else {
                return "Tanggal Proyek Dimulai"
            }
        case "Project Ended Date":
            if (lg) {
                return "Project Ended Date"
            } else {
                return "Tanggal Proyek Berakhir"
            }
        case "Update Project":
            if (lg) {
                return "Update Project"
            } else {
                return "Perbarui Proyek"
            }
        case "Proyek berhasil diubah":
            if (lg) {
                return "Project successfully changed"
            } else {
                return "Proyek berhasil diubah"
            }
        case "Informasi Akun":
            if (lg) {
                return "Account Information"
            } else {
                return "Informasi Akun"
            }
        case "Ubah Akun":
            if (lg) {
                return "Change Account"
            } else {
                return "Ubah Akun"
            }
        case "Phone":
            if (lg) {
                return "Phone"
            } else {
                return "Telepon"
            }
        case "KTP":
            if (lg) {
                return "ID card"
            } else {
                return "KTP"
            }
        case "Profile Organisasi":
            if (lg) {
                return "Organization Profile"
            } else {
                return "Profil Organisasi"
            }
        case "Alamat":
            if (lg) {
                return "Address"
            } else {
                return "Alamat"
            }
        case "Kategori Organisasi":
            if (lg) {
                return "Organization Category"
            } else {
                return "Kategori Organisasi"
            }
        case "Blog/Website":
            if (lg) {
                return "Blog/Website"
            } else {
                return "Blog/Situs web"
            }
        case "Apakah Anda bertindak sebagai perwakilan langsung dari organisasi ini?":
            if (lg) {
                return "Are you acting as a direct representative of this organization?"
            } else {
                return "Apakah Anda bertindak sebagai perwakilan langsung dari organisasi ini?"
            }
        case "Setting Password":
            if (lg) {
                return "Password Settings"
            } else {
                return "Atur Kata Sandi"
            }
        case "Ubah Password":
            if (lg) {
                return "Change Password"
            } else {
                return "Ubah Kata Sandi"
            }
        case "Email is invalid":
            if (lg) {
                return "Email is invalid"
            } else {
                return "Email tidak valid"
            }
        case "URL is invalid":
            if (lg) {
                return "URL is invalid"
            } else {
                return "URL tidak valid"
            }
        case "Kembali ke Profile":
            if (lg) {
                return "Return to Profile"
            } else {
                return "Kembali ke Profil"
            }
        case "Simpan Akun":
            if (lg) {
                return "Save Account"
            } else {
                return "Simpan Akun"
            }
        case "Upload KTP":
            if (lg) {
                return "Upload ID card"
            } else {
                return "Unggah KTP"
            }
        case "Simpan Organisasi":
            if (lg) {
                return "Save Organization"
            } else {
                return "Simpan Organisasi"
            }
        case "Pilih Kategori Organisasi":
            if (lg) {
                return "Select the Organization Category"
            } else {
                return "Pilih Kategori Organisasi"
            }
        case "Simpan Password":
            if (lg) {
                return "Save password"
            } else {
                return "Simpan Kata Sandi"
            }
        case "Verifikasi Ubah Password":
            if (lg) {
                return "Verify Change Password"
            } else {
                return "Verifikasi Ubah Kata Sandi"
            }
        case "Silakan konfirmasi nomor telepon dan password lama Anda.":
            if (lg) {
                return "Please confirm your old telephone number and password."
            } else {
                return "Silakan konfirmasi nomor telepon dan kata sandi lama Anda."
            }
        case "Password Lama":
            if (lg) {
                return "Old Password"
            } else {
                return "Kata Sandi Lama"
            }
        case "Verifikasi":
            if (lg) {
                return "Verify"
            } else {
                return "Verifikasi"
            }
        case "Profil Organisasi berhasil diubah":
            if (lg) {
                return "Organization Profile successfully changed"
            } else {
                return "Profil Organisasi berhasil diubah"
            }
        case "Password berhasil diubah":
            if (lg) {
                return "Password successfully changed"
            } else {
                return "Kata Sandi berhasil diubah"
            }
        case "Informasi akun berhasil diubah":
            if (lg) {
                return "Account information successfully changed"
            } else {
                return "Informasi akun berhasil diubah"
            }
        case "Kembali ke My Project":
            if (lg) {
                return "Return to My Project"
            } else {
                return "Kembali ke Proyek Saya"
            }
        case "Ubah Project":
            if (lg) {
                return "Change Project"
            } else {
                return "Ubah Project"
            }
        case "Submit Progress":
            if (lg) {
                return "Submit Progress"
            } else {
                return "Buat Progress"
            }
        case "Berhasil menambahkan progress":
            if (lg) {
                return "Successfully added progress"
            } else {
                return "Berhasil menambahkan progress"
            }
        case "Project Submission":
            if (lg) {
                return "Project Submission"
            } else {
                return "Pengajuan Proyek"
            }
        case "Submit Project":
            if (lg) {
                return "Submit Project"
            } else {
                return "Buat Proyek"
            }
        case "Proyek berhasil dibuat":
            if (lg) {
                return "Project successfully created"
            } else {
                return "Proyek berhasil dibuat"
            }
        case "Status Data":
            if (lg) {
                return "Status Data"
            } else {
                return "Data Status"
            }
        case "Lihat KTP":
            if (lg) {
                return "See ID card"
            } else {
                return "Lihat KTP"
            }
        case "Lihat Organisasi":
            if (lg) {
                return "See Organization"
            } else {
                return "Lihat Organisasi"
            }
        case "Hapus User":
            if (lg) {
                return "Remove User"
            } else {
                return "Hapus Pengguna"
            }
        case "Berhasil verifikasi user":
            if (lg) {
                return "User verification successful"
            } else {
                return "Berhasil verifikasi pengguna"
            }
        case "Berhasil menghapus user":
            if (lg) {
                return "Successfully deleted user"
            } else {
                return "Berhasil menghapus pengguna"
            }
        case "Daftar User":
            if (lg) {
                return "User List"
            } else {
                return "Daftar Pengguna"
            }
        case "Cari user…":
            if (lg) {
                return "Search user…"
            } else {
                return "Cari pengguna…"
            }
        case "Add New User":
            if (lg) {
                return "Add New User"
            } else {
                return "Tambah Pengguna Baru"
            }
        case "Login With":
            if (lg) {
                return "Login With"
            } else {
                return "Masuk dengan"
            }
        case "Akses Level":
            if (lg) {
                return "Access Level"
            } else {
                return "Tingkat Akses"
            }
        case "Status User":
            if (lg) {
                return "Status User"
            } else {
                return "Status Pengguna"
            }
        case "User tidak ditemukan.":
            if (lg) {
                return "User not found."
            } else {
                return "Pengguna tidak ditemukan."
            }
        case "Belum ada user":
            if (lg) {
                return "There are no users yet"
            } else {
                return "Belum ada pengguna"
            }
        case "Approved":
            if (lg) {
                return "Approved"
            } else {
                return "Disetujui"
            }
        case "Organisasi":
            if (lg) {
                return "Organization"
            } else {
                return "Organisasi"
            }
        case "User ini tidak memiliki KTP":
            if (lg) {
                return "This user does not have an ID card"
            } else {
                return "Pengguna ini tidak memiliki KTP"
            }
        case "User ini tidak memiliki organisasi":
            if (lg) {
                return "This user has no organization"
            } else {
                return "Pengguna ini tidak memiliki organisasi"
            }
        case "Tutup":
            if (lg) {
                return "Close"
            } else {
                return "Tutup"
            }
        case "Kembali ke User Management":
            if (lg) {
                return "Return to User Management"
            } else {
                return "Kembali ke Manajemen Pengguna"
            }
        case "Profile Organisasi ":
            if (lg) {
                return "Organization Profile "
            } else {
                return "Profil Organisasi "
            }
        case "User":
            if (lg) {
                return "User"
            } else {
                return "Pengguna"
            }
        case "Admin PMT":
            if (lg) {
                return "PMT Admin"
            } else {
                return "Admin PMT"
            }
        case "Password User":
            if (lg) {
                return "Password User"
            } else {
                return "Kata Sandi Pengguna"
            }
        case "User berhasil ditambahkan":
            if (lg) {
                return "User successfully added"
            } else {
                return "Pengguna berhasil ditambahkan"
            }
        case "Verified":
            if (lg) {
                return "Verified"
            } else {
                return "Terverifikasi"
            }
        case "Unverified":
            if (lg) {
                return "Unverified"
            } else {
                return "Tidak terverifikasi"
            }
        case "Admin User":
            if (lg) {
                return "Admin"
            } else {
                return "Admin"
            }
        case "PMT User":
            if (lg) {
                return "PMT"
            } else {
                return "PMT"
            }
        case "General User":
            if (lg) {
                return "User"
            } else {
                return "Pengguna"
            }
        case "Open Funding: ":
            if (lg) {
                return "Open Funding: "
            } else {
                return "Pendanaan Terbuka: "
            }
        case "Close Funding: ":
            if (lg) {
                return "Close Funding: "
            } else {
                return "Pendanaan Ditutup: "
            }
        case "Project Started: ":
            if (lg) {
                return "Project Started: "
            } else {
                return "Proyek Dimulai: "
            }
        case "Project Ended: ":
            if (lg) {
                return "Project Ended: "
            } else {
                return "Proyek Berakhir: "
            }
        case "Rejected: ":
            if (lg) {
                return "Rejected: "
            } else {
                return "Ditolak: "
            }
        case "Pending: ":
            if (lg) {
                return "Pending: "
            } else {
                return "Tertunda: "
            }
        case "Open Funding":
            if (lg) {
                return "Open Funding"
            } else {
                return "Pendanaan Terbuka"
            }
        case "Close Funding":
            if (lg) {
                return "Close Funding"
            } else {
                return "Pendanaan Ditutup"
            }
        case "Project Started":
            if (lg) {
                return "Project Started"
            } else {
                return "Proyek Dimulai"
            }
        case "Project Ended":
            if (lg) {
                return "Project Ended"
            } else {
                return "Proyek Berakhir"
            }
        case "Rejected":
            if (lg) {
                return "Rejected"
            } else {
                return "Ditolak"
            }
        case "Pending":
            if (lg) {
                return "Pending"
            } else {
                return "Tertunda"
            }
        case "Tidak ada koneksi":
            if (lg) {
                return "There is no connection"
            } else {
                return "Tidak ada koneksi"
            }
        case "id-ID":
            if (lg) {
                return "en-En"
            } else {
                return "id-ID"
            }
        case "Ubah Organisasi":
            if (lg) {
                return "Change Organization"
            } else {
                return "Ubah Organisasi"
            }
        case "Cari user berhasil":
            if (lg) {
                return "Search user succeeded"
            } else {
                return "Cari pengguna berhasil"
            }
        case "Yayasan":
            if (lg) {
                return "Foundation"
            } else {
                return "Yayasan"
            }
        case "Perusahaan":
            if (lg) {
                return "Company"
            } else {
                return "Perusahaan"
            }
        case "Ngo":
            if (lg) {
                return "Ngo"
            } else {
                return "Ngo"
            }
        case "Pemerintahan":
            if (lg) {
                return "Government"
            } else {
                return "Pemerintahan"
            }
        case "Pendidikan":
            if (lg) {
                return "Education"
            } else {
                return "Pendidikan"
            }
        case "Last update: ":
            if (lg) {
                return "Last update: "
            } else {
                return "Pembaharuan Terakhir: "
            }
        case "Failed":
            if (lg) {
                return "Failed"
            } else {
                return "Gagal"
            }
        case "Succeed":
            if (lg) {
                return "Succeed"
            } else {
                return "Berhasil"
            }
        case "Success":
            if (lg) {
                return "Success"
            } else {
                return "Berhasil"
            }
        case "Debit Card":
            if (lg) {
                return "Debit Card"
            } else {
                return "Kartu Debit"
            }
        case "Credit Card":
            if (lg) {
                return "Credit Card"
            } else {
                return "Kartu Kredit"
            }
        case "id":
            if (lg) {
                return "en"
            } else {
                return "id"
            }
        case "Kembali":
            if (lg) {
                return "Back"
            } else {
                return "Kembali"
            }
        case "Credit Debit Card":
            if (lg) {
                return "Credit/ Debit Card"
            } else {
                return "Kartu Kredit/ Debit"
            }
        // Privacy Policy
        case '<p style="font-size: 18px; font-weight: bold;">Umum</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> General </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Umum</p><br />'
            }
        case '<p>Selamat datang di website indonesiagreen.com maupun aplikasi Indonesia Green (IGE) (“Situs”), halaman ini dikelola oleh tim IGE (“Kami”), kebijakan privasi yang dimaksud pada halaman ini (“Kebijakan”) adalah acuan untuk mengatur dan melindungi penggunaan data dan informasi penting para pengguna Situs (“Anda”). Data dan informasi yang telah dikumpulkan pada saat mendaftar, mengakses dan menggunakan fitur/layanan Situs, seperti alamat, nomor kontak, alamat email, foto, video, gambar dan informasi lain (“Data”). </p>':
            if (lg) {
                return '<p> Welcome to the indonesiagreen.com website and the Indonesia Green (IGE) application ("Site"), this page is managed by the IGE team ("We"), the privacy policy referred to on this page ("Policy") is a reference for regulating and protecting use of important data and information on the users of the Site ("You"). Data and information that has been collected when registering, accessing and using Site features / services, such as addresses, contact numbers, e-mail addresses, photos, videos, images and other information ("Data"). </p>'
            } else {
                return '<p>Selamat datang di website indonesiagreen.com maupun aplikasi Indonesia Green (IGE) (“Situs”), halaman ini dikelola oleh tim IGE (“Kami”), kebijakan privasi yang dimaksud pada halaman ini (“Kebijakan”) adalah acuan untuk mengatur dan melindungi penggunaan data dan informasi penting para pengguna Situs (“Anda”). Data dan informasi yang telah dikumpulkan pada saat mendaftar, mengakses dan menggunakan fitur/layanan Situs, seperti alamat, nomor kontak, alamat email, foto, video, gambar dan informasi lain (“Data”). </p>'
            }
        case '<p>Sebelum Anda menggunakan atau memberikan informasi apapun kepada Situs ini, mohon untuk membaca dan memahami terlebih dahulu mengenai Kebijakan privasi yang telah Kami buat ini. Dengan menggunakan Situs ini maka pengguna dianggap telah membaca, mengerti, memahami, dan menyetujui semua isi dalam Kebijakan ini. <a style="font-weight: bold;">Apabila Anda sebagai pengguna Situs tidak menyetujui salah satu, sebagian atau seluruh isi Kebijakan ini, maka pengguna tidak diperkenankan untuk menggunakan layanan IGE.</a> </p>':
            if (lg) {
                return '<p> Before you use or provide any information to this Site, please read and understand in advance about the privacy policy that we have created. By using this Site, users are deemed to have read, understood, understood and agreed to all contents in this Policy. <a style="font-weight: bold;"> If you as a user of the Site do not agree with any, part or all of the contents of this Policy, then users are not permitted to use IGE services. </a> </p>'
            } else {
                return '<p>Sebelum Anda menggunakan atau memberikan informasi apapun kepada Situs ini, mohon untuk membaca dan memahami terlebih dahulu mengenai Kebijakan privasi yang telah Kami buat ini. Dengan menggunakan Situs ini maka pengguna dianggap telah membaca, mengerti, memahami, dan menyetujui semua isi dalam Kebijakan ini. <a style="font-weight: bold;">Apabila Anda sebagai pengguna Situs tidak menyetujui salah satu, sebagian atau seluruh isi Kebijakan ini, maka pengguna tidak diperkenankan untuk menggunakan layanan IGE.</a> </p>'
            }
        case '<p>Kami berhak atas kebijakan untuk mengubah atau memodifikasi sebagian atau keseluruhan dari isi Kebijakan ini setiap saat, artinya aturan yang berlaku pada halaman ini dapat berubah sewaktu-waktu secara sepihak oleh Kami, apabila hal tersebut terjadi maka Kami akan mencoba memberikan pemberitahuan kepada seluruh pengguna Situs, bisa melalui email, sosial media Kami, maupun melalui Situs ini secara langsung. Aturan yang baru akan mulai berlaku setelah pemberitahuan sudah terpublikasikan atau terkirim ke seluruh pengguna Situs. Kesepakatan di atas tidak berlaku apabila terdapat perubahan karena alasan hukum Negara Indonesia, Kebijakan pada Situs ini akan berubah menyesuaikan dengan peraturan pemerintah yang berlaku.</p><br />':
            if (lg) {
                return '<p>We have the right to a policy to change or modify part or all of the contents of this Policy at any time, meaning that the rules applicable to this page may change at any time unilaterally by us, if that happens we will try to give a notice to all Site users, can be via email, our social media, or through this Site directly. The new rules will take effect after the notice has been published or sent to all users of the Site. The above agreement does not apply if there is a change for reasons of Indonesian law, the Policies on this Site will change according to applicable government regulations. </p> <br />'
            } else {
                return '<p>Kami berhak atas kebijakan untuk mengubah atau memodifikasi sebagian atau keseluruhan dari isi Kebijakan ini setiap saat, artinya aturan yang berlaku pada halaman ini dapat berubah sewaktu-waktu secara sepihak oleh Kami, apabila hal tersebut terjadi maka Kami akan mencoba memberikan pemberitahuan kepada seluruh pengguna Situs, bisa melalui email, sosial media Kami, maupun melalui Situs ini secara langsung. Aturan yang baru akan mulai berlaku setelah pemberitahuan sudah terpublikasikan atau terkirim ke seluruh pengguna Situs. Kesepakatan di atas tidak berlaku apabila terdapat perubahan karena alasan hukum Negara Indonesia, Kebijakan pada Situs ini akan berubah menyesuaikan dengan peraturan pemerintah yang berlaku.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Persetujuan</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Agreement </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Persetujuan</p><br />'
            }
        case '<p>Informasi terlebih lanjut akan Kami jelaskan di bawah ini, bila Anda menggunakan atau sebaliknya mengakses layanan Situs, Kami mungkin mengumpulkan informasi Anda dalam berbagai cara, termasuk ketika Anda memberikan informasi secara langsung kepada Kami, dan ketika Kami secara pasif mengumpulkan informasi dari Situs.</p><br />':
            if (lg) {
                return '<p> We will explain further information below, if you use or otherwise access Site services, We may collect your information in various ways, including when you provide information directly to us, and when we passively collect information from the Site . </p> <br />'
            } else {
                return '<p>Informasi terlebih lanjut akan Kami jelaskan di bawah ini, bila Anda menggunakan atau sebaliknya mengakses layanan Situs, Kami mungkin mengumpulkan informasi Anda dalam berbagai cara, termasuk ketika Anda memberikan informasi secara langsung kepada Kami, dan ketika Kami secara pasif mengumpulkan informasi dari Situs.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Informasi Yang Diminta</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Requested Information </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Informasi Yang Diminta</p><br />'
            }
        case '<p>Ketika Anda hendak menggunakan Situs Kami sebagai user, yaitu donatur terdapat beberapa informasi pribadi yang akan Kami minta seperti nama, alamat email, dan nomor telepon. </p>':
            if (lg) {
                return '<p> When you want to use our Site as a user, that is a donors there is some personal information that we will ask for such as your name, email address and telephone number. </p>'
            } else {
                return '<p>Ketika Anda hendak menggunakan Situs Kami sebagai user, yaitu donatur terdapat beberapa informasi pribadi yang akan Kami minta seperti nama, alamat email, dan nomor telepon. </p>'
            }
        case '<p>Apabila Anda mendaftar sebagai penggalang dana terdapat beberapa informasi pribadi yang akan Kami minta seperti nama, alamat email, nomor telepon, sosial media, foto diri, KTP, no rekening dan informasi lainnya sesuai dengan keperluan Kami. Kebijakan tersebut Kami minta diperuntukkan keperluan lebih lanjut agar Kami bisa menghubungi Anda.</p>':
            if (lg) {
                return '<p> If you register as a fundraiser there will be some personal information that we will request such as your name, e-mail address, telephone number, social media, photo self, ID card, account number and other information in accordance with our requirements. We ask that these policies be set for further needs so that we can contact you. </p>'
            } else {
                return '<p>Apabila Anda mendaftar sebagai penggalang dana terdapat beberapa informasi pribadi yang akan Kami minta seperti nama, alamat email, nomor telepon, sosial media, foto diri, KTP, no rekening dan informasi lainnya sesuai dengan keperluan Kami. Kebijakan tersebut Kami minta diperuntukkan keperluan lebih lanjut agar Kami bisa menghubungi Anda.</p>'
            }
        case '<p>Kami meminta informasi yang diperlukan agar dapat memenuhi permintaan Anda untuk menggunakan Situs. Informasi Anda Kami simpan dan digunakan sesuai dengan ketentuan Undang-Undang, peraturan, kebijakan dan hukum pemerintah yang berlaku. Apabila Anda memilih untuk tidak memberikan informasi apapun, Kami mungkin tidak dapat memenuhi permintaan Anda dalam menggunakan Situs ini, tetapi Anda tetap bisa menggunakan Situs ini sebagai pengguna umum.</p><br />':
            if (lg) {
                return '<p>We request the information needed to fulfill your request to use the Site. Your information is stored and used in accordance with applicable laws, regulations, policies and government laws. If you choose not to provide any information, we may not be able to fulfill your request to use this Site, but you can still use this Site as a general user. </p> <br />'
            } else {
                return '<p>Kami meminta informasi yang diperlukan agar dapat memenuhi permintaan Anda untuk menggunakan Situs. Informasi Anda Kami simpan dan digunakan sesuai dengan ketentuan Undang-Undang, peraturan, kebijakan dan hukum pemerintah yang berlaku. Apabila Anda memilih untuk tidak memberikan informasi apapun, Kami mungkin tidak dapat memenuhi permintaan Anda dalam menggunakan Situs ini, tetapi Anda tetap bisa menggunakan Situs ini sebagai pengguna umum.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Penggunaan Informasi</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Use of Information </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Penggunaan Informasi</p><br />'
            }
        case '<p>Kami menggunakan informasi pribadi Anda untuk berbagai keperluan verifikasi, seperti verifikasi akun, rekening atau identitas lainnya. Hal tersebut diperlukan agar terdapat kejelasan informasi dan tujuan dalam menggunakan Situs Kami.</p>':
            if (lg) {
                return '<p>We use your personal information for various verification purposes, such as account verification, accounts or other identities. This is necessary so that there is clarity of information and purpose in using Our Site. </p>'
            } else {
                return '<p>Kami menggunakan informasi pribadi Anda untuk berbagai keperluan verifikasi, seperti verifikasi akun, rekening atau identitas lainnya. Hal tersebut diperlukan agar terdapat kejelasan informasi dan tujuan dalam menggunakan Situs Kami.</p>'
            }
        case '<p>Kami menggunakan alamat email yang Anda berikan untuk mengirimkan pembaharuan atau pesan dari Situs Kami baik secara berkala maupun saat itu juga. </p>':
            if (lg) {
                return '<p> We use the e-mail address you provide to send updates or messages from our site, both periodically and at that time. </p>'
            } else {
                return '<p>Kami menggunakan alamat email yang Anda berikan untuk mengirimkan pembaharuan atau pesan dari Situs Kami baik secara berkala maupun saat itu juga. </p>'
            }
        case '<p>Dalam hal tertentu Kami menggunakan informasi Anda untuk meningkatkan mutu dan pelayanan Situs.</p>':
            if (lg) {
                return '<p> In certain cases we use your information to improve the quality and service of the Site. </p>'
            } else {
                return '<p>Dalam hal tertentu Kami menggunakan informasi Anda untuk meningkatkan mutu dan pelayanan Situs.</p>'
            }
        case '<p>Kami tidak menjual, menyewakan dan memberikan informasi Anda kepada pihak lain, kecuali apabila ditentukan pada persyaratan lain dan diminta oleh pihak yang berwajib menghendaki untuk meminta informasi kepada Kami.</p>':
            if (lg) {
                return '<p> We do not sell, rent and provide your information to other parties, except if specified in other conditions and requested by the authorities to request information from us. </p>'
            } else {
                return '<p>Kami tidak menjual, menyewakan dan memberikan informasi Anda kepada pihak lain, kecuali apabila ditentukan pada persyaratan lain dan diminta oleh pihak yang berwajib menghendaki untuk meminta informasi kepada Kami.</p>'
            }
        case '<p>Memperbaiki Informasi Anda</p>':
            if (lg) {
                return '<p> Correcting Your Information </p>'
            } else {
                return '<p>Memperbaiki Informasi Anda</p>'
            }
        case '<p>Ketika Anda memberikan Kami Data Anda, pastikan memberikan informasi yang akurat dan lengkap. Apabila Anda merasa bahwa terdapat informasi yang Anda berikan kepada Kami mengandung kesalahan atau kelalaian, harap login ke akun Anda di Situs dan perbaiki informasinya.</p>':
            if (lg) {
                return '<p> When you provide us with your data, be sure to provide accurate and complete information. If you feel that the information you have provided to us contains errors or omissions, please log in to your account on the Site and correct the information. </p>'
            } else {
                return '<p>Ketika Anda memberikan Kami Data Anda, pastikan memberikan informasi yang akurat dan lengkap. Apabila Anda merasa bahwa terdapat informasi yang Anda berikan kepada Kami mengandung kesalahan atau kelalaian, harap login ke akun Anda di Situs dan perbaiki informasinya.</p>'
            }
        case '<p>Apabila Anda ingin memperbaiki kesalahan atau kelalaian pada saat pengisian Data manapun di bawah kepemilikan atau kendali Kami yang tidak dapat dikoreksi oleh Anda, harap kirimkan permintaan Anda menggunakan identitas yang telah terdaftar ke kontak Kami yang tertera di bawah ini. Mengenai proses perubahan Data, Kami mungkin akan meminta Data tambahan sesuai dengan permintaan yang Anda ajukan.</p><br />':
            if (lg) {
                return '<p> If you wish to correct an error or omission when filling in any Data under our ownership or control that cannot be corrected by you, please send your request using the registered identity to our contact listed below. Regarding the data change process, we may request additional data in accordance with the request you submit. </p> <br />'
            } else {
                return '<p>Apabila Anda ingin memperbaiki kesalahan atau kelalaian pada saat pengisian Data manapun di bawah kepemilikan atau kendali Kami yang tidak dapat dikoreksi oleh Anda, harap kirimkan permintaan Anda menggunakan identitas yang telah terdaftar ke kontak Kami yang tertera di bawah ini. Mengenai proses perubahan Data, Kami mungkin akan meminta Data tambahan sesuai dengan permintaan yang Anda ajukan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Keamanan Data</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Data Security </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Keamanan Data</p><br />'
            }
        case '<p>Kami tidak pernah memberikan informasi pribadi Anda kepada pihak ketiga, kecuali apabila ketentuan hukum berkehendak lain. Kami menjaga keamanan dan kerahasiaan informasi Anda yang telah masuk dalam database Kami. Untuk mencegah akses yang tidak sah, menjaga keakuratan Data dan memastikan penggunaan informasi yang benar, apabila terdapat informasi yang menurut Anda yang bersifat privasi atau rahasia akan Kami simpan sesuai dengan ketentuan yang disepakati.</p><br />':
            if (lg) {
                return '<p> We never give your personal information to third parties, except if the legal provisions have other wishes. We maintain the security and confidentiality of your information that has entered our database. To prevent unauthorized access, maintain the accuracy of the data and ensure the correct use of information, if there is information that you think is privacy or confidential we will keep it in accordance with the agreed terms. </p> <br />'
            } else {
                return '<p>Kami tidak pernah memberikan informasi pribadi Anda kepada pihak ketiga, kecuali apabila ketentuan hukum berkehendak lain. Kami menjaga keamanan dan kerahasiaan informasi Anda yang telah masuk dalam database Kami. Untuk mencegah akses yang tidak sah, menjaga keakuratan Data dan memastikan penggunaan informasi yang benar, apabila terdapat informasi yang menurut Anda yang bersifat privasi atau rahasia akan Kami simpan sesuai dengan ketentuan yang disepakati.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Pemberitahuan</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Notification </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Pemberitahuan</p><br />'
            }
        case '<p>Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging) yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik tersebut.</p><br />':
            if (lg) {
                return '<p> Platform visitors or Platform Users agree and are willing to receive all notifications via electronic media, including, but not limited to e-mail, short message service (SMS) and / or instant messaging registered on the Platform and for that purpose give permission to the Platform Provider to contact Platform Visitors or Platform Users via the electronic media. </p> <br />'
            } else {
                return '<p>Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging) yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik tersebut.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Konten Publik</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Public Content </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Konten Publik</p><br />'
            }
        case '<p>Anda mengetahui dan sepakat bahwa setiap informasi yang Anda tampilkan pada konten atau layanan Kami, dapat diakses atau dilihat oleh publik, seperti nama, nomor telepon, email, nominal donasi, komentar pada donasi dan sosial media Anda. Hal tersebut Kami lakukan supaya terdapat keterbukaan informasi bagi pengguna Situs agar tidak dapat menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan.</p><br />':
            if (lg) {
                return '<p> You acknowledge and agree that any information that you display on our content or services, can be accessed or seen by the public, such as your name, telephone number, email, nominal donations, comments on your donations and social media. We do this so that there is information disclosure for Site users so as not to cause suspicion or other prejudices that cannot be accounted for. </p> <br />'
            } else {
                return '<p>Anda mengetahui dan sepakat bahwa setiap informasi yang Anda tampilkan pada konten atau layanan Kami, dapat diakses atau dilihat oleh publik, seperti nama, nomor telepon, email, nominal donasi, komentar pada donasi dan sosial media Anda. Hal tersebut Kami lakukan supaya terdapat keterbukaan informasi bagi pengguna Situs agar tidak dapat menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Cookies</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Cookies </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Cookies</p><br />'
            }
        case '<p>Cookies adalah file kecil yang secara otomatis akan mengambil tempat di dalam perangkat Pengguna yang menjalankan fungsi dalam menyimpan preferensi maupun konfigurasi Pengguna selama mengunjungi suatu Situs.</p>':
            if (lg) {
                return '<p> Cookies are small files that will automatically take place in the User\'s device which performs the function of storing User preferences and configurations while visiting a Site. </p>'
            } else {
                return '<p>Cookies adalah file kecil yang secara otomatis akan mengambil tempat di dalam perangkat Pengguna yang menjalankan fungsi dalam menyimpan preferensi maupun konfigurasi Pengguna selama mengunjungi suatu Situs.</p>'
            }
        case '<p>Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses Data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk disampaikan.</p>':
            if (lg) {
                return '<p> These cookies are not intended to be used when accessing other Data that the User has on the User\'s computer, other than what the User has agreed to submit. </p>'
            } else {
                return '<p>Cookies tersebut tidak diperuntukkan untuk digunakan pada saat melakukan akses Data lain yang Pengguna miliki di perangkat komputer Pengguna, selain dari yang telah Pengguna setujui untuk disampaikan.</p>'
            }
        case '<p>Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat membatasi layanan optimal pada saat melakukan akses ke Situs).</p><br />':
            if (lg) {
                return '<p> Even though the User\'s computer device will automatically accept cookies, the User can make choices to make modifications through the User\'s browser settings by choosing to reject cookies(this option can limit optimal service when accessing the Site). </p> <br / > '
            } else {
                return '<p>Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookies, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan browser Pengguna yaitu dengan memilih untuk menolak cookies (pilihan ini dapat membatasi layanan optimal pada saat melakukan akses ke Situs).</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Informasi Pribadi dari Anak di Bawah Umur dan Individu Lainnya</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Personal Information of Minors and Other Individuals </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Informasi Pribadi dari Anak di Bawah Umur dan Individu Lainnya</p><br />'
            }
        case '<p>Sebagai orang tua atau wali yang sah, mohon untuk tidak mengizinkan anak di bawah umur (di bawah 17 (delapan belas) tahun) yang berada dalam pengawasan Anda untuk memberikan Data ke Situs. Apabila Data tersebut diberikan kepada Situs, Anda dengan ini menyetujui pemrosesan Data anak di bawah umur tersebut dan secara pribadi menerima dan setuju untuk terikat oleh Kebijakan ini dan bertanggung jawab atas tindakannya.</p>':
            if (lg) {
                return '<p>As a parent or legal guardian, please do not allow minors (under 17 (eighteen) years) who are under your supervision to provide Data to the Site. If the Data is provided to the Site, you hereby agree to the processing of the data of minors and personally accept and agree to be bound by this Policy and are responsible for their actions. </p>'
            } else {
                return '<p>Sebagai orang tua atau wali yang sah, mohon untuk tidak mengizinkan anak di bawah umur (di bawah 17 (delapan belas) tahun) yang berada dalam pengawasan Anda untuk memberikan Data ke Situs. Apabila Data tersebut diberikan kepada Situs, Anda dengan ini menyetujui pemrosesan Data anak di bawah umur tersebut dan secara pribadi menerima dan setuju untuk terikat oleh Kebijakan ini dan bertanggung jawab atas tindakannya.</p>'
            }
        case '<p>Dalam keadaan tertentu, Anda mungkin telah memberikan Data yang berkaitan dengan orang lain (seperti pasangan, anggota keluarga atau teman) dan dalam keadaan tersebut, Anda mewakili dan menjamin bahwa Anda diberi wewenang untuk memberikan Data mereka ke Situs dan Anda telah memperoleh persetujuan dari mereka untuk Anda mereka diproses dan digunakan sebagaimana diatur dalam Kebijakan ini.</p><br />':
            if (lg) {
                return '<p> Under certain circumstances, you may have provided data relating to other people (such as a spouse, family member or friend) and in such circumstances, you represent and guarantee that you are authorized to provide their data to the Site and you have obtained consent from them for you they are processed and used as stipulated in this Policy. </p> <br />'
            } else {
                return '<p>Dalam keadaan tertentu, Anda mungkin telah memberikan Data yang berkaitan dengan orang lain (seperti pasangan, anggota keluarga atau teman) dan dalam keadaan tersebut, Anda mewakili dan menjamin bahwa Anda diberi wewenang untuk memberikan Data mereka ke Situs dan Anda telah memperoleh persetujuan dari mereka untuk Anda mereka diproses dan digunakan sebagaimana diatur dalam Kebijakan ini.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Pengecualian</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Exceptions </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Pengecualian</p><br />'
            }
        case '<p>Kami tidak bertanggungjawab atas pemberian, pengungkapan dan pertukaran informasi yang dilakukan oleh Anda sendiri. Kami tidak bertanggungjawab atas segala sesuatu yang mungkin terjadi terhadap setiap informasi mengenai identitas diri yang terdapat pada halaman galang dana.</p><br />':
            if (lg) {
                return '<p> We are not responsible for the giving, disclosure and exchange of information made by you. We are not responsible for anything that might happen to any information about the identity contained on the fundraising page. </p> <br />'
            } else {
                return '<p>Kami tidak bertanggungjawab atas pemberian, pengungkapan dan pertukaran informasi yang dilakukan oleh Anda sendiri. Kami tidak bertanggungjawab atas segala sesuatu yang mungkin terjadi terhadap setiap informasi mengenai identitas diri yang terdapat pada halaman galang dana.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Ketentuan Hukum <i>(legal disclaimer)</i></p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Legal provisions <i> (legal disclaimer) </i> </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Ketentuan Hukum <i>(legal disclaimer)</i></p><br />'
            }
        case '<p>Kami tunduk terhadap kebijakan perlindungan Data Pengguna sebagaimana yang diatur dalam Peraturan Menteri Komunikasi dan Informatika Nomor 20 Tahun 2016 Tentang Perlindungan Data Pribadi Dalam Sistem Elektronik yang mengatur dan melindungi penggunaan Data dan informasi penting para Pengguna.</p>':
            if (lg) {
                return '<p> We abide by the User Data protection policy as regulated in Minister of Communication and Information Regulation Number 20 Year 2016 Regarding Personal Data Protection in the Electronic System which regulates and protects the use of important User Data and information. </p>'
            } else {
                return '<p>Kami tunduk terhadap kebijakan perlindungan Data Pengguna sebagaimana yang diatur dalam Peraturan Menteri Komunikasi dan Informatika Nomor 20 Tahun 2016 Tentang Perlindungan Data Pribadi Dalam Sistem Elektronik yang mengatur dan melindungi penggunaan Data dan informasi penting para Pengguna.</p>'
            }
        case '<p>Saran</p>':
            if (lg) {
                return '<p> Suggestions </p>'
            } else {
                return '<p>Saran</p>'
            }
        case '<p>Apabila terdapat pertanyaan mengenai Kebijakan ini mohon kirim email pada <a style="font-weight: bold;">support@indonesiagreen.com</a></p><br />':
            if (lg) {
                return '<p> If you have questions about this Policy please send an email at <a style="font-weight: bold;"> support@indonesiagreen.com </a> </p> <br />'
            } else {
                return '<p>Apabila terdapat pertanyaan mengenai Kebijakan ini mohon kirim email pada <a style="font-weight: bold;">support@indonesiagreen.com</a></p><br />'
            }
        // TNC
        case '<p><a style="font-weight: bold;">indonesiagreen.com</a> (“Situs”) dikelola oleh PT KREASI ENERGI TERBARUKAN bersama dengan YAYASAN KREASI ENERGI TERBARUKAN (“Kami”). ':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> indonesiagreen.com </a> ("Site") is managed by PT KREASI ENERGI TERBARUKAN together with the YAYASAN KREASI ENERGI TERBARUKAN ("We"). '
            } else {
                return '<p><a style="font-weight: bold;">indonesiagreen.com</a> (“Situs”) dikelola oleh PT KREASI ENERGI TERBARUKAN bersama dengan YAYASAN KREASI ENERGI TERBARUKAN (“Kami”). '
            }
        case 'Dengan mengunjungi dan/atau menggunakan platform indonesiagreen.com, maka baik pengunjung maupun pengguna (“Anda”) dinyatakan telah memahami dan menyepakati semua isi dalam syarat dan ketentuan di bawah ini.':
            if (lg) {
                return 'By visiting and / or using the indonesiagreen.com platform, both visitors and users (\"You\") are declared to have understood and agreed to all contents in the terms and conditions below. '
            } else {
                return 'Dengan mengunjungi dan/atau menggunakan platform indonesiagreen.com, maka baik pengunjung maupun pengguna (“Anda”) dinyatakan telah memahami dan menyepakati semua isi dalam syarat dan ketentuan di bawah ini.'
            }
        case 'Apabila Anda sebagai pengguna situs tidak menyetujui salah satu,sebagian atau seluruh isi syarat dan ketentuan ini, maka Anda tidak diperkenankan untuk menggunakan layanan di situs kami.':
            if (lg) {
                return "If you as a user of the site do not agree with any, part or all of the contents of these terms and conditions, you are not permitted to use services on our site."
            } else {
                return 'Apabila Anda sebagai pengguna situs tidak menyetujui salah satu,sebagian atau seluruh isi syarat dan ketentuan ini, maka Anda tidak diperkenankan untuk menggunakan layanan di situs kami.'
            }
        case 'Kami menyediakan layanan Penggalangan Dana dalam jaringan (online crowdfunding) kepada pengguna baik melalui web maupun aplikasi mobile (“Platform”) berdasarkan … Kepada Yayasan Kreasi Energi Terbarukan untuk berbagai bentuk Penggalangan Dana.':
            if (lg) {
                return "We provide online crowdfunding services to users both through the web and mobile applications (\"Platforms\") based on ... To the Yayasan Kreasi Energi Terbarukan for various forms of Fundraising."
            } else {
                return 'Kami menyediakan layanan Penggalangan Dana dalam jaringan (online crowdfunding) kepada pengguna baik melalui web maupun aplikasi mobile (“Platform”) berdasarkan … Kepada Yayasan Kreasi Energi Terbarukan untuk berbagai bentuk Penggalangan Dana.'
            }
        case 'Kami berhak atas kebijakan untuk mengubah atau memodifikasi sebagian atau keseluruhan dari isi syarat dan ketentuan ini setiap saat, artinya aturan yang berlaku pada halaman ini dapat berubah sewaktu-waktu secara sepihak oleh kami, apabila hal tersebut terjadi maka kami akan mencoba memberikan pemberitahuan kepada seluruh pengguna situs, bisa melalui email, sosial media kami, maupun melalui situs ini secara langsung.':
            if (lg) {
                return 'We reserve the right to change or modify some or all of the contents of these terms and conditions at any time, meaning that the rules applicable to this page may change at any time unilaterally by us, if that happens we will try to give notice to all site users, can be via email, our social media, or through this site directly. '
            } else {
                return 'Kami berhak atas kebijakan untuk mengubah atau memodifikasi sebagian atau keseluruhan dari isi syarat dan ketentuan ini setiap saat, artinya aturan yang berlaku pada halaman ini dapat berubah sewaktu-waktu secara sepihak oleh kami, apabila hal tersebut terjadi maka kami akan mencoba memberikan pemberitahuan kepada seluruh pengguna situs, bisa melalui email, sosial media kami, maupun melalui situs ini secara langsung.'
            }
        case 'Aturan yang baru akan mulai berlaku setelah pemberitahuan sudah terpublikasikan atau terkirim ke seluruh pengguna situs Indonesia Green (IGE). Kesepakatan di atas tidak berlaku apabila terdapat perubahan karena alasan hukum negara Indonesia, syarat dan ketentuan pada situs ini akan berubah menyesuaikan dengan peraturan pemerintah yang berlaku.</p>':
            if (lg) {
                return 'The new rules will come into force after the notification has been published or sent to all users of the Indonesia Green (IGE) site. The above agreement does not apply if there is a change for reasons of Indonesian law, the terms and conditions on this site will change according to applicable government regulations. </p> '
            } else {
                return 'Aturan yang baru akan mulai berlaku setelah pemberitahuan sudah terpublikasikan atau terkirim ke seluruh pengguna situs Indonesia Green (IGE). Kesepakatan di atas tidak berlaku apabila terdapat perubahan karena alasan hukum negara Indonesia, syarat dan ketentuan pada situs ini akan berubah menyesuaikan dengan peraturan pemerintah yang berlaku.</p>'
            }
        case 'Pengguna situs sangat dianjurkan untuk membaca dengan seksama segala ketentuan di bawah ini karena akan berdampak kepada hak dan kewajiban sebagai pengguna situs ini. Pertanyaan lainnya seputar Indonesia Green (IGE) dapat dibaca di halaman FAQ atau anda menanyakan langsung kepada kami di support@indonesiagreen.com.':
            if (lg) {
                return 'Users of the site are strongly encouraged to read carefully all the provisions below as this will affect the rights and obligations as a user of this site. Other questions about Indonesia Green (IGE) can be read on the FAQ page or you can ask us directly at support@indonesiagreen.com. '
            } else {
                return 'Pengguna situs sangat dianjurkan untuk membaca dengan seksama segala ketentuan di bawah ini karena akan berdampak kepada hak dan kewajiban sebagai pengguna situs ini. Pertanyaan lainnya seputar Indonesia Green (IGE) dapat dibaca di halaman FAQ atau anda menanyakan langsung kepada kami di support@indonesiagreen.com.'
            }
        case '<p style="font-size: 24px; font-weight: bold;">Ketentuan Umum</p><br />':
            if (lg) {
                return '<p style = "font-size: 24px; font-weight: bold;"> General Provisions </p> <br />'
            } else {
                return '<p style="font-size: 24px; font-weight: bold;">Ketentuan Umum</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">A. Definisi</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> A. Definition </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">A. Definisi</p><br />'
            }
        case '<p>Dalam Syarat dan Ketentuan ini yang dimaksud dengan:</p><br />':
            if (lg) {
                return '<p> In these Terms and Conditions what is meant by: </p> <br />'
            } else {
                return '<p>Dalam Syarat dan Ketentuan ini yang dimaksud dengan:</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.1 Akun</a> adalah suatu pengaturan (arrangement) antara Penyedia Platform dengan Pengunjung Platform atau Pengguna Platform berdasarkan mana Pengunjung Platfrom atau Pengguna Platform diberikan akses oleh Penyedia Platform terhadap fitur-fitur Platform setelah melakukan pendaftaran data pribadi, nama pengguna (username) dan kata sandi (password).</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.1 Account </a> is an arrangement between the Platform Provider and Platform Visitors or Platform Users based on which Platform Visitors or Platform Users are granted access by the Platform Provider platform features after registering personal data, usernames and passwords. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">1.1 Akun</a> adalah suatu pengaturan (arrangement) antara Penyedia Platform dengan Pengunjung Platform atau Pengguna Platform berdasarkan mana Pengunjung Platfrom atau Pengguna Platform diberikan akses oleh Penyedia Platform terhadap fitur-fitur Platform setelah melakukan pendaftaran data pribadi, nama pengguna (username) dan kata sandi (password).</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.2 BANI</a> adalah singkatan dari Badan Arbitrase Nasional Indonesia, suatu lembaga arbitrase yang berbentuk perkumpulan berbadan hukum</p>':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.2 BANI </a> is an abbreviation of the Indonesian National Arbitration Board, an arbitration institution in the form of a legal body association </p>'
            } else {
                return '<p><a style="font-weight: bold;">1.2 BANI</a> adalah singkatan dari Badan Arbitrase Nasional Indonesia, suatu lembaga arbitrase yang berbentuk perkumpulan berbadan hukum</p>'
            }
        case '<p>melalui akta No.23 tanggal 14 Juni 2016, yang dibuat di hadapan Ny.Hj.Devi Kantini Rolaswati, SH, M.Kn, Notari di Jakarta,yang telah mendapat persetujuan dari Menteri Kehakiman dan Hak Asasi Manusia Republik Indonesia dengan surat keputusan No.AHU-0064837.AH.01.07.Tahun 2016, tanggal 20 Juni 2016, berikut perubahannya.</p><br />':
            if (lg) {
                return '<p> through deed No.23 dated June 14, 2016, which was made before Mrs. Hj.Devi Kantini Rolaswati, SH, M.Kn, Notari in Jakarta, which has received approval from the Minister of Justice and Human Rights of the Republic of Indonesia with Decree No.AHU-0064837.AH.01.07.Tahun 2016, dated June 20, 2016, the following changes. </p> <br /> '
            } else {
                return '<p>melalui akta No.23 tanggal 14 Juni 2016, yang dibuat di hadapan Ny.Hj.Devi Kantini Rolaswati, SH, M.Kn, Notari di Jakarta,yang telah mendapat persetujuan dari Menteri Kehakiman dan Hak Asasi Manusia Republik Indonesia dengan surat keputusan No.AHU-0064837.AH.01.07.Tahun 2016, tanggal 20 Juni 2016, berikut perubahannya.</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.3 Beneficiary</a> adalah:</p>':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.3 Beneficiary </a> is: </p>'
            } else {
                return '<p><a style="font-weight: bold;">1.3 Beneficiary</a> adalah:</p>'
            }
        case '<p>i.  Pihak yang merangkap sebagai Project Owner dan terdaftar dalam Platform; atau</p>':
            if (lg) {
                return '<p> i. The party who is also a Project Owner and registered with the Platform; or </p> '
            } else {
                return '<p>i.  Pihak yang merangkap sebagai Project Owner dan terdaftar dalam Platform; atau</p>'
            }
        case '<p>ii. Pihak lain selain Project Owner yang adalah individu, kelompok, badan usaha atau badan hukum, yang menerima manfaat (beneficiary) atas Dana dari suatu Project.</p><br />':
            if (lg) {
                return '<p> ii. Other parties besides the Project Owner who are individuals, groups, business entities or legal entities, who receive benefits (beneficiary) for Funds from a Project. </p> <br /> '
            } else {
                return '<p>ii. Pihak lain selain Project Owner yang adalah individu, kelompok, badan usaha atau badan hukum, yang menerima manfaat (beneficiary) atas Dana dari suatu Project.</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.4 Dana</a> adalah sumbangan atau donasi (donation) yang dinyatakan dalam mata uang Rupiah.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.4 Funds </a> are donations or donations which are stated in Rupiah. </p> <br />'
            } else {
                return '<p><a style="font-weight: bold;">1.4 Dana</a> adalah sumbangan atau donasi (donation) yang dinyatakan dalam mata uang Rupiah.</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.5 Project</a> adalah suatu usaha Penggalangan Dana untuk maksud dan tujuan tertentu, termasuk, namun tidak terbatas pada, kategori bantuan energi terbarukan yang berdampak kepada kebutuhan masyarakat, baik dengan maupun tanpa janjian imbalan (reward).</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.5 Project </a> is a Fundraising effort for specific purposes and objectives, including, but not limited to, renewable energy assistance categories that impact on community needs , both with and without a reward agreement. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">1.5 Project</a> adalah suatu usaha Penggalangan Dana untuk maksud dan tujuan tertentu, termasuk, namun tidak terbatas pada, kategori bantuan energi terbarukan yang berdampak kepada kebutuhan masyarakat, baik dengan maupun tanpa janjian imbalan (reward).</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.6 Random Project</a> adalah Project yang dipilih secara acak (random Project) berdasarkan diskresi penuh Penyedia Platform untuk menerima hasil Penggalangan Dana suatu Project lainnya dalam keadaan-keadaan tertentu.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.6 Random Project </a> is a randomly selected Project based on the full discretion of the Platform Provider to receive the results of Fundraising from another Project in circumstances- certain circumstances. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">1.6 Random Project</a> adalah Project yang dipilih secara acak (random Project) berdasarkan diskresi penuh Penyedia Platform untuk menerima hasil Penggalangan Dana suatu Project lainnya dalam keadaan-keadaan tertentu.</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.7 Project Owner</a> adalah individu, kelompok, badan usaha atau badan hukum yang menggunakan fasilitas Penggalangan Dana pada Platform untuk sebuah Project tertentu dan bertanggung jawab atas Pelaksanaan Project yang bersangkutan.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.7 Project Owner </a> is an individual, group, business entity or legal entity that uses the Fundraising facility on the Platform for a particular Project and is responsible for Implementation Project in question. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">1.7 Project Owner</a> adalah individu, kelompok, badan usaha atau badan hukum yang menggunakan fasilitas Penggalangan Dana pada Platform untuk sebuah Project tertentu dan bertanggung jawab atas Pelaksanaan Project yang bersangkutan.</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.8 Donatur</a> adalah individu, kelompok, badan usaha maupun badan hukum yang melakukan pendaftaran ke Platform untuk mendukung Project dengan menyalurkan Dana.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.8 Donors </a> are individuals, groups, business entities or legal entities that register to the Platform to support the Project by channeling Funds. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">1.8 Donatur</a> adalah individu, kelompok, badan usaha maupun badan hukum yang melakukan pendaftaran ke Platform untuk mendukung Project dengan menyalurkan Dana.</p><br />'
            }
        case '<p><a style="font-weight: bold;">1.9 Fundraiser</a> adalah seseorang atau organisasi yang menyatakan dukungan terhadap suatu Project yang dibuat oleh Project Owner dalam bentuk pembuatan halaman Project baru yang terhubung dengan Project utama. Dana yang masuk dari halaman fundraiser akan masuk di Project utama.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 1.9 Fundraiser </a> is a person or organization that expresses support for a Project created by Project Owner in the form of creating a new Project page that is linked to the main Project. Funds coming in from the fundraiser page will go to the main Project. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">1.9 Fundraiser</a> adalah seseorang atau organisasi yang menyatakan dukungan terhadap suatu Project yang dibuat oleh Project Owner dalam bentuk pembuatan halaman Project baru yang terhubung dengan Project utama. Dana yang masuk dari halaman fundraiser akan masuk di Project utama.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.0 Konten</a> adalah segala jenis materi dan/atau muatan yang dibuat dan/atau diunggah ke dalam Platform secara mandiri oleh Pengguna Platform (user generated content) dan bukan oleh Penyedia Platform.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.0 Content </a> is any type of material and / or content that is created and / or uploaded to the Platform independently by Platform Users (user generated content) and not by the Platform Provider. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">2.0 Konten</a> adalah segala jenis materi dan/atau muatan yang dibuat dan/atau diunggah ke dalam Platform secara mandiri oleh Pengguna Platform (user generated content) dan bukan oleh Penyedia Platform.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.1 Konten Yang Dilarang</a> adalah segala jenis materi dan/atau muatan yang melanggar ketentuan peraturan perundang-undangan.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.1 Prohibited Content </a> is any type of material and / or content that violates the provisions of the legislation. </p> <br />'
            } else {
                return '<p><a style="font-weight: bold;">2.1 Konten Yang Dilarang</a> adalah segala jenis materi dan/atau muatan yang melanggar ketentuan peraturan perundang-undangan.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.2 Laporan</a> adalah pemberitahuan yang disampaikan oleh Pengunjung Platform dan/atau Pengguna Platform kepada Penyedia Platform tentang telah atau sedang atau diduga akan terjadinya peristiwa pelanggaran Syarat dan Ketentuan.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.2 Report </a> is a notification sent by Platform Visitors and / or Platform Users to the Platform Provider of whether or has been or is suspected of a violation of the Terms and Conditions. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">2.2 Laporan</a> adalah pemberitahuan yang disampaikan oleh Pengunjung Platform dan/atau Pengguna Platform kepada Penyedia Platform tentang telah atau sedang atau diduga akan terjadinya peristiwa pelanggaran Syarat dan Ketentuan.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.3 Mitra Pencairan Dana</a> adalah bank yang bekerjasama dengan Penyedia Platform dalam rangka memfasilitasi Pencairan Dana.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.3 Disbursement Partners </a> are banks that collaborate with Platform Providers in order to facilitate Funds Disbursement. </p> <br />'
            } else {
                return '<p><a style="font-weight: bold;">2.3 Mitra Pencairan Dana</a> adalah bank yang bekerjasama dengan Penyedia Platform dalam rangka memfasilitasi Pencairan Dana.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.4 Pelaksanaan Project</a> adalah tahap perwujudan atau realisasi dari maksud dan tujuan sebuah Project dengan menggunakan hasil Penggalangan Dana yang bersangkutan secara bertanggung jawab.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.4 Project Implementation </a> is the stage of the realization or realization of the aims and objectives of a Project by using the results of the Fundraising concerned responsibly. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">2.4 Pelaksanaan Project</a> adalah tahap perwujudan atau realisasi dari maksud dan tujuan sebuah Project dengan menggunakan hasil Penggalangan Dana yang bersangkutan secara bertanggung jawab.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.5 Pencairan Dana</a> adalah tindakan Penyedia Platform mengalihkan (transfer) Dana yang terkumpul dalam:</p>':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.5 Funds Disbursement </a> is an act of a Platform Provider transferring funds collected in: </p>'
            } else {
                return '<p><a style="font-weight: bold;">2.5 Pencairan Dana</a> adalah tindakan Penyedia Platform mengalihkan (transfer) Dana yang terkumpul dalam:</p>'
            }
        case '<p>i. Rekening atas nama Donatur, kepada rekening asal Donatur; atau</p>':
            if (lg) {
                return '<p> i. Account in the name of the Donors, to the account from the Donors; or </p> '
            } else {
                return '<p>i. Rekening atas nama Donatur, kepada rekening asal Donatur; atau</p>'
            }
        case '<p>ii. Rekening atas nama Project Owner atau Penerima Manfaat, ke rekening tujuan yang telah ditunjuk Project Owner atau Penerima Manfaat pada saat pendaftaran yang dilakukan atas permohonan (request) Donatur, Project Owner atau Penerima Manfaat.</p><br />':
            if (lg) {
                return '<p> ii. Account in the name of the Project Owner or Beneficiary, to the destination account designated by the Project Owner or Beneficiary at the time of registration that is made at the request of the Donors, Project Owner or Beneficiary. </p> <br /> '
            } else {
                return '<p>ii. Rekening atas nama Project Owner atau Penerima Manfaat, ke rekening tujuan yang telah ditunjuk Project Owner atau Penerima Manfaat pada saat pendaftaran yang dilakukan atas permohonan (request) Donatur, Project Owner atau Penerima Manfaat.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.6 Penggalangan Dana</a> adalah proses pengumpulan Dana dari masyarakat dalam rangka pembangunan proyek di bidang energi terbarukan berlandaskan sosial.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.6 Fundraising </a> is the process of collecting funds from the community in the context of project development in the field of renewable energy on a social basis. </p> <br />'
            } else {
                return '<p><a style="font-weight: bold;">2.6 Penggalangan Dana</a> adalah proses pengumpulan Dana dari masyarakat dalam rangka pembangunan proyek di bidang energi terbarukan berlandaskan sosial.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.7 Pengguna Platform</a> terdiri dari Project Owner, Project Owner dan/atau Beneficiary, baik secara langsung maupun tidak langsung, sebagaimana berlaku.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.7 Platform Users </a> consist of Project Owner, Project Owner and / or Beneficiary, both directly and indirectly, as applicable. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">2.7 Pengguna Platform</a> terdiri dari Project Owner, Project Owner dan/atau Beneficiary, baik secara langsung maupun tidak langsung, sebagaimana berlaku.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.8 Pengunjung Platform</a> adalah pihak yang mengakses, membuka, dan memperoleh informasi dari Platform.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.8 Platform Visitors </a> are those who access, open and obtain information from the Platform. </p> <br />'
            } else {
                return '<p><a style="font-weight: bold;">2.8 Pengunjung Platform</a> adalah pihak yang mengakses, membuka, dan memperoleh informasi dari Platform.</p><br />'
            }
        case '<p><a style="font-weight: bold;">2.9 Platform</a> adalah wadah berupa aplikasi, situs internet dan/atau layanan lainnya berbasis internet yang digunakan untuk transaksi dan/atau fasilitasi Penggalangan Dana melalui sistem elektronik yang dikelola dan disediakan oleh PT KREASI ENERGI TERBARUKAN dan/atau YAYASAN KREASI ENERGI TERBARUKAN, termasuk, namun tidak terbatas pada situs indonesiagreen.com.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 2.9 Platform </a> is a container in the form of applications, internet sites and / or other internet-based services used for transactions and / or facilitating Fundraising through an electronic system which is managed and provided by PT KREASI ENERGI TERBARUKAN and / or YAYASAN KREASI ENERGI TERBARUKAN, including, but not limited to the site indonesiagreen.com. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">2.9 Platform</a> adalah wadah berupa aplikasi, situs internet dan/atau layanan lainnya berbasis internet yang digunakan untuk transaksi dan/atau fasilitasi Penggalangan Dana melalui sistem elektronik yang dikelola dan disediakan oleh PT KREASI ENERGI TERBARUKAN dan/atau YAYASAN KREASI ENERGI TERBARUKAN, termasuk, namun tidak terbatas pada situs indonesiagreen.com.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.0 Pengaduan</a> adalah Laporan yang disertai permintaan kepada Penyedia Platform untuk memeriksa Pengguna Platform yang telah atau sedang atau diduga melakukan pelanggaran Syarat dan Ketentuan.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.0 Complaint </a> is a Report that is accompanied by a request to the Platform Provider to examine Platform Users who have or are in or suspected of violating the Terms and Conditions. </ p > <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.0 Pengaduan</a> adalah Laporan yang disertai permintaan kepada Penyedia Platform untuk memeriksa Pengguna Platform yang telah atau sedang atau diduga melakukan pelanggaran Syarat dan Ketentuan.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.1 Penyedia Platform</a> adalah PT KREASI ENERGI TERBARUKAN dan YAYASAN KREASI ENERGI TERBARUKAN yang menyelenggarakan program Penggalangan Dana dalam jaringan (online crowdfunding) berdasarkan … tentang … Kepada Yayasan Kreasi Energi Terbarukan Di Jakarta Selatan tanggal ….</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.1 Platform Provider </a> is PT KREASI ENERGI TERBARUKAN and YAYASAN KREASI ENERGI TERBARUKAN which organizes online crowdfunding programs based on ... about ... To Yayasan Kreasi Energi Terbarukan in South Jakarta dated ... </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.1 Penyedia Platform</a> adalah PT KREASI ENERGI TERBARUKAN dan YAYASAN KREASI ENERGI TERBARUKAN yang menyelenggarakan program Penggalangan Dana dalam jaringan (online crowdfunding) berdasarkan … tentang … Kepada Yayasan Kreasi Energi Terbarukan Di Jakarta Selatan tanggal ….</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.2 Syarat dan Ketentuan</a> adalah syarat dan ketentuan penggunaan Platform yang ditetapkan dan dapat diubah sewaktu-waktu oleh Penyedia Platform serta mengikat bagi Pengunjung Platform dan/atau Pengguna Platform.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.2 Terms and Conditions </a> are the terms and conditions for using the Platform that are set and can be changed at any time by the Platform Provider and are binding for Platform Visitors and / or Platform Users. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.2 Syarat dan Ketentuan</a> adalah syarat dan ketentuan penggunaan Platform yang ditetapkan dan dapat diubah sewaktu-waktu oleh Penyedia Platform serta mengikat bagi Pengunjung Platform dan/atau Pengguna Platform.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.3 Update</a> adalah fitur yang terdapat pada halaman Project yang difungsikan untuk setiap Project Owner agar dapat memberikan pemberitahuan kepada seluruh donatur melalui email secara otomatis mengenai keadaan terbaru Project, penggunaan dana maupun hal lainnya.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.3 Update </a> is a feature found on the Project page that is enabled for every Project Owner to automatically notify all donors by email about the latest situation Projects, use of funds or other things. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.3 Update</a> adalah fitur yang terdapat pada halaman Project yang difungsikan untuk setiap Project Owner agar dapat memberikan pemberitahuan kepada seluruh donatur melalui email secara otomatis mengenai keadaan terbaru Project, penggunaan dana maupun hal lainnya.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.4 Verifikasi</a> adalah tindakan pemeriksaan kebenaran dan kelengkapan yang dilakukan oleh Penyedia Platform terhadap Akun, Konten dan/atau Pencairan Dana yang didaftarkan, diunggah dan/atau dimohonkan oleh Pengguna Platform, atau untuk keperluan lainnya berdasarkan diskresi penuh Penyedia Platform.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.4 Verification </a> is an act of verifying truth and completeness carried out by the Platform Provider on the Account, Content and / or Funds Disbursement that is registered, uploaded and / or requested by Platform Users, or for other purposes based on the full discretion of the Platform Provider. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.4 Verifikasi</a> adalah tindakan pemeriksaan kebenaran dan kelengkapan yang dilakukan oleh Penyedia Platform terhadap Akun, Konten dan/atau Pencairan Dana yang didaftarkan, diunggah dan/atau dimohonkan oleh Pengguna Platform, atau untuk keperluan lainnya berdasarkan diskresi penuh Penyedia Platform.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.5 Mitra Konsultan</a> adalah pihak ketiga yang mendampingi konsep maupun perencanaan dari proyek.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.5 Consultant Partners </a> are third parties who accompany the concepts and planning of the project. </p> <br />'
            } else {
                return '<p><a style="font-weight: bold;">3.5 Mitra Konsultan</a> adalah pihak ketiga yang mendampingi konsep maupun perencanaan dari proyek.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.6 Kontraktor</a> adalah pihak ketiga yang merealisasikan proyek yang diusulkan dan bertanggungjawab secara teknis, sumber daya, dan konsep.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.6 Contractor </a> is a third party who is implementing the proposed project and is responsible for the technical, resources, and concept. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.6 Kontraktor</a> adalah pihak ketiga yang merealisasikan proyek yang diusulkan dan bertanggungjawab secara teknis, sumber daya, dan konsep.</p><br />'
            }
        case '<p><a style="font-weight: bold;">3.7 Project Management Team (PMT)</a> adalah kelompok pendamping proyek yang ditunjuk oleh IGE untuk melakukan verifikasi, analisa, investigasi dan evaluasi terhadap proyek-proyek yang sedang dijalankan oleh project owner.</p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> 3.7 Project Management Team (PMT) </a> is a project accompanying group appointed by IGE to conduct verification, analysis, investigation and evaluation of projects that are is being run by the project owner. </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">3.7 Project Management Team (PMT)</a> adalah kelompok pendamping proyek yang ditunjuk oleh IGE untuk melakukan verifikasi, analisa, investigasi dan evaluasi terhadap proyek-proyek yang sedang dijalankan oleh project owner.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">B. Biaya Operasional dan Biaya Tambahan</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> B. Operational Costs and Additional Costs </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">B. Biaya Operasional dan Biaya Tambahan</p><br />'
            }
        case '<p>1.	Dana yang diperoleh dari Project melalui Platform akan dikenakan biaya administrasi:</p>':
            if (lg) {
                return '<p> 1. Funds obtained from the Project through the Platform will incur administrative costs: </p> '
            } else {
                return '<p>1.	Dana yang diperoleh dari Project melalui Platform akan dikenakan biaya administrasi:</p>'
            }
        case '<p>i. Oleh Penyedia Platform sebesar 5% (lima persen); </p>':
            if (lg) {
                return '<p> i. By the Platform Provider of 10% (ten percent); </p> '
            } else {
                return '<p>i. Oleh Penyedia Platform sebesar 10% (sepuluh persen); </p>'
            }
        case '<p>ii. Oleh Mitra Pencairan Dana, yang besarannya masing-masing dapat dilihat dengan klik di sini; </p>':
            if (lg) {
                return '<p> ii. By the Fund Disbursement Partner, the amount of which can be viewed by clicking here; </p> '
            } else {
                return '<p>ii. Oleh Mitra Pencairan Dana, yang besarannya masing-masing dapat dilihat dengan klik di sini; </p>'
            }
        case '<p>iii. Oleh Mitra Konsultan atau Kontraktor proyek;</p>':
            if (lg) {
                return '<p> iii. By a project consultant or contractor partner; </p> '
            } else {
                return '<p>iii. Oleh Mitra Konsultan atau Kontraktor proyek;</p>'
            }
        case '<p>2.	Dalam hal terdapat pajak, retribusi dan/atau pungutan lainnya yang dikenakan oleh peraturan perundang-undangan yang berlaku terhadap Dana yang diperoleh dari Project, maka Penyedia Platform akan menetapkan biaya tambahan sesuai dengan besaran sebagaimana ditentukan peraturan perundang-undangan yang bersangkutan. </p><br />':
            if (lg) {
                return '<p> 2. In the event that there are taxes, fees and / or other levies imposed by the laws and regulations applicable to the Funds obtained from the Project, the Platform Provider will set additional costs in accordance with the amount as determined by the relevant laws and regulations. </p> <br /> '
            } else {
                return '<p>2.	Dalam hal terdapat pajak, retribusi dan/atau pungutan lainnya yang dikenakan oleh peraturan perundang-undangan yang berlaku terhadap Dana yang diperoleh dari Project, maka Penyedia Platform akan menetapkan biaya tambahan sesuai dengan besaran sebagaimana ditentukan peraturan perundang-undangan yang bersangkutan. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">C. Pengungkapan Secara Sukarela</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> C. Voluntary Disclosure </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">C. Pengungkapan Secara Sukarela</p><br />'
            }
        case '<p>Segala pengungkapan (disclosure) oleh Pengunjung Platform atau  Pengguna Platform dalam bentuk pertanyaan, pernyataan, komentar,ide, kritik, saran atau informasi pada Platform, yang bukan merupakan pengungkapan yang disyaratkan atau diminta oleh Penyedia Platform, adalah pengungkapan yang dibuat secara sukarela (voluntary disclosure) dan sepenuhnya merupakan tanggung jawab dari Pengunjung Platform atau Pengguna Platform yang bersangkutan.</p><br />':
            if (lg) {
                return '<p> All disclosures by Platform Visitors or Platform Users in the form of questions, statements, comments, ideas, criticisms, suggestions or information on the Platform, which are not disclosures required or requested by the Platform Provider, are disclosures made in voluntary (voluntary disclosure) and is entirely the responsibility of the Platform Visitor or Platform User in question. </p> <br /> '
            } else {
                return '<p>Segala pengungkapan (disclosure) oleh Pengunjung Platform atau  Pengguna Platform dalam bentuk pertanyaan, pernyataan, komentar,ide, kritik, saran atau informasi pada Platform, yang bukan merupakan pengungkapan yang disyaratkan atau diminta oleh Penyedia Platform, adalah pengungkapan yang dibuat secara sukarela (voluntary disclosure) dan sepenuhnya merupakan tanggung jawab dari Pengunjung Platform atau Pengguna Platform yang bersangkutan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">D. Posisi Indonesia Green (IGE)</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> D. Indonesia Green (IGE) Position </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">D. Posisi Indonesia Green (IGE)</p><br />'
            }
        case '<p>indonesiagreen.com Bukanlah Broker/Lembaga Penyalur Amal/Lembaga Keuangan/Kreditor/Investasi. </p>':
            if (lg) {
                return '<p>indonesiagreen.com is not a broker / charity channeling institution / financial institution / creditor / investment. </p> '
            } else {
                return '<p>indonesiagreen.com Bukanlah Broker/Lembaga Penyalur Amal/Lembaga Keuangan/Kreditor/Investasi. </p>'
            }
        case '<p>indonesiagreen.com merupakan platform untuk memfasilitasi transaksi donasi antara Project Owner dan donatur. Indonesia Green (IGE) tidak bertanggung jawab atas pelaksanaan, atau informasi yang disediakan oleh Project Owner, donatur, beneficiary atau pengguna lainnya. Indonesia Green (IGE) dengan ini melepaskan semua tanggung jawab dalam hal tersebut selama diizinkan oleh hukum yang berlaku di Indonesia.</p>':
            if (lg) {
                return '<p>indonesiagreen.com is a platform to facilitate donation transactions between Project Owners and donors. Indonesia Green (IGE) is not responsible for the implementation, or information provided by Project Owners, donors, beneficiaries or other users. Indonesia Green (IGE) hereby waives all responsibility in this matter to the extent permitted by applicable law in Indonesia. </p> '
            } else {
                return '<p>indonesiagreen.com merupakan platform untuk memfasilitasi transaksi donasi antara Project Owner dan donatur. Indonesia Green (IGE) tidak bertanggung jawab atas pelaksanaan, atau informasi yang disediakan oleh Project Owner, donatur, beneficiary atau pengguna lainnya. Indonesia Green (IGE) dengan ini melepaskan semua tanggung jawab dalam hal tersebut selama diizinkan oleh hukum yang berlaku di Indonesia.</p>'
            }
        case '<p>Semua informasi di dalam konten Project yang disediakan oleh Indonesia Green (IGE) merupakan bagian dari pemberitahuan, kami tidak menjamin keakuratan, kelengkapan, ketepatan waktu atau kebenaran dari konten yang dibuat oleh Project Owner.</p>':
            if (lg) {
                return '<p> All information in the Project content provided by Indonesia Green (IGE) is part of the notification, we do not guarantee the accuracy, completeness, timeliness or correctness of the content created by the Project Owner. </p>'
            } else {
                return '<p>Semua informasi di dalam konten Project yang disediakan oleh Indonesia Green (IGE) merupakan bagian dari pemberitahuan, kami tidak menjamin keakuratan, kelengkapan, ketepatan waktu atau kebenaran dari konten yang dibuat oleh Project Owner.</p>'
            }
        case '<p>Maka, Anda mengetahui bahwa informasi dan konten yang ada pada halaman Project dalam situs kami merupakan risiko Anda sendiri.</p>':
            if (lg) {
                return '<p> Then, you acknowledge that the information and content contained on the Project pages on our site are at your own risk. </p>'
            } else {
                return '<p>Maka, Anda mengetahui bahwa informasi dan konten yang ada pada halaman Project dalam situs kami merupakan risiko Anda sendiri.</p>'
            }
        case '<p>Indonesia Green (IGE) tidak menjamin bahwa setiap Project yang terdapat pada situs kami akan mendapatkan sejumlah donasi tertentu atau akan terpenuhi. Indonesia Green (IGE) secara tersirat maupun tersurat tidak selalu mendukung penyelenggaraan sebuah Project, kecuali terdapat perjanjian tertulis terlebih dahulu. Kami dengan tegas menolak kewajiban atau tanggung jawab atas kegagalan setiap Project atau total donasi yang Project Owner tetapkan tidak terpenuhi.</p><br />':
            if (lg) {
                return '<p> Indonesia Green (IGE) does not guarantee that every Project contained on our site will get a certain number of donations or will be fulfilled. Indonesia Green (IGE) implicitly or explicitly does not always support the implementation of a Project, unless there is a written agreement in advance. We expressly reject the obligation or responsibility for the failure of each Project or the total donations that the Project Owner determines are not fulfilled. </p> <br /> '
            } else {
                return '<p>Indonesia Green (IGE) tidak menjamin bahwa setiap Project yang terdapat pada situs kami akan mendapatkan sejumlah donasi tertentu atau akan terpenuhi. Indonesia Green (IGE) secara tersirat maupun tersurat tidak selalu mendukung penyelenggaraan sebuah Project, kecuali terdapat perjanjian tertulis terlebih dahulu. Kami dengan tegas menolak kewajiban atau tanggung jawab atas kegagalan setiap Project atau total donasi yang Project Owner tetapkan tidak terpenuhi.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">E. Laporan dan Pengaduan</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> E. Reports and Complaints </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">E. Laporan dan Pengaduan</p><br />'
            }
        case '<p>1.1 Pengunjung Platform dan Pengguna Platform berhak untuk mengajukan Laporan dan/atau Pengaduan kepada Penyedia Platform mengenai dugaan dan/atau peristiwa pelanggaran Syarat dan Ketentuan ini, termasuk, namun tidak terbatas pada:</p><br />':
            if (lg) {
                return '<p> 1.1 Platform Visitors and Platform Users have the right to submit Reports and / or Complaints to Platform Providers regarding alleged and / or incidents of violation of these Terms and Conditions, including, but not limited to: </p> <br />'
            } else {
                return '<p>1.1 Pengunjung Platform dan Pengguna Platform berhak untuk mengajukan Laporan dan/atau Pengaduan kepada Penyedia Platform mengenai dugaan dan/atau peristiwa pelanggaran Syarat dan Ketentuan ini, termasuk, namun tidak terbatas pada:</p><br />'
            }
        case '<p>i. Pengunjung Platform atau Pengguna Platform yang memasukkan data dan informasi yang tidak lengkap, tidak benar, menyesatkan dan/atau merupakan pemalsuan;</p>':
            if (lg) {
                return '<p> i. Platform Visitors or Platform Users who enter data and information that is incomplete, incorrect, misleading and / or constitute falsification; </p> '
            } else {
                return '<p>i. Pengunjung Platform atau Pengguna Platform yang memasukkan data dan informasi yang tidak lengkap, tidak benar, menyesatkan dan/atau merupakan pemalsuan;</p>'
            }
        case '<p>ii. Pengunjung Platform atau Pengguna Platform yang memasukkan dan mengunggah Konten Yang Dilarang;</p>':
            if (lg) {
                return '<p> ii. Platform Visitors or Platform Users who enter and upload Prohibited Content; </p> '
            } else {
                return '<p>ii. Pengunjung Platform atau Pengguna Platform yang memasukkan dan mengunggah Konten Yang Dilarang;</p>'
            }
        case '<p>iii. Project Owner yang menyalahgunakan Dana yang berasal dari Project; dan/atau</p>':
            if (lg) {
                return '<p> iii. Project Owners who misuse Funds originating from Projects; and / or </p> '
            } else {
                return '<p>iii. Project Owner yang menyalahgunakan Dana yang berasal dari Project; dan/atau</p>'
            }
        case '<p>iv. Project Owner yang tidak memenuhi atau hanya memenuhi sebagian dari Pelaksanaan Project, atau memenuhi Pelaksanaan Project tetapi tidak sesuai dengan yang dijanjikan oleh Project Owner melalui Platform.</p><br />':
            if (lg) {
                return '<p> iv. Project Owners who do not meet or only fulfill part of Project Implementation, or fulfill Project Implementation but are not in accordance with what was promised by the Project Owner through the Platform. </p> <br /> '
            } else {
                return '<p>iv. Project Owner yang tidak memenuhi atau hanya memenuhi sebagian dari Pelaksanaan Project, atau memenuhi Pelaksanaan Project tetapi tidak sesuai dengan yang dijanjikan oleh Project Owner melalui Platform.</p><br />'
            }
        case '<p>1.2  Konten Yang Dilarang sebagaimana disebut dalam huruf a butir ii di atas termasuk, namun tidak terbatas pada:</p>':
            if (lg) {
                return '<p> 1.2 Prohibited Content as referred to in letter a point ii above includes, but is not limited to: </p>'
            } else {
                return '<p>1.2  Konten Yang Dilarang sebagaimana disebut dalam huruf a butir ii di atas termasuk, namun tidak terbatas pada:</p>'
            }
        case '<p>i.   Konten negatif (pornografi, perjudian, kekerasan);</p>':
            if (lg) {
                return '<p> i. Negative content (pornography, gambling, violence); </p> '
            } else {
                return '<p>i.   Konten negatif (pornografi, perjudian, kekerasan);</p>'
            }
        case '<p>ii.  Konten dengan materi kebencian;</p>':
            if (lg) {
                return '<p> ii. Hateful content; </p> '
            } else {
                return '<p>ii.  Konten dengan materi kebencian;</p>'
            }
        case '<p>iii. Konten gambar grafis yang menimbulkan kengerian;</p>':
            if (lg) {
                return '<p> iii. Graphic content content that causes horror; </p> '
            } else {
                return '<p>iii. Konten gambar grafis yang menimbulkan kengerian;</p>'
            }
        case '<p>iv. Konten yang melanggar hak kekayaan intelektual;</p>':
            if (lg) {
                return '<p> iv. Content that violates intellectual property rights; </p> '
            } else {
                return '<p>iv. Konten yang melanggar hak kekayaan intelektual;</p>'
            }
        case '<p>v.  Konten jasa peretasan (hacking dan/atau cracking) dan/atau menyediakan akses tanpa hak atau melawan hukum atas sistem elektronik</p>':
            if (lg) {
                return '<p> v. Content of hacking and / or cracking services and / or providing unauthorized or unlawful access to electronic systems </p> '
            } else {
                return '<p>v.  Konten jasa peretasan (hacking dan/atau cracking) dan/atau menyediakan akses tanpa hak atau melawan hukum atas sistem elektronik</p>'
            }
        case '<p>vi. Konten penyediaan dan/atau akses terhadap narkoba, zat adiktif, dan psikotropika</p>':
            if (lg) {
                return '<p> vi. Content provision and / or access to drugs, addictive substances, and psychotropic drugs </p> '
            } else {
                return '<p>vi. Konten penyediaan dan/atau akses terhadap narkoba, zat adiktif, dan psikotropika</p>'
            }
        case '<p>vii. Konten dengan materi ketidakjujuran, kecurangan atau menyesatkan;</p>':
            if (lg) {
                return '<p> vii. Content with dishonesty, cheating or misleading material; </p> '
            } else {
                return '<p>vii. Konten dengan materi ketidakjujuran, kecurangan atau menyesatkan;</p>'
            }
        case '<p>viii.Konten yang mendukung perdagangan manusia (human trafficking); dan/atau</p>':
            if (lg) {
                return '<p> viii.Content that supports human trafficking; and / or </p> '
            } else {
                return '<p>viii.Konten yang mendukung perdagangan manusia (human trafficking); dan/atau</p>'
            }
        case '<p>ix. Konten penyediaan dan/atau akses terhadap rokok</p>':
            if (lg) {
                return '<p> ix. Content provision and / or access to cigarettes </p> '
            } else {
                return '<p>ix. Konten penyediaan dan/atau akses terhadap rokok</p>'
            }
        case '<p>x.  Konten dengan muatan Politik Praktis, yaitu konten pada setiap Project yang mengandung muatan ajakan untuk memilih individu atau partai politik tertentu, baik yang dilakukan oleh individu maupun badan hukum lainnya. </p><br />':
            if (lg) {
                return '<p> x. Content with Practical Political content, i.e. the content in each Project that contains the content of an invitation to elect certain individuals or political parties, whether conducted by individuals or other legal entities. </p> <br /> '
            } else {
                return '<p>x.  Konten dengan muatan Politik Praktis, yaitu konten pada setiap Project yang mengandung muatan ajakan untuk memilih individu atau partai politik tertentu, baik yang dilakukan oleh individu maupun badan hukum lainnya. </p><br />'
            }
        case '<p>1.3 Laporan dan Pengaduan diajukan oleh Pengunjung Platform atau Pengguna Platform kepada Penyedia Platform dengan cara mengisi</p>':
            if (lg) {
                return '<p> 1.3 Reports and Complaints submitted by Platform Visitors or Platform Users to Platform Providers by filling in </p>'
            } else {
                return '<p>1.3 Laporan dan Pengaduan diajukan oleh Pengunjung Platform atau Pengguna Platform kepada Penyedia Platform dengan cara mengisi</p>'
            }
        case '<p>formulir laporan pada halaman Project dari Project Owner yang bersangkutan.</p><br />':
            if (lg) {
                return '<p> report form on the Project page of the relevant Project Owner. </p> <br />'
            } else {
                return '<p>formulir laporan pada halaman Project dari Project Owner yang bersangkutan.</p><br />'
            }
        case '<p>1.4 Dengan mengajukan Laporan dan Pengaduan, Pengunjung Platform atau Pengguna Platform menyatakan sepakat dan bersedia untuk dipanggil sebagai saksi untuk dimintakan keterangannya dalam rangka pemeriksaan, termasuk, namun tidak terbatas pada, menghadap ke Penyedia Platform, instansi terkait, aparat penegak hukum, dan/atau pengadilan.</p><br />':
            if (lg) {
                return '<p> 1.4 By submitting a Report and Complaint, Platform Visitors or Platform Users express their agreement and are willing to be called as witnesses to be asked for their testimony in the context of the examination, including, but not limited to, facing the Platform Provider, relevant agencies, law enforcement officers, and / or court. </p> <br /> '
            } else {
                return '<p>1.4 Dengan mengajukan Laporan dan Pengaduan, Pengunjung Platform atau Pengguna Platform menyatakan sepakat dan bersedia untuk dipanggil sebagai saksi untuk dimintakan keterangannya dalam rangka pemeriksaan, termasuk, namun tidak terbatas pada, menghadap ke Penyedia Platform, instansi terkait, aparat penegak hukum, dan/atau pengadilan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">F. Penonaktifan, Pemutusan dan Penghapusan Akun</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> F. Account Disabling, Termination and Deletion </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">F. Penonaktifan, Pemutusan dan Penghapusan Akun</p><br />'
            }
        case '<p>1.1 Pengunjung Platform atau Pengguna Platform berhak untuk mengajukan permohonan penonaktifan dan/atau pemutusan dan penghapusan Akun yang terdaftar atas namanya pada Platform kepada Penyedia Platform dengan mengirim email ke <a style="font-weight: bold;">support@indonesiagreen.com</a> disertai alasan-alasannya.</p><br />':
            if (lg) {
                return '<p> 1.1 Platform Visitors or Platform Users have the right to submit requests for deactivation and / or termination and deletion of accounts registered in their name on the Platform to the Platform Provider by sending an email to <a style="font-weight: bold;"> support @ indonesiagreen.com </a> along with the reasons. </p> <br /> '
            } else {
                return '<p>1.1 Pengunjung Platform atau Pengguna Platform berhak untuk mengajukan permohonan penonaktifan dan/atau pemutusan dan penghapusan Akun yang terdaftar atas namanya pada Platform kepada Penyedia Platform dengan mengirim email ke <a style="font-weight: bold;">support@indonesiagreen.com</a> disertai alasan-alasannya.</p><br />'
            }
        case '<p>1.2 Penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengunjung Platform atau Pengguna Platform dilakukan dengan memperhatikan kewajiban Penyedia Platform untuk melakukan penyimpanan data pribadi sesuai batas waktu yang ditentukan dalam</p>':
            if (lg) {
                return '<p> 1.2 Deactivation and / or termination and deletion of Accounts from Platform Visitors or Platform Users is made with due regard to the obligation of the Platform Provider to store personal data within the time limit specified in </p>'
            } else {
                return '<p>1.2 Penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengunjung Platform atau Pengguna Platform dilakukan dengan memperhatikan kewajiban Penyedia Platform untuk melakukan penyimpanan data pribadi sesuai batas waktu yang ditentukan dalam</p>'
            }
        case '<p>peraturan perundang-undangan yang berlaku sejak tanggal penonaktifan dan/atau pemutusan dan penghapusan Akun tersebut. </p><br />':
            if (lg) {
                return '<p> the statutory regulations in effect from the date of deactivation and / or termination and deletion of the Account. </p> <br /> '
            } else {
                return '<p>peraturan perundang-undangan yang berlaku sejak tanggal penonaktifan dan/atau pemutusan dan penghapusan Akun tersebut. </p><br />'
            }
        case '<p>1.3 Penyedia Platform berhak untuk melakukan penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengunjung Platform atau Pengguna Platform apabila terbukti melanggar Syarat dan Ketentuan ini.</p><br />':
            if (lg) {
                return '<p> 1.3 Platform Providers have the right to deactivate and / or terminate and delete accounts from Platform Visitors or Platform Users if they prove to violate these Terms and Conditions. </p> <br />'
            } else {
                return '<p>1.3 Penyedia Platform berhak untuk melakukan penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengunjung Platform atau Pengguna Platform apabila terbukti melanggar Syarat dan Ketentuan ini.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">G. Batasan Penyimpanan Data</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> G. Data Storage Limits </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">G. Batasan Penyimpanan Data</p><br />'
            }
        case '<p>Anda mengetahui bahwa situs memiliki batasan dalam penyediaan layanan kepada seluruh pengguna situs, termasuk batasan jangka waktu data atau konten lainnya yang disimpan oleh server Indonesia Green (IGE) atas nama Anda. Karena server kami memiliki kapasitas maksimal untuk menyimpan data seluruh pengguna. Maka dari alasan tersebut, Anda setuju dan memahami bahwa kami berhak untuk mengakhiri akun atau Project atas nama Anda yang sudah tidak aktif dalam jangka waktu tertentu, sebelum melakukan hal tersebut kami akan berusaha memberikan pemberitahuan terlebih dahulu kepada Anda via email. </p><br />':
            if (lg) {
                return '<p> You acknowledge that the site has restrictions on providing services to all site users, including limitations on the period of data or other content stored by the Indonesia Green (IGE) server on your behalf. Because our server has the maximum capacity to store data for all users. So from that reason, you agree and understand that we have the right to terminate an account or Project on your behalf that has been inactive for a certain period of time, before doing so we will try to give you advance notice via email. </p> <br /> '
            } else {
                return '<p>Anda mengetahui bahwa situs memiliki batasan dalam penyediaan layanan kepada seluruh pengguna situs, termasuk batasan jangka waktu data atau konten lainnya yang disimpan oleh server Indonesia Green (IGE) atas nama Anda. Karena server kami memiliki kapasitas maksimal untuk menyimpan data seluruh pengguna. Maka dari alasan tersebut, Anda setuju dan memahami bahwa kami berhak untuk mengakhiri akun atau Project atas nama Anda yang sudah tidak aktif dalam jangka waktu tertentu, sebelum melakukan hal tersebut kami akan berusaha memberikan pemberitahuan terlebih dahulu kepada Anda via email. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">H. Syarat Pengguna Situs</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> H. Site User Terms </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">H. Syarat Pengguna Situs</p><br />'
            }
        case '<p>1.1 Berusia 17 tahun atau di antara usia 13 sampai 16 disertai pengawasan orang tua;</p><br />':
            if (lg) {
                return '<p> 1.1 Aged 17 years or between the ages of 13 and 16 accompanied by parental supervision; </p> <br />'
            } else {
                return '<p>1.1 Berusia 17 tahun atau di antara usia 13 sampai 16 disertai pengawasan orang tua;</p><br />'
            }
        case '<p>1.2 Pengunjung situs ini wajib menyatakan diri sebagai seseorang yang cakap di mata hukum sehingga dapat bertanggung jawab atas segala tindakan ataupun kelalaian apabila melanggar syarat dan ketentuan ini;</p><br />':
            if (lg) {
                return '<p> 1.2 Visitors to this site must declare themselves competent before the law so they can be held responsible for any actions or omissions if they violate these terms and conditions; </p> <br />'
            } else {
                return '<p>1.2 Pengunjung situs ini wajib menyatakan diri sebagai seseorang yang cakap di mata hukum sehingga dapat bertanggung jawab atas segala tindakan ataupun kelalaian apabila melanggar syarat dan ketentuan ini;</p><br />'
            }
        case '<p>1.3 Tidak diperkenankan bagi pengunjung situs untuk melakukan tindakan yang dapat melanggar ketentuan privasi seperti yang diatur dalam kebijakan privasi pada situs ini. </p><br />':
            if (lg) {
                return '<p> 1.3 It is not permissible for site visitors to take actions that could violate the privacy provisions as stipulated in the privacy policy on this site. </p> <br /> '
            } else {
                return '<p>1.3 Tidak diperkenankan bagi pengunjung situs untuk melakukan tindakan yang dapat melanggar ketentuan privasi seperti yang diatur dalam kebijakan privasi pada situs ini. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">I. Notifikasi</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> I. Notifications </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">I. Notifikasi</p><br />'
            }
        case '<p>Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging) yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik tersebut. </p><br />':
            if (lg) {
                return '<p> Platform Visitors or Platform Users agree and are willing to receive all notifications via electronic media, including, but not limited to e-mail, short message service (SMS) and / or instant messaging registered on Platform and for that purpose give permission to Platform Providers to contact Platform Visitors or Platform Users via the electronic media. </p> <br /> '
            } else {
                return '<p>Pengunjung Platform atau Pengguna Platform sepakat dan bersedia untuk menerima segala notifikasi melalui media elektronik, termasuk, namun tidak terbatas pada email, layanan pesan singkat (short messaging service atau SMS) dan/atau pesan instan (instant messaging) yang didaftarkannya pada Platform dan untuk itu memberikan izin kepada Penyedia Platform untuk menghubungi Pengunjung Platform atau Pengguna Platform melalui media elektronik tersebut. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">J. Konten Publik</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> J. Public Content </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">J. Konten Publik</p><br />'
            }
        case '<p>Anda mengetahui bahwa setiap informasi yang Anda tampilkan pada konten atau layanan kami, dapat diakses oleh publik, seperti nama, nomer telepon, email dan sosial media. Hal tersebut kami lakukan agar terdapat keterbukaan informasi bagi pengguna situs agar tidak dapat menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan. </p><br />':
            if (lg) {
                return '<p> You acknowledge that any information that you display on our content or services, can be accessed by the public, such as name, telephone number, email and social media. We do this so that there is information disclosure for site users so as not to cause suspicion or other prejudices that cannot be accounted for. </p> <br /> '
            } else {
                return '<p>Anda mengetahui bahwa setiap informasi yang Anda tampilkan pada konten atau layanan kami, dapat diakses oleh publik, seperti nama, nomer telepon, email dan sosial media. Hal tersebut kami lakukan agar terdapat keterbukaan informasi bagi pengguna situs agar tidak dapat menimbulkan kecurigaan ataupun prasangka lainnya yang tidak dapat dipertanggungjawabkan. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Ketentuan Penggalangan Dana (Fundraising)</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Fundraising Provisions </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Ketentuan Penggalangan Dana (Fundraising)</p><br />'
            }
        case '<p>1.1 Indonesia Green (IGE) tidak memberikan ganti rugi dalam bentuk apapun atas penggalangan dana yang dilakukan pada situs kami.</p><br />':
            if (lg) {
                return '<p> 1.1 Indonesia Green (IGE) does not provide any form of compensation for fundraising carried out on our site. </p> <br />'
            } else {
                return '<p>1.1 Indonesia Green (IGE) tidak memberikan ganti rugi dalam bentuk apapun atas penggalangan dana yang dilakukan pada situs kami.</p><br />'
            }
        case '<p>1.2 Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa terdapat Project yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan Indonesia Green (IGE) untuk melaporkan kepada kami dan ikut serta membantu menyelesaikan perkara tersebut.</p><br />':
            if (lg) {
                return '<p> 1.2 If in the future you as a site user discovers or discovers that there is a Project that is suspicious or indicates fraud, you agree to assist and cooperate with Indonesia Green (IGE) to report to us and participate in helping to resolve the case. </p> <br /> '
            } else {
                return '<p>1.2 Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa terdapat Project yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan Indonesia Green (IGE) untuk melaporkan kepada kami dan ikut serta membantu menyelesaikan perkara tersebut.</p><br />'
            }
        case '<p>1.3 Terdapat beberapa contoh jenis fundraising yang wajib dipahami oleh Anda:</p>':
            if (lg) {
                return '<p> 1.3 There are several examples of types of fundraising that you must understand: </p>'
            } else {
                return '<p>1.3 Terdapat beberapa contoh jenis fundraising yang wajib dipahami oleh Anda:</p>'
            }
        case '<p>i. Kasus A: Apabila jumlah donasi tercapai dan waktu donasi selesai, maka project owner dapat mencairkan donasi.</p>':
            if (lg) {
                return '<p> i. Case A: If the donation amount is reached and when the donation is complete, the project owner can withdraw the donation. </p> '
            } else {
                return '<p>i. Kasus A: Apabila jumlah donasi tercapai dan waktu donasi selesai, maka project owner dapat mencairkan donasi.</p>'
            }
        case '<p>ii. Kasus B: Apabila jumlah donasi tidak tercapai namun waktu donasi selesai, maka IGE koordinasi dengan project owner dengan pilihan melanjutkan proyek dengan dana yang difundraise atau menambah waktu fundraising.</p>':
            if (lg) {
                return '<p> ii. Case B: If the amount of donations is not reached but the time of donations is complete, then IGE coordinates with the project owner with the choice of continuing the project with funds that are fundraised or increasing fundraising time. </p> '
            } else {
                return '<p>ii. Kasus B: Apabila jumlah donasi tidak tercapai namun waktu donasi selesai, maka IGE koordinasi dengan project owner dengan pilihan melanjutkan proyek dengan dana yang difundraise atau menambah waktu fundraising.</p>'
            }
        case '<p>iii. Kasus C: Apabila jumlah donasi tercapai dan waktu donasi belum selesai, maka fundraising dianggap selesai atau menunggu hingga waktu donasi selesai.</p>':
            if (lg) {
                return '<p> iii. Case C: If the donation amount is reached and the donation time has not yet been completed, then fundraising is considered complete or waiting until the donation time is complete. </p> '
            } else {
                return '<p>iii. Kasus C: Apabila jumlah donasi tercapai dan waktu donasi belum selesai, maka fundraising dianggap selesai atau menunggu hingga waktu donasi selesai.</p>'
            }
        case '<p>iv. Kasus D: Apabila jumlah donasi tidak tercapai dan waktu donasi belum selesai, maka fundraising dilanjutkan hingga waktu selesai.</p>':
            if (lg) {
                return '<p> iv. Case D: If the donation amount is not reached and the donation time has not yet been completed, then the fundraising is continued until the time is over. </p> '
            } else {
                return '<p>iv. Kasus D: Apabila jumlah donasi tidak tercapai dan waktu donasi belum selesai, maka fundraising dilanjutkan hingga waktu selesai.</p>'
            }
        case '<p>v. Kasus E: Apabila jumlah donasi tercapai dan waktu donasi selesai, namun donatur ingin donasi ke proyek tersebut, maka donasi akan tetap disalurkan ke proyek tersebut.</p><br />':
            if (lg) {
                return '<p> v. Case E: If the donation amount is reached and the time of donation is completed, but the donors wants to donate to the project, then the donation will still be distributed to the project. </p> <br /> '
            } else {
                return '<p>v. Kasus E: Apabila jumlah donasi tercapai dan waktu donasi selesai, namun donatur ingin donasi ke proyek tersebut, maka donasi akan tetap disalurkan ke proyek tersebut.</p><br />'
            }
        case '<p>1.4 Fundraising yang tidak mencapai kesepakatan waktu atau jumlah yang disepakati, maka donasi akan masuk ke kas IGE.</p><br />':
            if (lg) {
                return '<p> 1.4 Fundraising that does not reach the agreed time or amount, then donations will go to IGE cash. </p> <br />'
            } else {
                return '<p>1.4 Fundraising yang tidak mencapai kesepakatan waktu atau jumlah yang disepakati, maka donasi akan masuk ke kas IGE.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Ketentuan Untuk Project Owner</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Provisions for Project Owner </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Ketentuan Untuk Project Owner</p><br />'
            }
        case '<p>Peringatan : Anda setuju bahwa Indonesia Green (IGE) tidak bertanggung jawab atau berkewajiban atas penghapusan atau kegagalan Anda dalam menyimpan data atau konten pada akun atau Project Anda. Selain itu, Anda berkewajiban menjaga kerahasiaan sandi serta keamanan akun Anda sendiri. Kami menyarankan Anda untuk senantiasa menjaga kerahasiaan akun Anda dari pihak lain, memastikan bahwa Anda selalu ‘log out’ setiap selesai menggunakan layanan pada situs Indonesia Green (IGE). Apabila di kemudian hari Anda mendapati bahwa akun Anda disalahgunakan atau diakses oleh pihak lain tanpa persetujuan Anda, segera hubungi kami melalui email:support@indonesiagreen.com agar kami dapat memproses akun Anda.</p>':
            if (lg) {
                return '<p> Warning: You agree that Indonesia Green (IGE) is not responsible or liable for your deletion or failure to store data or content in your account or project. In addition, you are obliged to maintain the confidentiality of your own password and account security. We recommend that you always maintain the confidentiality of your account from other parties, ensuring that you always \'log out\' after completing using the services on the Indonesia Green (IGE) site. If you later find that your account has been misused or accessed by another party without your consent, please contact us immediately via email: support@indonesiagreen.com so we can process your account. </p> '
            } else {
                return '<p>Peringatan : Anda setuju bahwa Indonesia Green (IGE) tidak bertanggung jawab atau berkewajiban atas penghapusan atau kegagalan Anda dalam menyimpan data atau konten pada akun atau Project Anda. Selain itu, Anda berkewajiban menjaga kerahasiaan sandi serta keamanan akun Anda sendiri. Kami menyarankan Anda untuk senantiasa menjaga kerahasiaan akun Anda dari pihak lain, memastikan bahwa Anda selalu ‘log out’ setiap selesai menggunakan layanan pada situs Indonesia Green (IGE). Apabila di kemudian hari Anda mendapati bahwa akun Anda disalahgunakan atau diakses oleh pihak lain tanpa persetujuan Anda, segera hubungi kami melalui email:support@indonesiagreen.com agar kami dapat memproses akun Anda.</p>'
            }
        case '<p>Melalui persetujuan Anda kami dapat melakukan beberapa tindakan untuk melakukan pencegahan penyalahgunaan akun seperti : pembekuan sementara akun, penghapusan akun atau pemblokiran akun. Kami tidak bertanggung jawab untuk setiap kerugian atau kerusakan yang diakibatkan dari kegagalan Anda untuk mematuhi aturan pada bagian ini.</p><br />':
            if (lg) {
                return '<p> Through your agreement, we can take several actions to prevent account misuse, such as: temporary suspension of account, deletion of account or account blocking. We are not responsible for any loss or damage resulting from your failure to comply with the rules in this section. </p> <br /> '
            } else {
                return '<p>Melalui persetujuan Anda kami dapat melakukan beberapa tindakan untuk melakukan pencegahan penyalahgunaan akun seperti : pembekuan sementara akun, penghapusan akun atau pemblokiran akun. Kami tidak bertanggung jawab untuk setiap kerugian atau kerusakan yang diakibatkan dari kegagalan Anda untuk mematuhi aturan pada bagian ini.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">A. Syarat Menjadi Project Owner</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> A. Requirements to Become a Project Owner </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">A. Syarat Menjadi Project Owner</p><br />'
            }
        case '<p>1.1 Wajib memiliki badan Hukum, adalah merupakan organisasi/komunitas/yayasan yang memiliki (Nomor Pokok Wajib Pajak) NPWP dan Akta Notaris (Bukti Hukum) tentang pendirian organisasi/komunitas/yayasan yang bersangkutan beserta KTP perseorangan yang mewakilkan berbadan hukum tersebut yang terdaftar di dalam platform;</p><br />':
            if (lg) {
                return '<p> 1.1 Must have a legal entity, is an organization / community / foundation that has a Taxpayer Identification Number (NPWP) and Notary Deed (Evidence of Law) regarding the establishment of the relevant organization / community / foundation along with an individual KTP representing the legal entity. registered in the platform; </p> <br /> '
            } else {
                return '<p>1.1 Wajib memiliki badan Hukum, adalah merupakan organisasi/komunitas/yayasan yang memiliki (Nomor Pokok Wajib Pajak) NPWP dan Akta Notaris (Bukti Hukum) tentang pendirian organisasi/komunitas/yayasan yang bersangkutan beserta KTP perseorangan yang mewakilkan berbadan hukum tersebut yang terdaftar di dalam platform;</p><br />'
            }
        case '<p>1.2 Bersedia melakukan serangkaian proses verifikasi akun dan kelengkapan lainnya apabila diperlukan;</p><br />':
            if (lg) {
                return '<p> 1.2 Willing to carry out a series of account verification processes and other completeness if needed; </p> <br />'
            } else {
                return '<p>1.2 Bersedia melakukan serangkaian proses verifikasi akun dan kelengkapan lainnya apabila diperlukan;</p><br />'
            }
        case '<p>1.3 Project Owner menjamin bahwa setiap informasi yang diberikan kepada situs Indonesia Green (IGE) merupakan data yang benar dan dapat dipertanggung jawabkan.</p><br />':
            if (lg) {
                return '<p> 1.3 The Project Owner guarantees that any information provided to the Indonesia Green (IGE) website is true and reliable data. </p> <br />'
            } else {
                return '<p>1.3 Project Owner menjamin bahwa setiap informasi yang diberikan kepada situs Indonesia Green (IGE) merupakan data yang benar dan dapat dipertanggung jawabkan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">B. Kewajiban Project Owner</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> B. Project Owner Obligations </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">B. Kewajiban Project Owner</p><br />'
            }
        case '<p>Project Owner wajib untuk:</p><br />':
            if (lg) {
                return '<p> Project Owner is required to: </p> <br />'
            } else {
                return '<p>Project Owner wajib untuk:</p><br />'
            }
        case '<p>1.1 Menjamin dan menyatakan bahwa segala informasi yang dimasukkan dan diunggah oleh Project Owner ke Platform, termasuk, namun tidak terbatas pada:</p>':
            if (lg) {
                return '<p> 1.1 Guarantees and states that all information entered and uploaded by the Project Owner to the Platform, including, but not limited to: </p>'
            } else {
                return '<p>1.1 Menjamin dan menyatakan bahwa segala informasi yang dimasukkan dan diunggah oleh Project Owner ke Platform, termasuk, namun tidak terbatas pada:</p>'
            }
        case '<p>i.   Hubungan antara Project Owner dengan Penerima Manfaat;</p>':
            if (lg) {
                return '<p> i. Relationship between Project Owners and Beneficiaries; </p> '
            } else {
                return '<p>i.   Hubungan antara Project Owner dengan Penerima Manfaat;</p>'
            }
        case '<p>ii.  Status sebagai bukan pihak yang sedang terlibat dalam proses hukum pidana maupun perdata; dan</p>':
            if (lg) {
                return '<p> ii. Status as a party not involved in criminal or civil law proceedings; and </p> '
            } else {
                return '<p>ii.  Status sebagai bukan pihak yang sedang terlibat dalam proses hukum pidana maupun perdata; dan</p>'
            }
        case '<p>iii. Status sebagai bukan penyelenggara negara sebagaimana dimaksud dalam Undang-Undang Nomor 28 Tahun 1999 tentang Penyelenggaraan Negara yang Bersih dan Bebas Dari Korupsi, adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;</p><br />':
            if (lg) {
                return '<p> iii. Status as a non-state organizer as referred to in Law Number 28 of 1999 concerning State Administration that is Clean and Free of Corruption, is in accordance with facts and truth that can be accounted for; </p> <br /> '
            } else {
                return '<p>iii. Status sebagai bukan penyelenggara negara sebagaimana dimaksud dalam Undang-Undang Nomor 28 Tahun 1999 tentang Penyelenggaraan Negara yang Bersih dan Bebas Dari Korupsi, adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;</p><br />'
            }
        case '<p>1.2 Menjamin dan menyatakan bahwa semua Konten yang dimasukkan dan diunggah oleh Project Owner ke Platform, tidak memuat Konten Yang Dilarang;</p><br />':
            if (lg) {
                return '<p> 1.2 Guarantees and certifies that all Content submitted and uploaded by the Project Owner to the Platform, does not contain Prohibited Content; </p> <br />'
            } else {
                return '<p>1.2 Menjamin dan menyatakan bahwa semua Konten yang dimasukkan dan diunggah oleh Project Owner ke Platform, tidak memuat Konten Yang Dilarang;</p><br />'
            }
        case '<p>1.3 Menunjuk 1 (satu) rekening bank dari salah satu Mitra Pencairan Dana sebagai rekening tujuan dalam rangka Pencairan Dana yang sifatnya tidak dapat diubah, kecuali dalam keadaan darurat, dimana Project Owner dapat mengajukan permohonan perubahan data kepada Penyedia Platform dengan mengirim email ke support@indonesiagreen.com disertai alasan-alasannya;</p><br />':
            if (lg) {
                return '<p> 1.3 Appoint 1 (one) bank account of one of the Funds Disbursement Partners as the destination account in the context of Funds Disbursement which cannot be changed, except in an emergency, where the Project Owner can submit an application for data changes to the Platform Provider by sending an email to support@indonesiagreen.com with the reasons; </p> <br /> '
            } else {
                return '<p>1.3 Menunjuk 1 (satu) rekening bank dari salah satu Mitra Pencairan Dana sebagai rekening tujuan dalam rangka Pencairan Dana yang sifatnya tidak dapat diubah, kecuali dalam keadaan darurat, dimana Project Owner dapat mengajukan permohonan perubahan data kepada Penyedia Platform dengan mengirim email ke support@indonesiagreen.com disertai alasan-alasannya;</p><br />'
            }
        case '<p>1.4 Dalam hal tujuan Project berbentuk proyek:</p>':
            if (lg) {
                return '<p> 1.4 In the case of Project objectives being a project: </p>'
            } else {
                return '<p>1.4 Dalam hal tujuan Project berbentuk proyek:</p>'
            }
        case '<p>i.   Menyatakan bahwa proyek tersebut merupakan proyek yang tidak melanggar perundang-undangan yang berlaku;</p>':
            if (lg) {
                return '<p> i. Stating that the project is a project that does not violate applicable laws; </p> '
            } else {
                return '<p>i.   Menyatakan bahwa proyek tersebut merupakan proyek yang tidak melanggar perundang-undangan yang berlaku;</p>'
            }
        case '<p>ii.  Menyatakan bahwa telah memperoleh izin tertentu, termasuk apabila bersumber dari pihak lain, sebagaimana diatur dalam peraturan perundang-undangan yang berlaku;</p>':
            if (lg) {
                return '<p> ii. Stating that it has obtained certain permits, including if sourced from other parties, as stipulated in the applicable laws and regulations; </p> '
            } else {
                return '<p>ii.  Menyatakan bahwa telah memperoleh izin tertentu, termasuk apabila bersumber dari pihak lain, sebagaimana diatur dalam peraturan perundang-undangan yang berlaku;</p>'
            }
        case '<p>iii. Menyatakan bahwa proyek tersebut bukan bertujuan untuk komersial;</p>':
            if (lg) {
                return '<p> iii. States that the project is not for commercial purposes; </p> '
            } else {
                return '<p>iii. Menyatakan bahwa proyek tersebut bukan bertujuan untuk komersial;</p>'
            }
        case '<p>iv. Menyiapkan segala perencanaan yang sesuai, termasuk dengan standar kualitas dan keahlian sumber daya manusia yang sesuai dengan proyek yang diusulkan; dan</p><br />':
            if (lg) {
                return '<p> iv. Prepare all appropriate planning, including with quality standards and human resource expertise in accordance with the proposed project; and </p> <br /> '
            } else {
                return '<p>iv. Menyiapkan segala perencanaan yang sesuai, termasuk dengan standar kualitas dan keahlian sumber daya manusia yang sesuai dengan proyek yang diusulkan; dan</p><br />'
            }
        case '<p>1.5 Sepakat dan bersedia untuk membebaskan Penyedia Platform dari setiap gugatan maupun tuntutan hukum, dan untuk mengganti segala kerugian yang mungkin dialami oleh Penyedia Platform di kemudian hari atas, namun tidak terbatas pada, hal-hal berikut:</p>':
            if (lg) {
                return '<p> 1.5 Agree and be willing to release the Platform Provider from any lawsuits or lawsuits, and to compensate for any losses that may occur to the Platform Provider in the future for, but not limited to, the following: </p>'
            } else {
                return '<p>1.5 Sepakat dan bersedia untuk membebaskan Penyedia Platform dari setiap gugatan maupun tuntutan hukum, dan untuk mengganti segala kerugian yang mungkin dialami oleh Penyedia Platform di kemudian hari atas, namun tidak terbatas pada, hal-hal berikut:</p>'
            }
        case '<p>i.Pelaksanaan Project yang tidak dipenuhi, dipenuhi sebagian atau yang dipenuhi tetapi tidak sesuai dengan yang dijanjikan oleh Project Owner melalui Platform;</p>':
            if (lg) {
                return '<p> i. Project Implementation that was not fulfilled, partially fulfilled or that was fulfilled but did not match what was promised by the Project Owner through the Platform; </p>'
            } else {
                return '<p>i.Pelaksanaan Project yang tidak dipenuhi, dipenuhi sebagian atau yang dipenuhi tetapi tidak sesuai dengan yang dijanjikan oleh Project Owner melalui Platform;</p>'
            }
        case '<p>ii.Penggelapan dan/atau penyalahgunaan Dana oleh Project Owner; dan</p>':
            if (lg) {
                return '<p> ii. Darkening and / or misuse of Funds by the Project Owner; and </p> '
            } else {
                return '<p>ii.Penggelapan dan/atau penyalahgunaan Dana oleh Project Owner; dan</p>'
            }
        case '<p>iii.Segala perbuatan melanggar hukum lainnya yang terjadi baik pada saat, selama maupun setelah masa Project dan/atau Pelaksanaan Project. </p><br />':
            if (lg) {
                return '<p> iii. All other unlawful acts that occur both during, during and after the Project period and / or Project Implementation. </p> <br /> '
            } else {
                return '<p>iii.Segala perbuatan melanggar hukum lainnya yang terjadi baik pada saat, selama maupun setelah masa Project dan/atau Pelaksanaan Project. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">C. Larangan Pencantuman Rekening Pribadi Maupun Pihak Lain</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> C. Prohibition of Inclusion of Personal Accounts or Other Parties </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">C. Larangan Pencantuman Rekening Pribadi Maupun Pihak Lain</p><br />'
            }
        case '<p>1.1 Project Owner dilarang mencantumkan rekening pribadi dari Project Owner pada halaman Project di Platform. </p><br />':
            if (lg) {
                return '<p> 1.1 Project Owner is prohibited from listing the personal account of the Project Owner on the Project page on the Platform. </p> <br /> '
            } else {
                return '<p>1.1 Project Owner dilarang mencantumkan rekening pribadi dari Project Owner pada halaman Project di Platform. </p><br />'
            }
        case '<p>1.2 Dalam hal Project Owner menerima donasi luar jaringan dari Donatur, dan bukan melalui Dompet pada Platform, maka Project Owner sepenuhnya bertanggung jawab atas hubungan hukum yang timbul antara Project Owner dan Donatur yang bersangkutan.</p><br />':
            if (lg) {
                return '<p> 1.2 In the event that the Project Owner receives donations outside the network from the Donors, and not through the Wallet on the Platform, the Project Owner is fully responsible for the legal relationship arising between the Project Owner and the Donors concerned. </p> <br /> '
            } else {
                return '<p>1.2 Dalam hal Project Owner menerima donasi luar jaringan dari Donatur, dan bukan melalui Dompet pada Platform, maka Project Owner sepenuhnya bertanggung jawab atas hubungan hukum yang timbul antara Project Owner dan Donatur yang bersangkutan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">D. Kerjasama Khusus</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> D. Special Collaboration </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">D. Kerjasama Khusus</p><br />'
            }
        case '<p>1.1 Project Owner dapat mengadakan kerjasama khusus dengan Penyedia Platform untuk Project tertentu yang dituangkan dalam perjanjian terpisah yang mengatur hak dan kewajiban para pihak.</p><br />':
            if (lg) {
                return '<p> 1.1 The Project Owner may enter into special collaboration with the Platform Provider for a particular Project as outlined in a separate agreement governing the rights and obligations of the parties. </p> <br />'
            } else {
                return '<p>1.1 Project Owner dapat mengadakan kerjasama khusus dengan Penyedia Platform untuk Project tertentu yang dituangkan dalam perjanjian terpisah yang mengatur hak dan kewajiban para pihak.</p><br />'
            }
        case '<p>1.2 Penyedia Platform memiliki diskresi penuh untuk menyepakati atau menolak kerjasama khusus yang diusulkan oleh Project Owner.</p><br />':
            if (lg) {
                return '<p> 1.2 Platform Providers have full discretion to agree or reject the specific collaboration proposed by the Project Owner. </p> <br />'
            } else {
                return '<p>1.2 Penyedia Platform memiliki diskresi penuh untuk menyepakati atau menolak kerjasama khusus yang diusulkan oleh Project Owner.</p><br />'
            }
        case '<p>1.3 Anda dapat menghubungi kami melalui support@indonesiagreen.com untuk mendapatkan informasi lebih lanjut.</p><br />':
            if (lg) {
                return '<p> 1.3 You can contact us via support@indonesiagreen.com to get more information. </p> <br />'
            } else {
                return '<p>1.3 Anda dapat menghubungi kami melalui support@indonesiagreen.com untuk mendapatkan informasi lebih lanjut.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">E. Pelaksanaan Project</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> E. Project Implementation </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">E. Pelaksanaan Project</p><br />'
            }
        case '<p>Dalam Pelaksanaan Project, Project Owner berkewajiban untuk:</p><br />':
            if (lg) {
                return '<p> In Project Implementation, Project Owner is obliged to: </p> <br />'
            } else {
                return '<p>Dalam Pelaksanaan Project, Project Owner berkewajiban untuk:</p><br />'
            }
        case '<p>1.1 Melaksanakan apa yang telah dijanjikan dalam Project melalui Platform, termasuk memenuhi secara tuntas imbalan kepada Donatur dalam hal suatu imbalan telah dijanjikan;</p><br />':
            if (lg) {
                return '<p> 1.1 Carry out what has been promised in the Project through the Platform, including fully fulfilling rewards to Donors in the event that a reward has been promised; </p> <br />'
            } else {
                return '<p>1.1 Melaksanakan apa yang telah dijanjikan dalam Project melalui Platform, termasuk memenuhi secara tuntas imbalan kepada Donatur dalam hal suatu imbalan telah dijanjikan;</p><br />'
            }
        case '<p>1.2 Memberikan laporan Pelaksanaan Project yang transparan, kredibel dan dilengkapi dengan dokumentasi dan bukti pendukung yang layak kepada Donatur dan Pengelola Platform melalui Platform;</p><br />':
            if (lg) {
                return '<p> 1.2 Provide transparent, credible Project Implementation reports and supplemented with appropriate documentation and supporting evidence to Donors and Platform Managers through the Platform; </p> <br />'
            } else {
                return '<p>1.2 Memberikan laporan Pelaksanaan Project yang transparan, kredibel dan dilengkapi dengan dokumentasi dan bukti pendukung yang layak kepada Donatur dan Pengelola Platform melalui Platform;</p><br />'
            }
        case '<p>1.3 Dalam hal ada perjanjian kerjasama khusus antara Project Owner dengan Penyedia Platform, menjalankan kewajibannya sebagaimana dimuat pada perjanjian kerjasama khusus tersebut; </p><br />':
            if (lg) {
                return '<p> 1.3 In the case of a special cooperation agreement between the Project Owner and the Platform Provider, performing its obligations as contained in the special cooperation agreement; </p> <br /> '
            } else {
                return '<p>1.3 Dalam hal ada perjanjian kerjasama khusus antara Project Owner dengan Penyedia Platform, menjalankan kewajibannya sebagaimana dimuat pada perjanjian kerjasama khusus tersebut; </p><br />'
            }
        case '<p>1.4 Bertanggungjawab untuk mengkalkulasi dan memproyeksi dampak dari proyek yang telah berjalan; </p><br />':
            if (lg) {
                return '<p> 1.4 Responsible for calculating and projecting the impacts of existing projects; </p> <br /> '
            } else {
                return '<p>1.4 Bertanggungjawab untuk mengkalkulasi dan memproyeksi dampak dari proyek yang telah berjalan; </p><br />'
            }
        case '<p>1.5 Implementasi skema pembiayaan perawatan mandiri proyek terhadap beneficiary; dan</p><br />':
            if (lg) {
                return '<p> 1.5 Implementation of the project\'s self-care funding scheme for beneficiaries; and </p> <br /> '
            } else {
                return '<p>1.5 Implementasi skema pembiayaan perawatan mandiri proyek terhadap beneficiary; dan</p><br />'
            }
        case '<p>1.6 Apabila proyek yang telah berjalan namun dengan suatu hal tertentu proyek diputuskan untuk tidak dilanjutkan oleh project owner, maka donasi yang telah disalurkan kepada project owner wajib dikembalikan sebesar 100%.</p><br />':
            if (lg) {
                return '<p> 1.6 If the project is already running but with a certain case the project is decided not to be continued by the project owner, then donations that have been distributed to the project owner must be returned by 100%. </p> <br />'
            } else {
                return '<p>1.6 Apabila proyek yang telah berjalan namun dengan suatu hal tertentu proyek diputuskan untuk tidak dilanjutkan oleh project owner, maka donasi yang telah disalurkan kepada project owner wajib dikembalikan sebesar 100%.</p><br />'
            }
        case '<p>1.7 Dalam hal Pelaksanaan Project tidak dapat dipenuhi sesuai dengan yang telah dijanjikan dan/atau tidak dapat dipenuhi sama sekali karena suatu keadaan memaksa, segera mengajukan laporan kepada Penyedia Platform dengan mengirim email ke support@indonesiagreen.com untuk ditindaklanjuti. </p><br />':
            if (lg) {
                return '<p> 1.7 In the event that Project Implementation cannot be fulfilled as promised and / or cannot be fulfilled at all due to a compelling condition, immediately submit a report to the Platform Provider by sending an email to support@indonesiagreen.com for further action. </p> <br /> '
            } else {
                return '<p>1.7 Dalam hal Pelaksanaan Project tidak dapat dipenuhi sesuai dengan yang telah dijanjikan dan/atau tidak dapat dipenuhi sama sekali karena suatu keadaan memaksa, segera mengajukan laporan kepada Penyedia Platform dengan mengirim email ke support@indonesiagreen.com untuk ditindaklanjuti. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">F. Penunjukkan dan Pemberian Kuasa Kepada Penyedia Platform</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> F. Appointment and Authorization of Platform Providers </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">F. Penunjukkan dan Pemberian Kuasa Kepada Penyedia Platform</p><br />'
            }
        case '<p>Project Owner setuju dan sepakat untuk menunjuk dan memberi kuasa kepada Penyedia Platform untuk bertindak untuk dan atas nama Project Owner untuk melakukan, antara lain, hal-hal berikut:</p><br />':
            if (lg) {
                return '<p> The Project Owner agrees and agrees to appoint and authorize the Platform Provider to act for and on behalf of the Project Owner to do, inter alia, the following: </p> <br />'
            } else {
                return '<p>Project Owner setuju dan sepakat untuk menunjuk dan memberi kuasa kepada Penyedia Platform untuk bertindak untuk dan atas nama Project Owner untuk melakukan, antara lain, hal-hal berikut:</p><br />'
            }
        case '<p>1.1 Menjadi perantara eksklusif antara Project Owner dengan Donatur dan/atau penyebar informasi dari Project yang dilakukan Project Owner;</p><br />':
            if (lg) {
                return '<p> 1.1 Become an exclusive intermediary between Project Owners and Donors and / or disseminators of information from Projects conducted by Project Owners; </p> <br />'
            } else {
                return '<p>1.1 Menjadi perantara eksklusif antara Project Owner dengan Donatur dan/atau penyebar informasi dari Project yang dilakukan Project Owner;</p><br />'
            }
        case '<p>1.2 Menyimpan dan mengelola Dana dari Donatur yang telah masuk rekening atas nama Project Owner;</p><br />':
            if (lg) {
                return '<p> 1.2 Saving and managing Funds from Donors who have entered accounts in the name of Project Owner; </p> <br />'
            } else {
                return '<p>1.2 Menyimpan dan mengelola Dana dari Donatur yang telah masuk rekening atas nama Project Owner;</p><br />'
            }
        case '<p>1.3 Dalam hal terdapat perjanjian kerjasama khusus antara Project Owner dengan Penyedia Platform, maka Project Owner dan Penyedia Platform akan bertindak sebagaimana yang telah diperjanjikan dalam perjanjian khusus tersebut; dan</p><br />':
            if (lg) {
                return '<p> 1.3 In the event of a special cooperation agreement between the Project Owner and the Platform Provider, the Project Owner and Platform Provider will act as agreed in the special agreement; and </p> <br /> '
            } else {
                return '<p>1.3 Dalam hal terdapat perjanjian kerjasama khusus antara Project Owner dengan Penyedia Platform, maka Project Owner dan Penyedia Platform akan bertindak sebagaimana yang telah diperjanjikan dalam perjanjian khusus tersebut; dan</p><br />'
            }
        case '<p>1.4 Segala hal yang diperlukan untuk pelaksanaan kuasa di atas, termasuk, namun tidak terbatas pada, menandatangani setiap dokumen yang diperlukan, melakukan surat-menyurat dengan dan menghadap kepada instansi yang berwenang, notaris dan/atau pejabat publik lainnya.</p><br />':
            if (lg) {
                return '<p> 1.4 All matters required for the implementation of the above power of attorney, including, but not limited to, signing any required documents, conducting correspondence with and facing the relevant authorities, notaries and / or other public officials. </ p> <br /> '
            } else {
                return '<p>1.4 Segala hal yang diperlukan untuk pelaksanaan kuasa di atas, termasuk, namun tidak terbatas pada, menandatangani setiap dokumen yang diperlukan, melakukan surat-menyurat dengan dan menghadap kepada instansi yang berwenang, notaris dan/atau pejabat publik lainnya.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">G. Penolakan dan Penundaan Verifikasi</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> G. Verification and Delay Verification </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">G. Penolakan dan Penundaan Verifikasi</p><br />'
            }
        case '<p>Penyedia Platform memiliki kewenangan penuh untuk menolak dan/atau menunda melakukan Verifikasi terhadap Akun dan/atau Pencairan Dana apabila Project Owner:</p><br />':
            if (lg) {
                return '<p> The Platform Provider has full authority to refuse and / or postpone Verification of the Account and / or Disbursement of Funds if the Project Owner: </p> <br />'
            } else {
                return '<p>Penyedia Platform memiliki kewenangan penuh untuk menolak dan/atau menunda melakukan Verifikasi terhadap Akun dan/atau Pencairan Dana apabila Project Owner:</p><br />'
            }
        case '<p>1.1 Belum menyampaikan data-data yang cukup sehubungan dengan pendaftaran Akun dan/atau Pencairan Dana kepada Penyedia Platform; atau</p><br />':
            if (lg) {
                return '<p> 1.1 Have not submitted sufficient data relating to the registration of the Account and / or Disbursement of Funds to the Platform Provider; or </p> <br /> '
            } else {
                return '<p>1.1 Belum menyampaikan data-data yang cukup sehubungan dengan pendaftaran Akun dan/atau Pencairan Dana kepada Penyedia Platform; atau</p><br />'
            }
        case '<p>1.2 Diduga telah melanggar atau berpotensi melanggar Syarat dan Ketentuan.</p><br />':
            if (lg) {
                return '<p> 1.2 Suspected of having violated or potentially violated the Terms and Conditions. </p> <br />'
            } else {
                return '<p>1.2 Diduga telah melanggar atau berpotensi melanggar Syarat dan Ketentuan.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">H. Penutupan Project</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> H. Project Closing </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">H. Penutupan Project</p><br />'
            }
        case '<p>1.1 Penyedia Platform memiliki kewenangan penuh untuk menutup Project apabila Project Owner ditemukan telah melanggar Syarat dan Ketentuan.</p><br />':
            if (lg) {
                return '<p> 1.1 The Platform Provider has full authority to close the Project if the Project Owner is found to have violated the Terms and Conditions. </p> <br />'
            } else {
                return '<p>1.1 Penyedia Platform memiliki kewenangan penuh untuk menutup Project apabila Project Owner ditemukan telah melanggar Syarat dan Ketentuan.</p><br />'
            }
        case '<p>1.2 Dalam hal Penyedia Platform menutup sebuah Project, Dana yang telah masuk ke dalam Dompet dari Project Owner bersangkutan akan disalurkan kepada Project Acak.</p><br />':
            if (lg) {
                return '<p> 1.2 In the event that the Platform Provider closes a Project, Funds that have entered the Wallet of the Project Owner will be distributed to the Random Project. </p> <br />'
            } else {
                return '<p>1.2 Dalam hal Penyedia Platform menutup sebuah Project, Dana yang telah masuk ke dalam Dompet dari Project Owner bersangkutan akan disalurkan kepada Project Acak.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">I. Ketentuan Project Owner</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> I. Project Owner Provisions </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">I. Ketentuan Project Owner</p><br />'
            }
        case '<p>1.1 Project Owner menjamin dan menyatakan bahwa semua konten yang diberikan kepada situs Indonesia Green (IGE) baik tulisan, foto dan video yang diterangkan dalam deskripsi adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;</p><br />':
            if (lg) {
                return '<p> 1.1 The Project Owner guarantees and states that all content provided to the Indonesia Green (IGE) site in terms of writing, photos and videos explained in the description is in accordance with facts and truth that can be accounted for; </p> <br / > '
            } else {
                return '<p>1.1 Project Owner menjamin dan menyatakan bahwa semua konten yang diberikan kepada situs Indonesia Green (IGE) baik tulisan, foto dan video yang diterangkan dalam deskripsi adalah sesuai dengan fakta dan kebenaran yang dapat dipertanggung jawabkan;</p><br />'
            }
        case '<p>1.2 Setiap penggalangan Dana merupakan tanggung jawab penuh dari pihak Project Owner dan atau fundraiser;</p><br />':
            if (lg) {
                return '<p> 1.2 Every fundraising is the full responsibility of the Project Owner and or fundraiser; </p> <br />'
            } else {
                return '<p>1.2 Setiap penggalangan Dana merupakan tanggung jawab penuh dari pihak Project Owner dan atau fundraiser;</p><br />'
            }
        case '<p>1.3 Penggalangan Dana dilarang untuk dilakukan apabila mengandung unsur: </p>':
            if (lg) {
                return '<p> 1.3 Fundraising is prohibited if it contains elements: </p>'
            } else {
                return '<p>1.3 Penggalangan Dana dilarang untuk dilakukan apabila mengandung unsur: </p>'
            }
        case '<p>i.   Pornografi, konten dewasa dan mengandung unsur seksualitas;</p>':
            if (lg) {
                return '<p> i. Pornography, adult content and contain elements of sexuality; </p> '
            } else {
                return '<p>i.   Pornografi, konten dewasa dan mengandung unsur seksualitas;</p>'
            }
        case '<p>ii.  Konten yang menurut kami, teridentifikasi mendukung kegiatan terorisme, menebar kebencian, kekerasan, pelecehan, bullying, diskriminasi, atau intoleransi berkaitan dengan ras, etnis, afiliasi agama atau kegiatan yang akan menurut kami akan menimbulkan perpecahan di kemudian hari;</p>':
            if (lg) {
                return '<p> ii. Content which, in our opinion, is identified as supporting terrorist activities, spreading hatred, violence, harassment, bullying, discrimination or intolerance relating to race, ethnicity, religious affiliation or activities which we believe will cause divisions in the future; </p> '
            } else {
                return '<p>ii.  Konten yang menurut kami, teridentifikasi mendukung kegiatan terorisme, menebar kebencian, kekerasan, pelecehan, bullying, diskriminasi, atau intoleransi berkaitan dengan ras, etnis, afiliasi agama atau kegiatan yang akan menurut kami akan menimbulkan perpecahan di kemudian hari;</p>'
            }
        case '<p>iii. Konten atau Project yang palsu, menyesatkan, tidak akurat, tidak jujur, Project dengan target yang tidak realistis dan Project yang meniru (imitation) Project pihak lain di Indonesia Green (IGE) maupun penggalangan Dana di luar situs Indonesia Green (IGE) tanpa persetujuan pihak lain tersebut;</p>':
            if (lg) {
                return '<p> iii. False, misleading, inaccurate, dishonest content or projects, projects with unrealistic targets and projects that imitate other parties\' projects in Indonesia Green (IGE) or fundraising outside the Indonesia Green (IGE) site without party approval such others; </p> '
            } else {
                return '<p>iii. Konten atau Project yang palsu, menyesatkan, tidak akurat, tidak jujur, Project dengan target yang tidak realistis dan Project yang meniru (imitation) Project pihak lain di Indonesia Green (IGE) maupun penggalangan Dana di luar situs Indonesia Green (IGE) tanpa persetujuan pihak lain tersebut;</p>'
            }
        case '<p>iv. Konten berkaitan dengan benda tajam dan berbahaya seperti: pisau, bahan peledak, amunisi, senjata api dan lain lain;</p>':
            if (lg) {
                return '<p> iv. Content related to sharp and dangerous objects such as: knives, explosives, ammunition, firearms and others; </p> '
            } else {
                return '<p>iv. Konten berkaitan dengan benda tajam dan berbahaya seperti: pisau, bahan peledak, amunisi, senjata api dan lain lain;</p>'
            }
        case '<p>v.  Perdagangan manusia, ekploitasi manusia dan suap;</p>':
            if (lg) {
                return '<p> v. Human trafficking, human exploitation and bribery; </p> '
            } else {
                return '<p>v.  Perdagangan manusia, ekploitasi manusia dan suap;</p>'
            }
        case '<p>vi. Terdapat musik, film, perangkat lunak atau materi lain di dalam konten Project yang tidak berlisensi atau berizin resmi dari pemegang hak;</p>':
            if (lg) {
                return '<p> vi. There is music, movies, software or other material in the Project content that is not licensed or authorized by the rights holder; </p> '
            } else {
                return '<p>vi. Terdapat musik, film, perangkat lunak atau materi lain di dalam konten Project yang tidak berlisensi atau berizin resmi dari pemegang hak;</p>'
            }
        case '<p>vii. Berkaitan dengan narkotika dan zat berbahaya lainnya;</p>':
            if (lg) {
                return '<p> vii. Regarding narcotics and other dangerous substances; </p> '
            } else {
                return '<p>vii. Berkaitan dengan narkotika dan zat berbahaya lainnya;</p>'
            }
        case '<p>viii.Berkaitan dengan perjudian, pertaruhan, togel, lotere atau undian;</p>':
            if (lg) {
                return '<p> viii.In connection with gambling, betting, lottery, lottery or lottery; </p>'
            } else {
                return '<p>viii.Berkaitan dengan perjudian, pertaruhan, togel, lotere atau undian;</p>'
            }
        case '<p>ix.  Kegiatan yang dapat merusak lingkungan dan habibat alam;</p>':
            if (lg) {
                return '<p> ix. Activities that can damage the environment and be natural; </p> '
            } else {
                return '<p>ix.  Kegiatan yang dapat merusak lingkungan dan habibat alam;</p>'
            }
        case '<p>x.   Mengumpulkan atau menggalang Dana untuk tujuan apapun selain seperti yang dijelaskan dalam deskripsi Project;</p>':
            if (lg) {
                return '<p> x. Collecting or raising funds for any purpose other than as described in the Project description; </p> '
            } else {
                return '<p>x.   Mengumpulkan atau menggalang Dana untuk tujuan apapun selain seperti yang dijelaskan dalam deskripsi Project;</p>'
            }
        case '<p>xi.  Konten atau aktivitas yang menurut Indonesia Green (IGE) tidak dapat diterima.</p><br />':
            if (lg) {
                return '<p> xi. Content or activities which according to Indonesia Green (IGE) cannot be accepted. </p> <br /> '
            } else {
                return '<p>xi.  Konten atau aktivitas yang menurut Indonesia Green (IGE) tidak dapat diterima.</p><br />'
            }
        case '<p>1.4 Dalam hal tujuan Project berbentuk proyek, maka Project Owner menyatakan bahwa proyek tersebut merupakan proyek yang tidak melanggar perundang-undangan yang berlaku; Menyatakan bahwa telah memperoleh izin tertentu, termasuk apabila bersumber dari pihak lain, sebagaimana diatur dalam peraturan perundang-undangan yang berlaku; Menyatakan bahwa proyek tersebut bukan bertujuan untuk komersial; Menyiapkan segala perencanaan yang sesuai, termasuk dengan standar kualitas dan keahlian sumber daya manusia yang sesuai dengan proyek yang diusulkan</p>':
            if (lg) {
                return '<p> 1.4 In the case that the objectives of the Project are in the form of a project, the Project Owner states that the project is a project that does not violate applicable laws; Stating that it has obtained certain permits, including when sourced from other parties, as stipulated in the applicable laws and regulations; States that the project is not for commercial purposes; Prepare all appropriate planning, including with quality standards and human resource expertise in accordance with the proposed project </p> '
            } else {
                return '<p>1.4 Dalam hal tujuan Project berbentuk proyek, maka Project Owner menyatakan bahwa proyek tersebut merupakan proyek yang tidak melanggar perundang-undangan yang berlaku; Menyatakan bahwa telah memperoleh izin tertentu, termasuk apabila bersumber dari pihak lain, sebagaimana diatur dalam peraturan perundang-undangan yang berlaku; Menyatakan bahwa proyek tersebut bukan bertujuan untuk komersial; Menyiapkan segala perencanaan yang sesuai, termasuk dengan standar kualitas dan keahlian sumber daya manusia yang sesuai dengan proyek yang diusulkan</p>'
            }
        case '<p>1.5 Indonesia Green (IGE) tidak bertanggung jawab atas penyalahgunaan, penggunaan tanpa izin, pelanggaran Hak Kekayaan Intelektual yang dilakukan oleh Project Owner;</p><br />':
            if (lg) {
                return '<p> 1.5 Indonesia Green (IGE) is not responsible for misuse, unauthorized use, violation of Intellectual Property Rights by Project Owner; </p> <br />'
            } else {
                return '<p>1.5 Indonesia Green (IGE) tidak bertanggung jawab atas penyalahgunaan, penggunaan tanpa izin, pelanggaran Hak Kekayaan Intelektual yang dilakukan oleh Project Owner;</p><br />'
            }
        case '<p>1.6 Project Owner dilarang mencantumkan nomor rekening pribadi atau lainnya dalam Project dengan alasan apapun, Indonesia Green (IGE) berhak untuk menghapus nomor rekening tersebut apabila ketentuan ini dilanggar;</p><br />':
            if (lg) {
                return '<p> 1.6 Project Owners are prohibited from including personal or other account numbers in the Project for any reason, Indonesia Green (IGE) has the right to delete the account number if this provision is violated; </p> <br />'
            } else {
                return '<p>1.6 Project Owner dilarang mencantumkan nomor rekening pribadi atau lainnya dalam Project dengan alasan apapun, Indonesia Green (IGE) berhak untuk menghapus nomor rekening tersebut apabila ketentuan ini dilanggar;</p><br />'
            }
        case '<p>1.7 Indonesia Green (IGE) berwenang melakukan sensor/menghapus gambar yang kami anggap mengganggu atau tidak pantas untuk diperlihatkan kepada publik;</p><br />':
            if (lg) {
                return '<p> 1.7 Indonesia Green (IGE) has the authority to censor / delete images that we consider disturbing or inappropriate for public display; </p> <br />'
            } else {
                return '<p>1.7 Indonesia Green (IGE) berwenang melakukan sensor/menghapus gambar yang kami anggap mengganggu atau tidak pantas untuk diperlihatkan kepada publik;</p><br />'
            }
        case '<p>1.8 Donasi yang diterima dapat dicairkan setelah akun dan rekening bank terverifikasi dalam waktu paling cepat 3x24 jam sejak donasi diterima, apabila ada keterlambatan, silakan hubungi support@indonesiagreen.com;</p><br />':
            if (lg) {
                return '<p> 1.8 Donations received can be disbursed after the account and bank account are verified in the fastest time 3x24 hours after the donation is received, if there is a delay, please contact support@indonesiagreen.com; </p> <br />'
            } else {
                return '<p>1.8 Donasi yang diterima dapat dicairkan setelah akun dan rekening bank terverifikasi dalam waktu paling cepat 3x24 jam sejak donasi diterima, apabila ada keterlambatan, silakan hubungi support@indonesiagreen.com;</p><br />'
            }
        case '<p>1.9 Setiap Dana yang masuk ke rekening merupakan donasi bersih yang telah dipotong biaya administrasi;</p><br />':
            if (lg) {
                return '<p> 1.9 Every Fund that enters the account is a net donation which has been deducted by administrative costs; </p> <br />'
            } else {
                return '<p>1.9 Setiap Dana yang masuk ke rekening merupakan donasi bersih yang telah dipotong biaya administrasi;</p><br />'
            }
        case '<p>2.0 Terkhusus untuk donatur yang melakukan donasi menggunakan Credit Card, nominal donasi akan terpotong oleh biaya administrasi dan biaya potongan dari Credit Card secara otomatis;</p><br />':
            if (lg) {
                return '<p> 2.0 Specifically for donors who make donations using Credit Cards, the nominal donations will be deducted by administrative fees and deduction fees from Credit Cards automatically; </p> <br />'
            } else {
                return '<p>2.0 Terkhusus untuk donatur yang melakukan donasi menggunakan Credit Card, nominal donasi akan terpotong oleh biaya administrasi dan biaya potongan dari Credit Card secara otomatis;</p><br />'
            }
        case '<p>2.1 Donasi yang terdapat pada Project Owner dapat dicairkan dengan klik “Pencairan Dana” pada menu yang terdapat di dashboard;</p><br />':
            if (lg) {
                return '<p> 2.1 Donations made to the Project Owner can be disbursed by clicking on "Disbursement of Funds" on the menu found on the dashboard; </p> <br />'
            } else {
                return '<p>2.1 Donasi yang terdapat pada Project Owner dapat dicairkan dengan klik “Pencairan Dana” pada menu yang terdapat di dashboard;</p><br />'
            }
        case '<p>2.2 Project Owner bertanggung jawab atas apa yang telah ditulis dan dijanjikan pada halaman Project;</p><br />':
            if (lg) {
                return '<p> 2.2 The Project Owner is responsible for what has been written and promised on the Project page; </p> <br />'
            } else {
                return '<p>2.2 Project Owner bertanggung jawab atas apa yang telah ditulis dan dijanjikan pada halaman Project;</p><br />'
            }
        case '<p>2.3 Setiap proses pencairan Dana akan dikenakan biaya administrasi dari mitra pencairan (disbursement) Indonesia Green (IGE). Detail biaya tersebut dapat dilihat dengan klik di sini;</p><br />':
            if (lg) {
                return '<p> 2.3 Each fund disbursement process will be subject to administrative costs from the disbursement partner of Indonesia Green (IGE). Details of these costs can be seen by clicking here; </p> <br /> '
            } else {
                return '<p>2.3 Setiap proses pencairan Dana akan dikenakan biaya administrasi dari mitra pencairan (disbursement) Indonesia Green (IGE). Detail biaya tersebut dapat dilihat dengan klik di sini;</p><br />'
            }
        case '<p>2.4 Bahwa apabila terdapat pajak dan/atau retribusi dan/atau pungutan legal lain terhadap donasi yang diberikan, maka Indonesia Green (IGE) dapat menetapkan biaya tambahan pada setiap Project sesuai dengan besaran yang berlaku;</p><br />':
            if (lg) {
                return '<p> 2.4 That if there are taxes and / or levies and / or other legal levies on donations given, then Indonesia Green (IGE) can set additional costs on each Project in accordance with the applicable amount; </p> <br / > '
            } else {
                return '<p>2.4 Bahwa apabila terdapat pajak dan/atau retribusi dan/atau pungutan legal lain terhadap donasi yang diberikan, maka Indonesia Green (IGE) dapat menetapkan biaya tambahan pada setiap Project sesuai dengan besaran yang berlaku;</p><br />'
            }
        case '<p>2.5 Project Owner berkewajiban untuk memenuhi imbalan yang telah dijanjikan kepada donatur;</p><br />':
            if (lg) {
                return '<p> 2.5 Project Owners are obliged to fulfill the promised benefits to donors; </p> <br />'
            } else {
                return '<p>2.5 Project Owner berkewajiban untuk memenuhi imbalan yang telah dijanjikan kepada donatur;</p><br />'
            }
        case '<p>2.6 Project Owner berkewajiban untuk memberikan laporan pelaksanaan atau perkembangan Project secara transparan melalui fitur “Update” pada situs Indonesia Green (IGE) untuk diketahui oleh publik dan donatur pada setiap halaman Project yang dibuat;</p><br />':
            if (lg) {
                return '<p> 2.6 The Project Owner is obliged to provide a transparent report on the implementation or progress of the Project through the "Update" feature on the Indonesia Green (IGE) site to be known to the public and donors on every page of the Project created; </p> <br /> '
            } else {
                return '<p>2.6 Project Owner berkewajiban untuk memberikan laporan pelaksanaan atau perkembangan Project secara transparan melalui fitur “Update” pada situs Indonesia Green (IGE) untuk diketahui oleh publik dan donatur pada setiap halaman Project yang dibuat;</p><br />'
            }
        case '<p>2.7 Apabila anda sebagai Project Owner tidak/belum melakukan update pada halaman Project setelah melakukan pencairan pertama, maka kami berhak untuk menahan pencairan Anda sampai klausul (kesepakatan) dari kami terpenuhi;</p><br />':
            if (lg) {
                return '<p> 2.7 If you as a Project Owner do not / have not updated the Project page after the first disbursement, we have the right to hold your disbursement until our clause (agreement) has been fulfilled; </p> <br />'
            } else {
                return '<p>2.7 Apabila anda sebagai Project Owner tidak/belum melakukan update pada halaman Project setelah melakukan pencairan pertama, maka kami berhak untuk menahan pencairan Anda sampai klausul (kesepakatan) dari kami terpenuhi;</p><br />'
            }
        case '<p>2.8 Project Owner wajib memberikan keterangan atau laporan melalui situs Indonesia Green (IGE) apabila pelaksanaan Project tidak sesuai dengan rencana dan/atau apabila terdapat reward/imbalan yang tidak dapat dipenuhi kepada Donatur;</p><br />':
            if (lg) {
                return '<p> 2.8 The Project Owner is obliged to provide information or reports through the Indonesia Green (IGE) website if the Project implementation is not in accordance with the plan and / or if there is a reward that cannot be fulfilled to the Donors; </p> <br />'
            } else {
                return '<p>2.8 Project Owner wajib memberikan keterangan atau laporan melalui situs Indonesia Green (IGE) apabila pelaksanaan Project tidak sesuai dengan rencana dan/atau apabila terdapat reward/imbalan yang tidak dapat dipenuhi kepada Donatur;</p><br />'
            }
        case '<p>2.9 Apabila terdapat perjanjian kerjasama khusus antara Project Owner dengan Indonesia Green (IGE), maka kedua belah pihak wajib menjalankan kewajibannya sebagaimana yang telah tertera pada perjanjian kerjasama tersebut;</p><br />':
            if (lg) {
                return '<p> 2.9 If there is a special collaboration agreement between the Project Owner and Indonesia Green (IGE), then both parties must carry out their obligations as stated in the cooperation agreement; </p> <br />'
            } else {
                return '<p>2.9 Apabila terdapat perjanjian kerjasama khusus antara Project Owner dengan Indonesia Green (IGE), maka kedua belah pihak wajib menjalankan kewajibannya sebagaimana yang telah tertera pada perjanjian kerjasama tersebut;</p><br />'
            }
        case '<p>3.0 Project Owner bertanggung jawab sepenuhnya terhadap pelaksanaan Project, penggunaan Dana donasi, dan hal-hal lain yang terkait dengan Project miliknya. Oleh karena itu, Project Owner menyatakan dan bersedia mengganti segala kerugian yang dialami oleh Indonesia Green (IGE) apabila hal tersebut terjadi di kemudian hari, termasuk membebaskan Indonesia Green (IGE) dari setiap tuntutan hukum yang timbul pada permasalahan yang disebabkan dan diakibatkan oleh Project Owner.</p><br />':
            if (lg) {
                return '<p> 3.0 The Project Owner is fully responsible for the implementation of the Project, the use of donations, and other matters related to his Project. Therefore, the Project Owner states and is willing to compensate for all losses incurred by Indonesia Green (IGE) if it occurs in the future, including freeing Indonesia Green (IGE) from any lawsuits arising from problems caused and caused by the Project Owner . </p> <br /> '
            } else {
                return '<p>3.0 Project Owner bertanggung jawab sepenuhnya terhadap pelaksanaan Project, penggunaan Dana donasi, dan hal-hal lain yang terkait dengan Project miliknya. Oleh karena itu, Project Owner menyatakan dan bersedia mengganti segala kerugian yang dialami oleh Indonesia Green (IGE) apabila hal tersebut terjadi di kemudian hari, termasuk membebaskan Indonesia Green (IGE) dari setiap tuntutan hukum yang timbul pada permasalahan yang disebabkan dan diakibatkan oleh Project Owner.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Verifikasi Akun Project Owner</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Verify the Project Owner Account </p> <br />'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Verifikasi Akun Project Owner</p><br />'
            }
        case '<p>Setiap Project Owner harus melakukan verifikasi akun untuk melakukan pencairan Dana. Verifikasi akun perlu dilakukan agar kami memiliki data terkait orang/lembaga yang bertanggung jawab atas sebuah Project.  Proses verifikasi akun Project Owner akan kami selesaikan dalam kurun waktu maksimal 2 hari kerja. Proses verifikasi akun hanya dapat dilakukan secara online melalui situs Indonesia Green (IGE).</p>':
            if (lg) {
                return '<p> Each Project Owner must verify their account in order to withdraw funds. Account verification needs to be done so that we have data regarding the person / institution responsible for a Project. We will complete the Project Owner account verification process within a maximum of 2 working days. The account verification process can only be done online through the Indonesia Green (IGE) website. </p> '
            } else {
                return '<p>Setiap Project Owner harus melakukan verifikasi akun untuk melakukan pencairan Dana. Verifikasi akun perlu dilakukan agar kami memiliki data terkait orang/lembaga yang bertanggung jawab atas sebuah Project.  Proses verifikasi akun Project Owner akan kami selesaikan dalam kurun waktu maksimal 2 hari kerja. Proses verifikasi akun hanya dapat dilakukan secara online melalui situs Indonesia Green (IGE).</p>'
            }
        case '<p>Pada saat proses verifikasi akun, kami akan meminta beberapa data dan berkas sesuai dengan akun Project Owner, apakah Organisasi/Yayasan. Adapun data dan berkas yang diminta saat proses verifikasi adalah sebagai berikut:</p><br />':
            if (lg) {
                return '<p> During the account verification process, we will ask for some data and files according to the Project Owner account, whether Organization / Foundation. The data and files requested during the verification process are as follows: </p> <br /> '
            } else {
                return '<p>Pada saat proses verifikasi akun, kami akan meminta beberapa data dan berkas sesuai dengan akun Project Owner, apakah Organisasi/Yayasan. Adapun data dan berkas yang diminta saat proses verifikasi adalah sebagai berikut:</p><br />'
            }
        case '<p>a. Akun Organisasi/Yayasan : Scan NPWP atas nama Organisasi/Yayasan, Akta pendirian, kartu identitas pemegang akun, nomor rekening atas nama Organisasi/Yayasan, nomor telepon pemegang akun dan media sosial. Nomor rekening dan NPWP yang dilampirkan harus atas nama yayasan. KTP perseorangan yang mewakilkan berbadan hukum tersebut yang terdaftar di dalam platform. Apabila diperlukan, Indonesia Green (IGE) berhak melakukan proses verifikasi lebih lanjut berupa permintaan berkas pendukung tambahan, wawancara online, kunjungan, dan bentuk verifikasi lain sesuai dengan jenis dan kategori Project. Kami berhak menolak verifikasi akun Anda apabila belum memenuhi standar yang telah ditetapkan Indonesia Green (IGE). </p>':
            if (lg) {
                return '<p> a. Organization / Foundation Account: Scan of NPWP on behalf of the Organization / Foundation, Deed of establishment, account holder\'s identity card, account number on behalf of the Organization / Foundation, account holder\'s telephone number and social media. The attached account number and NPWP must be in the name of the foundation. Individual KTPs representing legal entities registered on the platform. If necessary, Indonesia Green (IGE) has the right to carry out further verification processes in the form of requests for additional supporting documents, online interviews, visits and other forms of verification according to the type and category of the Project. We have the right to refuse verification of your account if it does not meet the standards set by Indonesia Green (IGE). </p> '
            } else {
                return '<p>a. Akun Organisasi/Yayasan : Scan NPWP atas nama Organisasi/Yayasan, Akta pendirian, kartu identitas pemegang akun, nomor rekening atas nama Organisasi/Yayasan, nomor telepon pemegang akun dan media sosial. Nomor rekening dan NPWP yang dilampirkan harus atas nama yayasan. KTP perseorangan yang mewakilkan berbadan hukum tersebut yang terdaftar di dalam platform. Apabila diperlukan, Indonesia Green (IGE) berhak melakukan proses verifikasi lebih lanjut berupa permintaan berkas pendukung tambahan, wawancara online, kunjungan, dan bentuk verifikasi lain sesuai dengan jenis dan kategori Project. Kami berhak menolak verifikasi akun Anda apabila belum memenuhi standar yang telah ditetapkan Indonesia Green (IGE). </p>'
            }
        case '<p><a style="font-weight: bold;">Indonesia Green (IGE) berhak melakukan investigasi terhadap sebuah atau beberapa Project yang dianggap patut untuk ditelusuri lebih lanjut. Maka dari itu, Anda setuju untuk bekerjasama dengan baik serta memberikan informasi dengan benar dan sedetail mungkin.</a></p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> Indonesia Green (IGE) has the right to conduct an investigation of one or several Projects that are deemed worth exploring further. Therefore, you agree to cooperate properly and provide information as true and as detailed as possible. </a> </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">Indonesia Green (IGE) berhak melakukan investigasi terhadap sebuah atau beberapa Project yang dianggap patut untuk ditelusuri lebih lanjut. Maka dari itu, Anda setuju untuk bekerjasama dengan baik serta memberikan informasi dengan benar dan sedetail mungkin.</a></p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">A. Pencairan Dana</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> A. Disbursement of Funds </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">A. Pencairan Dana</p><br />'
            }
        case '<p>Anda sebagai Project Owner dapat melakukan pencairan Dana terhadap donasi telah terkumpul pada setiap Project anda kapanpun setelah akun dan rekening Anda terverifikasi. </p>':
            if (lg) {
                return '<p> You as a Project Owner can withdraw funds from donations that have been collected in each of your Projects at any time after your account and account have been verified. </p> '
            } else {
                return '<p>Anda sebagai Project Owner dapat melakukan pencairan Dana terhadap donasi telah terkumpul pada setiap Project anda kapanpun setelah akun dan rekening Anda terverifikasi. </p>'
            }
        case '<p>Pada proses verifikasi rekening Anda cukup melakukannya sekali untuk setiap halaman Project. Namun, hal ini mengakibatkan Anda harus melakukan verifikasi rekening di setiap Project yang Anda buat, apabila Anda memiliki beberapa Project dalam satu akun.</p>':
            if (lg) {
                return '<p> In the account verification process, you only have to do it once for each Project page. However, this results in you having to verify accounts in each Project that you create, if you have multiple Projects in one account. </p> '
            } else {
                return '<p>Pada proses verifikasi rekening Anda cukup melakukannya sekali untuk setiap halaman Project. Namun, hal ini mengakibatkan Anda harus melakukan verifikasi rekening di setiap Project yang Anda buat, apabila Anda memiliki beberapa Project dalam satu akun.</p>'
            }
        case '<p>Adapun syarat untuk melakukan verifikasi rekening, yaitu: </p><br />':
            if (lg) {
                return '<p> The requirements for verifying accounts, namely: </p> <br />'
            } else {
                return '<p>Adapun syarat untuk melakukan verifikasi rekening, yaitu: </p><br />'
            }
        case '<p>1.1 Sudah melakukan verifikasi Akun;</p>':
            if (lg) {
                return '<p> 1.1 Verified Account; </p>'
            } else {
                return '<p>1.1 Sudah melakukan verifikasi Akun;</p>'
            }
        case '<p>1.2 Project sudah memiliki minimal satu donatur.</p><br />':
            if (lg) {
                return '<p> 1.2 Project already has at least one donors. </p> <br />'
            } else {
                return '<p>1.2 Project sudah memiliki minimal satu donatur.</p><br />'
            }
        case '<p>Anda dapat memilih ke mana uang donasi akan dicairkan apabila memenuhi kriteria yang telah kami tentukan, yaitu:</p><br />':
            if (lg) {
                return '<p> You can choose where the donation money will be disbursed if it meets the criteria we have set, which are: </p> <br />'
            } else {
                return '<p>Anda dapat memilih ke mana uang donasi akan dicairkan apabila memenuhi kriteria yang telah kami tentukan, yaitu:</p><br />'
            }
        case '<p>i.   Rekening Project Owner sendiri;</p>':
            if (lg) {
                return '<p> i. Own Project Owner Account; </p> '
            } else {
                return '<p>i.   Rekening Project Owner sendiri;</p>'
            }
        case '<p>ii.  Rekening pihak ketiga. Yang dimaksud dengan pihak ketiga yaitu beneficiary, keluarga beneficiary, atau yayasan penyalur donasi. Pada ketentuan ini, Project Owner harus menyertakan alasan mengalihkan pencairan ke pihak ketiga dan melampirkan scan identitas atas nama yang bersangkutan. Khusus pencairan ke Organisasi/Yayasan,  Project Owner perlu menambahkan lampiran scan NPWP atas nama Organisasi/Yayasan yang bersangkutan.</p>':
            if (lg) {
                return '<p> ii. Third party account. What is meant by a third party is a beneficiary, beneficiary family, or foundation channeling donations. Under this provision, the Project Owner must include the reason for transferring the disbursement to a third party and attach an identity scan on behalf of the person concerned. Specifically for disbursement to the Organization / Foundation, the Project Owner needs to add an NPWP scan attachment on behalf of the Organization / Foundation concerned. </p> '
            } else {
                return '<p>ii.  Rekening pihak ketiga. Yang dimaksud dengan pihak ketiga yaitu beneficiary, keluarga beneficiary, atau yayasan penyalur donasi. Pada ketentuan ini, Project Owner harus menyertakan alasan mengalihkan pencairan ke pihak ketiga dan melampirkan scan identitas atas nama yang bersangkutan. Khusus pencairan ke Organisasi/Yayasan,  Project Owner perlu menambahkan lampiran scan NPWP atas nama Organisasi/Yayasan yang bersangkutan.</p>'
            }
        case '<p>iii. Rekening mitra penyalur Indonesia Green (IGE). Anda cukup menghubungi kami untuk memproses pilihan ini.  </p><br />':
            if (lg) {
                return '<p> iii. Indonesia Green (IGE) distributor partner account. You simply contact us to process this choice. </p> <br /> '
            } else {
                return '<p>iii. Rekening mitra penyalur Indonesia Green (IGE). Anda cukup menghubungi kami untuk memproses pilihan ini.  </p><br />'
            }
        case '<p>Dana donasi yang terdapat dalam Project dapat dialihkan sewaktu-sewaktu kepada pihak lain dengan melibatkan kesepakatan antara Project Owner dan Indonesia Green (IGE), hal tersebut dapat terjadi apabila:</p>':
            if (lg) {
                return '<p> Donation funds contained in the Project can be transferred at any time to other parties by involving agreements between the Project Owner and Indonesia Green (IGE), this can occur if: </p>'
            } else {
                return '<p>Dana donasi yang terdapat dalam Project dapat dialihkan sewaktu-sewaktu kepada pihak lain dengan melibatkan kesepakatan antara Project Owner dan Indonesia Green (IGE), hal tersebut dapat terjadi apabila:</p>'
            }
        case '<p>i.  Beneficiary yang hendak mendapatkan manfaat atau bantuan dari Project telah meninggal dunia;</p>':
            if (lg) {
                return '<p> i. Beneficiary who wants to get benefits or assistance from the Project has passed away; </p> '
            } else {
                return '<p>i.  Beneficiary yang hendak mendapatkan manfaat atau bantuan dari Project telah meninggal dunia;</p>'
            }
        case '<p>ii.  Beneficiary yang hendak mendapatkan manfaat atau menolak mendapatkan santunan;</p>':
            if (lg) {
                return '<p> ii. Beneficiary who wants to get benefits or refuses to get compensation; </p> '
            } else {
                return '<p>ii.  Beneficiary yang hendak mendapatkan manfaat atau menolak mendapatkan santunan;</p>'
            }
        case '<p>iii. Dana donasi melebihi target yang direncanakan, sebagian Dana donasi yang lebih tersebut dapat diberikan kepada pihak lain;</p>':
            if (lg) {
                return '<p> iii. Donation funds exceed the planned target, some of the excess donation funds can be given to other parties; </p> '
            } else {
                return '<p>iii. Dana donasi melebihi target yang direncanakan, sebagian Dana donasi yang lebih tersebut dapat diberikan kepada pihak lain;</p>'
            }
        case '<p>iv. Project Owner terbukti menggunakan sebagian atau seluruh Dana donasi tanpa persetujuan beneficiary yang bersangkutan;</p>':
            if (lg) {
                return '<p> iv. Project Owner is proven to have used part or all of the donation Fund without the consent of the beneficiary concerned; </p> '
            } else {
                return '<p>iv. Project Owner terbukti menggunakan sebagian atau seluruh Dana donasi tanpa persetujuan beneficiary yang bersangkutan;</p>'
            }
        case '<p>v.  Status Project Owner sedang menjalani proses hukum dengan pihak yang berwajib;</p>':
            if (lg) {
                return '<p> v. Project Owner status is undergoing legal process with the authorities; </p> '
            } else {
                return '<p>v.  Status Project Owner sedang menjalani proses hukum dengan pihak yang berwajib;</p>'
            }
        case '<p>vi. Kondisi tertentu yang menurut Indonesia Green (IGE) diperlukan pengalihan Dana donasi.</p><br />':
            if (lg) {
                return '<p> vi. Certain conditions which according to Indonesia Green (IGE) are required to divert donation funds. </p> <br /> '
            } else {
                return '<p>vi. Kondisi tertentu yang menurut Indonesia Green (IGE) diperlukan pengalihan Dana donasi.</p><br />'
            }
        case '<p><a style="font-weight: bold;">Peringatan:</a> Indonesia Green (IGE) berhak menolak verifikasi rekening, menyarankan pilihan verifikasi rekening dan mengubah secara sepihak pilihan rekening yang sudah terverifikasi.</p>':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> Warning: </a> Indonesia Green (IGE) has the right to refuse account verification, suggest account verification options and change unilaterally account choices that have already been verified. </ p > '
            } else {
                return '<p><a style="font-weight: bold;">Peringatan:</a> Indonesia Green (IGE) berhak menolak verifikasi rekening, menyarankan pilihan verifikasi rekening dan mengubah secara sepihak pilihan rekening yang sudah terverifikasi.</p>'
            }
        case '<p>Penolakan, saran, atau perubahan verifikasi rekening menjadi hak prerogatif Indonesia Green (IGE). Perlu kami sampaikan kembali bahwa Anda sebagai Project Owner bertanggung jawab atas amanat yang Anda sampaikan sendiri sesuai dengan yang tertera pada konten Project.</p>':
            if (lg) {
                return '<p> Rejection, suggestion, or change in account verification becomes the prerogative of Indonesia Green (IGE). We need to reiterate that you as a Project Owner are responsible for the mandate that you convey yourself in accordance with what is stated in the Project content. </p> '
            } else {
                return '<p>Penolakan, saran, atau perubahan verifikasi rekening menjadi hak prerogatif Indonesia Green (IGE). Perlu kami sampaikan kembali bahwa Anda sebagai Project Owner bertanggung jawab atas amanat yang Anda sampaikan sendiri sesuai dengan yang tertera pada konten Project.</p>'
            }
        case '<p>Setelah donasi kami salurkan kepada pihak Project Owner, Anda wajib memberikan laporan penggunaan Dana yang nantinya akan terlihat pada fitur update di situs kami. Kami tidak bertanggung jawab atas segala hal yang terjadi terhadap uang donasi yang telah dicairkan kepada Project Owner. </p><br />':
            if (lg) {
                return '<p> After we give the donation to the Project Owner, you are required to provide a report on the use of the Fund which will later be seen in the update feature on our site. We are not responsible for everything that happens to donations that have been disbursed to the Project Owner. </p> <br /> '
            } else {
                return '<p>Setelah donasi kami salurkan kepada pihak Project Owner, Anda wajib memberikan laporan penggunaan Dana yang nantinya akan terlihat pada fitur update di situs kami. Kami tidak bertanggung jawab atas segala hal yang terjadi terhadap uang donasi yang telah dicairkan kepada Project Owner. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">B. Kewenangan Indonesia Green (IGE) terhadap Project Owner</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> B. The Authority of Indonesia Green (IGE) to Project Owner </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">B. Kewenangan Indonesia Green (IGE) terhadap Project Owner</p><br />'
            }
        case '<p>1.1 Indonesia Green (IGE) berhak untuk melakukan edit/hapus deskripsi Project dan deskripsi update apabila pihak Project Owner:</p>':
            if (lg) {
                return '<p> 1.1 Indonesia Green (IGE) has the right to edit / delete the Project description and description update if the Project Owner: </p>'
            } else {
                return '<p>1.1 Indonesia Green (IGE) berhak untuk melakukan edit/hapus deskripsi Project dan deskripsi update apabila pihak Project Owner:</p>'
            }
        case '<p>i.   Mencantumkan no rekening selain daripada atas nama YAYASAN INDONESIA GREEN (IGE);</p>':
            if (lg) {
                return '<p> i. Include account number other than on behalf of INDONESIA GREEN FOUNDATION (IGE); </p> '
            } else {
                return '<p>i.   Mencantumkan no rekening selain daripada atas nama YAYASAN INDONESIA GREEN (IGE);</p>'
            }
        case '<p>ii.  Terbukti melakukan pencatutan nama pihak ke 3 tanpa seizin dan sepengetahuan yang bersangkutan baik disadari oleh kami maupun laporan;</p>':
            if (lg) {
                return '<p> ii. Proven to carry out profiteering the name of a third party without the permission and knowledge of the concerned concerned both realized by us and the report; </p> '
            } else {
                return '<p>ii.  Terbukti melakukan pencatutan nama pihak ke 3 tanpa seizin dan sepengetahuan yang bersangkutan baik disadari oleh kami maupun laporan;</p>'
            }
        case '<p>iii.  Menurut kami mengandung unsur hate speech kepada pihak ke 3 maupun kepada Indonesia Green (IGE);</p><br />':
            if (lg) {
                return '<p> iii. In our opinion it contains elements of hate speech to third parties as well as to Indonesia Green (IGE); </p> <br /> '
            } else {
                return '<p>iii.  Menurut kami mengandung unsur hate speech kepada pihak ke 3 maupun kepada Indonesia Green (IGE);</p><br />'
            }
        case '<p>1.2 Indonesia Green (IGE) berhak untuk mengalihkan pencairan donasi milik akun Project Owner secara sepihak apabila Project Owner:</p>':
            if (lg) {
                return '<p> 1.2 Indonesia Green (IGE) has the right to transfer the donation from Project Owner account unilaterally if Project Owner: </p>'
            } else {
                return '<p>1.2 Indonesia Green (IGE) berhak untuk mengalihkan pencairan donasi milik akun Project Owner secara sepihak apabila Project Owner:</p>'
            }
        case '<p>i. Terbukti melakukan penipuan disertai bukti bukti yang kami dapatkan melalui investigasi maupun laporan dari publik;</p>':
            if (lg) {
                return '<p> i. Proven to commit fraud accompanied by evidence we have obtained through investigations and reports from the public; </p> '
            } else {
                return '<p>i. Terbukti melakukan penipuan disertai bukti bukti yang kami dapatkan melalui investigasi maupun laporan dari publik;</p>'
            }
        case '<p>ii. Permintaan Project Owner sendiri;</p>':
            if (lg) {
                return '<p> ii. Request for Project Owner himself; </p> '
            } else {
                return '<p>ii. Permintaan Project Owner sendiri;</p>'
            }
        case '<p>iii. Project Owner telah meninggal dunia.</p><br />':
            if (lg) {
                return '<p> iii. Project Owner has passed away. </p> <br /> '
            } else {
                return '<p>iii. Project Owner telah meninggal dunia.</p><br />'
            }
        case '<p>1.3 Indonesia Green (IGE) berhak menolak/menunda verifikasi akun atau membekukan akun dan pencairan donasi apabila pihak Project Owner:</p>':
            if (lg) {
                return '<p> 1.3 Indonesia Green (IGE) has the right to refuse / postpone account verification or freeze the account and withdraw funds if the Project Owner: </p>'
            } else {
                return '<p>1.3 Indonesia Green (IGE) berhak menolak/menunda verifikasi akun atau membekukan akun dan pencairan donasi apabila pihak Project Owner:</p>'
            }
        case '<p>i.  Belum mengirimkan data-data yang diperlukan oleh indonesiagreen.com;</p>':
            if (lg) {
                return '<p> i. Not sending the data required by indonesiagreen.com; </p> '
            } else {
                return '<p>i.  Belum mengirimkan data-data yang diperlukan oleh indonesiagreen.com;</p>'
            }
        case '<p>ii. Apabila dana yang terkumpul harus diberikan secara langsung kepada beneficiary sesuai dengan ketentuan yang berlaku;</p>':
            if (lg) {
                return '<p> ii. If the funds collected must be given directly to the beneficiary in accordance with applicable regulations; </p> '
            } else {
                return '<p>ii. Apabila dana yang terkumpul harus diberikan secara langsung kepada beneficiary sesuai dengan ketentuan yang berlaku;</p>'
            }
        case '<p>iii. Apabila kami memiliki alasan dan bukti bahwa Project Owner melanggar ketentuan layanan pada situs kami;</p>':
            if (lg) {
                return '<p> iii. If we have reasons and evidence that Project Owner violates the terms of service on our site; </p> '
            } else {
                return '<p>iii. Apabila kami memiliki alasan dan bukti bahwa Project Owner melanggar ketentuan layanan pada situs kami;</p>'
            }
        case '<p>iv. Apabila diperlukan dalam rangka untuk memenuhi perintah pengadilan, surat perintah, keputusan pemerintah atau ketentuan hukum yang berlaku di Indonesia.</p><br />':
            if (lg) {
                return '<p> iv. If necessary in order to fulfill court orders, warrants, government decisions or legal provisions in force in Indonesia. </p> <br /> '
            } else {
                return '<p>iv. Apabila diperlukan dalam rangka untuk memenuhi perintah pengadilan, surat perintah, keputusan pemerintah atau ketentuan hukum yang berlaku di Indonesia.</p><br />'
            }
        case '<p>1.4 Indonesia Green (IGE) berhak untuk melakukan verifikasi, analisa, investigasi, dan evaluasi terhadap proyek yang sedang dijalankan oleh project owner melalui atau tanpa Project Management Team (PMT).</p><br />':
            if (lg) {
                return '<p> 1.4 Indonesia Green (IGE) has the right to verify, analyze, investigate and evaluate projects that are being carried out by the project owner through or without the Project Management Team (PMT). </p> <br />'
            } else {
                return '<p>1.4 Indonesia Green (IGE) berhak untuk melakukan verifikasi, analisa, investigasi, dan evaluasi terhadap proyek yang sedang dijalankan oleh project owner melalui atau tanpa Project Management Team (PMT).</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Donatur</p>':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Donors </p>'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Donatur</p>'
            }
        case '<p>Semua sumbangan dari donatur merupakan risiko dari donatur itu sendiri. Situs kami tidak menjamin bahwa setiap Project yang berada dalam situs kami bebas dari penipuan dan penyalahgunaan. Apabila di kemudian hari Anda meyakini terdapat konten penipuan dan penyalahgunaan dana atau kecurigaan lainnya pada Project di situs kami, Anda bisa melaporkan hal tersebut dengan cara ‘klik’ tombol laporkan yang tersedia pada setiap Project.</p><br />':
            if (lg) {
                return '<p> All donations from donors are at the risk of the donors themselves. Our site does not guarantee that every Project on our site is free from fraud and abuse. If you later believe that there is fraudulent and misuse of funds or other suspicious content on the Project on our site, you can report it by clicking the report button available on each Project. </p> <br /> '
            } else {
                return '<p>Semua sumbangan dari donatur merupakan risiko dari donatur itu sendiri. Situs kami tidak menjamin bahwa setiap Project yang berada dalam situs kami bebas dari penipuan dan penyalahgunaan. Apabila di kemudian hari Anda meyakini terdapat konten penipuan dan penyalahgunaan dana atau kecurigaan lainnya pada Project di situs kami, Anda bisa melaporkan hal tersebut dengan cara ‘klik’ tombol laporkan yang tersedia pada setiap Project.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">A. Kewajiban menjadi Donatur </p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> A. Obligation to be a Donors </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">A. Kewajiban menjadi Donatur </p><br />'
            }
        case '<p>Donatur wajib: </p><br />':
            if (lg) {
                return '<p> Donors required: </p> <br />'
            } else {
                return '<p>Donatur wajib: </p><br />'
            }
        case '<p>1.1 Bertanggung jawab penuh atas donasi yang diberikan, untuk itu donatur wajib mencermati segala informasi mengenai ide dan/atau Project penggalangan Dana yang dimuat di dalam situs sebelum memberikan dukungan;</p><br />':
            if (lg) {
                return '<p> 1.1 Fully responsible for donations, donors must pay close attention to all information regarding Fundraising ideas and / or projects posted on the site before providing support; </p> <br />'
            } else {
                return '<p>1.1 Bertanggung jawab penuh atas donasi yang diberikan, untuk itu donatur wajib mencermati segala informasi mengenai ide dan/atau Project penggalangan Dana yang dimuat di dalam situs sebelum memberikan dukungan;</p><br />'
            }
        case '<p>1.2 Tidak memberikan informasi tambahan yang palsu dan/atau menyesatkan atas segala halaman, tautan, dan berbagai bentuk media lainnya dari Project suatu penggalangan Dana di indonesiagreen.com;</p><br />':
            if (lg) {
                return '<p> 1.2 Do not provide false and / or misleading additional information on all pages, links, and various other forms of media from the Project for a Fundraiser on indonesiagreen.com; </p> <br />'
            } else {
                return '<p>1.2 Tidak memberikan informasi tambahan yang palsu dan/atau menyesatkan atas segala halaman, tautan, dan berbagai bentuk media lainnya dari Project suatu penggalangan Dana di indonesiagreen.com;</p><br />'
            }
        case '<p>1.3 Bertanggung jawab atas donasi yang telah disalurkan dan menyadari konsekuensi bahwa Project Owner berkemungkinan tidak dapat melaksanakan secara sebagian atau sepenuhnya atas Project dan/atau reward/imbalan yang telah dijanjikan sebelumnya pada Project tersebut;</p><br />':
            if (lg) {
                return '<p> 1.3 Responsible for donations that have been distributed and aware of the consequences that the Project Owner may not be able to carry out in part or in full on the Project and / or rewards that have been promised previously to the Project; </p> <br /> '
            } else {
                return '<p>1.3 Bertanggung jawab atas donasi yang telah disalurkan dan menyadari konsekuensi bahwa Project Owner berkemungkinan tidak dapat melaksanakan secara sebagian atau sepenuhnya atas Project dan/atau reward/imbalan yang telah dijanjikan sebelumnya pada Project tersebut;</p><br />'
            }
        case '<p>1.4 Donatur menyadari bahwa Indonesia Green (IGE) hanya bertanggung jawab sebatas penyaluran donasi kepada Project Owner;</p><br />':
            if (lg) {
                return '<p> 1.4 Donors realize that Indonesia Green (IGE) is only responsible for the distribution of donations to the Project Owner; </p> <br />'
            } else {
                return '<p>1.4 Donatur menyadari bahwa Indonesia Green (IGE) hanya bertanggung jawab sebatas penyaluran donasi kepada Project Owner;</p><br />'
            }
        case '<p>1.5 Donatur wajib mencermati setiap kali hendak melakukan donasi terhadap Project, kami tidak menyarankan donatur untuk berdonasi kepada suatu Project yang mengandung unsur ketidakjelasan, kebohongan, mencurigakan, penipuan dan kegiatan yang dilarang oleh negara. Indonesia Green (IGE) tidak bertanggung jawab atas kelalaian donatur atas hal yang sudah kami peringatkan di atas;</p><br />':
            if (lg) {
                return '<p> 1.5 Donors must pay close attention every time they want to make a donation to the Project, we do not recommend donors to donate to a Project that contains elements of obscurity, lies, suspicion, fraud and activities that are prohibited by the state. Indonesia Green (IGE) is not responsible for donors \'negligence for what we have warned above; </p> <br />'
            } else {
                return '<p>1.5 Donatur wajib mencermati setiap kali hendak melakukan donasi terhadap Project, kami tidak menyarankan donatur untuk berdonasi kepada suatu Project yang mengandung unsur ketidakjelasan, kebohongan, mencurigakan, penipuan dan kegiatan yang dilarang oleh negara. Indonesia Green (IGE) tidak bertanggung jawab atas kelalaian donatur atas hal yang sudah kami peringatkan di atas;</p><br />'
            }
        case '<p>1.6 Sepakat untuk tidak mempermasalahkan dan/atau menuntut secara hukum Pihak Indonesia Green (IGE) atas penyalahgunaan donasi yang dilakukan oleh Pihak Project Owner;</p><br />':
            if (lg) {
                return '<p> 1.6 Agree not to challenge and / or prosecute Indonesia Green (IGE) for misuse of donations made by the Project Owner; </p> <br />'
            } else {
                return '<p>1.6 Sepakat untuk tidak mempermasalahkan dan/atau menuntut secara hukum Pihak Indonesia Green (IGE) atas penyalahgunaan donasi yang dilakukan oleh Pihak Project Owner;</p><br />'
            }
        case '<p>1.7 Memahami bahwa berdonasi menggunakan Credit Card akan dikenakan biaya administrasi dari partner Credit Card Indonesia Green (IGE). Jumlah nominal donasi akan secara otomatis terpotong dengan biaya administrasi;</p><br />':
            if (lg) {
                return '<p> 1.7 Understand that donating using a Credit Card will incur administrative costs from the Indonesia Green Credit Card partner (IGE). The nominal amount of the donation will be automatically deducted by administrative costs; </p> <br /> '
            } else {
                return '<p>1.7 Memahami bahwa berdonasi menggunakan Credit Card akan dikenakan biaya administrasi dari partner Credit Card Indonesia Green (IGE). Jumlah nominal donasi akan secara otomatis terpotong dengan biaya administrasi;</p><br />'
            }
        case '<p>1.8 Donatur bersedia apabila sewaktu-waktu dihubungi oleh Indonesia Green (IGE) walaupun tercatat sebagai anonim (anonymous donation) untuk keperluan tertentu;</p><br />':
            if (lg) {
                return '<p> 1.8 Donors are willing to be contacted at any time by Indonesia Green (IGE) at any time even though they are listed as anonymous (anonymous donation) for certain purposes; </p> <br />'
            } else {
                return '<p>1.8 Donatur bersedia apabila sewaktu-waktu dihubungi oleh Indonesia Green (IGE) walaupun tercatat sebagai anonim (anonymous donation) untuk keperluan tertentu;</p><br />'
            }
        case '<p>1.9 Tidak melakukan pencucian uang (money laundry) dan/atau menggunakan uang yang berasal dari sumber yang tidak sah secara hukum dalam mendonasikan uangnya untuk mendukung ide dan/atau Project penggalangan Dana yang ada di dalam situs. </p><br />':
            if (lg) {
                return '<p> 1.9 Do not do money laundering (money laundry) and / or use money from illegitimate sources in donating money to support ideas and / or Fundraising Projects that are on the site. </p> <br /> '
            } else {
                return '<p>1.9 Tidak melakukan pencucian uang (money laundry) dan/atau menggunakan uang yang berasal dari sumber yang tidak sah secara hukum dalam mendonasikan uangnya untuk mendukung ide dan/atau Project penggalangan Dana yang ada di dalam situs. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">B. Ketentuan Donatur</p><br /><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> B. Donors Provisions </p> <br /> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">B. Ketentuan Donatur</p><br /><br />'
            }
        case '<p>Donatur memahami bahwa:</p><br />':
            if (lg) {
                return '<p> Donors understand that: </p> <br />'
            } else {
                return '<p>Donatur memahami bahwa:</p><br />'
            }
        case '<p>1.1 Setiap donasi yang masuk ke dalam suatu Project akan terpotong biaya administrasi sebesar 5%;</p><br />':
            if (lg) {
                return '<p> 1.1 Every donation entering a Project will be deducted by an administration fee of 10%; </p> <br />'
            } else {
                return '<p>1.1 Setiap donasi yang masuk ke dalam suatu Project akan terpotong biaya administrasi sebesar 10%;</p><br />'
            }
        case '<p>1.2 Penyedia Platform memfasilitasi penyaluran Dana melalui Platform kepada Project Owner dan/atau Penerima Manfaat;</p><br />':
            if (lg) {
                return '<p> 1.2 Platform Providers facilitate channeling Funds through the Platform to Project Owners and / or Beneficiaries; </p> <br />'
            } else {
                return '<p>1.2 Penyedia Platform memfasilitasi penyaluran Dana melalui Platform kepada Project Owner dan/atau Penerima Manfaat;</p><br />'
            }
        case '<p>1.3 Dana yang diterima akan masuk ke rekening atas nama Project Owner paling lambat 3 (tiga) x 24 (dua puluh empat) jam sejak Dana yang dialihkan oleh Donatur telah dilakukan Verifikasi oleh Penyedia Platform;</p><br />':
            if (lg) {
                return '<p> 1.3 The funds received will go into the account in the name of the Project Owner no later than 3 (three) x 24 (twenty four) hours after the Funds transferred by the Donors have been verified by the Platform Provider; </p> <br / > '
            } else {
                return '<p>1.3 Dana yang diterima akan masuk ke rekening atas nama Project Owner paling lambat 3 (tiga) x 24 (dua puluh empat) jam sejak Dana yang dialihkan oleh Donatur telah dilakukan Verifikasi oleh Penyedia Platform;</p><br />'
            }
        case '<p>1.4 Fundraising project telah dilakukan namun diberhentikan saat proses fundraising karena keputusan dari keadaan ataupun adanya pelanggaran ketentuan yang telah dipertimbangkan oleh pihak IGE, maka donasi yang telah diberikan oleh donatur akan tetap berada di kas IGE.</p><br />':
            if (lg) {
                return '<p> 1.4 Fundraising projects have been carried out but were terminated during the fundraising process due to a decision of circumstances or violations of provisions that have been considered by the IGE, then donations that have been given by donors will remain in IGE cash. </p> <br / > '
            } else {
                return '<p>1.4 Fundraising project telah dilakukan namun diberhentikan saat proses fundraising karena keputusan dari keadaan ataupun adanya pelanggaran ketentuan yang telah dipertimbangkan oleh pihak IGE, maka donasi yang telah diberikan oleh donatur akan tetap berada di kas IGE.</p><br />'
            }
        case '<p>1.5 Tanpa mengurangi hak penolakan dan/atau penundaan Verifikasi Akun, Konten dan/atau Pencairan Dana dari Penyedia Platform, Dana yang masuk ke dalam Dompet atas nama Project Owner sepenuhnya menjadi hak dari Penerima Manfaat, yang harus dipertanggungjawabkan dan hanya dapat digunakan untuk Pelaksanaan Project.</p><br />':
            if (lg) {
                return '<p> 1.5 Without prejudice to the right to refuse and / or postpone Account Verification, Content and / or Disbursement of Funds from the Platform Provider, Funds that enter the Wallet on behalf of the Project Owner are fully the rights of the Beneficiaries, which must be accounted for and can only be used for Project Implementation. </p> <br /> '
            } else {
                return '<p>1.5 Tanpa mengurangi hak penolakan dan/atau penundaan Verifikasi Akun, Konten dan/atau Pencairan Dana dari Penyedia Platform, Dana yang masuk ke dalam Dompet atas nama Project Owner sepenuhnya menjadi hak dari Penerima Manfaat, yang harus dipertanggungjawabkan dan hanya dapat digunakan untuk Pelaksanaan Project.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">C. Kontak Narahubung</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> C. Contact Person </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">C. Kontak Narahubung</p><br />'
            }
        case '<p>Donatur dapat memanfaatkan kontak narahubung yang dimuat pada tiap halaman Project untuk mengetahui detail suatu Project dan/atau Pelaksanaan Project.</p><br />':
            if (lg) {
                return '<p> Donors can make use of the contact persons contained on each Project page to find out the details of a Project and / or Project Execution. </p> <br />'
            } else {
                return '<p>Donatur dapat memanfaatkan kontak narahubung yang dimuat pada tiap halaman Project untuk mengetahui detail suatu Project dan/atau Pelaksanaan Project.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">D. Donasi Anonim dan Donasi Luar Jaringan</p><br /><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> D. Anonymous Donations and Donations Outside the Network </p> <br /> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">D. Donasi Anonim dan Donasi Luar Jaringan</p><br /><br />'
            }
        case '<p>1.1 Donatur memahami dengan berdonasi secara anonim (anonymous donation), maka nama dan alamat email Donatur tidak akan terlihat dan/atau timbul di halaman Project yang bersangkutan, namun kami tetap dapat melihat nama dan alamat email donatur secara lengkap.</p><br />':
            if (lg) {
                return '<p> 1.1 Donors understand by donating anonymously (anonymous donation), the name and email address of the Donors will not be visible and / or appear on the Project page concerned, but we can still see the donors\'s name and email address in full. </ p> <br /> '
            } else {
                return '<p>1.1 Donatur memahami dengan berdonasi secara anonim (anonymous donation), maka nama dan alamat email Donatur tidak akan terlihat dan/atau timbul di halaman Project yang bersangkutan, namun kami tetap dapat melihat nama dan alamat email donatur secara lengkap.</p><br />'
            }
        case '<p>1.2 Donatur yang membuat donasi luar jaringan (offline donation) dengan cara mengalihkan Dana langsung kepada rekening pribadi Project Owner, dan bukan melalui Platform, sepakat untuk melepaskan dan membebaskan Penyedia Platform dari segala tanggung jawab atas kerugian yang mungkin dialaminya sehubungan dengan donasi luar jaringan tersebut.</p><br />':
            if (lg) {
                return '<p> 1.2 Donors who make offline donations by transferring Funds directly to the Project Owner\'s personal account, and not through the Platform, agree to release and release the Platform Provider from any responsibility for losses that may be experienced in connection with donations outside the network. </p> <br /> '
            } else {
                return '<p>1.2 Donatur yang membuat donasi luar jaringan (offline donation) dengan cara mengalihkan Dana langsung kepada rekening pribadi Project Owner, dan bukan melalui Platform, sepakat untuk melepaskan dan membebaskan Penyedia Platform dari segala tanggung jawab atas kerugian yang mungkin dialaminya sehubungan dengan donasi luar jaringan tersebut.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">E. Random Project</p><br /><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> E. Random Project </p> <br /> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">E. Random Project</p><br /><br />'
            }
        case '<p>Donatur sepakat dalam hal:</p><br />':
            if (lg) {
                return '<p> Donors agree on: </p> <br />'
            } else {
                return '<p>Donatur sepakat dalam hal:</p><br />'
            }
        case '<p>1.1 Donatur membuat donasi dengan cara mengalihkan Dana ke Platform, sehingga dicatat sebagai donasi umum; dan/atau</p><br />':
            if (lg) {
                return '<p> 1.1 Donors make donations by transferring Funds to the Platform, so they are recorded as general donations; and / or </p> <br /> '
            } else {
                return '<p>1.1 Donatur membuat donasi dengan cara mengalihkan Dana ke Platform, sehingga dicatat sebagai donasi umum; dan/atau</p><br />'
            }
        case '<p>1.2 Sebuah Project ditutup oleh Penyedia Platform sebab Project Owner bersangkutan melanggar, diduga melanggar atau berpotensi melanggar Syarat dan Ketentuan, maka Penyedia Platform berhak untuk menyalurkan Dana yang telah masuk Platform kepada Project Acak.</p><br />':
            if (lg) {
                return '<p> 1.2 A Project is closed by the Platform Provider because the relevant Project Owner violates, is suspected of violating or has the potential to violate the Terms and Conditions, the Platform Provider has the right to channel Funds that have entered the Platform to the Random Project. </p> <br />'
            } else {
                return '<p>1.2 Sebuah Project ditutup oleh Penyedia Platform sebab Project Owner bersangkutan melanggar, diduga melanggar atau berpotensi melanggar Syarat dan Ketentuan, maka Penyedia Platform berhak untuk menyalurkan Dana yang telah masuk Platform kepada Project Acak.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">F. Refund</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> F. Refund </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">F. Refund</p><br />'
            }
        case '<p>Indonesia Green (IGE) tidak dapat melakukan pengembalian uang donatur, maka akan didonasikan kembali di sebuah atau beberapa Project di situs Indonesia Green (IGE):</p><br />':
            if (lg) {
                return '<p> Indonesia Green (IGE) cannot refund donors, it will be donated back on one or several Projects on the Indonesia Green (IGE) website: </p> <br />'
            } else {
                return '<p>Indonesia Green (IGE) tidak dapat melakukan pengembalian uang donatur, maka akan didonasikan kembali di sebuah atau beberapa Project di situs Indonesia Green (IGE):</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">G. Hukum Kekayaan Intelektual</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> G. Intellectual Property Law </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">G. Hukum Kekayaan Intelektual</p><br />'
            }
        case '<p>Semua Hak Kekayaan Intelektual dalam situs ini dimiliki oleh Indonesia Green (IGE). Semua informasi dan bahan yang tertera pada situs kami seperti : logo, ikon, kode html dan kode lainnya dalam situs web ini dilarang untuk dimodifikasi, direproduksi atau diubah dengan cara apapun di luar wilayah situs ini tanpa izin yang dinyatakan oleh Indonesia Green (IGE).</p>':
            if (lg) {
                return '<p> All Intellectual Property Rights in this site are owned by Indonesia Green (IGE). All information and materials contained on our site such as: logos, icons, html codes and other codes on this website are prohibited to be modified, reproduced or changed in any way outside the territory of this site without permission stated by Indonesia Green (IGE). </p> '
            } else {
                return '<p>Semua Hak Kekayaan Intelektual dalam situs ini dimiliki oleh Indonesia Green (IGE). Semua informasi dan bahan yang tertera pada situs kami seperti : logo, ikon, kode html dan kode lainnya dalam situs web ini dilarang untuk dimodifikasi, direproduksi atau diubah dengan cara apapun di luar wilayah situs ini tanpa izin yang dinyatakan oleh Indonesia Green (IGE).</p>'
            }
        case '<p>Apabila Anda melanggar hak-hak ini, kami berhak untuk membuat gugatan perdata untuk jumlah keseluruhan kerusakan atau kerugian yang diderita. Pelanggaran terhadap poin ini bisa termasuk dalam tindak pidana.</p><br />':
            if (lg) {
                return '<p> If you violate these rights, we reserve the right to make a civil claim for the total amount of damage or loss suffered. Violation of this point may be a crime. </p> <br /> '
            } else {
                return '<p>Apabila Anda melanggar hak-hak ini, kami berhak untuk membuat gugatan perdata untuk jumlah keseluruhan kerusakan atau kerugian yang diderita. Pelanggaran terhadap poin ini bisa termasuk dalam tindak pidana.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">H. Pengecualian dan Pelepasan Tanggungjawab</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> H. Exclusion and Disclaimer </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">H. Pengecualian dan Pelepasan Tanggungjawab</p><br />'
            }
        case '<p>1.1 Indonesia Green (IGE) tidak bertanggung jawab atas segala hal yang dijanjikan pihak Project Owner galang Dana terhadap beneficiary atau donatur apabila terjadi sengketa di antara mereka. Indonesia Green (IGE) tidak memberikan garansi atas apa yang terjadi di kemudian hari.</p><br />':
            if (lg) {
                return '<p> 1.1 Indonesia Green (IGE) is not responsible for everything that the Project Owner Galang Dana promised to the beneficiaries or donors in the event of a dispute between them. Indonesia Green (IGE) does not guarantee what will happen in the future. </p> <br /> '
            } else {
                return '<p>1.1 Indonesia Green (IGE) tidak bertanggung jawab atas segala hal yang dijanjikan pihak Project Owner galang Dana terhadap beneficiary atau donatur apabila terjadi sengketa di antara mereka. Indonesia Green (IGE) tidak memberikan garansi atas apa yang terjadi di kemudian hari.</p><br />'
            }
        case '<p>1.2 Indonesia Green (IGE) tidak bertanggung jawab atas ketidakpuasan Anda apabila Project Owner dan atau beneficiary atas penggunaan donasi yang telah digalangkan pada situs kami atau situs yang didukung oleh Indonesia Green (IGE).</p><br />':
            if (lg) {
                return '<p> 1.2 Indonesia Green (IGE) is not responsible for your dissatisfaction if the Project Owner and or beneficiary for the use of donations that have been raised on our site or sites supported by Indonesia Green (IGE). </p> <br /> '
            } else {
                return '<p>1.2 Indonesia Green (IGE) tidak bertanggung jawab atas ketidakpuasan Anda apabila Project Owner dan atau beneficiary atas penggunaan donasi yang telah digalangkan pada situs kami atau situs yang didukung oleh Indonesia Green (IGE).</p><br />'
            }
        case '<p>1.3 Indonesia Green (IGE) tidak bertanggung jawab atas apa yang terjadi apabila donasi telah diberikan kepada Project Owner dan atau beneficiary seperti pencurian, penggelapan atau tindakan apapun yang menyebabkan kehilangan Dana donasi.</p><br />':
            if (lg) {
                return '<p> 1.3 Indonesia Green (IGE) is not responsible for what happens if donations have been given to Project Owners and / or beneficiaries such as theft, embezzlement or any actions that cause the loss of donation funds. </p> <br />'
            } else {
                return '<p>1.3 Indonesia Green (IGE) tidak bertanggung jawab atas apa yang terjadi apabila donasi telah diberikan kepada Project Owner dan atau beneficiary seperti pencurian, penggelapan atau tindakan apapun yang menyebabkan kehilangan Dana donasi.</p><br />'
            }
        case '<p>1.4 Dalam keadaan apapun, Pengguna Platform akan membayar kerugian Indonesia Green (IGE) dan/atau menghindarkan Indonesia Green (IGE) (termasuk petugas, direktur, karyawan, agen, dan lainnya) dari setiap biaya kerugian apapun, kehilangan, pengeluaran atau kerusakan yang berasal dari tuntutan atau klaim pihak ketiga yang timbul dari pelanggaran Pengguna Platform terhadap Aturan Penggunaan Indonesia Green (IGE), dan/atau pelanggaran terhadap hak dari pihak ketiga.</p><br />':
            if (lg) {
                return '<p> 1.4 Under any circumstance, Platform Users will pay Indonesia Green (IGE) losses and / or avoid Indonesia Green (IGE) (including officers, directors, employees, agents, etc.) from any cost of any losses, losses, expenses or damage arising from claims or claims of third parties arising from violations of Platform Users against the Indonesia Green Usage Rules (IGE), and / or violations of the rights of third parties. </p> <br /> '
            } else {
                return '<p>1.4 Dalam keadaan apapun, Pengguna Platform akan membayar kerugian Indonesia Green (IGE) dan/atau menghindarkan Indonesia Green (IGE) (termasuk petugas, direktur, karyawan, agen, dan lainnya) dari setiap biaya kerugian apapun, kehilangan, pengeluaran atau kerusakan yang berasal dari tuntutan atau klaim pihak ketiga yang timbul dari pelanggaran Pengguna Platform terhadap Aturan Penggunaan Indonesia Green (IGE), dan/atau pelanggaran terhadap hak dari pihak ketiga.</p><br />'
            }
        case '<p>1.5 <a style="font-weight: bold;">INDONESIA GREEN (IGE) TIDAK MEMBERIKAN GANTI RUGI DALAM BENTUK APAPUN ATAS PENGGALANGAN DANA YANG DILAKUKAN PADA SITUS KAMI.</a></p><br />':
            if (lg) {
                return '<p> 1.5 <a style="font-weight: bold;"> INDONESIA GREEN (IGE) DOES NOT PROVIDE LOSS IN ANY FORM OF RUNNING THE FUNDS THAT ARE CONDUCTED ON OUR SITE. </a> </p> <br / > '
            } else {
                return '<p>1.5 <a style="font-weight: bold;">INDONESIA GREEN (IGE) TIDAK MEMBERIKAN GANTI RUGI DALAM BENTUK APAPUN ATAS PENGGALANGAN DANA YANG DILAKUKAN PADA SITUS KAMI.</a></p><br />'
            }
        case '<p>1.6 Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa terdapat Project yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan Indonesia Green (IGE) untuk melaporkan kepada kami dan ikut serta membantu menyelesaikan perkara tersebut.</p><br />':
            if (lg) {
                return '<p> 1.6 If in the future you as a site user discovers or discovers that there is a Project that is suspicious or indicates fraud, you agree to assist and cooperate with Indonesia Green (IGE) to report to us and participate in helping to resolve the case. </p> <br /> '
            } else {
                return '<p>1.6 Apabila di kemudian hari Anda sebagai pengguna situs menemukan atau mendapati bahwa terdapat Project yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan Indonesia Green (IGE) untuk melaporkan kepada kami dan ikut serta membantu menyelesaikan perkara tersebut.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">I. Hak Cipta dan Merek Dagang</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> I. Copyright and Trademarks </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">I. Hak Cipta dan Merek Dagang</p><br />'
            }
        case '<p>Semua fitur yang terdapat di dalam situs ini adalah milik PT KREASI ENERGI TERBARUKAN bersama dengan YAYASAN KREASI ENERGI TERBARUKAN dan dilindungi oleh ketentuan hukum Indonesia yang terdapat dalam Undang-undang HKI (Hak Kekayaan Intelektual). Oleh karena itu, pengguna situs tidak diperkenankan untuk menyalahgunakan fitur yang terdapat dalam situs ini untuk kepentingan pribadi, kolektif maupun komersil.</p><br />':
            if (lg) {
                return '<p> All features contained in this website are the property of PT KREASI ENERGI TERBARUKAN together with YAYASAN KREASI ENERGI TERBARUKAN and are protected by Indonesian legal provisions contained in the IPR Law (Intellectual Property Rights). Therefore, site users are not permitted to misuse the features contained in this site for personal, collective or commercial purposes. </p> <br /> '
            } else {
                return '<p>Semua fitur yang terdapat di dalam situs ini adalah milik PT KREASI ENERGI TERBARUKAN bersama dengan YAYASAN KREASI ENERGI TERBARUKAN dan dilindungi oleh ketentuan hukum Indonesia yang terdapat dalam Undang-undang HKI (Hak Kekayaan Intelektual). Oleh karena itu, pengguna situs tidak diperkenankan untuk menyalahgunakan fitur yang terdapat dalam situs ini untuk kepentingan pribadi, kolektif maupun komersil.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">J. Hukum yang Mengatur dan Yurisdiksi</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> J. Governing Law and Jurisdiction </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">J. Hukum yang Mengatur dan Yurisdiksi</p><br />'
            }
        case '<p>Syarat dan Ketentuan ini diatur, ditafsirkan dan dilaksanakan berdasarkan hukum Negara Kesatuan Republik Indonesia dan Pengunjung  maupun Pengguna Platform menundukkan diri pada yurisdiksi Badan Abritase Nasional Indonesia (BANI).</p>':
            if (lg) {
                return '<p> These Terms and Conditions are regulated, interpreted and implemented based on the laws of the Unitary Republic of Indonesia and Visitors and Platform Users submit to the jurisdiction of the Indonesian National Abritase Agency (BANI). </p>'
            } else {
                return '<p>Syarat dan Ketentuan ini diatur, ditafsirkan dan dilaksanakan berdasarkan hukum Negara Kesatuan Republik Indonesia dan Pengunjung  maupun Pengguna Platform menundukkan diri pada yurisdiksi Badan Abritase Nasional Indonesia (BANI).</p>'
            }
        case '<p><a style="font-weight: bold;">Setiap orang dengan sengaja, dan tanpa hak menyebarkan berita bohong dan menyesatkan yang mengakibatkan kerugian konsumen dalam Transaksi Elektronik akan terjerat ketentuan pidana yang tertulis pada Pasal 28 Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik (UU ITE). </a></p><br />':
            if (lg) {
                return '<p> <a style="font-weight: bold;"> Anyone who intentionally, and without the right to spread false and misleading news that results in consumer harm in Electronic Transactions will be ensnared with the criminal provisions written in Article 28 of Law Number 11 of 2008 concerning Information and Electronic Transactions (UU ITE). </a> </p> <br /> '
            } else {
                return '<p><a style="font-weight: bold;">Setiap orang dengan sengaja, dan tanpa hak menyebarkan berita bohong dan menyesatkan yang mengakibatkan kerugian konsumen dalam Transaksi Elektronik akan terjerat ketentuan pidana yang tertulis pada Pasal 28 Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik (UU ITE). </a></p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">K. Sanksi</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> K. Sanctions </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">K. Sanksi</p><br />'
            }
        case '<p>Apabila pengguna situs terbukti melanggar syarat dan ketentuan yang telah dibuat baik secara sengaja maupun tidak dilakukan secara sebagian maupun secara keseluruhan, maka: </p><br />':
            if (lg) {
                return '<p> If a site user is found to have violated the terms and conditions that have been made intentionally or not in part or in whole, then: </p> <br />'
            } else {
                return '<p>Apabila pengguna situs terbukti melanggar syarat dan ketentuan yang telah dibuat baik secara sengaja maupun tidak dilakukan secara sebagian maupun secara keseluruhan, maka: </p><br />'
            }
        case '<p>1.1 Kami akan memberitahu/notifikasi melalui pesan/email maupun telpon kepada pengguna mengenai ketentuan apa yang dilanggar;</p><br />':
            if (lg) {
                return '<p> 1.1 We will notify / notify via message / email or telephone to the user what conditions have been violated; </p> <br />'
            } else {
                return '<p>1.1 Kami akan memberitahu/notifikasi melalui pesan/email maupun telpon kepada pengguna mengenai ketentuan apa yang dilanggar;</p><br />'
            }
        case '<p>1.2 Kami akan memberikan sanksi sesuai dengan pelanggaran yang dilakukan oleh pengguna situs. </p><br />':
            if (lg) {
                return '<p> 1.2 We will impose sanctions according to violations committed by site users. </p> <br /> '
            } else {
                return '<p>1.2 Kami akan memberikan sanksi sesuai dengan pelanggaran yang dilakukan oleh pengguna situs. </p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">L. Penyelesaian Sengketa dan Keadaan Terpaksa</p><br />':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> L. Settlement of Disputes and Forced Conditions </p> <br /> '
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">L. Penyelesaian Sengketa dan Keadaan Terpaksa</p><br />'
            }
        case '<p>1.1 Dalam hal terdapat suatu perselisihan atau sengketa yang timbul dari atau sehubungan dengan penafsiran atau pelaksanaan dari Syarat dan Ketentuan ini, Pengunjung Platform maupun Pengguna Platform sepakat untuk menggunakan segala upaya untuk menyelesaikan perselisihan atau sengketa tersebut melalui musyawarah untuk mufakat.</p><br />':
            if (lg) {
                return '<p> 1.1 In the event of a dispute or dispute arising out of or in connection with the interpretation or implementation of these Terms and Conditions, both Platform Visitors and Platform Users agree to use all efforts to resolve the dispute or dispute through deliberation to reach consensus. </ p> <br /> '
            } else {
                return '<p>1.1 Dalam hal terdapat suatu perselisihan atau sengketa yang timbul dari atau sehubungan dengan penafsiran atau pelaksanaan dari Syarat dan Ketentuan ini, Pengunjung Platform maupun Pengguna Platform sepakat untuk menggunakan segala upaya untuk menyelesaikan perselisihan atau sengketa tersebut melalui musyawarah untuk mufakat.</p><br />'
            }
        case '<p>1.2 Semua sengketa yang timbul dari dan/atau sehubungan dengan Syarat dan Ketentuan ini dan/atau pelaksanaan Syarat dan Ketentuan ini, yang tidak dapat diselesaikan secara musyawarah, baik mengenai cidera janji maupun perbuatan melawan hukum, termasuk</p>':
            if (lg) {
                return '<p> 1.2 All disputes arising from and / or in connection with these Terms and Conditions and / or the implementation of these Terms and Conditions, which cannot be resolved by deliberation, both concerning breach of contract or unlawful acts, including </p>'
            } else {
                return '<p>1.2 Semua sengketa yang timbul dari dan/atau sehubungan dengan Syarat dan Ketentuan ini dan/atau pelaksanaan Syarat dan Ketentuan ini, yang tidak dapat diselesaikan secara musyawarah, baik mengenai cidera janji maupun perbuatan melawan hukum, termasuk</p>'
            }
        case '<p>mengenai pengakhiran dan/atau keabsahan Syarat dan Ketentuan ini, akan diselesaikan dan diputus melalui BANI di wilayah setempat. Meskipun terdapat suatu perselisihan atau sengketa, Pengunjung Platform atau Pengguna Platform harus tetap memenuhi kewajibannya berdasarkan Syarat dan Ketentuan ini, tanpa mengurangi keberlakuan keputusan arbitrase final.</p><br />':
            if (lg) {
                return '<p> regarding the termination and / or validity of these Terms and Conditions, will be resolved and decided through BANI in the local area. Even if there is a dispute or dispute, Platform Visitors or Platform Users must continue to fulfill their obligations under these Terms and Conditions, without reducing the validity of the final arbitration decision. </p> <br /> '
            } else {
                return '<p>mengenai pengakhiran dan/atau keabsahan Syarat dan Ketentuan ini, akan diselesaikan dan diputus melalui BANI di wilayah setempat. Meskipun terdapat suatu perselisihan atau sengketa, Pengunjung Platform atau Pengguna Platform harus tetap memenuhi kewajibannya berdasarkan Syarat dan Ketentuan ini, tanpa mengurangi keberlakuan keputusan arbitrase final.</p><br />'
            }
        case '<p>1.3 Anda memahami dan mengerti bahwa Platform kami dapat berhenti beroperasi dikarenakan kejadian di luar kemampuan manusia dan atau tidak dapat dihindarkan seperti terjadi peperangan, kerusuhan, kebakaran, bencana alam, permogokan dan bencana lainnya yang dinyatakan oleh instansi berwenang.</p><br />':
            if (lg) {
                return '<p> 1.3 You understand and understand that our Platform may cease to operate due to events beyond human capabilities and or unavoidable events such as wars, riots, fires, natural disasters, strikes and other disasters declared by the competent authority. </ p > <br /> '
            } else {
                return '<p>1.3 Anda memahami dan mengerti bahwa Platform kami dapat berhenti beroperasi dikarenakan kejadian di luar kemampuan manusia dan atau tidak dapat dihindarkan seperti terjadi peperangan, kerusuhan, kebakaran, bencana alam, permogokan dan bencana lainnya yang dinyatakan oleh instansi berwenang.</p><br />'
            }
        case '<p style="font-size: 18px; font-weight: bold;">Penutup</p>':
            if (lg) {
                return '<p style = "font-size: 18px; font-weight: bold;"> Final </p>'
            } else {
                return '<p style="font-size: 18px; font-weight: bold;">Penutup</p>'
            }
        case '<p>Demikian syarat dan ketentuan penggunaan situs Indonesia Green (IGE), dengan menggunakan situs ini maka pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam syarat dan ketentuan ini. Atas perhatian dan kesepakatan Anda, kami sampaikan terimakasih.</p><br />':
            if (lg) {
                return '<p> Thus the terms and conditions of use of the Indonesia Green (IGE) site, by using this site the user is deemed to have read, understood, understood and agreed to all contents in these terms and conditions. For your attention and agreement, we extend our thanks. </p> <br /> '
            } else {
                return '<p>Demikian syarat dan ketentuan penggunaan situs Indonesia Green (IGE), dengan menggunakan situs ini maka pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam syarat dan ketentuan ini. Atas perhatian dan kesepakatan Anda, kami sampaikan terimakasih.</p><br />'
            }
        case 'File size cannot upload more than 5MB':
            if (lg) {
                return 'File size cannot upload more than 5MB'
            } else {
                return 'Ukuran file tidak boleh lebih dari 5MB'
            }
        default: {
            return text;
        }
    }
}