import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
    Button,
    Card,
    Alert,
    Modal,
} from 'react-bootstrap';
import './style.css';
import ListProjectComponent from '../../component/listProject';
import { UserState, setUser, me, setAllUser, getUsers, setOrganization, getOrganization } from '../../reducers/User';
import NavbarComponent from '../../component/navbar';
import { User } from '../../model/User';
import { Dispatch } from 'redux';
import { Project } from '../../model/Project';
import { getProjects, setListProject, trackDonationPage, setSelectedProject, trackProjectPage } from '../../reducers/Project';
import { SummaryDashboard } from '../../model/SummaryDashboard';
import { getSummary, setSummaryAction } from '../../reducers/Summary';

import { PaymentMethod } from '../../model/PaymentMethod';
import { getPaymentMethods, setPaymentMethod } from '../../reducers/Payment';
import { Organization } from '../../model/Organization';
import Navigation from '../../utils/Navigation';
import { Donation } from '../../model/Donation';
import { Progress } from '../../model/Progress';
import { setDonationByProject } from '../../reducers/Donation';
import { setProgressByProject } from '../../reducers/Progress';
import { clearMedia } from '../../reducers/Media';
import { getTranslate } from '../../utils/General';

export interface DashboardProps {
    user: UserState,
    project: Project[],
    summary: SummaryDashboard
}

interface DashboardAction {
    getProjects(status: string, orderBy: string, startingAfter?: string): any
    getPaymentMethods(): any
    getSummary(): any
    setProject(projects: Project[]): void
    setPaymentMethod(paymentMethod: PaymentMethod[]): void
    setSummary(payload: SummaryDashboard): void
    trackDonationPage(a: boolean): void
    selectedProject(project: Project): void

    getOrganization(id_user: string): any
    setOrganization(payload: Organization): void
    trackProjectPage(a: boolean): void
    setDonationByProject(donation: Donation[]): void
    setProgressByProject(progress: Progress[]): void
    clearMedia(): void
}
interface DashboardState {
    hasMoreItems: boolean
    showModal: boolean
}

class Dashboard extends React.Component<DashboardProps & DashboardAction, DashboardState>{

    constructor(props: any) {
        super(props);
        this.state = {
            hasMoreItems: true,
            showModal: false
        }
    }

    async loadData() {
        try {
            await this.getOrganization()
            const summaryGet: SummaryDashboard = await this.props.getSummary()
            this.props.setSummary(summaryGet)
            const paymentMethods: PaymentMethod[] = await this.props.getPaymentMethods()
            this.props.setPaymentMethod(paymentMethods)

            const listProject: Project[] = await this.props.getProjects('no-pending', 'updated_at_desc')
            if (listProject && listProject.length > 0)
                this.props.setProject(listProject)
        } catch (e: any) {
            console.error(e)
        }
    }

    async onLoadMore(id: string) {
        try {
            // this.setState({ hasMoreItems: true })
            const listProject: Project[] = await this.props.getProjects('no-pending', 'updated_at_desc', id)
            if (listProject && listProject.length > 0) {
                const currentProject = this.props.project.concat(...listProject)
                this.props.setProject(currentProject)
                this.setState({ hasMoreItems: true })
            } else {
                this.setState({ hasMoreItems: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItems: false })
        }
    }

    componentDidMount() {
        this.props.clearMedia()
        this.props.setDonationByProject([])
        this.props.setProgressByProject([])
        this.loadData();
    }

    onItemProjectClick(project: Project) {
        this.props.trackProjectPage(false)
        this.props.selectedProject(project)
        Navigation.to('/dashboard/detail')
    }

    onMyDonationClick() {
        Navigation.to('/dashboard/my-donation')
    }

    onMyProjectClick() {
        Navigation.to('/dashboard/my-project')
    }

    onSubmissionClick() {
        if (this.props.user.user && ((this.props.user.user.id_card_status &&
            this.props.user.user.id_card_status.toLowerCase() === 'approved') ||
            (this.props.user.user.access_level !== 'general'))) {
            this.props.trackProjectPage(false)
            Navigation.to('/dashboard/submission')
        } else {
            this.setState({ showModal: true })
        }
    }

    emptyState = () => {
        return (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Image className="image-pohon-empty" style={{ objectFit: 'contain' }} src={require('../../../src/assets/images/illus-empty-project/illus-empty-project.png')} />
                <p style={{ fontSize: '16px', marginTop: '36px', color: '#808080' }} className="titleMyProject">{getTranslate('Belum ada proyek')}</p>
            </div>
        )
    }

    async getOrganization() {
        try {
            const organization = await this.props.getOrganization(this.props.user.user?.organization_id + "")
            this.props.setOrganization(organization)
        } catch (e: any) {
            console.error(e)
        }
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <Row className="label-new-project">
                            <div className="label-title">{getTranslate('Indonesia Green Project')}</div>
                            <div className="button-container">
                                <Button onClick={() => this.onSubmissionClick()}>{getTranslate('Create New Project')}</Button>
                            </div>
                        </Row>

                        <Row className="card-container" style={{ margin: '0px', padding: '0px', marginBottom: '56px' }}>
                            <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                <Card style={{
                                    padding: '48px', borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }} onClick={() => this.onMyProjectClick()}>
                                    <Row style={{
                                        margin: '0px', padding: '0px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                            <div className="w-100-mobile"><Image style={{
                                                width: '64',
                                                height: '70', objectFit: 'contain'
                                            }} src={require('../../../src/assets/images/ic-project/ic-project.png')} /></div>
                                            <div className="label-card-1" style={{
                                                marginRight: '42px',
                                                marginLeft: '42px',
                                                fontSize: '24px',
                                                color: '#808080',
                                                minWidth: '120px',
                                                fontWeight: 'bold',
                                            }}>{getTranslate('My Project')}</div>
                                        </Row>
                                        <div className="card-number">
                                            {
                                                this.props.summary ?
                                                    this.props.summary.my_projects : 0
                                            }
                                        </div>
                                        <div className="label-card" style={{
                                                marginRight: '42px',
                                                marginLeft: '42px',
                                                fontSize: '24px',
                                                color: '#808080',
                                                minWidth: '120px',
                                                fontWeight: 'bold',
                                            }}>{getTranslate('My Project')}</div>
                                    </Row>
                                </Card>
                            </Col>

                            <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                <Card style={{
                                    padding: '48px', borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }} onClick={() => this.onMyDonationClick()}>
                                    <Row style={{
                                        margin: '0px', padding: '0px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                        <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                            <div className="w-100-mobile"><Image style={{
                                                width: '64',
                                                height: '70', objectFit: 'contain'
                                            }} src={require('../../../src/assets/images/ic-shopping-wallet/ic-shopping-wallet.png')} /></div>
                                            <div className="label-card-1" style={{
                                                marginRight: '42px',
                                                marginLeft: '42px',
                                                fontSize: '24px',
                                                color: '#808080',
                                                minWidth: '120px',
                                                fontWeight: 'bold',
                                            }}>{getTranslate('My Donation')}</div>
                                        </Row>
                                        <div className="card-number">{
                                                this.props.summary ?
                                                    this.props.summary.my_donations : 0
                                            }</div>
                                        <div className="label-card" style={{
                                                marginRight: '42px',
                                                marginLeft: '42px',
                                                fontSize: '24px',
                                                color: '#808080',
                                                minWidth: '120px',
                                                fontWeight: 'bold',
                                            }}>{getTranslate('My Donation')}</div>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        <Row style={{ margin: '0px', padding: '0px' }}>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div className="div-all-projects" style={{ fontSize: '24px', marginBottom: '16px' }}>{getTranslate('All Projects')}</div>
                                {this.props.project && this.props.project.length > 0 ?
                                    <Col className="col-infinite-scroll" style={{
                                        margin: '0px', padding: '0px',
                                        borderColor: 'transparent',
                                        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                    }}>
                                        <ListProjectComponent
                                            data={this.props.project}
                                            onLoadMore={(id: string) => this.onLoadMore(id)}
                                            hasMoreItems={this.state.hasMoreItems}
                                            onItemClick={(item) => this.onItemProjectClick(item)} />
                                    </Col>
                                    : <div>{this.emptyState()}</div>
                                }
                            </Col>
                        </Row>
                        <Modal
                            show={this.state.showModal}
                            centered
                        >
                            <Modal.Header closeButton onClick={() => this.setState({ showModal: false })} style={{
                                padding: '24px'
                            }}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <div style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#333333',
                                    }}>{getTranslate('Unverified User')}</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{
                                padding: '24px'
                            }}>
                                <div style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    color: '#333333',
                                }}>{this.props.user.user?.id_card ?
                                    (this.props.user.user?.id_card.toLowerCase() === 'rejected' ?
                                        getTranslate('Status profile anda ditolak oleh admin, silakan melakukan verifikasi ulang dengan melakukan update data profile anda dan selanjutnya data anda akan di verifikasi ulang oleh admin.')
                                        :
                                        getTranslate('Status profile anda masih belum terverifikasi oleh admin, silakan menunggu sekitar 2x24 jam untuk proses verifikasi.')) :
                                    getTranslate('Anda perlu verifikasi akun untuk membuat project dengan mengupload KTP di bagian menu profile dan selanjutnya data anda akan di verifikasi oleh admin.')}</div>
                            </Modal.Body>
                            <Modal.Footer style={{
                                padding: '24px'
                            }}>
                                <Button style={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    borderColor: '#5bc69a', paddingLeft: '32px', paddingRight: '32px',
                                    backgroundColor: '#5bc69a', opacity: '1'
                                }} onClick={() => this.setState({ showModal: false })} block
                                >{getTranslate('Ok, saya mengerti')}</Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user,
        project: state.project.projects,
        summary: state.summary.summary
    } as DashboardProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        getProjects: (status: string, orderBy: string, startingAfter?: string) => getProjects(undefined, startingAfter, orderBy, undefined, status),
        getPaymentMethods: () => getPaymentMethods(),
        getSummary: () => getSummary(),
        setProject: (projects: Project[]) => dispatch(setListProject(projects)),
        setPaymentMethod: (paymentMethod: PaymentMethod[]) => dispatch(setPaymentMethod(paymentMethod)),
        setSummary: (payload: SummaryDashboard) => dispatch(setSummaryAction(payload)),
        trackDonationPage: (a: boolean) => dispatch(trackDonationPage(a)),

        getOrganization: (id_user: string) => getOrganization(id_user),
        setOrganization: (payload: Organization) => dispatch(setOrganization(payload)),
        selectedProject: (project: Project) => dispatch(setSelectedProject(project)),
        trackProjectPage: (a: boolean) => dispatch(trackProjectPage(a)),
        setDonationByProject: (donations: Donation[]) => dispatch(setDonationByProject(donations)),
        setProgressByProject: (progress: Progress[]) => dispatch(setProgressByProject(progress)),
        clearMedia: () => dispatch(clearMedia()),
    } as DashboardAction;
}

export default connect(stateToProps, dispatchToProps)(Dashboard);
