/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../AppState';
import {
    Image,
    Row,
    Col,
    Card,
    Button,
    FormControl,
    FormLabel,
    InputGroup,
    Modal,
    Alert,
} from 'react-bootstrap';
import NavbarComponent from '../../../component/navbar';
import { UserState, checkPass, editOrganization, submitOrganization, getOrganization, setOrganization } from '../../../reducers/User';
import { Organization } from '../../../model/Organization';
import { User } from '../../../model/User';
import { editProfile } from '../../../reducers/User';
import { CheckPassBody } from '../../../model/payload/CheckPassBody';
import { changePassword } from '../../../reducers/User';
import { NewPasswordBody } from '../../../model/payload/NewPasswordBody';
import { getInitialName, toTitleCase, removeAllSpecialChar, mappingAccessLevelUser, mappingStatusUser, getTranslate } from '../../../utils/General';
import { getUploadedMedia } from '../../../reducers/Media';
import { validateEmail } from '../../../utils/General';
import { Media } from '../../../model/Media';
import Navigation from '../../../utils/Navigation';
import '../style.css';
import { validateUrl } from '../../../utils/General';
import { OrganizationBody } from '../../../model/payload/OrganizationBody';
import { getStatusColor } from '../../../utils/General';

export interface EditProfileProps {
    editedPage: string
    user: UserState
    loading: boolean
    organization: Organization
}

export interface EditProfileAction {
    editProfile(payload: User): any
    checkPassword(payload: CheckPassBody): any
    changePassword(payload: NewPasswordBody): any
    mediaUpload(data: FormData, id_user: string, file_type: string): any
    editOrganization(id_organization: string, payload: OrganizationBody): void
    submitOrganization(payload: OrganizationBody): void
}

export interface EditProfileState {
    name: string
    email: string
    phone: string
    password: string
    old_password: string,
    showModal: boolean
    loading: boolean
    imageFile: string
    imageFormData?: FormData
    ktpFile: string
    ktpFormData?: FormData
    passwordToggleImage: string
    buttonPassType: string
    showAlert: Boolean
    isError: string

    //organization details
    organization_name: string,
    organization_email: string,
    category?: 'yayasan' | 'perusahaan' | 'ngo' | 'pemerintahan' | 'pendidikan' | 'other' | '',
    address: string,
    organization_phone: string,
    website: string,
    is_vice_organization_1?: boolean,
}

class EditProfilePage extends React.Component<EditProfileProps & EditProfileAction, EditProfileState>{
    private fileUploader: any | null
    private fileUploaderKTP: any | null
    constructor(props: any) {
        super(props);
        this.state = {
            passwordToggleImage: "images/ic-actions-closed-view/ic-actions-closed-view.png",
            name: "",
            buttonPassType: 'password',
            email: "",
            phone: "",
            password: "",
            old_password: "",
            showModal: false,
            loading: false,
            imageFile: "",
            ktpFile: "",
            showAlert: false,
            isError: "",

            //organization details
            organization_name: "",
            organization_email: "",
            category: "",
            address: "",
            organization_phone: "",
            website: "",
            is_vice_organization_1: undefined,
        }
    }

    setOrganization = () => {
        const o = this.props.organization
        if (this.props.organization) {
            this.setState({
                organization_name: o.name || "",
                organization_email: o.email || "",
                category: o.category || "",
                address: o.address || "",
                organization_phone: o.phone || "",
                website: o.website || "",
                is_vice_organization_1: this.props.user.user?.is_organization_owner,
            })
        }
    }

    componentDidMount() {
        this.setOrganization()
    }

    onBackHome() {
        Navigation.to('/dashboard/my-profile')
    }

    async editOrSubmitOrganization() {
        const s = this.state
        let payload: OrganizationBody = {
            name: removeAllSpecialChar(s.organization_name),
            address: removeAllSpecialChar(s.address),
            email: removeAllSpecialChar(s.organization_email),
            phone: s.organization_phone,
            website: removeAllSpecialChar(s.website),
            category: s.category!!
        }
        const userPayload: User = {
            ...this.props.user.user!!,
            is_organization_owner: this.state.is_vice_organization_1 || false
        }

        if (userPayload.id_card_status !== 'approved') {
            userPayload.id_card_status = 'pending'
        }
        try {
            this.setState({
                loading: true
            })

            await this.props.editProfile(userPayload)
            if (this.props.organization) {
                await this.props.editOrganization(this.props.user.user?.organization_id ? this.props.user.user?.organization_id : '', payload)
            } else {
                await this.props.submitOrganization(payload)
            }
            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ loading: false, showAlert: false })
                Navigation.to('/dashboard/my-profile')
            }, 1000);
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async editProfile() {
        try {
            this.setState({
                loading: true
            })

            if (this.props.user && this.props.user.user) {
                let userPayload: User = {
                    ...this.props.user.user,
                    email: removeAllSpecialChar(this.state.email),
                    name: removeAllSpecialChar(this.state.name),
                    phone: this.state.phone
                }

                if (userPayload.id_card_status !== 'approved') {
                    userPayload.id_card_status = 'pending'
                }

                if (this.state.imageFormData) {
                    const media: Media = await this.props.mediaUpload(this.state.imageFormData, this.props.user.user.id, 'user_pict')
                    userPayload.picture_url = media.data
                }
                if (this.state.ktpFormData) {
                    const media: Media = await this.props.mediaUpload(this.state.ktpFormData, this.props.user.user.id, 'id_card')
                    userPayload.id_card = media.data
                }
                await this.props.editProfile(userPayload)
                this.setState({ showAlert: true })
                setTimeout(() => {
                    this.setState({ loading: false, showAlert: false })
                    Navigation.to('/dashboard/my-profile')
                }, 1000);
            }
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async changePassword() {
        try {
            this.setState({
                loading: true,
                showModal: false
            })
            let payload: CheckPassBody = {
                username: this.state.phone,
                old_password: this.state.old_password
            }
            const token = await this.props.checkPassword(payload)
            let payloadPass: NewPasswordBody = {
                token: token.token,
                new_password: this.state.password
            }
            await this.props.changePassword(payloadPass)
            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ loading: false, showAlert: false })
                Navigation.to('/dashboard/my-profile')
            }, 1000);
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    onSave() {
        this.editProfile()
    }

    onSavePassword() {
        this.changePassword()
    }

    getProfilePict = (user?: User) => {
        if (this.state.imageFile) {
            return (<Image style={{
                width: '136px',
                height: '136px', objectFit: 'cover'
            }} roundedCircle src={this.state.imageFile} />)
        } else {
            if (user?.picture_url) {
                return (<Image style={{
                    width: '136px',
                    height: '136px', objectFit: 'cover'
                }} roundedCircle src={user?.picture_url} />)
            } else {
                return getInitialName(user?.name || getTranslate('anonim'))
            }
        }
    }

    emailError = () => {
        if (this.state.email !== "" && !validateEmail(this.state.email)) {
            return (<div style={{ fontSize: '12px', color: 'red' }}>{getTranslate('Email is invalid')}</div>)
        }
    }

    emailOrganizationError = () => {
        if (this.state.organization_email !== "" && !validateEmail(this.state.organization_email)) {
            return (<div style={{ fontSize: '12px', color: 'red' }}>{getTranslate('Email is invalid')}</div>)
        }
    }

    urlError = () => {
        if (this.state.website !== "" && !validateUrl(this.state.website)) {
            return (<div style={{ fontSize: '12px', color: 'red' }}>{getTranslate('URL is invalid')}</div>)
        }
    }

    buttonDisableProfile = () => {
        if ((this.state.name !== '' && this.props.user.user?.name !== this.state.name) ||
            (this.state.email !== '' && this.props.user.user?.email !== this.state.email && validateEmail(this.state.email)) ||
            (this.state.phone !== '' && this.props.user.user?.phone !== this.state.phone) ||
            this.state.imageFile || this.state.ktpFile) {
            return false
        } else {
            return true
        }
    }

    togglePass() {
        if (this.state.buttonPassType === "password") {
            this.setState({
                buttonPassType: "text",
                passwordToggleImage: 'images/ic-actions-view/ic-actions-view.png',
            })
        } else {
            this.setState({
                buttonPassType: "password",
                passwordToggleImage: 'images/ic-actions-closed-view/ic-actions-closed-view.png',
            })
        }
    }

    buttonDisablePass = () => {
        if (this.state.showModal) {
            if (this.state.phone !== '' && this.state.old_password !== '' && this.state.password !== this.state.old_password) {
                return false
            } else {
                return true
            }
        } else {
            if (this.state.password !== '') {
                return false
            } else {
                return true
            }
        }
    }

    buttonDisableOrganization = () => {
        const s = this.state
        if (this.props.organization) {
            if ((s.organization_name && (s.organization_name !== this.props.organization.name)) ||
                (s.organization_email && validateEmail(s.organization_email) && (s.organization_email !== this.props.organization.email)) ||
                (s.category && (s.category !== this.props.organization.category)) ||
                (s.address && (s.address !== this.props.organization.address)) ||
                (s.organization_phone && (s.organization_phone !== this.props.organization.phone)) ||
                (s.is_vice_organization_1 !== undefined && (s.is_vice_organization_1 !== this.props.user.user?.is_organization_owner)) ||
                (s.website !== undefined && validateUrl(s.website) && (s.website !== this.props.organization.website))) {
                return false
            } else {
                return true
            }
        } else {
            if (s.organization_name && s.organization_email && validateEmail(s.organization_email)
                && s.category && s.address && s.organization_phone && s.is_vice_organization_1 !== undefined &&
                (s.website ? validateUrl(s.website) : true)) {
                return false
            } else {
                return true
            }
        }
    }

    onClickUpload = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);
            this.setState({
                imageFile: URL.createObjectURL(event.target.files[0]),
                imageFormData: data
            });
        }
    }

    onUploadKTP = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);
            this.setState({
                ktpFile: event.target.files[0].name,
                ktpFormData: data
            });
        }
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="col-edit-profile-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Profile')}</div>
                            </div>
                        </Col>
                        {
                            this.props.editedPage === "profile" &&
                            <Card style={{
                                padding: '32px', cursor: 'default', borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <div className="div-text-info" style={{
                                            color: '#333333', fontSize: '24px',
                                            fontWeight: 'bold'
                                        }}>{getTranslate('Informasi Akun')}</div>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', textAlign: 'end', alignSelf: 'center' }}>
                                        <Button style={{
                                            fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                            color: this.buttonDisableProfile() ? '#808080' : 'white',
                                            borderColor: this.buttonDisableProfile() ? '#cccccc' : '#5bc69a',
                                            backgroundColor: this.buttonDisableProfile() ? '#cccccc' : '#5bc69a', opacity: '1'
                                        }}
                                            onClick={() => this.onSave()}
                                            disabled={this.buttonDisableProfile()}
                                            className={this.state.loading ? "ui primary loading button" : ""}>{getTranslate('Simpan Akun')}</Button>
                                    </Col>
                                </Row>
                                <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <input type="file" id="image-file"
                                        ref={(ref) => this.fileUploader = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onClickUpload}
                                        accept="image/*" />
                                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <Row className="img-profile-container"  style={{
                                            margin: '0px', padding: '0px',
                                            width: '136px',
                                            height: '136px',
                                            borderRadius: '68px',
                                            backgroundColor: '#d8d8d8',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            color: '#4b4b4b',
                                            fontSize: '32px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            marginRight: '32px'
                                        }} onClick={() => this.fileUploader?.click()}>
                                            <div style={{
                                                position: 'absolute', width: 'inherit',
                                                height: 'inherit', backgroundColor: 'rgba(0, 0, 0, 0.40)',
                                                borderRadius: '68px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                                alignSelf: 'center',
                                                display: 'flex'
                                            }}>
                                                <Image className="camera-icon" style={{ alignSelf: 'center', objectFit: 'contain' }} src={require('../../../assets/images/ic-devices-camera/ic-devices-camera.png')} />
                                            </div>
                                            {this.getProfilePict(this.props.user.user)}
                                        </Row>
                                        <Col style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                            <Row style={{ margin: '0px', padding: '0px' }}>
                                                <div className="div-name" style={{ color: '#333333', fontSize: '24px', fontWeight: 'bold' }}>{this.props.user.user?.name ? this.props.user.user?.name : getTranslate('anonim')}</div>
                                                <Col className="desktop-show" style={{
                                                    margin: '0px', padding: '3px', paddingLeft: '8px', paddingRight: '8px', alignItems: 'center',
                                                    borderRadius: '16px', backgroundColor: '#50aa9f', marginLeft: '16px', display: 'flex', alignSelf: 'center'
                                                }}>
                                                    <div style={{ color: 'white', fontSize: '10px' }}>{
                                                        mappingAccessLevelUser(this.props.user.user?.access_level!!)}</div>
                                                </Col>
                                            </Row>
                                            <div className="div-verified" style={{
                                                color: getStatusColor(this.props.user.user?.id_card_status),
                                                fontSize: '12px', marginTop: '8px'
                                            }}>{mappingStatusUser(this.props.user.user?.id_card_status!!)}</div>
                                            <Row className="mobile-show" style={{ margin: '0px', padding: '0px' }}>
                                                <div className="user-level-mobile">
                                                    <div style={{ color: 'white', fontSize: '10px' }}>{
                                                        mappingAccessLevelUser(this.props.user.user?.access_level!!)}</div>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Row>
                                <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                                <Row className="row-form" style={{ margin: '0px', padding: '0px' }}>
                                    <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <FormLabel id="formLabel">{getTranslate('Nama')}</FormLabel>
                                        <InputGroup>
                                            <FormControl
                                                defaultValue={this.props.user.user?.name}
                                                onChange={(event: any) => this.setState({ name: event.target.value })}
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginRight: '8px', marginLeft: '8px' }}>
                                        <FormLabel id="formLabel">Email</FormLabel>
                                        <InputGroup>
                                            <FormControl type="email"
                                                disabled={this.props.user.user?.is_social_media_register}
                                                defaultValue={this.props.user.user?.email}
                                                onChange={(event: any) => this.setState({ email: event.target.value })}
                                            />
                                        </InputGroup>
                                        {this.emailError()}
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                        <FormLabel id="formLabel">{getTranslate('Phone')}</FormLabel>
                                        <InputGroup>
                                            <FormControl type="tel"
                                                defaultValue={this.props.user.user?.phone}
                                                onChange={(event: any) => this.setState({ phone: event.target.value })}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row className="row-ktp" style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                    <input type="file" id="image-file"
                                        ref={(ref) => this.fileUploaderKTP = ref}
                                        style={{ display: "none" }}
                                        onChange={this.onUploadKTP}
                                        accept="image/*" />
                                    <Col style={{ margin: '0px', padding: '0px' }}>
                                        <FormLabel>{getTranslate('KTP')}</FormLabel>
                                        <div
                                            id="textBox"
                                        >
                                            {this.state.ktpFile ? this.state.ktpFile :
                                                (this.props.user.user && this.props.user.user.id_card ? this.props.user.user.id_card.split("/").pop() : "")}
                                        </div>
                                    </Col>
                                    {(!this.props.user.user?.id_card_status || (this.props.user.user.id_card_status && this.props.user.user.id_card_status.toLowerCase() !== 'approved')) &&
                                        <Button style={{
                                            fontWeight: 'bold',
                                            color: '#4b4b4b', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px',
                                            borderColor: '#e1e1e1', alignSelf: 'flex-end',
                                            backgroundColor: '#e1e1e1', opacity: '1'
                                        }}
                                            onClick={() => this.fileUploaderKTP?.click()}>{getTranslate('Upload KTP')}</Button>
                                    }
                                </Row>
                            </Card>
                        }
                        {
                            this.props.editedPage === "organization"
                            &&
                            <Card style={{
                                padding: '32px', cursor: 'default', borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <div className="div-text-info" style={{
                                            color: '#333333', fontSize: '24px',
                                            fontWeight: 'bold'
                                        }}>{getTranslate('Profile Organisasi')}</div>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', textAlign: 'end', alignSelf: 'center' }}>
                                        <Button style={{
                                            fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                            color: !this.state.showModal && this.buttonDisableOrganization() ? '#808080' : 'white',
                                            borderColor: !this.state.showModal && this.buttonDisableOrganization() ? '#cccccc' : '#5bc69a',
                                            backgroundColor: !this.state.showModal && this.buttonDisableOrganization() ? '#cccccc' : '#5bc69a', opacity: '1'
                                        }}
                                            className={!this.state.showModal && this.state.loading ? "ui primary loading button" : ""}
                                            disabled={!this.state.showModal && this.buttonDisableOrganization()}
                                            onClick={() => this.editOrSubmitOrganization()}>{getTranslate('Simpan Organisasi')}</Button>
                                    </Col>
                                </Row>
                                <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <Row style={{ margin: '0px', padding: '0px' }}>
                                        <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Nama Organisasi')}</FormLabel>
                                            <FormControl
                                                defaultValue={this.state.organization_name}
                                                onChange={(event: any) => this.setState({ organization_name: event.target.value })}
                                            />
                                        </Col>
                                        <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                            <FormLabel>Email</FormLabel>
                                            <FormControl
                                                defaultValue={this.state.organization_email}
                                                onChange={(event: any) => this.setState({ organization_email: event.target.value })}
                                            />
                                            {this.emailOrganizationError()}
                                        </Col>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Alamat')}</FormLabel>
                                        <FormControl as="textarea"
                                            defaultValue={this.state.address}
                                            onChange={(event: any) => this.setState({ address: event.target.value })}
                                        />
                                    </Col>
                                    <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <Col style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Phone')}</FormLabel>
                                            <FormControl type="tel"
                                                defaultValue={this.state.organization_phone}
                                                onChange={(event: any) => this.setState({ organization_phone: event.target.value })}
                                            />
                                        </Col>
                                        <Col style={{ margin: '0px', padding: '0px', marginLeft: '8px' }} lg={6}>
                                            <FormLabel>{getTranslate('Kategori Organisasi')}</FormLabel>
                                            <select onChange={(e: any) => this.setState({ category: e.target.value })}
                                                className="custom-select" id="inputGroupSelect01" value={this.state.category}>
                                                <option disabled value="">{getTranslate('Pilih Kategori Organisasi')}</option>
                                                <option value="yayasan">{getTranslate('Yayasan')}</option>
                                                <option value="perusahaan">{getTranslate('Perusahaan')}</option>
                                                <option value="ngo">{getTranslate('Ngo')}</option>
                                                <option value="pemerintahan">{getTranslate('Pemerintahan')}</option>
                                                <option value="pendidikan">{getTranslate('Pendidikan')}</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Blog/Website')}</FormLabel>
                                        <FormControl
                                            placeholder="https://"
                                            defaultValue={this.state.website}
                                            onChange={(event: any) => this.setState({ website: event.target.value })}
                                        />
                                        {this.urlError()}
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Apakah Anda bertindak sebagai perwakilan langsung dari organisasi ini?')}</FormLabel>
                                        <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                                            <div className="ui radio checkbox"
                                                style={{
                                                    marginRight: '16px'
                                                }}>
                                                <input defaultChecked={this.state.is_vice_organization_1 === true ?
                                                    true : undefined} type="radio" name="is_vice_organization_1" value="Ya"
                                                    onClick={() => this.setState({ is_vice_organization_1: true })}
                                                />
                                                <label>{getTranslate('Ya')}</label>
                                            </div>
                                            <div className="ui radio checkbox">
                                                <input type="radio" defaultChecked={this.state.is_vice_organization_1 === false ?
                                                    true : undefined} name="is_vice_organization_1" value="Tidak"
                                                    onClick={() => this.setState({ is_vice_organization_1: false })}
                                                />
                                                <label>{getTranslate('Tidak')}</label>
                                            </div>
                                        </Row>
                                    </Col>
                                </Col>
                            </Card>
                        }
                        {
                            this.props.editedPage === "password"
                            &&
                            <Card style={{
                                padding: '32px', cursor: 'default', borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center' }}>
                                        <div className="div-text-info" style={{
                                            color: '#333333', fontSize: '24px',
                                            fontWeight: 'bold'
                                        }}>{getTranslate('Setting Password')}</div>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', textAlign: 'end', alignSelf: 'center' }}>
                                        <Button style={{
                                            fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                            color: !this.state.showModal && this.buttonDisablePass() ? '#808080' : 'white',
                                            borderColor: !this.state.showModal && this.buttonDisablePass() ? '#cccccc' : '#5bc69a',
                                            backgroundColor: !this.state.showModal && this.buttonDisablePass() ? '#cccccc' : '#5bc69a', opacity: '1'
                                        }}
                                            className={!this.state.showModal && this.state.loading ? "ui primary loading button" : ""}
                                            disabled={!this.state.showModal && this.buttonDisablePass()}
                                            onClick={() => this.setState({
                                                showModal: true
                                            })}>{getTranslate('Simpan Password')}</Button>
                                    </Col>
                                </Row>
                                <div style={{ height: '1px', width: '100%', backgroundColor: '#d8d8d8', marginBottom: '32px' }} />
                                <Row style={{ margin: '0px', padding: '0px' }}>
                                    <Col style={{ margin: '0px', padding: '0px' }} lg={6}>
                                        <FormLabel id="formLabel">{getTranslate('Password')}</FormLabel>
                                        <InputGroup>
                                            <FormControl
                                                disabled={this.props.user.user?.is_social_media_register}
                                                placeholder="*********" type="password"
                                                onChange={(event: any) => this.setState({ password: event.target.value })}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Card>
                        }

                        <Modal
                            show={this.state.showModal}
                            centered
                        >
                            <Modal.Header closeButton onClick={() => this.setState({ showModal: false })} style={{
                                padding: '24px'
                            }}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <div style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#333333',
                                    }}>{getTranslate('Verifikasi Ubah Password')}</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{
                                padding: '24px'
                            }}>
                                <div style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    color: "#333333",
                                    marginBottom: '24px'
                                }}>{getTranslate('Silakan konfirmasi nomor telepon dan password lama Anda.')}</div>
                                <Col style={{ margin: '0px', padding: '0px', marginBottom: '16px' }}>
                                    <FormLabel id="formLabel">{getTranslate('Phone')}</FormLabel>
                                    <InputGroup>
                                        <FormControl
                                            defaultValue={this.state.phone}
                                            onChange={(event: any) => this.setState({ phone: event.target.value })}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <FormLabel id="formLabel">{getTranslate('Password Lama')}</FormLabel>
                                    <InputGroup>
                                        <FormControl type={this.state.buttonPassType}
                                            defaultValue={this.state.old_password}
                                            onChange={(event: any) => this.setState({ old_password: event.target.value })}
                                        />
                                        <Image style={{ objectFit: 'contain' }} onClick={() => this.togglePass()} className="togglePass" src={require('../../../../src/assets/' + this.state.passwordToggleImage)} />
                                    </InputGroup>
                                </Col>
                            </Modal.Body>
                            <Modal.Footer style={{
                                padding: '24px'
                            }}>
                                <Button style={{
                                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                    color: this.buttonDisablePass() ? '#808080' : 'white',
                                    borderColor: this.buttonDisablePass() ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.buttonDisablePass() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }} onClick={() => this.changePassword()} block
                                    disabled={this.buttonDisablePass()}
                                    className={this.state.loading ? "ui primary loading button" : ""}
                                >{getTranslate('Verifikasi')}</Button>
                            </Modal.Footer>
                        </Modal>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : (this.props.editedPage === "organization" ? getTranslate("Profil Organisasi berhasil diubah") :
                                        (this.props.editedPage === "password" ?
                                            getTranslate('Password berhasil diubah') : getTranslate('Informasi akun berhasil diubah')))}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user,
        organization: state.user.organization,
        editedPage: state.user.editPage
    } as EditProfileProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        editProfile: (payload: User) => editProfile(payload),
        checkPassword: (payload: CheckPassBody) => checkPass(payload),
        changePassword: (payload: NewPasswordBody) => changePassword(payload),
        mediaUpload: (data: FormData, id_user: string, file_type: string) => getUploadedMedia(data, id_user, file_type),
        editOrganization: (id_organization: string, payload: OrganizationBody) => editOrganization(id_organization, payload),
        submitOrganization: (payload: OrganizationBody) => submitOrganization(payload)
    } as EditProfileAction;
}

export default connect(stateToProps, dispatchToProps)(EditProfilePage);
