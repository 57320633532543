import * as React from 'react'
import { connect } from 'react-redux';
import {
    Image,
    Row,
    Col,
    ProgressBar,
    Button,
    Card,
    Spinner,
} from 'react-bootstrap';
import '../Detail/style.css';
import { AppState } from '../../../AppState';
import NavbarComponent from '../../../component/navbar';
import { Dispatch } from 'redux';
import { trackDonationPage, getProjects, setSelectedProject } from '../../../reducers/Project';
import { Project } from '../../../model/Project';
import { toCurrency } from '../../../utils/Currency';
import { toTitleCase, getInitialName, filteredDonations, validateUrl, getFileExtensions, getTranslate, paymentMethodMapper, getProjectDetailFromId } from '../../../utils/General';
import { humanDate, manyDaysBetween, convertToIDDateString } from '../../../utils/Date';
import { Donation } from '../../../model/Donation';
import { setDonationByProject, getDonations } from '../../../reducers/Donation';
import { Progress } from '../../../model/Progress';
import { setProgressByProject, getProgress } from '../../../reducers/Progress';
import { User } from '../../../model/User';
import { getSummaryDonation, getSummaryProgress } from '../../../reducers/Summary';
import { SummaryDonation } from '../../../model/SummaryDonation';
import { SummaryProgress } from '../../../model/SummaryProgress';
import { FilteredDonation } from '../../../model/FilteredDonation';
import Navigation from '../../../utils/Navigation';
import InfiniteScroll from 'react-infinite-scroll-component';
import Truncate from 'react-truncate';

export interface DetailProjectPageProps {
    user: User,
    donation: Donation[],
    progress: Progress[],
    selectedProject: Project,
    trackProjectPage: boolean
}

export interface DetailProjectPageActions {
    trackDonationPage(a: boolean): void
    setDonationByProject(donation: Donation[]): void
    setProgressByProject(progress: Progress[]): void
    getDonations(project_id: string, sortBy: string, starting_after?: string): any
    getProgress(project_id: string, sortBy: string, starting_after?: string): any
    getSummaryDonation(project_id: string): any
    getSummaryProgress(project_id: string): any
    getProjects(): any
    setSelectedProject(project: Project): void
}

export interface DetailProjectPageState {
    hasMoreItemsDonation: boolean
    hasMoreItemsProgress: boolean
    donationCount: number
    progressCount: number
    textExpanded: boolean
    textExpandedProgress: boolean
    indexExpanded: number
}

class DetailProjectPage extends React.Component<DetailProjectPageProps & DetailProjectPageActions, DetailProjectPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            hasMoreItemsDonation: true,
            hasMoreItemsProgress: true,
            donationCount: 0,
            progressCount: 0,
            textExpanded: false,
            textExpandedProgress: false,
            indexExpanded: -1
        }
    }

    async loadData() {
        try {
            const donations: Donation[] = await this.props.getDonations(this.props.selectedProject.id, "updated_at_desc")
            const progress: Progress[] = await this.props.getProgress(this.props.selectedProject.id, "updated_at_desc")
            const donationCount: SummaryDonation = await this.props.getSummaryDonation(this.props.selectedProject.id)
            const progressCount: SummaryProgress = await this.props.getSummaryProgress(this.props.selectedProject.id)
            const listProject: Project[] = await this.props.getProjects()
            const project = getProjectDetailFromId(listProject, this.props.selectedProject.id)
            if (project)
                this.props.setSelectedProject(project)

            // const filteredDonation: FilteredDonation = filteredDonations(donations)
            if (donations && donations.length > 0)
                this.props.setDonationByProject(donations)
            this.setState({
                donationCount: (donationCount.count),
                progressCount: progressCount.count
            })
            if (progress && progress.length > 0)
                this.props.setProgressByProject(progress)
        } catch (e: any) {
            console.error(e)
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.props.setDonationByProject([])
        this.props.setProgressByProject([])
    }

    onBackHome() {
        if (this.props.trackProjectPage) {
            Navigation.to('/dashboard/my-project')
        } else {
            Navigation.to('/dashboard')
        }
    }

    onDonate() {
        this.props.trackDonationPage(true)
        Navigation.to('/dashboard/detail/donate')
    }

    getPercentage(project: Project): number {
        const value = Math.round((parseInt(project.current_amount) / parseInt(project.target_amount)) * 100)
        return value
    }

    onClickProgressMedia = (link: string) => {
        window.open(link, "_blank")
    }

    async onLoadMoreDonation(starting_after?: string) {
        try {
            this.setState({ hasMoreItemsDonation: true })
            const donations = await this.props.getDonations(this.props.selectedProject.id, "updated_at_desc", starting_after)
            if (donations && donations.length > 0) {
                const currentDonations = this.props.donation.concat(...donations)
                this.props.setDonationByProject(currentDonations)
                this.setState({ hasMoreItemsDonation: false })
            } else {
                this.setState({ hasMoreItemsDonation: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItemsDonation: false })
        }
    }

    async onLoadMoreProgress(starting_after?: string) {
        try {
            this.setState({ hasMoreItemsProgress: true })
            const progress = await this.props.getProgress(this.props.selectedProject.id, "updated_at_desc", starting_after)
            if (progress && progress.length > 0) {
                const currentProgress = this.props.progress.concat(...progress)
                this.props.setProgressByProject(currentProgress)
                this.setState({ hasMoreItemsProgress: false })
            } else {
                this.setState({ hasMoreItemsProgress: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItemsProgress: false })
        }
    }

    renderProgressMediaList(mediaList: string[]) {
        return mediaList.map((item, index) => {
            return (
                <Row key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{
                        margin: '0px', padding: '0px',
                        width: '64px', height: '64px', marginRight: '16px',
                        backgroundColor: '#cccccc', borderRadius: '4px',
                        alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
                    }} onClick={() => this.onClickProgressMedia(item)}>
                        {validateUrl(item) && (getFileExtensions(item.split('.')) === 'PNG' || getFileExtensions(item.split('.')) === 'JPG' || getFileExtensions(item.split('.')) === 'JPEG') ?
                            <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item} />
                            :
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(item.split('.'))}</div>
                        }
                    </Row>
                </Row>
            )
        })
    }

    listProgress = () => {
        if (this.props.progress && this.props.progress.length > 0)
            return this.props.progress.map((item, index) => {
                return (
                    <div key={item.id}>
                        <Row style={{ margin: '0px', padding: '0px', marginLeft: '82px', paddingBottom: '16px', marginRight: '82px' }}>
                            <div style={{ marginTop: '6px', marginBottom: '18px', marginRight: '8px' }}>
                                <div style={{
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '3px',
                                    backgroundColor: '#5bc69a',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }} />
                                <div style={{
                                    width: '1px',
                                    height: '100%',
                                    backgroundColor: '#5bc69a',
                                    marginTop: '8px',
                                    marginLeft: '2.5px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    alignSelf: 'center-horizontal'
                                }} />
                            </div>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <div style={{
                                        color: '#5bc69a',
                                        fontSize: '14px',
                                    }}>{convertToIDDateString(item.updated_at)}</div>
                                    <div style={{
                                        marginTop: '4px',
                                        color: '#808080',
                                        fontSize: '10px'
                                    }}>{getTranslate('oleh ') + item.user_name + ', ' +
                                        ((this.props.selectedProject.user_id === item.user_id) ? getTranslate('Penyelenggara') : 'PMT')}</div>
                                    <div style={{
                                        marginTop: '8px',
                                        color: '#4b4b4b',
                                        fontSize: '14px'
                                    }}>
                                        <div style={{ fontWeight: 'bold' }}>{item.progress_name}</div>
                                        <div>
                                            <Truncate
                                                lines={this.state.indexExpanded !== index ? undefined : (!this.state.textExpandedProgress && 4)}
                                                ellipsis={(
                                                    <a>
                                                        <span>...</span>
                                                        <div style={{ marginTop: '8px', fontSize: '12px', fontWeight: 'bold', color: '#1aa59a', cursor: 'pointer' }} onClick={() => this.onExpandStoryProgress(index)}>{getTranslate('Baca Selengkapnya')}</div>
                                                    </a>
                                                )}
                                            >
                                                <p dangerouslySetInnerHTML={{ __html: item.progress_desc }} />
                                            </Truncate>
                                        </div>
                                    </div>
                                    {item.amount && item.amount !== "0" &&
                                        <div style={{
                                            marginTop: '8px',
                                            color: '#4b4b4b',
                                            fontSize: '12px'
                                        }}>{getTranslate("Jumlah Amount: ") + toCurrency(item.amount)}</div>
                                    }
                                </Col>
                                <Row style={{ margin: '0px', padding: '0px' }}>
                                    {item.invoice_doc && item.invoice_doc.length > 0 && this.renderProgressMediaList(item.invoice_doc)}
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )
            })
    }

    getProfilePict = (item: Donation) => {
        if (item.is_anonim || !item.user_name) {
            return (
                <Image style={{
                    width: '16px',
                    height: '16px', objectFit: 'contain'
                }} src={require("../../../../src/assets/images/ic-user-2-copy/ic-user-2-copy.png")} />
            )
        } else {
            if (item.user_picture_url) {
                return (
                    <Image style={{
                        width: '32px',
                        height: '32px', objectFit: 'cover'
                    }} roundedCircle src={item.user_picture_url} />
                )
            } else {
                return getInitialName(item.user_name || getTranslate('anonim'))
            }
        }
    }

    listDonations = () => {
        if (this.props.donation && this.props.donation.length > 0)
            return this.props.donation.map((item) => {
                return (
                    <div key={item.id}>
                        <Row className="list-pay" style={{ margin: '0px', padding: '0px', paddingTop: '24px', paddingBottom: '24px', marginLeft: '82px', marginRight: '82px' }}>
                            <Row style={{
                                margin: '0px', padding: '0px',
                                width: '32px',
                                height: '32px',
                                borderRadius: '16px',
                                backgroundColor: '#d8d8d8',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                color: '#4b4b4b',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                marginRight: '16px'
                            }}> {this.getProfilePict(item)} </Row>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div style={{
                                    color: '#5bc69a',
                                    fontSize: '14px',
                                }}>{item.is_anonim ? (item.user_name.charAt(0) + "*****" +
                                    item.user_name.slice(-1)) :
                                    (item.user_name ?
                                        item.user_name : getTranslate('anonim'))}</div>
                                <div style={{
                                    marginTop: '4px',
                                    color: '#4b4b4b',
                                    fontSize: '12px'
                                }}>{getTranslate('Donasi ') + toCurrency(item.amount)}</div>
                                <div style={{
                                    marginTop: '4px',
                                    color: '#808080',
                                    fontSize: '10px'
                                }}>{"via " + paymentMethodMapper(item.payment_method) + " • " + humanDate(item.updated_at)}</div>
                            </Col>
                        </Row>

                        <div style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            height: '1px',
                            marginLeft: '82px', marginRight: '82px'
                        }} />
                    </div>
                )
            })
    }

    onExpandStoryProgress = (index: number) => {
        this.setState({ textExpandedProgress: true, indexExpanded: index })
    }

    onExpandStory = () => {
        this.setState({ textExpanded: true })
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row className="dashboard-detail-container" id="body">
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{this.props.trackProjectPage ? getTranslate('Kembali ke Detail Proyek') : getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>

                        <Col className="col-detail" style={{ margin: '0px', padding: '0px' }}>
                            <div className="desktop-show" style={{ fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '36px', color: '#333333', marginBottom: '56px' }}>{toTitleCase(this.props.selectedProject.name)}</div>
                            <Row style={{ margin: '0px', padding: '0px' }}>
                                <Image className="img-detail" style={{
                                    height: '250px', objectFit: 'cover', marginRight: '8px', width: '48%', borderRadius: '4px'
                                }} src={this.props.selectedProject.picture_url ? this.props.selectedProject.picture_url : require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                <Col className="div-dana" style={{ marginLeft: '8px', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                                    <Col style={{ margin: '0px', padding: '0px', marginBottom: '82px' }}>
                                        <div className="mobile-show" style={{ fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '36px', color: '#333333', marginBottom: '56px' }}>{toTitleCase(this.props.selectedProject.name)}</div>
                                        <ProgressBar style={{ borderRadius: '23px', height: '8px', marginBottom: '16px' }} now={this.getPercentage(this.props.selectedProject)}></ProgressBar>
                                        <div className="total-dana" style={{ fontSize: '24px', color: '#333333', fontWeight: 'bold', marginBottom: '8px' }}>{this.props.selectedProject?.status === 'open funding' ? toCurrency(this.props.selectedProject?.current_amount): ""}</div>
                                        <div className="target-dana" style={{ fontSize: '16px', color: '#333333', marginBottom: '16px' }}>{getTranslate('terkumpul dari target ')} {toCurrency(this.props.selectedProject.target_amount)}</div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }} src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} className="miniImage" />
                                            <div style={{ fontSize: '14px', color: '#808080' }}>{this.props.selectedProject.status === 'open funding' ? manyDaysBetween(this.props.selectedProject.close_funding_date) + getTranslate(' hari tersisa') : getTranslate(' batas waktu telah berakhir')}</div>
                                        </div>
                                    </Col>
                                    {(this.props.selectedProject.status === 'open funding') ? 
                                        <Button onClick={() => this.onDonate()} style={{
                                            fontWeight: 'bold',
                                            backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                                        }} block>{getTranslate('Donasi Sekarang')}</Button>:''
                                    }
                                    
                                </Col>
                            </Row>
                        </Col>

                        <Row className="row-detail-dashboard-card" style={{ margin: '0px', padding: '0px', marginTop: '24px', marginLeft: '0px', marginRight: '0px' }}>
                            <Col style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <div className="card-detail" style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px', fontSize: '24px', color: '#333333', fontWeight: 'bold' }}>{getTranslate('Cerita')}</div>
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                    <div className="card-detail-html" style={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px' }}>
                                        <Truncate
                                            lines={!this.state.textExpanded && 4}
                                            ellipsis={(
                                                <a>
                                                    <span>...</span>
                                                    <div style={{ marginTop: '16px', fontSize: '12px', fontWeight: 'bold', color: '#1aa59a', cursor: 'pointer' }} onClick={() => this.onExpandStory()}>{getTranslate('Baca Selengkapnya')}</div>
                                                </a>
                                            )}
                                        >
                                            <p dangerouslySetInnerHTML={{ __html: this.props.selectedProject?.description }} />
                                        </Truncate>
                                    </div>
                                </Col>
                            </Col>
                        </Row>

                        <Row className="row-detail-dashboard-card" style={{ margin: '0px', padding: '0px', marginTop: '24px', marginLeft: '0px', marginRight: '0px' }}>
                            <Col style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <div className="card-detail" style={{
                                        paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                        fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                    }}>{'Progress (' + this.state.progressCount + ')'}</div>
                                    {this.props.progress && this.props.progress.length > 0 &&
                                        <div>
                                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px', marginBottom: '24px' }} />
                                            {
                                                <InfiniteScroll
                                                    key={"progress"}
                                                    scrollThreshold={0.5}
                                                    dataLength={this.props.progress.length}
                                                    next={() => this.onLoadMoreProgress(this.props.progress[this.props.progress.length - 1].id)}
                                                    hasMore={this.state.hasMoreItemsProgress}
                                                    loader={
                                                        <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                                                            <Spinner size="sm" animation="border" variant="secondary" />
                                                        </Row>
                                                    }>
                                                    {this.listProgress()}
                                                </InfiniteScroll>
                                            }
                                        </div>
                                    }
                                    <div style={{ marginBottom: '8px' }} />
                                </Col>
                            </Col>
                        </Row>
                        <Row className="row-detail-dashboard-card" style={{ margin: '0px', padding: '0px', marginTop: '24px', marginLeft: '0px', marginRight: '0px' }}>
                            <Col style={{
                                margin: '0px', padding: '0px',
                                width: '100%',
                                wordBreak: 'break-all',
                                backgroundColor: 'white',
                                cursor: 'default',
                                borderColor: 'transparent',
                                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                            }}>
                                <Col style={{ margin: '0px', padding: '0px' }}>
                                    <div className="card-detail" style={{
                                        paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                        fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                    }}>{getTranslate('Donasi ') + '(' + this.state.donationCount + ')'}</div>
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                    {this.props.donation && this.props.donation.length > 0 &&
                                        <InfiniteScroll
                                            key={"donation"}
                                            scrollThreshold={0.5}
                                            dataLength={this.props.donation.length}
                                            next={() => this.onLoadMoreDonation(this.props.donation[this.props.donation.length - 1].id)}
                                            hasMore={this.state.hasMoreItemsDonation}
                                            loader={
                                                <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                                                    <Spinner size="sm" animation="border" variant="secondary" />
                                                </Row>
                                            }>
                                            {this.listDonations()}
                                        </InfiniteScroll>
                                    }
                                </Col>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        selectedProject: state.project.selectedProject,
        donation: state.donation.donationsByProject,
        progress: state.progress.progressByProject,
        trackProjectPage: state.project.trackProjectPage
    } as DetailProjectPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        getProjects: () => getProjects(undefined, undefined, undefined, 1000),
        getDonations: (project_id: string, sortBy: string, starting_after?: string) => getDonations(undefined, starting_after, sortBy, undefined, project_id, undefined, 'succeed'),
        getProgress: (project_id: string, sortBy: string, starting_after?: string) => getProgress(undefined, starting_after, sortBy, undefined, project_id),
        setDonationByProject: (donations: Donation[]) => dispatch(setDonationByProject(donations)),
        setProgressByProject: (progress: Progress[]) => dispatch(setProgressByProject(progress)),
        trackDonationPage: (a: boolean) => dispatch(trackDonationPage(a)),
        getSummaryDonation: (project_id: string) => getSummaryDonation(project_id),
        getSummaryProgress: (project_id: string) => getSummaryProgress(project_id),
        setSelectedProject: (project: Project) => dispatch(setSelectedProject(project))
    } as DetailProjectPageActions;
}

export default connect(stateToProps, dispatchToProps)(DetailProjectPage);
