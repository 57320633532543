import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
    Button,
} from 'react-bootstrap';
import './style.css';

import ListDonationComponent from '../../component/listDonation';
import NavbarComponent from '../../component/navbar';
import { Donation } from '../../model/Donation';
import { User } from '../../model/User';
import { getDonations, setMyDonation } from '../../reducers/Donation';
import { Dispatch } from 'redux';
import { Project } from '../../model/Project';
import { Payment } from '../../model/Payment';
import { getPayments, setMyPayment } from '../../reducers/Payment';
import Navigation from '../../utils/Navigation';
import { getTranslate } from '../../utils/General';


export interface DonationPageProps {
    donation: Donation[],
    user: User,
    project: Project[],
    payment: Payment[],
}

export interface DonationAction {
    setDonation(donation: Donation[]): void
    getDonations(userId: string, sortBy: string, startingAfter?: string): any
    setPayment(payment: Payment[]): void
    getPayments(userId: string): any
}

export interface DonationPageState {
    hasMoreItems: boolean
}

class DonationPage extends React.Component<DonationPageProps & DonationAction, DonationPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            hasMoreItems: true
        }
    }

    async loadData() {
        try {
            const donations: Donation[] = await this.props.getDonations(this.props.user.id, "updated_at_desc")
            if (donations && donations.length > 0)
                this.props.setDonation(donations)
            const payments: Payment[] = await this.props.getPayments(this.props.user.id)
            if (payments && payments.length > 0)
                this.props.setPayment(payments)
        } catch (e: any) {
            console.error(e)
        }
    }

    async onLoadMore(id: string) {
        try {
            // this.setState({ hasMoreItems: true })
            const donations: Donation[] = await this.props.getDonations(this.props.user.id, "updated_at_desc", id)
            if (donations && donations.length > 0) {
                const currentDonation = this.props.donation.concat(...donations)
                this.props.setDonation(currentDonation)
                this.setState({ hasMoreItems: true })
            } else {
                this.setState({ hasMoreItems: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItems: false })
        }
    }

    componentDidMount() {
        this.loadData()
    }

    onBackHome() {
        Navigation.to('/dashboard')
    }

    emptyState = () => {
        return (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Image className="image-pohon-empty" style={{ objectFit: 'contain' }} src={require('../../../src/assets/images/illus-onboarding-2/illus-onboarding-2.png')} />
                <p style={{ fontSize: '16px', marginTop: '36px', color: '#808080' }} className="titleMyProject">{getTranslate('Belum ada donasi')}</p>
                <Button style={{
                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                    backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                }} onClick={() => this.onBackHome()}>{getTranslate('Mulai Kontribusi')}</Button>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="my-donation-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div className="font-mobile-24" style={{
                                fontSize: '36px', color: '#333333', marginBottom: '40px',
                                alignItems: 'center', justifyContent: 'center', fontWeight: 'bold'
                            }}>{getTranslate('My Donation')}</div>
                            {this.props.donation && this.props.donation.length > 0 ?
                                <Col className="box-shadow-remove" style={{
                                    margin: '0px', padding: '0px',
                                    borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }}>
                                    <ListDonationComponent
                                        data={this.props.donation}
                                        project={this.props.project}
                                        payment={this.props.payment}
                                        onLoadMore={(id: string) => this.onLoadMore(id)}
                                        hasMoreItems={this.state.hasMoreItems} />
                                </Col>
                                : <div>{this.emptyState()}</div>
                            }
                        </Col>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        donation: state.donation.myDonations,
        project: state.project.projects,
        payment: state.payment.myPayments
    } as DonationPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        getDonations: (userId: string, sortBy: string, startingAfter?: string) => getDonations(userId, startingAfter, sortBy),
        getPayments: (userId: string) => getPayments(userId),
        setDonation: (donations: Donation[]) => dispatch(setMyDonation(donations)),
        setPayment: (payments: Payment[]) => dispatch(setMyPayment(payments)),
    } as DonationAction;
}

export default connect(stateToProps, dispatchToProps)(DonationPage);
