/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../AppState';
import {
    Navbar,
    Nav,
    Image,
    Row,
    Col,
    Alert,
    Card,
    Button,
    ListGroup,
    FormControl,
    FormLabel,
    InputGroup,
    ProgressBar,
} from 'react-bootstrap';
import '../../Login/style.css';
import NavbarComponent from '../../../component/navbar';
import { Authenticate } from '../../../model/payload/Authenticate';
import { UserState, setEditedPage, getOrganization, setOrganization, addUser } from '../../../reducers/User';
import { authenticate } from '../../../reducers/Auth';
import './style.css';
import { Organization } from '../../../model/Organization';
import { User } from '../../../model/User';
import { getInitialName, toTitleCase, mappingAccessLevelUser, mappingStatusUser, bytesToSize, removeAllSpecialChar, getTranslate } from '../../../utils/General';
import { setUser } from '../../../reducers/User';
import { me } from '../../../reducers/User';
import Navigation from '../../../utils/Navigation';
import { validateUrl } from '../../../utils/General';
import { getFileExtensions } from '../../../utils/General';
import { Media } from '../../../model/Media';
import { clearMedia } from '../../../reducers/Media';
import { setUploadedMedia } from '../../../reducers/Media';
import { getUploadedMedia } from '../../../reducers/Media';
import { ProgressBody } from '../../../model/payload/ProgressBody';
import { submitProgress } from '../../../reducers/Progress';
import { Project } from '../../../model/Project';
import { submitProgressPmt } from '../../../reducers/Progress';
import { UserBody } from '../../../model/payload/UserBody';

export interface AddUserProps {
    user: User
}

export interface AddUserAction {
    addUser(user: UserBody): any
}

export interface AddUserState {
    name: string
    accessLevel: 'general' | 'pmt' | 'admin'
    phone: string
    password: string

    showAlert: Boolean
    isError: string
    loading: boolean
}

class AddUser extends React.Component<AddUserProps & AddUserAction, AddUserState>{

    constructor(props: any) {
        super(props);
        this.state = {
            phone: "",
            name: "",
            accessLevel: "general",
            password: "",

            loading: false,
            showAlert: false,
            isError: ""
        }
    }

    onBackHome() {
        Navigation.to('/user-management')
    }

    async submitUser() {
        try {
            this.setState({
                loading: true
            })
            let user: UserBody = {
                name: removeAllSpecialChar(this.state.name),
                phone: this.state.phone,
                access_level: this.state.accessLevel,
                password: this.state.password
            }
            await this.props.addUser(user)
            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ loading: false, showAlert: false })
                this.onBackHome()
            }, 1000);
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    clickAddUser = () => {
        this.submitUser()
    }

    validationButton = () => {
        const s = this.state
        if (s.name && s.phone && s.password) {
            return false
        } else {
            return true
        }
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="submission-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke User Management')}</div>
                            </div>
                        </Col>

                        <Col className="card-submission" style={{
                            margin: '0px', padding: '0px',
                            width: '100%',
                            wordBreak: 'break-all',
                            backgroundColor: 'white',
                            cursor: 'default',
                            borderColor: 'transparent', marginBottom: '24px',
                            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                        }}>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div className="card-label-title padding-0" style={{
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                    fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                }}>{getTranslate('Add New User')}</div>
                                <div className="garis" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                <Col className="padding-0" style={{
                                    margin: '0px', padding: '0px',
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                }}>
                                    <Row style={{ margin: '0px', padding: '0px' }}>
                                        <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Nama')}</FormLabel>
                                            <FormControl placeholder=""
                                                onChange={(event: any) => this.setState({ name: event.target.value })}
                                            />
                                        </Col>
                                        <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                            <FormLabel>{getTranslate('Akses Level')}</FormLabel>
                                            <Row className="button-akses-container" style={{ margin: '0px', padding: '0px' }}>
                                                <Button style={{
                                                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px', borderBottomRightRadius: '0px',
                                                    color: this.state.accessLevel === 'pmt' ? '#5bc69a' : 'white', borderTopRightRadius: '0px',
                                                    borderColor: this.state.accessLevel === 'pmt' ? '#5bc69a' : '#5bc69a',
                                                    backgroundColor: this.state.accessLevel === 'pmt' ? 'white' : '#5bc69a', opacity: '1'
                                                }}
                                                    onClick={() => this.setState({ accessLevel: 'general' })}>
                                                    {getTranslate('User')}</Button>
                                                <Button style={{
                                                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px', borderBottomLeftRadius: '0px',
                                                    color: this.state.accessLevel === 'general' ? '#5bc69a' : 'white', borderTopLeftRadius: '0px',
                                                    borderColor: this.state.accessLevel === 'general' ? '#5bc69a' : '#5bc69a',
                                                    backgroundColor: this.state.accessLevel === 'general' ? 'white' : '#5bc69a', opacity: '1'
                                                }}
                                                    onClick={() => this.setState({ accessLevel: 'pmt' })}>
                                                    {getTranslate('Admin PMT')}</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="margin-top-16" style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Phone')}</FormLabel>
                                            <FormControl placeholder=""
                                                type="tel"
                                                onChange={(event: any) => this.setState({ phone: event.target.value })}
                                            />
                                        </Col>
                                        <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                            <FormLabel>{getTranslate('Password User')}</FormLabel>
                                            <FormControl placeholder=""
                                                onChange={(event: any) => this.setState({ password: event.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="submit-button-row" style={{ margin: '0px', padding: '0px', marginTop: '24px', justifyContent: 'flex-end' }}>
                                        <Button style={{
                                            fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                            color: this.validationButton() ? '#808080' : 'white',
                                            borderColor: this.validationButton() ? '#cccccc' : '#5bc69a',
                                            backgroundColor: this.validationButton() ? '#cccccc' : '#5bc69a', opacity: '1'
                                        }}
                                            className={this.state.loading ? "ui primary loading button" : ""}
                                            disabled={this.validationButton()}
                                            onClick={() => this.clickAddUser()}>
                                            {getTranslate('Simpan')}</Button>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('User berhasil ditambahkan')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div >
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user
    } as AddUserProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        addUser: (user: UserBody) => addUser(user)
    } as AddUserAction;
}

export default connect(stateToProps, dispatchToProps)(AddUser);
