import Redux, { AnyAction } from 'redux';

import SummaryApi from '../api/Summary';
import { SummaryDashboard } from '../model/SummaryDashboard';
import { SummaryPMTDashboard } from '../model/SummaryPMTDashboard';

export const SET_SUMMARY = `SET_SUMMARY`
export const SET_PMT_SUMMARY = `SET_PMT_SUMMARY`

export const setSummaryAction: Redux.ActionCreator<Redux.AnyAction> = (payload: SummaryDashboard) => {
    return { type: SET_SUMMARY, payload: payload }
}

export const setPMTSummaryAction: Redux.ActionCreator<Redux.AnyAction> = (payload: SummaryPMTDashboard) => {
    return { type: SET_PMT_SUMMARY, payload: payload }
}

export const getSummary = async function () {
    try {
        const result = await SummaryApi.getSummary()
        return result
    } catch (err: any) {
        throw err
    }
};

export const getPMTSummary = async function () {
    try {
        const result = await SummaryApi.getPMTSummary()
        return result
    } catch (err: any) {
        throw err
    }
};

export const getSummaryDonation = async function (project_id?: string, user_id?: string) {
    try {
        const result = await SummaryApi.getSummaryDonation(project_id, user_id)
        return result
    } catch (err: any) {
        throw err
    }
};

export const getPublicSummaryDonation = async function (project_id?: string, user_id?: string) {
    try {
        const result = await SummaryApi.getPublicSummaryDonation(project_id, user_id)
        return result
    } catch (err: any) {
        throw err
    }
};

export const getSummaryProgress = async function (project_id?: string) {
    try {
        const result = await SummaryApi.getSummaryProgress(project_id)
        return result
    } catch (err: any) {
        throw err
    }
};
export const getPublicSummaryProgress = async function (project_id?: string) {
    try {
        const result = await SummaryApi.getPublicSummaryProgress(project_id)
        return result
    } catch (err: any) {
        throw err
    }
};

export interface SummaryState {
    summary: SummaryDashboard | undefined
    summaryPMT: SummaryPMTDashboard | undefined
}

export const initialSummaryState: SummaryState = {
    summary: undefined,
    summaryPMT: undefined
}

const summaryReducers = (state: SummaryState = initialSummaryState, action: any): SummaryState => {
    switch (action.type) {
        case SET_SUMMARY:
            return {
                ...state,
                summary: action.payload
            }
        case SET_PMT_SUMMARY:
            return {
                ...state,
                summaryPMT: action.payload
            }
        default: {
            return state;
        }
    }
}

export default summaryReducers
