import Redux, { AnyAction } from 'redux';

import DonationApi from '../api/Donation';
import { Donation } from '../model/Donation';
import { DonationBody } from '../model/payload/DonationBody';

export const SET_MY_DONATION = `DONATION.SET_MY_DONATION`
export const SET_DONATION_BY_PROJECT = `DONATION.SET_DONATION_BY_PROJECT`
export const SET_DONATION = `DONATION.SET_DONATION`

export const setDonation: Redux.ActionCreator<Redux.AnyAction> = (payload: Donation[]) => {
    return { type: SET_DONATION, payload: payload }
}

export const setMyDonation: Redux.ActionCreator<Redux.AnyAction> = (payload: Donation[]) => {
    return { type: SET_MY_DONATION, payload: payload }
}

export const setDonationByProject: Redux.ActionCreator<Redux.AnyAction> = (payload: Donation[]) => {
    return { type: SET_DONATION_BY_PROJECT, payload: payload }
}

export const getDonations = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, payment_method?: string, status?: string, fromDate?: string, toDate?: string) {
    try {
        const result: Donation[] = await DonationApi.getDonations(user_id, starting_after, order_by, limit, project_id, payment_method, status, fromDate, toDate);
        return result
    } catch (err: any) {
        throw err
    }
};

export const getPublicDonations = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, payment_method?: string, status?: string, fromDate?: string, toDate?: string) {
    try {
        const result: Donation[] = await DonationApi.getPublicDonations(user_id, starting_after, order_by, limit, project_id, payment_method, status, fromDate, toDate);
        return result
    } catch (err: any) {
        throw err
    }
};

export const submitDonation = async function (payload: DonationBody) {
    try {
        await DonationApi.submitDonation(payload);
    } catch (err: any) {
        throw err
    }
}
export const submitPublicDonation = async function (payload: DonationBody) {
    try {
        await DonationApi.submitPublicDonation(payload);
    } catch (err: any) {
        throw err
    }
}

export interface DonationState {
    myDonations: Donation[] | undefined
    donationsByProject: Donation[] | undefined
    donations: Donation[] | undefined
}

export const initialDonationState: DonationState = {
    myDonations: undefined,
    donationsByProject: undefined,
    donations: undefined
}

const donationReducers = (state: DonationState = initialDonationState, action: any): DonationState => {
    switch (action.type) {
        case SET_MY_DONATION:
            return {
                ...state,
                myDonations: action.payload
            }
        case SET_DONATION_BY_PROJECT:
            return {
                ...state,
                donationsByProject: action.payload
            }
        case SET_DONATION:
            return {
                ...state,
                donations: action.payload
            }
        default: {
            return state;
        }
    }
}

export default donationReducers
