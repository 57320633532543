import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { Media } from '../model/Media';

const mediaConnectionWithAccessToken = JsonServerConnection(url, '/media').withAccessToken;

const upload = async (data: FormData, user_id?: string, file_type?: string) => {
    let finalQuery = "?"

    if (user_id && user_id !== "") {
        finalQuery += "user_id=" + user_id
    }
    if (file_type && file_type !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "file_type=" + file_type
    }
    const result = await mediaConnectionWithAccessToken.post('/upload' + finalQuery, data) as Media
    return result;
};

export interface MediaApi {
    upload: (data: FormData, user_id?: string, file_type?: string) => Promise<Media>;
}

export default {
    upload,
} as MediaApi;
