import axios, { AxiosError, AxiosResponse } from 'axios';
import { Error } from '../model/Error';

import { ErrorMap, NoConnectionError } from './ErrorMap';

import AuthHelper from '../utils/AuthHelper';
import languageHelper from './LanguageHelper';

const errorResponse = (result: AxiosError) => {
    if (result.response !== undefined) {
        const errorData: Error = {
            error_code: result.response.data.error_code,
            error_msg: result.response.data.error_msg
        };
        throw ErrorMap(errorData);
    } else {
        throw NoConnectionError();
        // throw new Error('Connection timeout');
    }
};

const successResponse = (result: AxiosResponse<any>) => {
    if (result !== undefined) {
        return result.data;
    } else {
        throw NoConnectionError();
        // throw new Error('Connection timeout');
    }
};

const getConfigWithAuthorizationHeader = (config: any) => {
    const accessToken: string = AuthHelper.getAccessToken();
    const language: boolean = languageHelper.getLanguage();
    config = getConfigWithHeader(config);
    config.headers.Authorization = 'Bearer ' + accessToken;
    if (language) {
        config.headers.Language = 'EN';
    } else {
        config.headers.Language = 'ID';
    }
    return config;
};

const getConfigWithHeader = (config: any) => {
    config = { ...config };
    if (!config.headers) {
        config.headers = {};
    }
    if (!config.headers['content-type']) {
        config.headers['content-type'] = 'application/json;charset=UTF-8';
    }
    const language: boolean = languageHelper.getLanguage();
    if (language) {
        config.headers.Language = 'EN';
    } else {
        config.headers.Language = 'ID';
    }
    return config;
};

const JsonServerConnection = (baseUrl: string, endPoint: string) => {
    const prefixPath = baseUrl + endPoint;

    const generateConfig = (path: string, method: string, body: any, baseConfig: any) => {
        return {
            ...baseConfig,
            url: prefixPath + path,
            method,
            data: body,
        };
    };

    const get = async(path: string, config: any) => {
        try {
            const result = await axios(generateConfig(path, 'get', null, config));
            return successResponse(result);
        } catch (e: any) {
            return errorResponse(e);
        }
    };

    const post = async(path: string, body: any, config: any) => {
        try {
            const result = await axios(generateConfig(path, 'post', body, config));
            return successResponse(result);
        } catch (e: any) {
            return errorResponse(e);
        }
    };

    const patch = async(path: string, body: any, config: any) => {
        try {
            const result = await axios(generateConfig(path, 'patch', body, config));
            return successResponse(result);
        } catch (e: any) {
            return errorResponse(e);
        }
    };

    const deleteMethod = async(path: string, body: any, config: any) => {
        try {
            const result = await axios(generateConfig(path, 'delete', body, config));
            return successResponse(result);
        } catch (e: any) {
            return errorResponse(e);
        }
    };

    const put = async(path: string, body: any, config: any) => {
        try {
            const result = await axios(generateConfig(path, 'put', body, config));
            return successResponse(result);
        } catch (e: any) {
            return errorResponse(e);
        }
    };

    return {
        get: (path: string, config?: any) => {
            const configTemp = getConfigWithHeader(config);
            return get(path, configTemp);
        },
        post: (path: string, body?: any, config?: any) => {
            const configTemp = getConfigWithHeader(config);
            return post(path, body, configTemp);
        },
        patch: (path: string, body?: any, config?: any) => {
            const configTemp = getConfigWithHeader(config);
            return patch(path, body, configTemp);
        },
        delete: (path: string, body?: any, config?: any) => {
            const configTemp = getConfigWithHeader(config);
            return deleteMethod(path, body, configTemp);
        },
        put: (path: string, body?: any, config?: any) => {
            const configTemp = getConfigWithHeader(config);
            return put(path, body, configTemp);
        },
        withAccessToken: {
            get: (path: string, config?: any) => {
                config = getConfigWithAuthorizationHeader(config);
                return get(path, config);
            },
            post: (path: string, body?: any, config?: any) => {
                config = getConfigWithAuthorizationHeader(config);
                return post(path, body, config);
            },
            patch: (path: string, body?: any, config?: any) => {
                config = getConfigWithAuthorizationHeader(config);
                return patch(path, body, config);
            },
            delete: (path: string, body?: any, config?: any) => {
                config = getConfigWithAuthorizationHeader(config);
                return deleteMethod(path, body, config);
            },
            put: (path: string, body?: any, config?: any) => {
                config = getConfigWithAuthorizationHeader(config);
                return put(path, body, config);
            },
        },
    };
};

export {
    JsonServerConnection,
};
