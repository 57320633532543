import { Token } from '../model/Token';

const AUTH_TOKEN = 'auth_token';

interface LocalToken extends Token {
    access_token: string;
}

let savedToken: LocalToken = {
    access_token: ''
};

class AuthHelper {

    public setToken(token: Token): void {
        savedToken = { ...token};
        return localStorage.setItem(AUTH_TOKEN, JSON.stringify(savedToken));
    }

    public getToken(): LocalToken {
        const stringToken = localStorage.getItem(AUTH_TOKEN);
        const token: LocalToken = JSON.parse(stringToken!!);
        savedToken = { ...token };
        return token;
    }

    public clearToken(): void {
        savedToken = {
            access_token: '',
        };
        return localStorage.removeItem(AUTH_TOKEN);
    }

    public getAccessToken(): string {
        return this.getToken() ? this.getToken().access_token : "";
    }
}

const authHelper: AuthHelper = new AuthHelper();
export default authHelper;
