import Redux, { AnyAction } from 'redux';

import ProjectApi from '../api/Project';
import { Project } from '../model/Project';
import { ProjectUserBody } from '../model/payload/ProjectUserBody';
import { ProjectPMTBody } from '../model/payload/ProjectPMTBody';

export const SET_LIST_PROJECT = `PROJECT.SET_LIST_PROJECT`
export const SET_MY_PROJECT = `PROJECT.SET_MY_PROJECT`
export const SET_SELECTED_PROJECT = `PROJECT.SET_SELECTED_PROJECT`
export const TRACK_DONATION_PAGE = `PROJECT.TRACK_DONATION_PAGE`
export const TRACK_PROJECT_PAGE = `PROJECT.TRACK_PROJECT_PAGE`

export const setListProject: Redux.ActionCreator<Redux.AnyAction> = (payload: Project[]) => {
    return { type: SET_LIST_PROJECT, payload: payload }
}

export const setMyProject: Redux.ActionCreator<Redux.AnyAction> = (payload: Project[]) => {
    return { type: SET_MY_PROJECT, payload: payload }
}

export const setSelectedProject: Redux.ActionCreator<Redux.AnyAction> = (payload: Project) => {
    return { type: SET_SELECTED_PROJECT, payload: payload }
}

export const trackDonationPage: Redux.ActionCreator<Redux.AnyAction> = (payload: boolean) => {
    return { type: TRACK_DONATION_PAGE, payload: payload }
}

export const trackProjectPage: Redux.ActionCreator<Redux.AnyAction> = (payload: boolean) => {
    return { type: TRACK_PROJECT_PAGE, payload: payload }
}

export const getProjects = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string, name?: string) {
    try {
        const result: Project[] = await ProjectApi.getProjects(user_id, starting_after, order_by, limit, status, name);
        return result
    } catch (err: any) {
        throw err
    }
};
export const getPublicProjects = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string, name?: string) {
    try {
        const result: Project[] = await ProjectApi.getPublicProjects(user_id, starting_after, order_by, limit, status, name);
        return result
    } catch (err: any) {
        throw err
    }
};

export const submitProject = async function (payload: ProjectUserBody) {
    try {
        await ProjectApi.submitProject(payload);
    } catch (err: any) {
        throw err
    }
}

export const editProjectPMT = async function (project_id: string, payload: ProjectPMTBody) {
    try {
        await ProjectApi.editProjectPMT(project_id, payload);
    } catch (err: any) {
        throw err
    }
}

export const editProjectUser = async function (project_id: string, payload: ProjectUserBody) {
    try {
        await ProjectApi.editProjectUser(project_id, payload);
    } catch (err: any) {
        throw err
    }
}

export interface ProjectState {
    projects: Project[] | undefined
    myProjects: Project[] | undefined
    selectedProject: Project | undefined
    trackDonationPage: boolean
    trackProjectPage: boolean
}

export const initialProjectState: ProjectState = {
    projects: undefined,
    myProjects: undefined,
    selectedProject: undefined,
    trackDonationPage: false,
    trackProjectPage: false
}

const projectReducers = (state: ProjectState = initialProjectState, action: any): ProjectState => {
    switch (action.type) {
        case SET_LIST_PROJECT:
            return {
                ...state,
                projects: action.payload
            }
        case SET_MY_PROJECT:
            return {
                ...state,
                myProjects: action.payload
            }
        case SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.payload
            }
        case TRACK_DONATION_PAGE:
            return {
                ...state,
                trackDonationPage: action.payload
            }
        case TRACK_PROJECT_PAGE:
            return {
                ...state,
                trackProjectPage: action.payload
            }
        default: {
            return state;
        }
    }
}

export default projectReducers
