import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { Donation } from '../model/Donation';
import { ListDescription } from '../model/ListDescription';
import { DonationBody } from '../model/payload/DonationBody';

const donationConnectionWithAccessToken = JsonServerConnection(url, '/donations').withAccessToken;


const getDonations = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, payment_method?: string, status?: string, fromDate?: string, toDate?: string) => {
    let finalQuery = "?"

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (project_id && project_id !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "project_id=" + project_id
    }
    if (payment_method && payment_method !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "payment_method=" + payment_method
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }
    if (fromDate && fromDate !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "from_date=" + fromDate
    }
    if (toDate && toDate !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "to_date=" + toDate
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await donationConnectionWithAccessToken.get(finalQuery) as ListDescription<Donation>
    return result.data;
};

const getPublicDonations = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, payment_method?: string, status?: string, fromDate?: string, toDate?: string) => {
    let finalQuery = "?"

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (project_id && project_id !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "project_id=" + project_id
    }
    if (payment_method && payment_method !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "payment_method=" + payment_method
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }
    if (fromDate && fromDate !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "from_date=" + fromDate
    }
    if (toDate && toDate !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "to_date=" + toDate
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await JsonServerConnection(url, '/public-donations').get(finalQuery) as ListDescription<Donation>
    return result.data;
};

const submitDonation = async (payload: DonationBody) => {
    await donationConnectionWithAccessToken.post("/me", payload)
}
const submitPublicDonation = async (payload: DonationBody) => {
    await donationConnectionWithAccessToken.post("/public/me", payload)
}

export interface DonationApi {
    getDonations: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, payment_method?: string, status?: string, fromDate?: string, toDate?: string) => Promise<Donation[]>;
    getPublicDonations: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, payment_method?: string, status?: string, fromDate?: string, toDate?: string) => Promise<Donation[]>;
    submitDonation: (payload: DonationBody) => void;
    submitPublicDonation: (payload: DonationBody) => void;
}

export default {
    getDonations,
    getPublicDonations,
    submitDonation,
    submitPublicDonation
} as DonationApi;
