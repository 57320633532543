import React from 'react';
import {
    Image,
    Row,
    Col,
    Card,
    Button,
    Badge,
    Spinner,
} from 'react-bootstrap';
import './style.css';
import { Project } from '../../model/Project';
import { toSFDateFormat, convertToIDDateString } from '../../utils/Date';
import { toTitleCase, getStatusColor, projectStatusMapper } from '../../utils/General';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface ListProjectPMTProps {
    data: Project[]
    hasMoreItems: boolean
    onItemClick(project: Project): void
    onLoadMore(projectId: string): void
}

export interface ListProjectPMTAction {

}

export interface ListProjectPMTState {

}

class ListProjectPMTComponent extends React.Component<ListProjectPMTProps & ListProjectPMTAction, ListProjectPMTState> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    myProjectList() {
        return this.props.data.map((item) => {
            return (
                <Col className="card-donation-list-container box-shadow-add" key={item.id} style={{ margin: '0px', padding: '0px' }}>
                    <Col className="card-donation-list-inside" onClick={() => this.props.onItemClick(item)} style={{
                        backgroundColor: 'white',
                        position: 'relative',
                        padding: '32px',
                        wordBreak: 'break-all',
                        cursor: 'pointer', borderRadius: '4px',
                    }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            justifyContent: 'flex-start',
                        }}>
                            <div className="mobile-show div-img-title">
                                <Image className="img-donation-list" src={item.picture_url ? item.picture_url : require('../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                <div className="img-title-mobile" style={{ fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(item?.name)}</div>
                                <Badge style={{
                                    right: '12px',top: '12px', position: 'absolute',
                                    backgroundColor: getStatusColor(item?.status), color: 'white',
                                    fontSize: '12px', padding: '6px', paddingLeft: '20px', paddingRight: '20px'
                                }} pill>{projectStatusMapper(item?.status)}</Badge>
                            </div>
                            <Image className="desktop-show" style={{
                                width: '320px',
                                height: '187px',
                                objectFit: 'cover', borderRadius: '4px'
                            }} src={item.picture_url ? item.picture_url : require('../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />

                            <Col className="col-amount" style={{ margin: '0px', padding: '0px', marginLeft: '24px' }}>
                                <Row className="desktop-show" style={{
                                    margin: '0px', padding: '0px', justifyContent: 'space-between',
                                    alignItems: 'center', marginBottom: '16px', wordBreak: 'break-all'
                                }}>
                                    <div style={{ fontSize: '24px', color: '#333333' }} className="titleMyProject">{toTitleCase(item?.name)}</div>                                    
                                </Row>
                                <div style={{ fontSize: '16px', color: '#808080' }}>{item.organization ? item.organization.name : '-'}</div>
                                <div className="desktop-show">
									<Badge style={{
                                        backgroundColor: getStatusColor(item?.status), color: 'white',
                                        fontSize: '14px', padding: '8px', paddingLeft: '24px', paddingRight: '24px'
                                    }} pill>{projectStatusMapper(item?.status)}</Badge>
								</div>
                            </Col>
                        </Row>
                    </Col>
                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px',
                    }} />
                </Col>
            )
        })
    }

    render() {
        return (
            <InfiniteScroll
                scrollThreshold={0.5}
                dataLength={this.props.data.length}
                next={() => this.props.onLoadMore(this.props.data[this.props.data.length - 1].id)}
                hasMore={this.props.hasMoreItems}
                loader={
                    <Row style={{ backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: '0px', padding: '6px' }}>
                        <Spinner size="sm" animation="border" variant="secondary" />
                    </Row>
                }>
                {this.myProjectList()}
            </InfiniteScroll>
        )
    }
}

export default ListProjectPMTComponent