import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { ListDescription } from '../model/ListDescription';
import { ProgressBody } from '../model/payload/ProgressBody';
import { Progress } from '../model/Progress';

const progressConnectionWithAccessToken = JsonServerConnection(url, '/progress').withAccessToken;

const getProgress = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, type?: string, status?: string) => {
    let finalQuery = "?"

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (project_id && project_id !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "project_id=" + project_id
    }
    if (type && type !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "type=" + type
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await progressConnectionWithAccessToken.get(finalQuery) as ListDescription<Progress>
    return result.data;
};

const getPublicProgress = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, type?: string, status?: string) => {
    let finalQuery = "?"

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (project_id && project_id !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "project_id=" + project_id
    }
    if (type && type !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "type=" + type
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await JsonServerConnection(url, '/public-progress').get(finalQuery) as ListDescription<Progress>
    return result.data;
};

const submitProgress = async (payload: ProgressBody) => {
    await progressConnectionWithAccessToken.post("/me", payload)
}

const submitProgressPmt = async (payload: ProgressBody) => {
    await progressConnectionWithAccessToken.post("/pmt/submit", payload)
}

export interface ProgressApi {
    getProgress: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, type?: string, status?: string) => Promise<Progress[]>;
    getPublicProgress: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, type?: string, status?: string) => Promise<Progress[]>;
    submitProgress: (payload: ProgressBody) => void;
    submitProgressPmt: (payload: ProgressBody) => void;
}

export default {
    getProgress,
    getPublicProgress,
    submitProgress,
    submitProgressPmt
} as ProgressApi;
