import { JsonServerConnection } from '../utils/ApiHelper';

import { url } from '../utils/ApiUrl';
import { Payment } from '../model/Payment';
import { ListDescription } from '../model/ListDescription';
import { PaymentBody } from '../model/payload/PaymentBody';
import { PaymentMethod } from '../model/PaymentMethod';
import { GopayCharge } from '../model/payload/GopayCharge';
import { CardCharge } from '../model/payload/CardCharge';
import { PaymentCheck } from '../model/payload/PaymentCheck';
import { PaymentResult } from '../model/PaymentResult';
import { PaymentCheckStatus } from '../model/PaymentCheckStatus';

const paymentConnectionWithAccessToken = JsonServerConnection(url, '/payments');


const getPayments = async (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string) => {
    let finalQuery = "?"

    console.log(123)

    if (starting_after && starting_after !== "") {
        finalQuery += "starting_after=" + starting_after
    }
    if (order_by && order_by !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "order_by=" + order_by
    }
    if (limit && limit > 0) {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "limit=" + limit
    }
    if (status && status !== "") {
        if (finalQuery !== "?") {
            finalQuery += "&"
        }
        finalQuery += "status=" + status
    }

    if (user_id && user_id !== "") {
        finalQuery = "/" + user_id + finalQuery
    }
    const result = await paymentConnectionWithAccessToken.get(finalQuery) as ListDescription<Payment>
    return result.data;
};

const getPublicPaymentMethods = async () => {
    const result = await paymentConnectionWithAccessToken.get("/public-method") as ListDescription<PaymentMethod>
    return result.data;
};

const submitPayment = async (payload: PaymentBody) => {
    await paymentConnectionWithAccessToken.post("/me", payload)
}

const chargeGopay = async (payload: GopayCharge) => {
    const result = await paymentConnectionWithAccessToken.post("/gopay/charge", payload) as PaymentResult
    return result;
}

const chargeCard = async (payload: CardCharge) => {
    const result = await paymentConnectionWithAccessToken.post("/card/charge", payload) as PaymentResult
    return result;
}

const checkStatus = async (payload: PaymentCheck) => {
    const result = await paymentConnectionWithAccessToken.post("/check_status", payload) as PaymentCheckStatus
    return result;
}

export interface PublicPaymentApi {
    getPayments: (user_id?: string, starting_after?: string, order_by?: string, limit?: number, status?: string) => Promise<Payment[]>;
    getPublicPaymentMethods: () => Promise<PaymentMethod[]>;
    submitPayment: (payload: PaymentBody) => void;
    chargeGopay: (payload: GopayCharge) => Promise<PaymentResult>;
    chargeCard: (payload: CardCharge) => Promise<PaymentResult>;
    checkStatus: (payload: PaymentCheck) => Promise<PaymentCheckStatus>;
}

export default {
    getPayments,
    getPublicPaymentMethods,
    submitPayment,
    chargeGopay,
    chargeCard,
    checkStatus
} as PublicPaymentApi;
