import React, { useEffect } from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login'
import Dashboard from './pages/Dashboard';
import Public from './pages/Public';
import DonationPage from './pages/Donation/index';
import ProjectPage from './pages/Project/index';
import MyProjectDetailPage from './pages/Project/Detail/index';
import SubmissionPage from './pages/Submission/index';
import DetailProjectPage from './pages/Dashboard/Detail/index';
import PublicDetailProjectPage from './pages/Public/Detail/index';
import DonatePage from './pages/Dashboard/Donate/index';
import PublicDonatePage from './pages/Public/Donate/index';
import EditProfilePage from './pages/Profile/EditProfile/index';
import ProfilePage from './pages/Profile/index';
import PMTDashboard from './pages/PMTDashboard/index';
import PMTDashboardDetail from './pages/PMTDashboard/Detail/index';
import PaymentQRPage from './pages/Payment/QR/index';
import PaymentCardPage from './pages/Payment/Card/index';
import PublicPaymentQRPage from './pages/PublicPayment/QR/index';
import PublicPaymentCardPage from './pages/PublicPayment/Card/index';
import { connect } from 'react-redux';
import { AppState } from './AppState';
import authHelper from './utils/AuthHelper';
import { setToken } from './reducers/Auth';
import { Token } from './model/Token';
import TNCPage from './pages/TNC'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import AddProgress from './pages/AddProgress';
import UserManagement from './pages/UserManagement';
import AddUser from './pages/UserManagement/AddUser';
import ViewOrganization from './pages/UserManagement/ViewOrganization';

function App(props: any) {
  const token = authHelper.getToken();
  if (token) {
    props.setToken(token)
  }

  const renderToRedirectAuth = () => {
    return (<Redirect to='/dashboard' />)
  };

  const renderToRedirectUnAuth = () => {
    return (<Redirect to='/login' />)
  };

  if (token && token.access_token) {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/dashboard" render={() => <Dashboard />} />
          <Route exact path="/pmt-dashboard" render={() => <PMTDashboard />} />
          <Route exact path="/pmt-dashboard/detail" render={() => <PMTDashboardDetail />} />
          <Route exact path="/dashboard/detail" render={() => <DetailProjectPage />} />
          <Route exact path="/dashboard/detail/donate" render={() => <DonatePage />} />
          <Route exact path="/dashboard/my-donation" render={() => <DonationPage />} />
          <Route exact path="/dashboard/my-project" render={() => <ProjectPage />} />
          <Route exact path="/dashboard/my-project/detail" render={() => <MyProjectDetailPage />} />
          <Route exact path="/dashboard/submission" render={() => <SubmissionPage />} />
          <Route exact path="/dashboard/my-profile" render={() => <ProfilePage />} />
          <Route exact path="/dashboard/my-profile/edit-profile" render={() => <EditProfilePage />} />
          <Route exact path="/add-progress" render={() => <AddProgress />} />
          <Route exact path="/user-management" render={() => <UserManagement />} />
          <Route exact path="/user-management/add" render={() => <AddUser />} />
          <Route exact path="/user-management/view-organization" render={() => <ViewOrganization />} />
          <Route exact path="/tnc" render={() => <TNCPage />} />
          <Route exact path="/privacy-policy" render={() => <PrivacyPolicyPage />} />
          <Route exact path="/payment/qr" render={() => <PaymentQRPage />} />
          <Route exact path="/payment/card" render={() => <PaymentCardPage />} />
          <Route render={renderToRedirectAuth} />
        </Switch>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/" render={() => <Public />} />
          <Route exact path="/login" render={() => <Login />} />
          <Route exact path="/donate/login" render={() => <Login />} />
          <Route exact path="/public/detail" render={() => <PublicDetailProjectPage />} />
          <Route exact path="/public/detail/donate" render={() => <PublicDonatePage />} />


          <Route exact path="/tnc" render={() => <TNCPage />} />
          <Route exact path="/public/payment/qr" render={() => <PublicPaymentQRPage />} />
          <Route exact path="/public/payment/card" render={() => <PublicPaymentCardPage />} />
          <Route exact path="/privacy-policy" render={() => <PublicPaymentCardPage />} />
          {/* <Route render={renderToRedirectUnAuth} /> */}



        </Switch>
      </React.Fragment>
    )
  }
}

export default connect(
  (state: AppState) => ({
    isAuthorized: !!state.auth.token,
  }),
  (dispatch) => ({
    setToken: (token: Token) => dispatch(setToken(token))
  })
)(App);
