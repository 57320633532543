import * as React from 'react'
import { connect } from 'react-redux';
import {
    Image,
    Row,
    Col,
    FormControl,
    FormLabel,
    InputGroup,
    Button,
    Card,
    ProgressBar,
    Alert,
    DropdownButton,
    Dropdown,
} from 'react-bootstrap';
import './style.css';
import { AppState } from '../../../AppState';
import PublicNavbarComponent from '../../../component/publicNavbar';
import { Project } from '../../../model/Project';
import { toCurrency } from '../../../utils/Currency';
import { Dispatch } from 'redux';
import { DonationBody } from '../../../model/payload/DonationBody';
import { submitPublicDonation } from '../../../reducers/Donation';
import { PaymentBody } from '../../../model/payload/PaymentBody';
import { submitPayment, getPayments, setMyPayment, getPaymentMethods, setPaymentMethod, publicGopayCharge, publicCheckStatus } from '../../../reducers/Payment';
import { Payment } from '../../../model/Payment';
import { User } from '../../../model/User';
import { toTitleCase, removeAllSpecialChar, getTranslate, paymentMethodMapper } from '../../../utils/General';
import { PaymentMethod } from '../../../model/PaymentMethod';
import Navigation from '../../../utils/Navigation';
import { manyDaysBetween } from '../../../utils/Date';
import NumberFormat from 'react-number-format';
import { PublicGopayCharge } from '../../../model/payload/GopayCharge';
import { PaymentResult } from '../../../model/PaymentResult';
import { PaymentCheck } from '../../../model/payload/PaymentCheck';
import { PaymentCheckStatus } from '../../../model/PaymentCheckStatus';

export interface PaymentQRProps {
    selectedProject: Project,
    payment: Payment[],
    user: User,
    paymentMethods: PaymentMethod[],
    paymentAmount: string,
    isAnonim: boolean,
    donatur: string,
    contact: string
}

export interface PaymentQRActions {
    submitPublicDonation(payload: DonationBody): void
    submitPayment(payload: PaymentBody): void
    setPayment(payment: Payment[]): void
    getPayments(userId: string): any
    getPaymentMethods(): any
    setPaymentMethod(paymentMethod: PaymentMethod[]): void
    publicGopayCharge(payload: PublicGopayCharge): any
    publicCheckStatus(payload: PaymentCheck): any
}

export interface PaymentQRState {
    isAnonim: boolean
    amount: string
    paymentMethod: string
    paymentMethodIcon: any
    isLoading: boolean
    showAlert: Boolean
    isError: string
    gopayUrl: string
    txtPayment: string
    trxId: string
    isClosed: boolean
}

class PaymentQRPage extends React.Component<PaymentQRProps & PaymentQRActions, PaymentQRState>{

    constructor(props: any) {
        super(props);
        this.state = {
            isAnonim: false,
            amount: '',
            paymentMethod: 'none',
            paymentMethodIcon: undefined,
            isLoading: false,
            showAlert: false,
            isError: "",
            gopayUrl: "",
            txtPayment: getTranslate("Sedang memuat QR..."),
            trxId: "",
            isClosed: false
        }
    }

    getPercentage(project: Project): number {
        const value = Math.round((parseInt(project.current_amount) / parseInt(project.target_amount)) * 100)
        return value
    }

    async loadData() {
        try {
            const result: PaymentResult = await this.props.publicGopayCharge({ 
                amount: this.props.paymentAmount, 
                callback_url: "" ,
                user_name: this.props.donatur ,
                contact: this.props.contact
            })
            result.actions.forEach((ac) => {
                if (ac.name === "generate-qr-code") {
                    this.setState({ gopayUrl: ac.url, txtPayment: getTranslate("Halaman akan otomatis di perbarui saat melakukan pembayaran via Gopay") })
                }
            })
            this.setState({ trxId: result.order_id })
            this.checkPaymentStatus()
        } catch (e: any) {
            console.error(e)
        }
    }

    async checkPaymentStatus() {
        if (!this.state.isClosed) {
            const result: PaymentCheckStatus = await this.props.publicCheckStatus({ id: this.state.trxId })

            if (result.transaction_status == "settlement") {
                this.submitPublicDonation(result)
            } else {
                this.checkPaymentStatus()
            }
        }
    }

    async submitPublicDonation(result: PaymentCheckStatus) {
        try {
            this.setState({ isLoading: true })
            console.log("this.prop ", this.props)
            var donation: DonationBody = {
                amount: this.props.paymentAmount,
                project_id: this.props.selectedProject.id,
                payment_method: "gopay",
                is_anonim: this.props.isAnonim,
                payment_id: result.order_id,
                project_name: removeAllSpecialChar(this.props.selectedProject.name),
                project_picture_url: removeAllSpecialChar(this.props.selectedProject.picture_url),
                project_status: this.props.selectedProject.status,
                project_target_amount: parseInt(this.props.selectedProject.target_amount),
                user_name: removeAllSpecialChar(this.props.donatur),
                user_organization_id: "",
                // user_organization_id: this.props.user.organization_id,
                user_access_level: "general",
                // user_access_level: this.props.user.access_level,
                user_picture_url: "",
                // user_picture_url: removeAllSpecialChar(this.props.user.picture_url),
                user_email: removeAllSpecialChar(this.props.contact),
                user_phone: this.props.contact,
                user_is_social_media_register: false,
                // user_is_social_media_register: this.props.user.is_social_media_register,
                payment_status: "succeed",
                payment_response: JSON.stringify(result)
            }
            console.log(donation)
            await this.props.submitPublicDonation(donation)

            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ isLoading: false, showAlert: false })
                Navigation.to('/')
            }, 1000);
        } catch (e: any) {
            console.log(e)
            this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
        this.setState({ isClosed: true })
    }

    onBackHome() {
        Navigation.to('/public/detail/donate')
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <PublicNavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="payment-qr-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Metode Pembayaran')}</div>
                            </div>
                        </Col>

                        <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between' }}>

                            {/* Left Card */}
                            <Col className="qr-container" style={{ margin: '0px', padding: '0px', marginRight: '36px', alignSelf: 'center', textAlign: 'center' }}>
                                <Image style={{
                                    height: '180px', objectFit: 'cover',
                                }} src={this.state.gopayUrl ? this.state.gopayUrl : require('../../../../src/assets/images/qr_placeholder.png')} />
                                <div style={{ fontWeight: 'bold', fontSize: '12px', color: '#333333', marginTop: '24px' }}>{this.state.txtPayment}</div>
                            </Col>

                            {/* Right Card */}
                            <Col className="qr-card-container" style={{ margin: '0px', padding: '0px', marginLeft: '36px' }}>
                                <Card style={{
                                    borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }}>
                                    <Image style={{
                                        height: '215px', objectFit: 'cover', borderRadius: '4px'
                                    }} src={this.props.selectedProject.picture_url ? this.props.selectedProject.picture_url : require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                    <Col style={{ margin: '0px', padding: '16px', alignItems: 'center' }}>
                                        <div className="font-mobile-16" style={{ fontWeight: 'bold', fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(this.props.selectedProject.name)}</div>
                                        <ProgressBar style={{ marginBottom: '16px', borderRadius: '23px', height: '8px' }} now={this.getPercentage(this.props.selectedProject)}></ProgressBar>
                                        <div className="font-mobile-14" style={{ fontWeight: 'bold', fontSize: '18px', color: '#333333', marginBottom: '8px' }}>
                                            {toCurrency(this.props.selectedProject.current_amount)}
                                        </div>
                                        <div className="font-mobile-12" style={{ fontSize: '14px', color: '#333333', marginBottom: '8px' }}>
                                            {getTranslate('terkumpul dari target ')} {toCurrency(this.props.selectedProject.target_amount)}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }} src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} className="miniImage" />
                                            <div className="font-mobile-12" style={{ fontSize: '14px', color: '#808080' }}>{this.props.selectedProject.close_funding_date ? manyDaysBetween(this.props.selectedProject.close_funding_date) + getTranslate(' hari tersisa') : '-'}</div>
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Berhasil donasi')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        selectedProject: state.project.selectedProject,
        user: state.user.user,
        payment: state.payment.myPayments,
        paymentMethods: state.payment.paymentMethods,
        paymentAmount: state.payment.amountPayment,
        isAnonim: state.payment.isAnonim,
        donatur: state.payment.userFullName,
        contact: state.payment.userContact
    } as PaymentQRProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        submitPublicDonation: (payload: DonationBody) => submitPublicDonation(payload),
        submitPayment: (payload: PaymentBody) => submitPayment(payload),
        getPayments: (userId: string) => getPayments(userId),
        setPayment: (payments: Payment[]) => dispatch(setMyPayment(payments)),
        getPaymentMethods: () => getPaymentMethods(),
        setPaymentMethod: (paymentMethod: PaymentMethod[]) => dispatch(setPaymentMethod(paymentMethod)),
        publicGopayCharge: (payload: PublicGopayCharge) => publicGopayCharge(payload),
        publicCheckStatus: (payload: PaymentCheck) => publicCheckStatus(payload),
    } as PaymentQRActions;
}

export default connect(stateToProps, dispatchToProps)(PaymentQRPage);
