import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
    Button,
    Modal,
} from 'react-bootstrap';
import './style.css';

import NavbarComponent from '../../component/navbar';
import ListMyProjectComponent from '../../component/listMyProject';
import { Project } from '../../model/Project';
import { User } from '../../model/User';
import { getProjects, setMyProject, setSelectedProject, trackProjectPage } from '../../reducers/Project';
import { Dispatch } from 'redux';
import Navigation from '../../utils/Navigation';
import { getTranslate } from '../../utils/General';

export interface ProjectPageProps {
    user: User,
    project: Project[],
}

export interface ProjectPageAction {
    setMyProject(project: Project[]): void
    getProjects(userId: string, orderBy: string, startingAfter?: string): any
    selectedProject(project: Project): void
    trackProjectPage(a: boolean): void
}

export interface ProjectPageState {
    hasMoreItems: boolean
    showModal: boolean
}

class ProjectPage extends React.Component<ProjectPageProps & ProjectPageAction, ProjectPageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            hasMoreItems: true,
            showModal: false
        }
    }

    async loadData() {
        try {
            const projects: Project[] = await this.props.getProjects(this.props.user.id, 'updated_at_desc')
            if (projects && projects.length > 0)
                this.props.setMyProject(projects)
        } catch (e: any) {
            console.error(e)
        }
    }

    async onLoadMore(id: string) {
        try {
            // this.setState({ hasMoreItems: true })
            const listProject: Project[] = await this.props.getProjects(this.props.user.id, 'updated_at_desc', id)
            if (listProject && listProject.length > 0) {
                const currentProject = this.props.project.concat(...listProject)
                this.props.setMyProject(currentProject)
                this.setState({ hasMoreItems: true })
            } else {
                this.setState({ hasMoreItems: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItems: false })
        }
    }

    async componentDidMount() {
        this.loadData()
    }

    onItemProjectClick(project: Project) {
        this.props.trackProjectPage(true)
        this.props.selectedProject(project)
        Navigation.to('/dashboard/my-project/detail')
    }

    onBackHome() {
        Navigation.to('/dashboard')
    }

    onSubmissionClick() {
        if (this.props.user && ((this.props.user.id_card_status &&
            this.props.user.id_card_status.toLowerCase() === 'approved') ||
            (this.props.user.access_level !== 'general'))) {
            this.props.trackProjectPage(true)
            Navigation.to('/dashboard/submission')
        } else {
            this.setState({ showModal: true })
        }
    }

    emptyState = () => {
        return (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Image className="image-pohon-empty" style={{ objectFit: 'contain' }} src={require('../../../src/assets/images/illus-empty-project/illus-empty-project.png')} />
                <p style={{ fontSize: '16px', marginTop: '36px', color: '#808080' }} className="titleMyProject">{getTranslate('Belum ada proyek')}</p>
                <Button style={{
                    fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                    backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                }} onClick={() => this.onSubmissionClick()}>{getTranslate('Create New Project')}</Button>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="project-list-container" style={{ margin: '0px', padding: '0px' }} >
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <Row className="margin-bottom-16" style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', marginBottom: '40px', alignItems: 'center' }}>
                                <div className="div-text-page-title" style={{ fontSize: '36px', color: '#333333', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{getTranslate('My Project')}</div>
                                <div className="desktop-show" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                    {this.props.project && this.props.project.length > 0 &&
                                        <Button style={{
                                            fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                            backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                                        }} onClick={() => this.onSubmissionClick()}>{getTranslate('Create New Project')}</Button>
                                    }
                                </div>
                            </Row>
                            <div className="mobile-show" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                {this.props.project && this.props.project.length > 0 &&
                                    <Button className="mobile-button" style={{
                                        fontWeight: 'bold', paddingLeft: '32px', paddingRight: '32px',
                                        backgroundColor: '#5bc69a', borderColor: '#5bc69a', opacity: '1'
                                    }} onClick={() => this.onSubmissionClick()}>{getTranslate('Create New Project')}</Button>
                                }
                            </div>
                            {this.props.project && this.props.project.length > 0 ?
                                <Col className="box-shadow-remove" style={{
                                    margin: '0px', padding: '0px',
                                    borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }}>
                                    <ListMyProjectComponent
                                        data={this.props.project}
                                        onLoadMore={(id: string) => this.onLoadMore(id)}
                                        hasMoreItems={this.state.hasMoreItems}
                                        onItemClick={(item) => this.onItemProjectClick(item)} />
                                </Col>
                                : <div>{this.emptyState()}</div>
                            }
                        </Col>
                        <Modal
                            show={this.state.showModal}
                            centered
                        >
                            <Modal.Header closeButton onClick={() => this.setState({ showModal: false })} style={{
                                padding: '24px'
                            }}>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <div style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '24px',
                                        fontWeight: 'bold',
                                        color: '#333333',
                                    }}>{getTranslate('Unverified User')}</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{
                                padding: '24px'
                            }}>
                                <div style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '16px',
                                    color: '#333333',
                                }}>{this.props.user?.id_card ?
                                    (this.props.user?.id_card.toLowerCase() === 'rejected' ?
                                        getTranslate('Status profile anda ditolak oleh admin, silakan melakukan verifikasi ulang dengan melakukan update data profile anda dan selanjutnya data anda akan di verifikasi ulang oleh admin.')
                                        :
                                        getTranslate('Status profile anda masih belum terverifikasi oleh admin, silakan menunggu sekitar 2x24 jam untuk proses verifikasi.')) :
                                    getTranslate('Anda perlu verifikasi akun untuk membuat project dengan mengupload KTP di bagian menu profile dan selanjutnya data anda akan di verifikasi oleh admin.')}</div>
                            </Modal.Body>
                            <Modal.Footer style={{
                                padding: '24px'
                            }}>
                                <Button style={{
                                    fontWeight: 'bold',
                                    color: 'white',
                                    borderColor: '#5bc69a', paddingLeft: '32px', paddingRight: '32px',
                                    backgroundColor: '#5bc69a', opacity: '1'
                                }} onClick={() => this.setState({ showModal: false })} block
                                >{getTranslate('Ok, saya mengerti')}</Button>
                            </Modal.Footer>
                        </Modal>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        project: state.project.myProjects,
    } as ProjectPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        getProjects: (userId: string, orderBy: string, startingAfter?: string) => getProjects(userId, startingAfter, orderBy),
        setMyProject: (projects: Project[]) => dispatch(setMyProject(projects)),
        selectedProject: (project: Project) => dispatch(setSelectedProject(project)),
        trackProjectPage: (a: boolean) => dispatch(trackProjectPage(a))
    } as ProjectPageAction;
}

export default connect(stateToProps, dispatchToProps)(ProjectPage);
