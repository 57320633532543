/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Navbar,
    Nav,
    Image,
    Row,
    Col,
    Alert,
    Card,
    Button,
    ListGroup,
    FormControl,
    FormLabel,
    InputGroup,
    ProgressBar,
} from 'react-bootstrap';
import '../Login/style.css';
import NavbarComponent from '../../component/navbar';
import { Authenticate } from '../../model/payload/Authenticate';
import { UserState, setEditedPage, getOrganization, setOrganization } from '../../reducers/User';
import { authenticate } from '../../reducers/Auth';
import './style.css';
import { Organization } from '../../model/Organization';
import { User } from '../../model/User';
import { getInitialName, toTitleCase, mappingAccessLevelUser, mappingStatusUser, bytesToSize, removeAllSpecialChar, getTranslate } from '../../utils/General';
import { setUser } from '../../reducers/User';
import { me } from '../../reducers/User';
import Navigation from '../../utils/Navigation';
import { validateUrl } from '../../utils/General';
import { getFileExtensions } from '../../utils/General';
import { Media } from '../../model/Media';
import { clearMedia } from '../../reducers/Media';
import { setUploadedMedia } from '../../reducers/Media';
import { getUploadedMedia } from '../../reducers/Media';
import { ProgressBody } from '../../model/payload/ProgressBody';
import { submitProgress } from '../../reducers/Progress';
import { Project } from '../../model/Project';
import { submitProgressPmt } from '../../reducers/Progress';
import NumberFormat from 'react-number-format';

export interface AddProgressProps {
    user: User,
    trackProjectPage: boolean,
    selectedProject: Project
}

export interface AddProgressAction {
    mediaUpload(data: FormData, id_user: string, file_type: string): any
    submitProgress(progress: ProgressBody): any
}

export interface AddProgressState {
    amount: string
    name: string
    description: string
    showAlert: Boolean
    isError: string
    loading: boolean

    uploadedFile: string[]
    uploadedName: string[]
    uploadedSize: string[]
    uploadedFormData: FormData[]
}

class AddProgress extends React.Component<AddProgressProps & AddProgressAction, AddProgressState>{

    constructor(props: any) {
        super(props);
        this.state = {
            amount: "",
            name: "",
            description: "",
            loading: false,
            showAlert: false,
            isError: "",
            uploadedFile: [],
            uploadedName: [],
            uploadedSize: [],
            uploadedFormData: []
        }
    }

    onBackHome() {
        if (this.props.trackProjectPage) {
            Navigation.to('/pmt-dashboard/detail')
        } else {
            Navigation.to('/dashboard')
        }
    }

    async submitProgress() {
        try {
            this.setState({
                loading: true
            })

            if (this.props.selectedProject) {
                let progressPayload: ProgressBody = {
                    progress_name: removeAllSpecialChar(this.state.name),
                    progress_desc: removeAllSpecialChar(this.state.description),
                    project_id: this.props.selectedProject.id,
                    amount: this.state.amount,
                    user_name: removeAllSpecialChar(this.props.user.name)
                }
                if (this.state.uploadedFormData.length > 0) {
                    progressPayload.invoice_doc = []
                    for (const mediaItem of this.state.uploadedFormData) {
                        const media: Media = await this.props.mediaUpload(mediaItem, this.props.user.id, 'invoice_doc')
                        progressPayload.invoice_doc!!.push(media.data)
                    }
                }
                await this.props.submitProgress(progressPayload)
                this.setState({ showAlert: true })
                setTimeout(() => {
                    this.setState({ loading: false, showAlert: false })
                    this.onBackHome()
                }, 1000);
            }
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    clickAddProgress = () => {
        this.submitProgress()
    }

    validationButton = () => {
        const s = this.state
        if (s.name && s.description) {
            return false
        } else {
            return true
        }
    }

    onClickUpload = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);

            const uploadedFiles = this.state.uploadedFile
            const uploadedNames = this.state.uploadedName
            const uploadedSizes = this.state.uploadedSize
            const formDatas = this.state.uploadedFormData
            uploadedFiles.push(URL.createObjectURL(event.target.files[0]))
            uploadedNames.push(event.target.files[0].name)
            uploadedSizes.push(bytesToSize(event.target.files[0].size))
            formDatas.push(data)

            this.setState({
                uploadedFile: uploadedFiles,
                uploadedFormData: formDatas
            });
        }
    }

    onRemoveMedia = (indexMedia: number) => {
        let uploadedFile = this.state.uploadedFile
        let uploadedName = this.state.uploadedName
        let uploadedSize = this.state.uploadedSize
        let uploadedFormData = this.state.uploadedFormData

        if (uploadedFile.length <= 1) {
            uploadedFile = []
            uploadedName = []
            uploadedSize = []
            uploadedFormData = []
        } else {
            uploadedFile.forEach((item: string, index: number) => {
                if (indexMedia === index) {
                    uploadedFile.splice(uploadedFile.indexOf(item), 1);
                }
            })
            uploadedName.forEach((item: string, index: number) => {
                if (indexMedia === index) {
                    uploadedName.splice(uploadedName.indexOf(item), 1);
                }
            })
            uploadedSize.forEach((item: string, index: number) => {
                if (indexMedia === index) {
                    uploadedSize.splice(uploadedSize.indexOf(item), 1);
                }
            })
            uploadedFormData.forEach((item: FormData, index: number) => {
                if (indexMedia === index) {
                    uploadedFormData.splice(uploadedFormData.indexOf(item), 1);
                }
            })
        }

        this.setState({ uploadedFile, uploadedName, uploadedFormData, uploadedSize })
    }

    renderMediaList() {
        var uploadedNames = this.state.uploadedName
        var uploadedSizes = this.state.uploadedSize
        return this.state.uploadedFile.map((item, index) => {
            return (
                <Col key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{ margin: '0px', padding: '0px' }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            width: '64px', height: '64px', marginRight: '16px',
                            backgroundColor: '#cccccc', borderRadius: '4px',
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            {validateUrl(uploadedNames[index]) && (getFileExtensions(uploadedNames[index].split('.')) === 'PNG' || getFileExtensions(uploadedNames[index].split('.')) === 'JPG' || getFileExtensions(uploadedNames[index].split('.')) === 'JPEG') ?
                                <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item} />
                                :
                                <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(uploadedNames[index].split('.'))}</div>
                            }
                        </Row>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{uploadedNames[index].split("/").pop()}</div>
                            <div style={{ color: '#808080', fontSize: '12px', fontWeight: 'normal' }}>{uploadedSizes[index]}</div>
                        </Col>

                        <Image onClick={() => this.onRemoveMedia(index)} style={{ width: '24px', height: '24px', cursor: 'pointer' }} src={require("../../../src/assets/images/ic-common-action-close-gray/ic-common-action-close-gray.png")} />
                    </Row>

                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px', marginTop: '16px'
                    }} />
                </Col>
            )
        })
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="add-progress-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Detail Proyek')}</div>
                            </div>
                        </Col>

                        <Col style={{
                            margin: '0px', padding: '0px',
                            width: '100%',
                            wordBreak: 'break-all',
                            backgroundColor: 'white',
                            cursor: 'default',
                            borderColor: 'transparent', marginBottom: '24px',
                            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                        }}>
                            <Col className="card-submission" style={{ margin: '0px', padding: '0px' }}>
                                <div className="card-label-title" style={{
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                    fontSize: '24px', color: '#333333', fontWeight: 'bold'
                                }}>{getTranslate('Add New Progress')}</div>
                                <div className="garis" style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)', height: '1px' }} />
                                <Col className="padding-0" style={{
                                    margin: '0px', padding: '0px',
                                    paddingTop: '24px', paddingBottom: '24px', paddingLeft: '82px', paddingRight: '82px',
                                }}>
                                    <Row style={{ margin: '0px', padding: '0px' }}>
                                        <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                            <FormLabel>{getTranslate('Jumlah amount dalam progress (opsional)')}</FormLabel>
                                            <NumberFormat
                                                style={{ width: '100%' }}
                                                id="number_format"
                                                thousandSeparator={true}
                                                onValueChange={(event: any) => this.setState({ amount: event.value })} />
                                        </Col>
                                        <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                                            <FormLabel>{getTranslate('Nama progress')}</FormLabel>
                                            <FormControl placeholder=""
                                                onChange={(event: any) => this.setState({ name: event.target.value })}
                                            />
                                        </Col>
                                    </Row>
                                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <FormLabel>{getTranslate('Deskripsi progress')}</FormLabel>
                                        <FormControl placeholder="" as="textarea"
                                            onChange={(event: any) => this.setState({ description: event.target.value })}
                                        />
                                    </Col>

                                    <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                        <Col style={{ margin: '0px', padding: '0px' }}>
                                            <FormLabel>{getTranslate('Upload bukti transfer')}</FormLabel>
                                            <div className="uploader">
                                                <div style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>{getTranslate('Drag & drop')}</div>
                                                <div style={{ color: '#808080', fontSize: '16px', marginTop: '4px' }}>{getTranslate('atau')}
                                                    <a style={{ color: '#1aa59a' }}>{getTranslate(' browse ')}</a>{getTranslate('your files')}</div>
                                                <div style={{ color: '#d8d8d8', fontSize: '14px', marginTop: '10px' }}>(format: doc, pdf, xls, image)</div>
                                                <input
                                                    type="file" name="project-file"
                                                    onChange={this.onClickUpload} id="filePhoto"
                                                    accept=".doc, .docx, .pdf, .xls, .xlsx, image/*" />
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.uploadedFile && this.state.uploadedName && this.state.uploadedName.length > 0 && this.state.uploadedFile.length > 0 &&
                                        <Col style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                                            {this.renderMediaList()}
                                        </Col>
                                    }
                                    <Row className="desktop-show" style={{ margin: '0px', padding: '0px', marginTop: '24px', justifyContent: 'flex-end' }}>
                                        <Button style={{
                                            fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                            color: this.validationButton() ? '#808080' : 'white',
                                            borderColor: this.validationButton() ? '#cccccc' : '#5bc69a',
                                            backgroundColor: this.validationButton() ? '#cccccc' : '#5bc69a', opacity: '1'
                                        }}
                                            className={this.state.loading ? "ui primary loading button" : ""}
                                            disabled={this.validationButton()}
                                            onClick={() => this.clickAddProgress()}>
                                            {getTranslate('Simpan')}</Button>
                                    </Row>
                                </Col>
                            </Col>
                        </Col>
                        <Row className="mobile-show submit-button-row" style={{ margin: '0px', padding: '0px', marginTop: '24px', justifyContent: 'flex-end' }}>
                            <Button style={{
                                fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                color: this.validationButton() ? '#808080' : 'white',
                                borderColor: this.validationButton() ? '#cccccc' : '#5bc69a',
                                backgroundColor: this.validationButton() ? '#cccccc' : '#5bc69a', opacity: '1'
                            }}
                                className={this.state.loading ? "ui primary loading button" : ""}
                                disabled={this.validationButton()}
                                onClick={() => this.clickAddProgress()}>
                                {getTranslate('Simpan')}</Button>
                        </Row>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Progress berhasil ditambahkan')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div >
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        trackProjectPage: state.project.trackProjectPage,
        selectedProject: state.project.selectedProject
    } as AddProgressProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        mediaUpload: (data: FormData, id_user: string, file_type: string) => getUploadedMedia(data, id_user, file_type),
        submitProgress: (progress: ProgressBody) => submitProgressPmt(progress)
    } as AddProgressAction;
}

export default connect(stateToProps, dispatchToProps)(AddProgress);
