import Redux, { AnyAction } from 'redux';
import { Authenticate } from '../model/payload/Authenticate';
import { Register } from '../model/payload/Register';
import UserApi from '../api/User';
import { Token } from '../model/Token';
import { User } from '../model/User';
import authHelper from '../utils/AuthHelper';
import { OrganizationBody } from '../model/payload/OrganizationBody';
import { Organization } from '../model/Organization';
import { CheckPassBody } from '../model/payload/CheckPassBody';
import { NewPasswordBody } from '../model/payload/NewPasswordBody';
import { OrganizationName } from '../model/OrganizationName';

export const SET_TOKEN = `AUTH.SET_TOKEN`

export const setToken: Redux.ActionCreator<Redux.AnyAction> = (token: Token) => {
    return { type: SET_TOKEN, payload: token }
}
export const authenticate = async function (payload: Authenticate) {
    try {
        const result = await UserApi.authenticate(payload);
        await authHelper.setToken(result)
        return result
    } catch (err: any) {
        throw err
    }
};
export const forgot = async function (payload: Authenticate) {
    try {
        const result = await UserApi.authenticate(payload);
        await authHelper.setToken(result)
        return result
    } catch (err: any) {
        throw err
    }
};

export interface AuthState {
    token: Token | undefined
}

export const initialAuthState: AuthState = {
    token: undefined
}

const authReducers = (state: AuthState = initialAuthState, action: any): AuthState => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        default: {
            return state;
        }
    }
}

export default authReducers
