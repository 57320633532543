import * as React from 'react'
import { connect } from 'react-redux';
import {
    Image,
    Row,
    Col,
    FormControl,
    FormLabel,
    InputGroup,
    Button,
    Card,
    ProgressBar,
    Alert,
    DropdownButton,
    Dropdown,
    Modal,
} from 'react-bootstrap';
import './style.css';
import { AppState } from '../../../AppState';
import NavbarComponent from '../../../component/navbar';
import { Project } from '../../../model/Project';
import { toCurrency } from '../../../utils/Currency';
import { Dispatch } from 'redux';
import { DonationBody } from '../../../model/payload/DonationBody';
import { submitDonation } from '../../../reducers/Donation';
import { PaymentBody } from '../../../model/payload/PaymentBody';
import { submitPayment, getPayments, setMyPayment, getPaymentMethods, setPaymentMethod, chargeGopay, checkStatus, chargeCard } from '../../../reducers/Payment';
import { Payment } from '../../../model/Payment';
import { User } from '../../../model/User';
import { toTitleCase, removeAllSpecialChar, getTranslate, paymentMethodMapper } from '../../../utils/General';
import { PaymentMethod } from '../../../model/PaymentMethod';
import Navigation from '../../../utils/Navigation';
import { manyDaysBetween } from '../../../utils/Date';
import NumberFormat from 'react-number-format';
import { GopayCharge } from '../../../model/payload/GopayCharge';
import { PaymentResult } from '../../../model/PaymentResult';
import { PaymentCheck } from '../../../model/payload/PaymentCheck';
import { PaymentCheckStatus } from '../../../model/PaymentCheckStatus';
import { CardCharge } from '../../../model/payload/CardCharge';

export interface PaymentCardProps {
    selectedProject: Project,
    payment: Payment[],
    user: User,
    paymentMethods: PaymentMethod[],
    paymentAmount: string,
    isAnonim: boolean
}

export interface PaymentCardActions {
    submitDonation(payload: DonationBody): void
    submitPayment(payload: PaymentBody): void
    setPayment(payment: Payment[]): void
    getPayments(userId: string): any
    getPaymentMethods(): any
    setPaymentMethod(paymentMethod: PaymentMethod[]): void
    chargeCard(payload: CardCharge): any
    checkStatus(payload: PaymentCheck): any
}

export interface PaymentCardState {
    isAnonim: boolean
    amount: string
    paymentMethod: string
    paymentMethodIcon: any
    isLoading: boolean
    showAlert: Boolean
    isError: string
    cardNumber: string
    cardMM: string
    cardYY: string
    cardCVV: string
    txtPayment: string
    trxId: string
    show3DSDialog: boolean
    redirectUrl: string
}

class PaymentCardPage extends React.Component<PaymentCardProps & PaymentCardActions, PaymentCardState>{
    constructor(props: any) {
        super(props);
        this.state = {
            isAnonim: false,
            amount: '',
            paymentMethod: 'none',
            paymentMethodIcon: undefined,
            isLoading: false,
            showAlert: false,
            isError: "",
            cardNumber: "",
            cardMM: "",
            cardYY: "",
            cardCVV: "",
            txtPayment: getTranslate("Sedang memuat QR..."),
            trxId: "",
            show3DSDialog: false,
            redirectUrl: ""
        }
    }

    disableBtn = () => {
        if (this.state.cardNumber && this.state.cardMM && this.state.cardYY && this.state.cardCVV) {
            return false;
        } else {
            return true;
        }
    }

    getPercentage(project: Project): number {
        const value = Math.round((parseInt(project.current_amount) / parseInt(project.target_amount)) * 100)
        return value
    }

    generatedCardData() {
        this.setState({ isLoading: true })
        let cardData = {
            "card_number": this.state.cardNumber,
            "card_exp_month": this.state.cardMM,
            "card_exp_year": this.state.cardYY,
            "card_cvv": this.state.cardCVV,
        };
        let options = {
            onSuccess: this.successGeneratedCardData,
            onFailure: this.failedGeneratedCardData
        };
        (window as any).MidtransNew3ds.getCardToken(cardData, options);
    }

    successGeneratedCardData = (response: any) => {
        this.loadData(response.token_id)
    }

    failedGeneratedCardData = (response: any) => {
        this.setState({ isLoading: false, isError: getTranslate('Data yang dimasukan salah'), showAlert: true, show3DSDialog: false })
        setTimeout(() => {
            this.setState({ showAlert: false, isError: "" })
        }, 3000);
    }

    async loadData(tokenId: string) {
        try {
            const result: PaymentResult = await this.props.chargeCard({ amount: this.props.paymentAmount, token_id: tokenId })
            this.setState({ trxId: result.order_id, isLoading: false })
            if (result.redirect_url) {
                this.proccedWith3DS(result.redirect_url)
            }
        } catch (e: any) {
            console.error(e)
        }
    }

    proccedWith3DS = (redirectUrl: string) => {
        var options = {
            performAuthentication: this.performAuth3DS,
            onSuccess: this.failedOrSuccess3DS,
            onFailure: this.failedOrSuccess3DS,
            onPending: this.failedOrSuccess3DS
        };

        (window as any).MidtransNew3ds.authenticate(redirectUrl, options);
    }

    performAuth3DS = (redirectUrl: string) => {
        this.setState({ show3DSDialog: true, redirectUrl })
    }

    failedOrSuccess3DS = (response: any) => {
        // console.log('Response 3DS:', response);
        this.setState({ show3DSDialog: false })
        if (response && response.transaction_status) {
            this.checkPaymentStatus()
        } else {
            this.setState({ isLoading: false, isError: getTranslate('Jenis Pembayaran ini tidak tersedia'), showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async checkPaymentStatus() {
        const result: PaymentCheckStatus = await this.props.checkStatus({ id: this.state.trxId })
        if (result.transaction_status === "capture" && result.fraud_status === "accept") {
            this.submitDonation(result)
        } else {
            this.setState({ isLoading: false, isError: getTranslate('Jenis Pembayaran ini tidak tersedia'), showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async submitDonation(result: PaymentCheckStatus) {
        try {
            this.setState({ isLoading: true })
            var donation: DonationBody = {
                amount: this.props.paymentAmount,
                project_id: this.props.selectedProject.id,
                payment_method: "credit card",
                is_anonim: this.props.isAnonim,
                payment_id: result.order_id,
                project_name: removeAllSpecialChar(this.props.selectedProject.name),
                project_picture_url: removeAllSpecialChar(this.props.selectedProject.picture_url),
                project_status: this.props.selectedProject.status,
                project_target_amount: parseInt(this.props.selectedProject.target_amount),
                user_name: removeAllSpecialChar(this.props.user.name),
                user_organization_id: this.props.user.organization_id,
                user_access_level: this.props.user.access_level,
                user_picture_url: removeAllSpecialChar(this.props.user.picture_url),
                user_email: removeAllSpecialChar(this.props.user.email),
                user_phone: this.props.user.phone,
                user_is_social_media_register: this.props.user.is_social_media_register,
                payment_status: "succeed",
                payment_response: JSON.stringify(result)
            }
            await this.props.submitDonation(donation)

            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ isLoading: false, showAlert: false })
                Navigation.to('/dashboard')
            }, 1000);
        } catch (e: any) {
            this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    onBackHome() {
        Navigation.to('/dashboard/detail/donate')
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="payment-kredit-container" style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke Metode Pembayaran')}</div>
                            </div>
                        </Col>

                        <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between' }}>

                            {/* Left Card */}
                            <Col className="kredit-number-container" style={{ margin: '0px', padding: '0px', marginRight: '36px' }}>
                                <div style={{ fontWeight: 'bold', fontSize: '16px', color: '#333333', marginTop: '24px' }}>{getTranslate('Card Details')}</div>

                                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                                    <NumberFormat
                                        id="number_format_3"
                                        format="#### #### #### ####"
                                        placeholder={getTranslate('Card Number')}
                                        defaultValue={this.state.cardNumber}
                                        onValueChange={(event: any) => this.setState({ cardNumber: event.value })} />
                                </Col>
                                <Row style={{ margin: '0px', padding: '0px', marginTop: '16px', alignItems: 'flex-end' }}>
                                    <Col style={{ margin: '0px', padding: '0px', textAlign: 'left' }}>
                                        <div style={{ fontSize: '12px', color: '#333333' }}>{getTranslate('Valid Through')}</div>
                                        <Row style={{ margin: '0px', padding: '0px', alignItems: 'baseline' }}>
                                            <Col style={{ margin: '0px', padding: '0px' }}>
                                                <NumberFormat
                                                    id="number_format_3"
                                                    format="##"
                                                    placeholder={"MM"}
                                                    defaultValue={this.state.cardMM}
                                                    onValueChange={(event: any) => this.setState({ cardMM: event.value })} />
                                            </Col>
                                            <div style={{ fontSize: '14px', color: '#333333', marginLeft: '8px', marginRight: '8px' }}>/</div>
                                            <Col style={{ margin: '0px', padding: '0px' }}>
                                                <NumberFormat
                                                    id="number_format_3"
                                                    format="####"
                                                    placeholder={"YYYY"}
                                                    defaultValue={this.state.cardYY}
                                                    onValueChange={(event: any) => this.setState({ cardYY: event.value })} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col style={{ margin: '0px', padding: '0px', marginLeft: '24px' }}>
                                        <NumberFormat
                                            id="number_format_3"
                                            format="###"
                                            placeholder={"CVV"}
                                            defaultValue={this.state.cardCVV}
                                            onValueChange={(event: any) => this.setState({ cardCVV: event.value })} />
                                    </Col>
                                </Row>

                                <Button style={{
                                    fontWeight: 'bold',
                                    color: this.disableBtn() ? '#808080' : 'white', paddingLeft: '32px', paddingRight: '32px',
                                    borderColor: this.disableBtn() ? '#cccccc' : '#5bc69a', marginTop: '32px',
                                    backgroundColor: this.disableBtn() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }} className={this.state.isLoading ? "ui primary loading button" : ""}
                                    disabled={this.disableBtn()} onClick={() => this.generatedCardData()} block>{getTranslate('Bayar')}</Button>
                            </Col>

                            {/* Right Card */}
                            <Col className="kredit-card-container" style={{ margin: '0px', padding: '0px', marginLeft: '36px' }}>
                                <Card style={{
                                    borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }}>
                                    <Image style={{
                                        height: '215px', objectFit: 'cover', borderRadius: '4px'
                                    }} src={this.props.selectedProject.picture_url ? this.props.selectedProject.picture_url : require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                    <Col style={{ margin: '0px', padding: '16px', alignItems: 'center' }}>
                                        <div className="font-mobile-16" style={{ fontWeight: 'bold', fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(this.props.selectedProject.name)}</div>
                                        <ProgressBar style={{ marginBottom: '16px', borderRadius: '23px', height: '8px' }} now={this.getPercentage(this.props.selectedProject)}></ProgressBar>
                                        <div className="font-mobile-14" style={{ fontWeight: 'bold', fontSize: '18px', color: '#333333', marginBottom: '8px' }}>
                                            {toCurrency(this.props.selectedProject.current_amount)}
                                        </div>
                                        <div className="font-mobile-12" style={{ fontSize: '14px', color: '#333333', marginBottom: '8px' }}>
                                            {getTranslate('terkumpul dari target ')} {toCurrency(this.props.selectedProject.target_amount)}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }} src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} className="miniImage" />
                                            <div className="font-mobile-12" style={{ fontSize: '14px', color: '#808080' }}>{this.props.selectedProject.close_funding_date ? manyDaysBetween(this.props.selectedProject.close_funding_date) + getTranslate(' hari tersisa') : '-'}</div>
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Berhasil donasi')}
                                </div>
                            </Alert>
                        }

                        <Modal
                            show={this.state.show3DSDialog}
                            centered
                        >
                            <Modal.Body style={{
                                padding: '24px'
                            }}>
                                <iframe frameBorder={"0"} style={{ height: '90vh', width: '100%' }} src={this.state.redirectUrl}></iframe>
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        selectedProject: state.project.selectedProject,
        user: state.user.user,
        payment: state.payment.myPayments,
        paymentMethods: state.payment.paymentMethods,
        paymentAmount: state.payment.amountPayment,
        isAnonim: state.payment.isAnonim
    } as PaymentCardProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        submitDonation: (payload: DonationBody) => submitDonation(payload),
        submitPayment: (payload: PaymentBody) => submitPayment(payload),
        getPayments: (userId: string) => getPayments(userId),
        setPayment: (payments: Payment[]) => dispatch(setMyPayment(payments)),
        getPaymentMethods: () => getPaymentMethods(),
        setPaymentMethod: (paymentMethod: PaymentMethod[]) => dispatch(setPaymentMethod(paymentMethod)),
        chargeCard: (payload: CardCharge) => chargeCard(payload),
        checkStatus: (payload: PaymentCheck) => checkStatus(payload),
    } as PaymentCardActions;
}

export default connect(stateToProps, dispatchToProps)(PaymentCardPage);
