import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../AppState';
import {
    Image,
    Row,
    Col,
    Button,
    Card,
    FormControl,
    Alert,
} from 'react-bootstrap';
import './style.css';
import ListProjectComponent from '../../component/listProject';
import { UserState, setUser, me, setAllUser, getUsers, setOrganization, getOrganization, setOrganizationName, getOrganizationName } from '../../reducers/User';
import NavbarComponent from '../../component/navbar';
import { User } from '../../model/User';
import { Dispatch } from 'redux';
import { Project } from '../../model/Project';
import { getProjects, setListProject, trackDonationPage, setSelectedProject, trackProjectPage } from '../../reducers/Project';
import { SummaryDashboard } from '../../model/SummaryDashboard';
import { getSummary, setSummaryAction, setPMTSummaryAction, getPMTSummary } from '../../reducers/Summary';

import { PaymentMethod } from '../../model/PaymentMethod';
import { getPaymentMethods, setPaymentMethod } from '../../reducers/Payment';
import { Organization } from '../../model/Organization';
import { Donation } from '../../model/Donation';
import { setDonation, getDonations, setDonationByProject } from '../../reducers/Donation';
import ListProjectPMTComponent from '../../component/listProjectPMT';
import { toCurrency } from '../../utils/Currency';
import { SummaryPMTDashboard } from '../../model/SummaryPMTDashboard';
import PieChart, { PieChartData } from 'react-minimal-pie-chart';
import { OrganizationName } from '../../model/OrganizationName';
import Navigation from '../../utils/Navigation';
import { clearMedia } from '../../reducers/Media';
import { Progress } from '../../model/Progress';
import { setProgressByProject } from '../../reducers/Progress';
import { getTranslate } from '../../utils/General';

export interface PMTDashboardProps {
    project: Project[],
    donation: Donation[],
    summaryPMT: SummaryPMTDashboard,
    organizationName: OrganizationName[]
}

export interface PMTDashboardAction {
    getPMTSummary(): any
    getProjects(orderBy: string, user_id?: string, starting_after?: string, status?: string, name?: string): any
    getDonations(orderBy: string, limit: number): any
    setProject(projects: Project[]): void
    setDonation(donations: Donation[]): void
    setPMTSummary(summary: SummaryPMTDashboard): void
    setOrganizationName(organizationName: OrganizationName[]): void
    getOrganizationName(): any
    selectedProject(project: Project): void
    setDonationByProject(donation: Donation[]): void
    setProgressByProject(progress: Progress[]): void
    clearMedia(): void
}

export interface PMTDashboardState {
    hasMoreItems: boolean,
    projectName: string,
    projectStatus: string,
    projectCategory: string,
    isLoading: boolean,
    pieChart: PieChartData[],
    showAlert: Boolean,
    isError: string
}

class PMTDashboard extends React.Component<PMTDashboardProps & PMTDashboardAction, PMTDashboardState>{
    constructor(props: any) {
        super(props);
        this.state = {
            hasMoreItems: true,
            projectStatus: 'unselected',
            projectName: '',
            projectCategory: 'unselected',
            isLoading: false,
            pieChart: [],
            showAlert: false,
            isError: ''
        }
    }

    async loadData() {
        try {
            const summaryPMT: SummaryPMTDashboard = await this.props.getPMTSummary()
            this.props.setPMTSummary(summaryPMT)
            this.setState({
                pieChart: [
                    {
                        color: '#aaaaaa',
                        title: getTranslate('Pending: ') + Math.round((this.props.summaryPMT.pending ?
                            (this.props.summaryPMT.pending / this.props.summaryPMT.total_projects * 100) : 0) * 100) / 100 + '%',
                        value: this.props.summaryPMT.pending ? parseInt("" + this.props.summaryPMT.pending) : 0,
                        desc: getTranslate('Pending')
                    },
                    {
                        color: '#f73859',
                        title: getTranslate('Rejected: ') + Math.round((this.props.summaryPMT.rejected ?
                            (this.props.summaryPMT.rejected / this.props.summaryPMT.total_projects * 100) : 0) * 100) / 100 + '%',
                        value: this.props.summaryPMT.rejected ? parseInt("" + this.props.summaryPMT.rejected) : 0,
                        desc: getTranslate('Rejected')
                    },
                    {
                        color: '#ff7657',
                        title: getTranslate('Open Funding: ') + Math.round((this.props.summaryPMT.open_funding ?
                            (this.props.summaryPMT.open_funding / this.props.summaryPMT.total_projects * 100) : 0) * 100) / 100 + '%',
                        value: this.props.summaryPMT.open_funding ? parseInt("" + this.props.summaryPMT.open_funding) : 0,
                        desc: getTranslate('Open Funding')
                    },
                    {
                        color: '#2c365d',
                        title: getTranslate('Close Funding: ') + Math.round((this.props.summaryPMT.close_funding ?
                            (this.props.summaryPMT.close_funding / this.props.summaryPMT.total_projects * 100) : 0) * 100) / 100 + '%',
                        value: this.props.summaryPMT.close_funding ? parseInt("" + this.props.summaryPMT.close_funding) : 0,
                        desc: getTranslate('Close Funding')
                    },
                    {
                        color: '#1aa59a',
                        title: getTranslate('Project Started: ') + Math.round((this.props.summaryPMT.project_started ?
                            (this.props.summaryPMT.project_started / this.props.summaryPMT.total_projects * 100) : 0) * 100) / 100 + '%',
                        value: this.props.summaryPMT.project_started ? parseInt("" + this.props.summaryPMT.project_started) : 0,
                        desc: getTranslate('Project Started')
                    },
                    {
                        color: '#4b4b4b',
                        title: getTranslate('Project Ended: ') + Math.round((this.props.summaryPMT.project_ended ?
                            (this.props.summaryPMT.project_ended / this.props.summaryPMT.total_projects * 100) : 0) * 100) / 100 + '%',
                        value: this.props.summaryPMT.project_ended ? parseInt("" + this.props.summaryPMT.project_ended) : 0,
                        desc: getTranslate('Project Ended')
                    },
                ]
            })
            const donations: Donation[] = await this.props.getDonations('amount_desc', 5)
            if (donations && donations.length > 0)
                this.props.setDonation(donations)

            const organizationName: OrganizationName[] = await this.props.getOrganizationName()
            this.props.setOrganizationName(organizationName)
            await this.getProjects()
        } catch (e: any) {
            console.error(e)
        }
    }

    async getProjects(user_id?: string, status?: string, name?: string, isFilter: boolean = false) {
        if (isFilter) {
            this.setState({ isLoading: true })
            try {
                const listProject: Project[] = await this.props.getProjects('updated_at_desc', user_id, undefined, status, name)
                if (listProject && listProject.length > 0) {
                    this.props.setProject(listProject)
                } else {
                    this.props.setProject([])
                }
                this.setState({ showAlert: true })
                setTimeout(() => {
                    this.setState({ isLoading: false, showAlert: false })
                }, 1000);
            } catch (e: any) {
                this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
                setTimeout(() => {
                    this.setState({ showAlert: false, isError: "" })
                }, 3000);
            }
        } else {
            const listProject: Project[] = await this.props.getProjects('updated_at_desc', user_id, undefined, status, name)
            if (listProject && listProject.length > 0)
                this.props.setProject(listProject)
        }
    }

    async onLoadMore(user_id?: string, starting_after?: string, status?: string, name?: string) {
        try {
            if (status === 'unselected')
                status = ''

            // this.setState({ hasMoreItems: true })
            const listProject: Project[] = await this.props.getProjects('updated_at_desc', user_id, starting_after, status, name)
            if (listProject && listProject.length > 0) {
                const currentProject = this.props.project.concat(...listProject)
                this.props.setProject(currentProject)
                this.setState({ hasMoreItems: true })
            } else {
                this.setState({ hasMoreItems: false })
            }
        } catch (e: any) {
            console.error(e)
            this.setState({ hasMoreItems: false })
        }
    }

    componentDidMount() {
        this.props.clearMedia()
        this.props.setDonationByProject([])
        this.props.setProgressByProject([])
        this.loadData();
    }

    onItemProjectClick(project: Project) {
        this.props.selectedProject(project)
        Navigation.to('/pmt-dashboard/detail')
    }

    onProjectFilter = () => {
        if (this.state.projectCategory == 'unselected') {
            this.setState({ projectCategory: '' })
        }
        this.getProjects(this.state.projectCategory, this.state.projectStatus, this.state.projectName, true)
    }

    disableBtn = () => {
        if (this.state.projectName || this.state.projectStatus !== "unselected" || this.state.projectCategory !== "unselected") {
            return false
        } else {
            return true
        }
    }

    onMouseOver = () => {
        // console.log('onMouseOver')
    }

    onMouseOut = () => {
        // console.log('onMouseOut')
    }

    emptyState = () => {
        return (
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Image className="image-pohon-empty" style={{ objectFit: 'contain' }} src={require('../../../src/assets/images/illus-empty-project/illus-empty-project.png')} />
                <p style={{ fontSize: '16px', marginTop: '36px', color: '#808080' }} className="titleMyProject">{getTranslate('Belum ada proyek')}</p>
            </div>
        )
    }

    getBackgroundItemList = (index: number) => {
        if (index % 2) {
            return '#fefefe'
        } else {
            return '#daf0ef'
        }
    }

    legendPieChart() {
        return this.state.pieChart.map((item, index) => {
            return (
                <Row key={index} style={{ margin: '0px', padding: '8px', alignItems: 'center' }}>
                    <div style={{
                        width: '9px', height: '9px', borderRadius: '4.5px',
                        backgroundColor: item.color, marginRight: '8px'
                    }} />
                    <div style={{ fontSize: '10px', color: '#485465' }}>{item.desc}</div>
                </Row>
            )
        })
    }

    topDonationsList() {
        return this.props.donation.map((item, index) => {
            return (
                <Row key={item.id} style={{
                    margin: '0px', padding: '8px', justifyContent: 'space-between',
                    paddingLeft: '16px', paddingRight: '16px', backgroundColor: this.getBackgroundItemList(index)
                }}>
                    <div style={{ fontSize: '14px', color: '#485465', minWidth: '54px' }}>{index + 1}</div>
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <div style={{ fontSize: '14px', color: '#485465' }}>{item.project_name}</div>
                    </Col>
                    <div style={{ fontSize: '14px', color: '#485465', alignSelf: 'center' }}>{toCurrency(item.amount)}</div>
                </Row>
            )
        })
    }

    organizationNameView() {
        return this.props.organizationName.map((item) => {
            return (
                <option key={item.organization_id} value={item.user_id}>{item.organization_name}</option>
            )
        })
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="pmt-dashboard-container" style={{ margin: '0px', padding: '0px' }}>
                        <Row className="margin-0 margin-bottom-16" style={{ justifyContent: 'space-between', margin: '0px', padding: '0px', marginBottom: '56px', alignItems: 'center', marginTop: '64px' }}>
                            <div className="card-label-title" style={{ fontSize: '36px', color: '##333333', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>{getTranslate('Dashboard PMT')}</div>
                        </Row>

                        <Row className="graph-container" style={{ margin: '0px', padding: '0px', marginBottom: '56px', justifyContent: 'space-between' }}>
                            {this.props.summaryPMT && this.state.pieChart.length > 0 &&
                                <Card style={{
                                    margin: '0px', padding: '20px', width: '48%',
                                    paddingTop: '22px', paddingBottom: '22px', borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px', cursor: 'default'
                                }}>
                                    <div style={{ fontSize: '14px', color: '#485465', marginBottom: '12px' }}>PIE CHART</div>
                                    <Row className="padding-0" style={{
                                        margin: '0px', padding: '8px', justifyContent: 'space-between',
                                        paddingLeft: '16px', paddingRight: '16px'
                                    }}>
                                        {this.legendPieChart()}
                                    </Row>
                                    <PieChart
                                        data={this.state.pieChart}
                                        style={{
                                            width: '210px', height: '210px', alignSelf: 'center',
                                            marginTop: '20px', marginBottom: '14px'
                                        }}
                                        onMouseOver={this.onMouseOver}
                                        onMouseOut={this.onMouseOut}
                                        animate
                                        totalValue={this.props.summaryPMT.total_projects
                                            ? parseInt("" + this.props.summaryPMT.total_projects) : 0}
                                        lineWidth={46}
                                        labelStyle={{ backgroundColor: 'blue' }}
                                    />
                                </Card>
                            }
                            {this.props.donation && this.props.donation.length > 0 &&
                                <Card style={{
                                    margin: '0px', padding: '20px', width: '48%',
                                    paddingTop: '22px', paddingBottom: '22px', borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px', cursor: 'default'
                                }}>
                                    <div style={{ fontSize: '14px', color: '#485465', marginBottom: '12px' }}>{getTranslate('TOP DONATION')}</div>
                                    <Col style={{ margin: '0px', padding: '0px' }}>
                                        <Row style={{
                                            margin: '0px', padding: '8px', justifyContent: 'space-between',
                                            paddingLeft: '16px', paddingRight: '16px'
                                        }}>
                                            <div style={{ fontSize: '14px', color: '#485465', fontWeight: 'bold', minWidth: '54px' }}>No.</div>
                                            <Col style={{ margin: '0px', padding: '0px' }}>
                                                <div style={{ fontSize: '14px', color: '#485465', fontWeight: 'bold' }}>{getTranslate('Nama Proyek')}</div>
                                            </Col>
                                            <div style={{ fontSize: '14px', color: '#485465', fontWeight: 'bold', alignSelf: 'center' }}>{getTranslate('Amount')}</div>
                                        </Row>
                                        <div style={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.15)',
                                            height: '1px', marginTop: '8px'
                                        }} />
                                        {this.topDonationsList()}
                                    </Col>
                                </Card>
                            }
                        </Row>

                        <Row style={{ margin: '0px', padding: '0px' }}>
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div className="card-label-title margin-bottom-16" style={{ fontSize: '24px', marginBottom: '32px', fontWeight: 'bold' }}>{getTranslate('Daftar Proyek')}</div>
                                <Row style={{
                                    margin: '0px', padding: '0px',
                                    marginBottom: '32px', justifyContent: 'space-between'
                                }}>
                                    <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <FormControl
                                            placeholder={getTranslate("Nama Proyek…")}
                                            defaultValue={this.state.projectName}
                                            onChange={(event: any) => this.setState({ projectName: event.target.value })}
                                        />
                                    </Col>
                                    <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <select onChange={(e: any) => this.setState({ projectStatus: e.target.value })}
                                            className="custom-select" id="inputGroupSelect01" value={this.state.projectStatus}>
                                            <option disabled value="unselected">{getTranslate('Status Proyek')}</option>
                                            <option value="">{getTranslate('Semua Status')}</option>
                                            <option value="pending">{getTranslate('Pending')}</option>
                                            <option value="rejected">{getTranslate('Rejected')}</option>
                                            <option value="open funding">{getTranslate('Open Funding')}</option>
                                            <option value="close funding">{getTranslate('Close Funding')}</option>
                                            <option value="project started">{getTranslate('Project Started')}</option>
                                            <option value="project ended">{getTranslate('Project Ended')}</option>
                                        </select>
                                    </Col>
                                    <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                                        <select onChange={(e: any) => this.setState({ projectCategory: e.target.value })}
                                            className="custom-select" id="inputGroupSelect01" value={this.state.projectCategory}>
                                            <option disabled value="unselected">{getTranslate('Nama Organisasi')}</option>
                                            <option value="">{getTranslate('Semua Organisasi')}</option>
                                            {this.organizationNameView()}
                                        </select>
                                    </Col>
                                    <Col className="col-basis-1" style={{ margin: '0px', padding: '0px' }}>
                                        <Button style={{
                                            fontWeight: 'bold',
                                            color: this.disableBtn() ? '#808080' : 'white', paddingLeft: '32px', paddingRight: '32px',
                                            borderColor: this.disableBtn() ? '#cccccc' : '#5bc69a',
                                            backgroundColor: this.disableBtn() ? '#cccccc' : '#5bc69a', opacity: '1'
                                        }} className={this.state.isLoading ? "ui primary loading button" : ""}
                                            disabled={this.disableBtn()} onClick={() => this.onProjectFilter()} block>{getTranslate('Terapkan Filter')}</Button>
                                    </Col>
                                </Row>
                                {this.props.project && this.props.project.length > 0 ?
                                    <Col className="box-shadow-remove" style={{
                                        margin: '0px', padding: '0px',
                                        borderColor: 'transparent',
                                        boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                    }}>
                                        <ListProjectPMTComponent
                                            data={this.props.project}
                                            onLoadMore={(id: string) => this.onLoadMore(this.state.projectCategory, id, this.state.projectStatus, this.state.projectName)}
                                            hasMoreItems={this.state.hasMoreItems}
                                            onItemClick={(item) => this.onItemProjectClick(item)} />
                                    </Col>
                                    : <div>{this.emptyState()}</div>
                                }
                            </Col>
                        </Row>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Filter proyek berhasil')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user,
        project: state.project.projects,
        donation: state.donation.donations,
        summaryPMT: state.summary.summaryPMT,
        organizationName: state.user.organizationName
    } as PMTDashboardProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        getProjects: (orderBy: string, user_id?: string, starting_after?: string, status?: string, name?: string) => getProjects(user_id, starting_after, orderBy, undefined, status, name),
        getPMTSummary: () => getPMTSummary(),
        getDonations: (orderBy: string, limit: number) => getDonations(undefined, undefined, orderBy, limit, undefined, undefined, 'succeed'),
        setDonation: (donations: Donation[]) => dispatch(setDonation(donations)),
        setProject: (projects: Project[]) => dispatch(setListProject(projects)),
        setPMTSummary: (summary: SummaryPMTDashboard) => dispatch(setPMTSummaryAction(summary)),
        setOrganizationName: (organizationName: OrganizationName[]) => dispatch(setOrganizationName(organizationName)),
        getOrganizationName: () => getOrganizationName(),
        selectedProject: (project: Project) => dispatch(setSelectedProject(project)),
        setDonationByProject: (donations: Donation[]) => dispatch(setDonationByProject(donations)),
        setProgressByProject: (progress: Progress[]) => dispatch(setProgressByProject(progress)),
        clearMedia: () => dispatch(clearMedia()),
    } as PMTDashboardAction;
}

export default connect(stateToProps, dispatchToProps)(PMTDashboard);
