import React from 'react';
import {
    InputGroup,
    Button,
    Image,
    Row,
    Col,
    FormControl,
} from 'react-bootstrap';
import './style.css';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login'
import GoogleLogin from 'react-google-login';
import { getTranslate } from '../../utils/General';
import PhoneInput from 'react-phone-number-input';

export interface RegisterProps {
    loading: boolean
    onLoginClick(): void
    onRegisterClick(name: string, phone: string, password: string): void
    onRegisterClickSocialMedia(email: string, password: string, name?: string, pictureUrl?: string): void
    onPrivacyPolicyClick(): void
    onTNCClick(): void
}

export interface RegisterAction {

}

export interface RegisterState {
    passwordToggleImage: string
    confirmPasswordToggleImage: string
    isDisabled: Boolean
    buttonPassType: string
    buttonConfirmPassType: string
    phone: string
    password: string
    confirmPass: string
    name: string
}

class RegisterComponent extends React.Component<RegisterProps & RegisterAction, RegisterState> {
    constructor(props: any) {
        super(props);
        this.state = {
            passwordToggleImage: "images/ic-actions-closed-view/ic-actions-closed-view.png",
            confirmPasswordToggleImage: "images/ic-actions-closed-view/ic-actions-closed-view.png",
            isDisabled: true,
            buttonPassType: 'password',
            buttonConfirmPassType: 'password',
            phone: '',
            password: '',
            confirmPass: '',
            name: ''
        }
    }

    togglePass() {
        if (this.state.buttonPassType === "password") {
            this.setState({
                buttonPassType: "text",
                passwordToggleImage: 'images/ic-actions-view/ic-actions-view.png',

            })
        } else {
            this.setState({
                buttonPassType: "password",
                passwordToggleImage: 'images/ic-actions-closed-view/ic-actions-closed-view.png',
            })
        }
    }

    toggleConfirmPass() {
        if (this.state.buttonConfirmPassType === "password") {
            this.setState({
                buttonConfirmPassType: "text",
                confirmPasswordToggleImage: 'images/ic-actions-view/ic-actions-view.png',
            })
        } else {
            this.setState({
                buttonConfirmPassType: "password",
                confirmPasswordToggleImage: 'images/ic-actions-closed-view/ic-actions-closed-view.png',
            })
        }
    }

    validationButton() {
        if (this.state.name !== "" && this.state.phone !== "" && this.state.password !== "" && this.state.confirmPass === this.state.password) {
            this.setState({ isDisabled: false })
        } else {
            this.setState({ isDisabled: true })
        }
    }

    setPhone(event: any) {
        this.setState({
            phone: event
        }, () => {
            this.validationButton()
        })
    }

    setPassword(event: any) {
        this.setState({
            password: event.target.value
        }, () => {
            this.validationButton()
        })
    }

    setConfirmPassword(event: any) {
        this.setState({
            confirmPass: event.target.value
        }, () => {
            this.validationButton()
        })
    }

    setName(event: any) {
        this.setState({
            name: event.target.value
        }, () => {
            this.validationButton()
        })
    }

    responseFacebook = (user: ReactFacebookLoginInfo) => {
        if (user)
            this.props.onRegisterClickSocialMedia(user.email ? user.email : '', user.id, user.name,
                user.picture && user.picture.data ? user.picture.data.url : undefined)
    }

    responseGoogle = (result: any) => {
        if (result && result.profileObj)
            this.props.onRegisterClickSocialMedia(result.profileObj.email ? result.profileObj.email : '', result.profileObj.googleId,
                result.profileObj.name,
                result.profileObj.imageUrl ? result.profileObj.imageUrl : undefined)
    }

    render() {
        return (
            <div className="login-form-container" style={{
                margin: '0px', padding: '0px',
                marginTop: '100px',
                marginLeft: '96px',
                marginRight: '96px',
            }}>
                <div className="label-masuk" style={{ fontSize: '24px', marginBottom: '40px', fontWeight: 'bold' }}>{getTranslate('Daftar akun baru dengan')}</div>
                <Row style={{ margin: '0px', padding: '0px', }}>
                    <Col style={{ margin: '0px', padding: '0px', marginBottom: '10px', fontSize: '14px', marginRight: '8px' }}>
                        <FacebookLogin
                            // appId="188189895728919"
                            appId="1296077177259377"
                            callback={this.responseFacebook}
                            fields="name,email,picture"
                            textButton={'Facebook'}
                            icon={
                                <Image style={{ objectFit: 'contain', float: 'left' }} src={require('../../../src/assets/images/f-logo-rgb-white-72/f-logo-rgb-white-72.png')} />
                            }
                        />
                    </Col>
                    <Col style={{ margin: '0px', padding: '0px', marginBottom: '10px', fontSize: '14px', marginLeft: '8px' }}>
                        <GoogleLogin
                            clientId="76662988209-irvubn0gtaumvj16vbtsvc3mjbfgl8kk.apps.googleusercontent.com"
                            buttonText={'Google'}
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            render={renderProps => (
                                <div style={{
                                    display: 'flex', alignItems: 'center',
                                    backgroundColor: '#ffffff', borderRadius: '4px', padding: '10px',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => renderProps.onClick()}>
                                    <Image style={{ objectFit: 'contain' }} src={require('../../../src/assets/images/google/google.png')} />
                                    <p style={{ flex: 1, color: '#4b4b4b', textAlign: 'center' }}> Google </p>
                                </div>
                            )}
                        />
                    </Col>
                </Row>

                <Row style={{ margin: '0px', padding: '0px', marginTop: '50px' }}>
                    <Col className="divider-line-login" style={{ margin: '0px', padding: '0px' }} lg={5}>
                        <div className="line"></div>
                    </Col>
                    <Col className="atau-div" style={{ margin: '0px', padding: '0px' }} lg={2}>
                        <div style={{ width: '100%', textAlign: 'center', fontSize: '16px' }}>{getTranslate('atau')}</div>
                    </Col>
                    <Col className="divider-line-login" style={{ margin: '0px', padding: '0px' }} lg={5}>
                        <div className="line"></div>
                    </Col>
                </Row>

                <Col style={{ margin: '0px', padding: '0px', marginTop: '50px', marginBottom: '50px' }}>
                    <InputGroup style={{ marginBottom: '8px' }}>
                        <FormControl ria-describedby="basic-addon1" onChange={(event: any) => this.setName(event)} placeholder={getTranslate("Nama")} />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: '8px' }}>
                        <PhoneInput
                            style={{ flex: 1 }}
                            international
                            defaultCountry="ID"
                            value={""}
                            placeholder={getTranslate("No Whatsapp")}
                            onChange={(event: any) => this.setPhone(event)} />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: '8px' }}>
                        <FormControl aria-describedby="basic-addon1" onChange={(event: any) => this.setPassword(event)} placeholder={getTranslate("Password")} type={this.state.buttonPassType} />
                        <Image style={{ objectFit: 'contain' }} onClick={() => this.togglePass()} className="togglePass" src={require('../../../src/assets/' + this.state.passwordToggleImage)} />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: '24px' }}>
                        <FormControl aria-describedby="basic-addon1" onChange={(event: any) => this.setConfirmPassword(event)} placeholder={getTranslate("Confirm Password")} type={this.state.buttonConfirmPassType} />
                        <Image style={{ objectFit: 'contain' }} onClick={() => this.toggleConfirmPass()} className="togglePass" src={require('../../../src/assets/' + this.state.confirmPasswordToggleImage)} />
                    </InputGroup>
                    <Button style={{
                        fontWeight: 'bold',
                        color: this.state.isDisabled ? '#808080' : 'white',
                        borderColor: this.state.isDisabled ? '#cccccc' : '#5bc69a', paddingLeft: '32px', paddingRight: '32px',
                        backgroundColor: this.state.isDisabled ? '#cccccc' : '#5bc69a', opacity: '1'
                    }} block disabled={this.state.isDisabled ? true : false}
                        onClick={() => this.props.onRegisterClick(this.state.name, this.state.phone, this.state.password)}
                        className={this.props.loading ? "ui primary loading button" : ""}
                    >{getTranslate('Daftar')}</Button>

                    <div style={{ color: '#4b4b4b', fontSize: '14px' }} className="registerText"> {getTranslate('Sudah punya akun?')} &nbsp;
                        <a onClick={() => this.props.onLoginClick()} style={{ fontWeight: 'bold', color: '#1aa59a', fontSize: '14px' }}>
                            {getTranslate('Login')}
                        </a>
                    </div>
                    <div style={{ color: '#4b4b4b', fontSize: '14px', marginTop: '56px' }} className="registerText"> 
                        <div style={{marginBottom: '10px'}}>
                            <Image 
                                style={{ objectFit: 'contain', width: '40%', height: 'auto' }} 
                                className="logo" src={require('../../../src/assets/images/footer_PYC.png')} />
                        </div>

                        {getTranslate('Dengan mendaftar berarti Anda menyetujui')} &nbsp;
                        <a onClick={() => this.props.onTNCClick()} style={{ fontWeight: 'bold', color: '#207561', fontSize: '14px' }}>
                            {getTranslate('Syarat dan Ketentuan')} &nbsp;
                        </a>
                        <a style={{ color: '#4b4b4b', fontSize: '14px' }}>
                            {getTranslate('dan')} &nbsp;
                        </a>
                        <a onClick={() => this.props.onPrivacyPolicyClick()} style={{ fontWeight: 'bold', color: '#207561', fontSize: '14px' }}>
                            {getTranslate('Kebijakan Privasi')}
                        </a>
                    </div>
                </Col>
            </div>
        )
    }
}

export default RegisterComponent