import * as React from 'react'
import { connect } from 'react-redux';
import {
    Image,
    Row,
    Col,
    FormControl,
    FormLabel,
    InputGroup,
    Button,
    Card,
    ProgressBar,
    Alert,
    DropdownButton,
    Dropdown,
} from 'react-bootstrap';
import './style.css';
import { AppState } from '../../../AppState';
import PublicNavbarComponent from '../../../component/publicNavbar';
import { Project } from '../../../model/Project';
import { toCurrency } from '../../../utils/Currency';
import { Dispatch } from 'redux';
import { DonationBody } from '../../../model/payload/DonationBody';
import { submitDonation } from '../../../reducers/Donation';
import { PaymentBody } from '../../../model/payload/PaymentBody';
import { submitPayment, getPayments, setMyPayment, getPublicPaymentMethods, setPaymentMethod, setAmountPayment, setAnonim, setDonatur, setUserContact } from '../../../reducers/Payment';
import { Payment } from '../../../model/Payment';
import { User } from '../../../model/User';
import { toTitleCase, removeAllSpecialChar, getTranslate, paymentMethodMapper } from '../../../utils/General';
import { PaymentMethod } from '../../../model/PaymentMethod';
import Navigation from '../../../utils/Navigation';
import { manyDaysBetween } from '../../../utils/Date';
import NumberFormat from 'react-number-format';

export interface PublicDonatePageProps {
    trackDonationPage: boolean,
    selectedProject: Project,
    payment: Payment[],
    user: User,
    paymentMethods: PaymentMethod[]
    amountPayment: string
}

export interface PublicDonatePageActions {
    submitDonation(payload: DonationBody): void
    submitPayment(payload: PaymentBody): void
    setPayment(payment: Payment[]): void
    getPayments(userId: string): any
    getPublicPaymentMethods(): any
    setPaymentMethod(paymentMethod: PaymentMethod[]): void
    setAmountPayment(amount: string): void
    setDonatur(donatur: string): void
    setUserContact(contact: string): void
    setAnonim(isAnonim: boolean): void
}

export interface PublicDonatePageState {
    isAnonim: boolean
    amount: string
    paymentMethod: string
    paymentMethodIcon: any
    isLoading: boolean
    showAlert: Boolean
    isError: string
    donatur: string
    contact: string
}

class PublicDonatePage extends React.Component<PublicDonatePageProps & PublicDonatePageActions, PublicDonatePageState>{
    constructor(props: any) {
        super(props);
        this.state = {
            isAnonim: false,
            amount: '',
            paymentMethod: 'none',
            paymentMethodIcon: undefined,
            isLoading: false,
            showAlert: false,
            isError: "",
            donatur: "",
            contact: ""
        }
    }

    async loadData() {
        try {
            console.log(this.props)
            const paymentMethods: PaymentMethod[] = await this.props.getPublicPaymentMethods()

            // filter payment only for gopay and card payment
            paymentMethods.forEach((pm, index) => {
                if (pm.label === "ovo" || pm.label === "debit card") {
                    paymentMethods.splice(index, 1)
                }
            })
            
            this.props.setPaymentMethod(paymentMethods)
        } catch (e: any) {
            console.error(e)
        }
    }

    componentDidMount() {
        this.loadData()
    }

    onBackHome() {
        if (this.props.trackDonationPage) {
            Navigation.to('/public/detail')
        } else {
            Navigation.to('/public')
        }
    }

    toggleCheckBoxAnonim = () => {
        const value = !(this.state.isAnonim);
        this.setState({ isAnonim: value });
    }

    getPercentage(project: Project): number {
        const value = Math.round((parseInt(project.current_amount) / parseInt(project.target_amount)) * 100)
        return value
    }

    async submitDonation() {
        var payment: PaymentBody = {
            status: 'succeed',
            response: 'ini contoh aja'
        }
        try {
            this.setState({ isLoading: true })
            await this.props.submitPayment(payment)
            const payments: Payment[] = await this.props.getPayments('' + this.props.user.id)
            if (payments && payments.length > 0)
                this.props.setPayment(payments)
            var donation: DonationBody = {
                amount: this.state.amount,
                project_id: this.props.selectedProject?.id,
                payment_method: this.state.paymentMethod.toLowerCase(),
                is_anonim: this.state.isAnonim,
                payment_id: this.props.payment[this.props.payment.length - 1].id,
                project_name: removeAllSpecialChar(this.props.selectedProject?.name),
                project_picture_url: removeAllSpecialChar(this.props.selectedProject?.picture_url),
                project_status: this.props.selectedProject?.status,
                project_target_amount: parseInt(this.props.selectedProject?.target_amount),
                user_name: removeAllSpecialChar(this.props.user.name),
                user_organization_id: this.props.user.organization_id,
                user_access_level: this.props.user.access_level,
                user_picture_url: removeAllSpecialChar(this.props.user.picture_url),
                user_email: removeAllSpecialChar(this.props.user.email),
                user_phone: this.props.user.phone,
                user_is_social_media_register: this.props.user.is_social_media_register,
                payment_status: this.props.payment[this.props.payment.length - 1].status,
                payment_response: this.props.payment[this.props.payment.length - 1].response ?
                    JSON.stringify(this.props.payment[this.props.payment.length - 1].response) : ""
            }
            await this.props.submitDonation(donation)
            this.setState({ showAlert: true })
            setTimeout(() => {
                this.setState({ isLoading: false, showAlert: false })
                this.onBackHome()
            }, 1000);
        } catch (e: any) {
            this.setState({ isLoading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    saveDonate = () => {
        if (!this.state.isLoading) {
            this.props.setAmountPayment(this.state.amount)
            this.props.setDonatur(this.state.donatur)
            this.props.setUserContact(this.state.contact)
            this.props.setAnonim(this.state.isAnonim)
            if (this.state.paymentMethod.toLowerCase() === "gopay") {
                Navigation.to('/public/payment/qr')
            } else {
                Navigation.to('/public/payment/card')
            }
        }
    }

    donateLogin = () => {
        // console.log(this.props)
        if (!this.state.isLoading) {
            this.props.setAmountPayment(this.state.amount)
            // this.props.setDonatur(this.state.donatur)
            // this.props.setUserContact(this.state.contact)

            this.props.setAnonim(this.state.isAnonim)

            Navigation.to('/donate/login')
            // if (this.state.paymentMethod.toLowerCase() === "gopay") {
            //     Navigation.to('/public/payment/qr')
            // } else {
            //     Navigation.to('/public/payment/card')
            // }
        }
    }

    disableBtn = () => {
        if (!this.state.amount) {
            return true
        }
        if (!this.state.donatur) {
            return true
        }
        if (!this.state.contact) {
            return true
        }

        const totalAmount = (parseInt(this.props.selectedProject?.current_amount) + parseInt(this.state.amount))
        if (parseInt(this.props.selectedProject?.target_amount) > totalAmount) {
            if ((parseInt(this.state.amount) >= 5000) && this.state.paymentMethod !== 'none') {
                return false
            } else {
                return true
            }
        } else {
            return true;
        }
    }

    getImagePaymentMethod = (index: number) => {
        switch (index) {
            // case 0:
            // return <Image style={{ height: '14px' }} src={require("../../../../src/assets/images/ic-ovo/ic-ovo.png")} />
            case 0:
                return <Image style={{ height: '14px' }} src={require("../../../../src/assets/images/logo-gopay/logo-gopay.png")} />
            // case 2:
            //     return <Image style={{ height: '14px' }} src={require("../../../../src/assets/images/ic-debit/ic-debit.png")} />
            case 1:
                return (
                    <Row style={{ margin: '0px', padding: '0px', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
                        <Image style={{ height: '14px' }} src={require("../../../../src/assets/images/ic-debit/ic-debit.png")} />
                        <Image style={{ height: '14px', marginLeft: '8px' }} src={require("../../../../src/assets/images/img-fi-visa/img-fi-visa.png")} />
                        <Image style={{ height: '14px', marginLeft: '8px' }} src={require("../../../../src/assets/images/img-fi-mastercard/img-fi-mastercard.png")} />
                    </Row>
                )
        }
    }

    paymentMethodView() {
        return this.props.paymentMethods.map((item, index) => {
            return (
                <Dropdown.Item style={{ display: 'inline-block' }} key={index} eventKey={item.label} onClick={() =>
                    this.setState({ paymentMethod: paymentMethodMapper(item.label), paymentMethodIcon: this.getImagePaymentMethod(index) })}>
                    <Row style={{ margin: '0px', padding: '0px', width: '100%', alignItems: 'center' }}>
                        <div style={{ minWidth: '100px', alignItems: 'center', textAlign: 'center' }}>
                            {this.getImagePaymentMethod(index)}
                        </div>
                        <div style={{ color: '#4b4b4b', fontSize: '16px' }}>{paymentMethodMapper(item.label)}</div>
                    </Row>
                </Dropdown.Item>
            )
        })
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <PublicNavbarComponent />
                </Col>

                <Row id="body">
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{this.props.trackDonationPage ? getTranslate('Kembali ke Detail Proyek') : getTranslate('Kembali ke Beranda')}</div>
                            </div>
                        </Col>

                        <Row className="row-donasi-container" style={{ margin: '0px', padding: '0px', justifyContent: 'space-between' }}>

                            <Col className="mobile-show" style={{ margin: '0px', padding: '0px', marginBottom: '16px' }}>
                                <Card style={{
                                    borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }}>
                                    <div className="div-img-title">
                                        <Image className="img-list-project" style={{height: '130px', objectFit: 'cover', borderRadius: '4px 4px 0 0'}} src={this.props.selectedProject?.picture_url ? this.props.selectedProject?.picture_url : require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                        <div className="mobile-show img-title-mobile" style={{ fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(this.props.selectedProject?.name)}</div>
                                    </div>

                                    <Col style={{ margin: '0px', padding: '16px 8px', alignItems: 'center' }}>
                                        <div className="desktop-show" style={{ fontWeight: 'bold', fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(this.props.selectedProject?.name)}</div>
                                        <ProgressBar style={{ marginBottom: '8px', borderRadius: '23px', height: '6px' }} now={this.getPercentage(this.props.selectedProject)}></ProgressBar>
                                        <div style={{ fontWeight: 'bold', fontSize: '14px', color: '#333333', marginBottom: '8px' }}>
                                            {toCurrency(this.props.selectedProject?.current_amount)}
                                        </div>
                                        <div style={{ fontSize: '12px', color: '#333333', marginBottom: '8px' }}>
                                            {getTranslate('terkumpul dari target ')} {toCurrency(this.props.selectedProject?.target_amount)}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }} src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} className="miniImage" />
                                            <div style={{ fontSize: '10px', color: '#808080' }}>{this.props.selectedProject?.close_funding_date ? manyDaysBetween(this.props.selectedProject?.close_funding_date) + getTranslate(' hari tersisa') : '-'}</div>
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                            {/* Left Card */}
                            <Col className="col-input-donasi" style={{ margin: '0px', padding: '0px', marginRight: '36px' }}>
                                <div className="font-14" style={{ fontSize: '16px', color: '#4b4b4b', marginBottom: '8px' }}>{getTranslate('Masukkan Nilai Donasi')}</div>
                                <InputGroup style={{ marginBottom: '8px' }}>
                                    <InputGroup.Prepend>
                                        <FormLabel style={{
                                            backgroundColor: 'rgba(26, 165, 154, 0.16)', padding: '8px', fontSize: '1rem', alignItems: 'center',
                                            fontWeight: 'bold', color: '#333333', borderRadius: '.25rem', height: 'calc(1.5em + .75rem + 2px)'
                                        }} id="basic-addon1">Rp</FormLabel>
                                    </InputGroup.Prepend>
                                    <NumberFormat
                                        id="number_format_2"
                                        thousandSeparator={true}
                                        onValueChange={(event: any) => this.setState({ amount: event.value })} />
                                </InputGroup>

                                <div className="font-12" style={{ fontSize: '14px', color: '#808080', marginBottom: '32px' }}>{getTranslate('Nominal donasi minimal ') + toCurrency("5000")}</div>
                                <div className="input-group mb-3" style={{ fontSize: '16px', color: '#4b4b4b' }} >
                                    <DropdownButton
                                        title={this.state.paymentMethod === 'none' ?
                                            <Row style={{ margin: '0px', padding: '0px', alignItems: 'center', display: 'inline-block' }}>
                                                <div style={{ color: '#4b4b4b', fontSize: '16px' }}>{getTranslate("Pilih Metode Pembayaran")}</div>
                                            </Row>
                                            :
                                            <Row style={{ margin: '0px', padding: '0px', alignItems: 'center', display: 'inline-flex' }}>
                                                <div style={{ alignItems: 'center', textAlign: 'center' }}>
                                                    {this.state.paymentMethodIcon}
                                                </div>
                                                <div style={{ color: '#4b4b4b', fontSize: '16px', marginLeft: '16px' }}>{this.state.paymentMethod}</div>
                                            </Row>
                                        }
                                        id="btn_primary_override"
                                        style={{ flex: 1, backgroundColor: 'white' }}
                                    >
                                        {this.props.paymentMethods && this.props.paymentMethods.length > 0 && this.paymentMethodView()}
                                    </DropdownButton>
                                </div>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                                    <div className="font-12" style={{ fontSize: '14px', color: '#808080' }}>{getTranslate('Sembunyikan nama saya (anonim)')}</div>
                                    <div className="ui fitted toggle checkbox">
                                        <input type="checkbox" defaultChecked={this.state.isAnonim} onClick={() => this.toggleCheckBoxAnonim()} />
                                        <label style={{ display: 'inline' }}></label>
                                    </div>
                                </Row>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'center', alignItems: 'center', marginBottom: '32px' }}>
                                    <FormLabel><span 
                                        style={{ cursor: 'pointer', textDecoration: 'underline' }} 
                                        id="public-donate-login" onClick={()=>this.donateLogin()}>Masuk</span> atau lengkapi data di bawah ini</FormLabel>

                                </Row>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                                    <FormLabel>{getTranslate('Nama')}</FormLabel>

                                    <FormControl
                                        onChange={(event: any) => this.setState({ donatur: event.target.value })}
                                    />
                                </Row>
                                <Row style={{ margin: '0px', padding: '0px', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
                                    <FormLabel>{getTranslate('Contact')}</FormLabel>

                                    <FormControl
                                        onChange={(event: any) => this.setState({ contact: event.target.value })}
                                    />
                                </Row>
                                <Button style={{
                                    fontWeight: 'bold',
                                    color: this.disableBtn() ? '#808080' : 'white', paddingLeft: '32px', paddingRight: '32px',
                                    borderColor: this.disableBtn() ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.disableBtn() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }} className={this.state.isLoading ? "ui primary loading button" : ""}
                                    disabled={this.disableBtn()} onClick={() => this.saveDonate()} block>{getTranslate('Lanjut Pembayaran')}</Button>
                            </Col>

                            {/* Right Card */}
                            <Col className="desktop-show" style={{ margin: '0px', padding: '0px', marginLeft: '36px' }}>
                                <Card style={{
                                    borderColor: 'transparent',
                                    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
                                }}>
                                    <Image style={{
                                        height: '215px', objectFit: 'cover', borderRadius: '4px'
                                    }} src={this.props.selectedProject?.picture_url ? this.props.selectedProject?.picture_url : require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />
                                    <Col style={{ margin: '0px', padding: '16px', alignItems: 'center' }}>
                                        <div style={{ fontWeight: 'bold', fontSize: '24px', color: '#333333', marginBottom: '16px' }}>{toTitleCase(this.props.selectedProject?.name)}</div>
                                        <ProgressBar style={{ marginBottom: '16px', borderRadius: '23px', height: '8px' }} now={this.getPercentage(this.props.selectedProject)}></ProgressBar>
                                        <div style={{ fontWeight: 'bold', fontSize: '18px', color: '#333333', marginBottom: '8px' }}>
                                            {toCurrency(this.props.selectedProject?.current_amount)}
                                        </div>
                                        <div style={{ fontSize: '14px', color: '#333333', marginBottom: '8px' }}>
                                            {getTranslate('terkumpul dari target ')} {toCurrency(this.props.selectedProject?.target_amount)}
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <Image style={{ width: '24px', height: '24px', objectFit: 'contain' }} src={require('../../../../src/assets/images/ic-actions-calendar/ic-actions-calendar.png')} className="miniImage" />
                                            <div style={{ fontSize: '14px', color: '#808080' }}>{this.props.selectedProject?.close_funding_date ? manyDaysBetween(this.props.selectedProject?.close_funding_date) + getTranslate(' hari tersisa') : '-'}</div>
                                        </div>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? this.state.isError : getTranslate('Berhasil donasi')}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        trackDonationPage: state.project.trackDonationPage,
        selectedProject: state.project.selectedProject,
        user: state.user.user,
        payment: state.payment.myPayments,
        paymentMethods: state.payment.paymentMethods
        
    } as PublicDonatePageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        submitDonation: (payload: DonationBody) => submitDonation(payload),
        submitPayment: (payload: PaymentBody) => submitPayment(payload),
        getPayments: (userId: string) => getPayments(userId),
        setPayment: (payments: Payment[]) => dispatch(setMyPayment(payments)),
        getPublicPaymentMethods: () => getPublicPaymentMethods(),
        setPaymentMethod: (paymentMethod: PaymentMethod[]) => dispatch(setPaymentMethod(paymentMethod)),
        setAmountPayment: (amount: string) => dispatch(setAmountPayment(amount)),
        setAnonim: (isAnonim: boolean) => dispatch(setAnonim(isAnonim)),
        setDonatur: (donatur: string) => dispatch(setDonatur(donatur)),
        setUserContact: (contact: string) => dispatch(setUserContact(contact)),
    } as PublicDonatePageActions;
}

export default connect(stateToProps, dispatchToProps)(PublicDonatePage);
