import * as React from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../../AppState';
import {
    Image,
    Row,
    Col,
    Card,
    Accordion,
    Button,
    FormControl,
    InputGroup,
    FormLabel,
    ProgressBar,
    Alert,
    Badge
} from 'react-bootstrap';
import './style.css';

import NavbarComponent from '../../../component/navbar';
import ListMyProjectComponent from '../../../component/listMyProject';
import { Project } from '../../../model/Project';
import { User } from '../../../model/User';
import { getProjects, setMyProject, setSelectedProject, trackProjectPage, editProjectUser } from '../../../reducers/Project';
import { Dispatch } from 'redux';
import { Organization } from '../../../model/Organization';
import { Media } from '../../../model/Media';
import { getOrganization, setOrganization, editProfile, editOrganization, submitOrganization } from '../../../reducers/User';
import { setUploadedMedia, getUploadedMedia, clearMedia } from '../../../reducers/Media';
import { getStatusColor, toTitleCase, getFileExtensions, bytesToSize, validateEmail, validateUrl, removeAllSpecialChar, getTranslate, projectStatusMapper } from '../../../utils/General';
import { Editor } from '@tinymce/tinymce-react';
import Navigation from '../../../utils/Navigation';
import { ProjectUserBody } from '../../../model/payload/ProjectUserBody';
import { OrganizationBody } from '../../../model/payload/OrganizationBody';
import { ProgressBody } from '../../../model/payload/ProgressBody';
import { submitProgressPmt, submitProgress } from '../../../reducers/Progress';
import NumberFormat from 'react-number-format';

export interface MyProjectDetailPageProps {
    user: User,
    project: Project,
    media: Media[],
}

export interface MyProjectDetailPageAction {
    setMediaUpload(medias: Media[]): void
    editProjectUser(project_id: string, payload: ProjectUserBody): any
    mediaUpload(data: FormData, id_user: string, file_type: string): any
    clearMedia(): void
    submitProgress(progress: ProgressBody): any
}

export interface MyProjectDetailPageState {
    //project details
    name: string,
    description: string,
    address_project: string,
    target_amount?: number,
    electrical_power?: number,
    electrical_target?: number,
    pv_location: string,
    is_pv_battery?: boolean,
    is_experience_contractor?: boolean,
    is_started_project?: boolean,
    creator_is_owner?: boolean,
    doc: string[],

    loading: boolean,
    loadingProgress: boolean,
    loadingUploadImage: boolean,
    showAlert: Boolean,
    isError: string,

    imageFile: string
    imageFormData?: FormData

    amountProgress: string
    nameProgress: string
    descriptionProgress: string

    uploadedFile: string[]
    uploadedName: string[]
    uploadedSize: string[]
    uploadedFormData: FormData[]

    isCallApiProgress: boolean
}

class MyProjectDetailPage extends React.Component<MyProjectDetailPageProps & MyProjectDetailPageAction, MyProjectDetailPageState>{
    private fileUploader: any | null
    constructor(props: any) {
        super(props);
        this.state = {
            //project details
            name: "",
            description: "",
            address_project: "",
            target_amount: undefined,
            electrical_power: undefined,
            electrical_target: undefined,
            pv_location: "",
            is_pv_battery: undefined,
            is_experience_contractor: undefined,
            is_started_project: undefined,
            creator_is_owner: undefined,
            doc: [],

            loading: false,
            loadingProgress: false,
            loadingUploadImage: false,
            showAlert: false,
            isError: "",
            imageFile: "",

            amountProgress: "",
            nameProgress: "",
            descriptionProgress: "",

            uploadedFile: [],
            uploadedName: [],
            uploadedSize: [],
            uploadedFormData: [],

            isCallApiProgress: false
        }
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }

    validationButton(): boolean {
        const s = this.state
        if ((s.name && (s.name !== this.props.project.name)) ||
            (s.description && (s.description !== this.props.project.description)) ||
            (s.address_project && (s.address_project !== this.props.project.address)) ||
            (s.target_amount && (("" + s.target_amount) !== this.props.project.target_amount)) ||
            (s.electrical_power && (("" + s.electrical_power) !== this.props.project.current_electricity)) ||
            (s.electrical_target && (("" + s.electrical_target) !== this.props.project.electricity_request)) ||
            (s.pv_location && (s.pv_location !== this.props.project.electricity_placement)) ||
            (s.is_pv_battery !== undefined && (s.is_pv_battery !== this.props.project.has_electricity_battery)) ||
            (s.is_experience_contractor !== undefined && (s.is_experience_contractor !== this.props.project.has_exp_contractor)) ||
            (s.is_started_project !== undefined && (s.is_started_project !== this.props.project.has_started)) ||
            (s.creator_is_owner !== undefined && (s.creator_is_owner !== this.props.project.creator_is_owner)) ||
            (this.props.project.submitted_doc && this.props.project.submitted_doc.length !== this.props.media.length) ||
            this.state.imageFormData
        ) {
            return false
        }
        return true
    }

    validationButtonProgress = () => {
        const s = this.state
        if (s.nameProgress && s.descriptionProgress) {
            return false
        } else {
            return true
        }
    }

    setProject = () => {
        const p = this.props.project
        if (this.props.project) {
            this.setState({
                name: p.name,
                description: p.description,
                address_project: p.address,
                target_amount: p.target_amount ? parseInt(p.target_amount) : 0,
                electrical_power: p.current_electricity ? parseInt(p.current_electricity) : 0,
                electrical_target: p.electricity_request ? parseInt(p.electricity_request) : 0,
                pv_location: p.electricity_placement,
                is_pv_battery: p.has_electricity_battery,
                is_experience_contractor: p.has_exp_contractor,
                is_started_project: p.has_started,
                creator_is_owner: p.creator_is_owner,
                doc: p.submitted_doc,
            })

            if (p.submitted_doc && p.submitted_doc.length > 0) {
                const medias: Media[] = []
                p.submitted_doc.forEach(pro => {
                    medias.push({
                        data: pro,
                        size: '-'
                    })
                })
                this.props.setMediaUpload(medias)
            }
        }
    }

    handleEditorChange(e: any) {
        this.setState({ description: e.target.getContent() });
    }

    async componentDidMount() {
        this.setProject()
    }

    onBackHome() {
        Navigation.to('/dashboard/my-project')
    }

    componentWillUnmount() {
        this.props.clearMedia()
    }

    async onSubmitProject() {
        this.setState({ loading: true })
        const s = this.state

        const submitDocs: string[] = []
        if (this.props.media && this.props.media.length > 0) {
            this.props.media.forEach(pro => {
                submitDocs.push(pro.data)
            })
        }

        let payload: ProjectUserBody = {
            name: removeAllSpecialChar(s.name),
            description: removeAllSpecialChar(s.description),
            target_amount: s.target_amount ? s.target_amount : 0,
            address: removeAllSpecialChar(s.address_project),
            current_electricity: s.electrical_power ? s.electrical_power : 0,
            electricity_request: s.electrical_target ? s.electrical_target : 0,
            electricity_placement: removeAllSpecialChar(s.pv_location),
            has_electricity_battery: s.is_pv_battery || false,
            has_exp_contractor: s.is_experience_contractor || false,
            has_started: s.is_started_project || false,
            creator_is_owner: s.creator_is_owner || false,
            submitted_doc: submitDocs
        };
        try {
            if (this.state.imageFormData) {
                const media: Media = await this.props.mediaUpload(this.state.imageFormData, this.props.user.id, 'project_pict')
                payload.picture_url = media.data
            }

            await this.props.editProjectUser(this.props.project.id, payload)
            this.setState({ isCallApiProgress: false, showAlert: true })
            setTimeout(() => {
                this.setState({ loading: false, showAlert: false })
                this.onBackHome()
            }, 1000);
        } catch (e: any) {
            this.setState({ loading: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    async upload(data: FormData) {
        this.setState({ loadingUploadImage: true })
        try {
            const media: Media = await this.props.mediaUpload(data, this.props.user.id, 'project_pict')
            const medias = this.props.media
            medias.push(media)
            this.setState({ loadingUploadImage: false })
            this.props.setMediaUpload(medias)
        } catch (e: any) {
            this.setState({ loadingUploadImage: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    onClickUploadPictureUrl = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);
            this.setState({
                imageFile: URL.createObjectURL(event.target.files[0]),
                imageFormData: data
            });
        }
    }

    onClickUploadProgress = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);

            const uploadedFiles = this.state.uploadedFile
            const uploadedNames = this.state.uploadedName
            const uploadedSizes = this.state.uploadedSize
            const formDatas = this.state.uploadedFormData
            uploadedFiles.push(URL.createObjectURL(event.target.files[0]))
            uploadedNames.push(event.target.files[0].name)
            uploadedSizes.push(bytesToSize(event.target.files[0].size))
            formDatas.push(data)

            this.setState({
                uploadedFile: uploadedFiles,
                uploadedFormData: formDatas
            });
        }
    }

    onClickUpload = (event: any) => {
        let file = event.target.files[0];

        if (file) {
            let data = new FormData();
            data.append('file_data', file);
            this.upload(data)
        }
    }

    getPictureUrl = (project: Project) => {
        if (this.state.imageFile) {
            return (<Image style={{
                width: '320px',
                height: '187px',
                objectFit: 'cover', borderRadius: '4px'
            }} src={this.state.imageFile} />)
        } else {
            if (project.picture_url) {
                return (<Image style={{
                    width: '320px',
                    height: '187px',
                    objectFit: 'cover', borderRadius: '4px'
                }} src={project.picture_url} />)
            } else {
                return (<Image style={{
                    width: '320px',
                    height: '187px',
                    objectFit: 'cover', borderRadius: '4px'
                }} src={require('../../../../src/assets/images/placeholder_image_project/placeholder_image_project.png')} />)
            }
        }
    }

    async submitProgress() {
        try {
            this.setState({
                loadingProgress: true
            })

            if (this.props.project) {
                let progressPayload: ProgressBody = {
                    progress_name: removeAllSpecialChar(this.state.nameProgress),
                    progress_desc: removeAllSpecialChar(this.state.descriptionProgress),
                    project_id: this.props.project.id,
                    amount: this.state.amountProgress,
                    user_name: removeAllSpecialChar(this.props.user.name)
                }
                if (this.state.uploadedFormData.length > 0) {
                    progressPayload.invoice_doc = []
                    for (const mediaItem of this.state.uploadedFormData) {
                        const media: Media = await this.props.mediaUpload(mediaItem, this.props.user.id, 'invoice_doc')
                        progressPayload.invoice_doc!!.push(media.data)
                    }
                }
                await this.props.submitProgress(progressPayload)
                this.setState({ isCallApiProgress: true, showAlert: true })
                setTimeout(() => {
                    this.setState({ loadingProgress: false, showAlert: false })
                    this.onBackHome()
                }, 1000);
            }
        } catch (e: any) {
            this.setState({ loadingProgress: false, isError: e.error_msg, showAlert: true })
            setTimeout(() => {
                this.setState({ showAlert: false, isError: "" })
            }, 3000);
        }
    }

    clickAddProgress = () => {
        this.submitProgress()
    }

    renderMediaList() {
        return this.props.media.map((item, index) => {
            return (
                <Col key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{ margin: '0px', padding: '0px' }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            width: '64px', height: '64px', marginRight: '16px',
                            backgroundColor: '#cccccc', borderRadius: '4px',
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            {validateUrl(item.data) && (getFileExtensions(item.data.split('.')) === 'PNG' || getFileExtensions(item.data.split('.')) === 'JPG' || getFileExtensions(item.data.split('.')) === 'JPEG') ?
                                <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item.data} />
                                :
                                <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(item.data.split('.'))}</div>
                            }
                        </Row>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{item.data}</div>
                            <div style={{ color: '#808080', fontSize: '12px', fontWeight: 'normal' }}>{item.size && item.size !== '-' ? bytesToSize(parseInt(item.size)) : '-'}</div>
                        </Col>

                        <Image onClick={() => this.onRemoveMediaProject(index)} style={{ width: '24px', height: '24px', cursor: 'pointer' }} src={require("../../../../src/assets/images/ic-common-action-close-gray/ic-common-action-close-gray.png")} />
                    </Row>

                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px', marginTop: '16px'
                    }} />
                </Col>
            )
        })
    }

    onRemoveMediaProject = (indexMedia: number) => {
        let medias = this.props.media

        if (medias.length <= 1) {
            medias = []
        } else {
            medias.forEach((item: Media, index: number) => {
                if (indexMedia === index) {
                    medias.splice(medias.indexOf(item), 1);
                }
            })
        }

        this.props.setMediaUpload(medias)
    }

    onRemoveMedia = (indexMedia: number) => {
        let uploadedFile = this.state.uploadedFile
        let uploadedName = this.state.uploadedName
        let uploadedSize = this.state.uploadedSize
        let uploadedFormData = this.state.uploadedFormData

        if (uploadedFile.length <= 1) {
            uploadedFile = []
            uploadedName = []
            uploadedSize = []
            uploadedFormData = []
        } else {
            uploadedSize.forEach((item: string, index: number) => {
                if (indexMedia === index) {
                    uploadedSize.splice(uploadedSize.indexOf(item), 1);
                }
            })
            uploadedFile.forEach((item: string, index: number) => {
                if (indexMedia === index) {
                    uploadedFile.splice(uploadedFile.indexOf(item), 1);
                }
            })
            uploadedName.forEach((item: string, index: number) => {
                if (indexMedia === index) {
                    uploadedName.splice(uploadedName.indexOf(item), 1);
                }
            })
            uploadedFormData.forEach((item: FormData, index: number) => {
                if (indexMedia === index) {
                    uploadedFormData.splice(uploadedFormData.indexOf(item), 1);
                }
            })
        }

        this.setState({ uploadedFile, uploadedName, uploadedFormData })
    }

    renderMediaListProgress() {
        var uploadedNames = this.state.uploadedName
        var uploadedSizes = this.state.uploadedSize
        return this.state.uploadedFile.map((item, index) => {
            return (
                <Col key={index} style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                    <Row style={{ margin: '0px', padding: '0px' }}>
                        <Row style={{
                            margin: '0px', padding: '0px',
                            width: '64px', height: '64px', marginRight: '16px',
                            backgroundColor: '#cccccc', borderRadius: '4px',
                            alignItems: 'center', justifyContent: 'center'
                        }}>
                            {validateUrl(uploadedNames[index]) && (getFileExtensions(uploadedNames[index].split('.')) === 'PNG' || getFileExtensions(uploadedNames[index].split('.')) === 'JPG' || getFileExtensions(uploadedNames[index].split('.')) === 'JPEG') ?
                                <Image style={{ width: '64px', height: '64px', objectFit: 'cover', borderRadius: '4px' }} src={item} />
                                :
                                <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{getFileExtensions(uploadedNames[index].split('.'))}</div>
                            }
                        </Row>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div style={{ color: '#4b4b4b', fontSize: '14px', fontWeight: 'bold' }}>{uploadedNames[index].split("/").pop()}</div>
                            <div style={{ color: '#808080', fontSize: '12px', fontWeight: 'normal' }}>{uploadedSizes[index]}</div>
                        </Col>

                        <Image onClick={() => this.onRemoveMedia(index)} style={{ width: '24px', height: '24px', cursor: 'pointer' }} src={require("../../../../src/assets/images/ic-common-action-close-gray/ic-common-action-close-gray.png")} />
                    </Row>

                    <div style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        height: '1px', marginTop: '16px'
                    }} />
                </Col>
            )
        })
    }

    renderProgressLayout = () => {
        return (
            <Col className="card-submission" style={{
                margin: '0px', padding: '24px', backgroundColor: 'white',
                marginTop: '48px',
                cursor: 'default',
                paddingLeft: '32px', paddingRight: '32px',
                borderColor: 'transparent',
                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
            }}>
                <Col style={{ margin: '0px', padding: '0px' }}>
                    <div className="card-label-title" style={{ color: '#333333', fontSize: '24px', fontWeight: 'bold' }}>
                        Progress
                    </div>
                    <div className="garis" style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                        marginLeft: '-32px', marginRight: '-32px',
                        height: '1px', marginTop: '24px', marginBottom: '24px'
                    }} />
                    <Row style={{ margin: '0px', padding: '0px' }}>
                        <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                            <FormLabel>{getTranslate('Jumlah amount dalam progress (opsional)')}</FormLabel>
                            <NumberFormat
                                style={{ width: '100%' }}
                                id="number_format"
                                thousandSeparator={true}
                                onValueChange={(event: any) => this.setState({ amountProgress: event.value })} />
                        </Col>
                        <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                            <FormLabel>{getTranslate('Nama progress')}</FormLabel>
                            <FormControl placeholder=""
                                onChange={(event: any) => this.setState({ nameProgress: event.target.value })}
                            />
                        </Col>
                    </Row>
                    <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                        <FormLabel>{getTranslate('Deskripsi progress')}</FormLabel>
                        <FormControl placeholder="" as="textarea"
                            onChange={(event: any) => this.setState({ descriptionProgress: event.target.value })}
                        />
                    </Col>

                    <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <FormLabel>{getTranslate('Upload bukti transfer')}</FormLabel>
                            <div className="uploader">
                                <div style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>{getTranslate('Drag & drop')}</div>
                                <div style={{ color: '#808080', fontSize: '16px', marginTop: '4px' }}>{getTranslate('atau')}
                                    <a style={{ color: '#1aa59a' }}>{getTranslate(' browse ')}</a>{getTranslate('your files')}</div>
                                <div style={{ color: '#d8d8d8', fontSize: '14px', marginTop: '10px' }}>(format: doc, pdf, xls, image)</div>
                                <input
                                    type="file" name="project-file"
                                    onChange={this.onClickUploadProgress} id="filePhoto"
                                    accept=".doc, .docx, .pdf, .xls, .xlsx, image/*" />
                            </div>
                        </Col>
                    </Row>
                    {this.state.uploadedFile && this.state.uploadedName && this.state.uploadedName.length > 0 && this.state.uploadedFile.length > 0 &&
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                            {this.renderMediaListProgress()}
                        </Col>
                    }
                </Col>
            </Col>
        )
    }

    renderProjectLayout = () => {
        return (
            <Col className="card-submission" style={{
                margin: '0px', padding: '24px', backgroundColor: 'white',
                marginTop: '40px',
                cursor: 'default',
                paddingLeft: '32px', paddingRight: '32px',
                borderColor: 'transparent',
                boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)', borderRadius: '4px'
            }}>
                <input type="file" id="image-file"
                    ref={(ref) => this.fileUploader = ref}
                    style={{ display: "none" }}
                    onChange={this.onClickUploadPictureUrl}
                    accept="image/*" />
                <div className="card-label-title" style={{ color: '#333333', fontSize: '24px', fontWeight: 'bold' }}>
                    {getTranslate('Detail Pengajuan Proyek')}
                </div>
                <div className="garis" style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.15)',
                    marginLeft: '-32px', marginRight: '-32px',
                    height: '1px', marginTop: '24px', marginBottom: '24px'
                }} />
                <Col style={{ margin: '0px', padding: '0px' }}>
                    <FormLabel>{getTranslate('Nama Proyek')}</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <FormControl placeholder={getTranslate("Contoh: Pemasangan Turbin di Kawasan Ciwidey")}
                            defaultValue={this.state.name}
                            onChange={(event: any) => this.setState({ name: event.target.value })}
                        />
                    </InputGroup>
                </Col>
                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <FormLabel>{getTranslate('Deskripsi Proyek')}</FormLabel>
                        <Editor
                            initialValue={this.state.description}
                            init={{
                                height: 400,
                                menubar: true,
                                plugins: 'link image code',
                                toolbar: 'undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat| image'
                            }}
                            onChange={this.handleEditorChange} />
                    </Col>
                </Row>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Jumlah Pengajuan Dana Proyek')} (Rp)</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <NumberFormat
                            value={this.state.target_amount}
                            placeholder={getTranslate("Contoh: ") + "500.000"}
                            id="number_format"
                            thousandSeparator={true}
                            onValueChange={(event: any) => this.setState({ target_amount: event.value })} />
                    </InputGroup>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Alamat Proyek')}</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <FormControl
                            defaultValue={this.state.address_project}
                            placeholder={getTranslate("Contoh: ") + "Jl. Dr. Ide Gde Agung No. 9, Setiabudi, Kuningan, Jakarta Selatan"} as="textarea"
                            onChange={(event: any) => this.setState({ address_project: event.target.value })}
                        />
                    </InputGroup>
                </Col>
                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <Col className="col-basis-1" style={{ margin: '0px', padding: '0px', marginRight: '8px' }}>
                        <FormLabel>{getTranslate('Daya Listrik yang telah Terpasang')} (Watt)</FormLabel>
                        <InputGroup style={{ margin: '0px', padding: '0px' }}>
                            <FormControl type="number" placeholder={getTranslate("Contoh: ") + "5000"}
                                defaultValue={this.state.electrical_power}
                                onChange={(event: any) => this.setState({ electrical_power: event.target.value })}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="col-basis-1 margin-top-16" style={{ margin: '0px', padding: '0px', marginLeft: '8px' }}>
                        <FormLabel>{getTranslate('Jumlah Pengajuan Daya PV')} (kWp)</FormLabel>
                        <InputGroup style={{ margin: '0px', padding: '0px' }}>
                            <FormControl type="number" placeholder={getTranslate("Contoh: ") + "1200"}
                                defaultValue={this.state.electrical_target}
                                onChange={(event: any) => this.setState({ electrical_target: event.target.value })}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Dimana PV akan dipasang?')}</FormLabel>
                    <InputGroup style={{ margin: '0px', padding: '0px' }}>
                        <FormControl
                            defaultValue={this.state.pv_location}
                            placeholder={getTranslate("Contoh: ") + "atap, lapangan, bukit, dll."}
                            as="textarea"
                            onChange={(event: any) => this.setState({ pv_location: event.target.value })}
                        />
                    </InputGroup>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah akan menggunakan PV Baterai?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.is_pv_battery === true ?
                                true : undefined} name="is_pv_battery" value="Ya"
                                onClick={() => this.setState({ is_pv_battery: true })}
                            />
                            <label>{getTranslate('Ya')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.is_pv_battery === false ?
                                true : undefined} name="is_pv_battery" value="Tidak"
                                onClick={() => this.setState({ is_pv_battery: false })}
                            />
                            <label>{getTranslate('Tidak')}</label>
                        </div>
                    </Row>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah proyek ini telah memiliki kontraktor berpengalaman?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.is_experience_contractor === true ?
                                true : undefined} name="is_experience_contractor" value="Ya"
                                onClick={() => this.setState({ is_experience_contractor: true })}
                            />
                            <label>{getTranslate('Ya')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.is_experience_contractor === false ?
                                true : undefined} name="is_experience_contractor" value="Tidak"
                                onClick={() => this.setState({ is_experience_contractor: false })}
                            />
                            <label>{getTranslate('Tidak')}</label>
                        </div>
                    </Row>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah proyek ini sudah dimulai?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.is_started_project === true ?
                                true : undefined} name="is_started_project" value="Ya"
                                onClick={() => this.setState({ is_started_project: true })}
                            />
                            <label>{getTranslate('Sudah')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.is_started_project === false ?
                                true : undefined} name="is_started_project" value="Tidak"
                                onClick={() => this.setState({ is_started_project: false })}
                            />
                            <label>{getTranslate('Belum')}</label>
                        </div>
                    </Row>
                </Col>
                <Col style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <FormLabel>{getTranslate('Apakah Anda bertindak sebagai perwakilan langsung / tim dari proyek ini?')}</FormLabel>
                    <Row style={{ margin: '0px', padding: '0px', marginTop: '8px' }}>
                        <div className="ui radio checkbox"
                            style={{
                                marginRight: '16px'
                            }}>
                            <input type="radio" defaultChecked={this.state.creator_is_owner === true ?
                                true : undefined} name="creator_is_owner" value="Ya"
                                onClick={() => this.setState({ creator_is_owner: true })}
                            />
                            <label>{getTranslate('Ya')}</label>
                        </div>
                        <div className="ui radio checkbox">
                            <input type="radio" defaultChecked={this.state.creator_is_owner === false ?
                                true : undefined} name="creator_is_owner" value="Tidak"
                                onClick={() => this.setState({ creator_is_owner: false })}
                            />
                            <label>{getTranslate('Tidak')}</label>
                        </div>
                    </Row>
                </Col>
                <Row style={{ margin: '0px', padding: '0px', marginTop: '24px' }}>
                    <Col style={{ margin: '0px', padding: '0px' }}>
                        <FormLabel>{getTranslate('Upload proposal / gambar proyek jika ada')}</FormLabel>
                        <div className="uploader">
                            <div style={{ color: '#333333', fontSize: '16px', fontWeight: 'bold' }}>{getTranslate('Drag & drop')}</div>
                            <div style={{ color: '#808080', fontSize: '16px', marginTop: '4px' }}>{getTranslate('or')}
                            <a style={{ color: '#1aa59a' }}>{getTranslate(' browse ')}</a>{getTranslate('your files')}</div>
                            <div style={{ color: '#d8d8d8', fontSize: '14px', marginTop: '10px' }}>(format: doc, pdf, xls, image)</div>
                            <input
                                type="file" name="project-file"
                                onChange={this.onClickUpload} id="filePhoto"
                                accept=".doc, .docx, .pdf, .xls, .xlsx, image/*" />
                        </div>
                    </Col>
                </Row>
                {((this.props.media && this.props.media.length > 0) || this.state.loadingUploadImage) &&
                    <Col style={{ margin: '0px', padding: '0px', marginTop: '16px' }}>
                        {this.state.loadingUploadImage &&
                            <Col style={{ margin: '0px', padding: '0px' }}>
                                <div style={{
                                    color: '#d8d8d8', fontSize: '14px',
                                    fontWeight: 'bold', marginBottom: '8px'
                                }}>
                                    {getTranslate('Uploading...')}
                                    </div>
                                <ProgressBar
                                    animated
                                    style={{ borderRadius: '7px', height: '8px' }}
                                    now={100} />
                            </Col>
                        }
                        {this.renderMediaList()}
                    </Col>
                }
            </Col>
        )
    }

    render() {
        return (
            <div>
                <Col style={{ margin: '0px', padding: '0px' }} id="defNav">
                    <NavbarComponent />
                </Col>

                <Row id="body">
                    <Col className="submission-container" style={{ margin: '0px', padding: '0px' }} >
                        <Col style={{ margin: '0px', padding: '0px', marginTop: '46px', marginBottom: '56px', alignItems: 'center' }} className="col-back-home">
                            <div id="onBackHome" onClick={() => this.onBackHome()} style={{ display: 'flex', alignItems: 'center' }}>
                                <Image style={{
                                    width: '23px',
                                    height: '14px', objectFit: 'contain'
                                }} src={require("../../../../src/assets/images/ic-arrows-left/ic-arrows-left.png")} />
                                <div style={{ marginLeft: '15px', fontSize: '24px', color: '#333333' }} className="back">{getTranslate('Kembali ke My Project')}</div>
                            </div>
                        </Col>

                        <Col style={{ margin: '0px', padding: '0px' }}>
                            <div className="font-mobile-20 margin-bottom-16" style={{ fontSize: '36px', color: '#333333', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', marginBottom: '40px' }}>{getTranslate('Detail Proyek')}</div>
                            <Row className="image-edit-container" style={{
                                margin: '0px', padding: '0px',
                                justifyContent: 'flex-start', alignItems: 'end',
                                wordBreak: 'break-all'
                            }}>
                                <Row style={{
                                    margin: '0px', padding: '0px',
                                    width: '320px',
                                    height: '187px',
                                    borderRadius: '4px',
                                    backgroundColor: '#d8d8d8',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    color: '#4b4b4b',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    marginRight: '32px'
                                }} onClick={() => this.fileUploader?.click()}>
                                    <div style={{
                                        position: 'absolute', width: 'inherit',
                                        height: 'inherit', backgroundColor: 'rgba(0, 0, 0, 0.40)',
                                        borderRadius: '4px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        alignSelf: 'center',
                                        display: 'flex'
                                    }}>
                                        <Image style={{ alignSelf: 'center', objectFit: 'contain' }} src={require('../../../assets/images/ic-devices-camera/ic-devices-camera.png')} />
                                    </div>
                                    {this.getPictureUrl(this.props.project)}
                                </Row>
                                <Col className="label-gambar" style={{ margin: '0px', padding: '0px', marginLeft: '24px' }}>
                                    <Row style={{
                                        margin: '0px', padding: '0px', justifyContent: 'space-between',
                                        alignItems: 'center', wordBreak: 'break-all'
                                    }}>
                                        <div style={{ fontSize: '24px', color: '#333333', marginRight: '120px' }} className="titleMyProject">{toTitleCase(this.props.project.name)}</div>
                                        <Badge className="desktop-show" style={{
                                            right: 0, position: 'absolute',
                                            backgroundColor: getStatusColor(this.props.project?.status), color: 'white',
                                            fontSize: '14px', padding: '8px', paddingLeft: '24px', paddingRight: '24px'
                                        }} pill>{projectStatusMapper(this.props.project?.status)}</Badge>
                                    </Row>
                                    <Badge className="mobile-show badge-mobile" style={{
                                            right: 0, position: 'absolute',
                                            backgroundColor: getStatusColor(this.props.project?.status), color: 'white',
                                            fontSize: '14px', padding: '8px', paddingLeft: '24px', paddingRight: '24px'
                                        }} pill>{projectStatusMapper(this.props.project?.status)}</Badge>
                                </Col>
                            </Row>
                            {this.renderProjectLayout()}
                            <Row className="submit-button-row" style={{ margin: '0px', padding: '0px', marginTop: '24px', justifyContent: 'flex-end' }}>
                                <Button style={{
                                    fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                    color: this.validationButton() ? '#808080' : 'white',
                                    borderColor: this.validationButton() ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.validationButton() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }}
                                    className={this.state.loading ? "ui primary loading button" : ""}
                                    disabled={this.validationButton()}
                                    onClick={() => this.onSubmitProject()}>
                                    {getTranslate('Ubah Project')} </Button>
                            </Row>

                            {this.renderProgressLayout()}
                            <Row className="submit-button-row" style={{ margin: '0px', padding: '0px', marginTop: '24px', justifyContent: 'flex-end' }}>
                                <Button style={{
                                    fontWeight: 'bold', marginLeft: '8px', paddingLeft: '32px', paddingRight: '32px',
                                    color: this.validationButtonProgress() ? '#808080' : 'white',
                                    borderColor: this.validationButtonProgress() ? '#cccccc' : '#5bc69a',
                                    backgroundColor: this.validationButtonProgress() ? '#cccccc' : '#5bc69a', opacity: '1'
                                }}
                                    className={this.state.loadingProgress ? "ui primary loading button" : ""}
                                    disabled={this.validationButtonProgress()}
                                    onClick={() => this.clickAddProgress()}>
                                    {getTranslate('Submit Progress')} </Button>
                            </Row>
                        </Col>
                        {this.state.showAlert === true &&
                            <Alert style={{
                                padding: '16px', margin: '0px', paddingLeft: '32px', paddingRight: '32px',
                                marginLeft: '350px', marginRight: '350px',
                                position: 'absolute', top: 0, marginTop: '23px', borderRadius: '4px',
                                backgroundColor: this.state.isError ? 'rgba(255, 0, 0, 0.3)' : 'rgba(54,179,152,0.3)'
                            }}>
                                <div style={{ fontSize: '16px', color: this.state.isError ? '#ff0000' : '#1aa59a', textAlign: 'center' }}>
                                    {this.state.isError ? (this.state.isError) : (this.state.isCallApiProgress ? getTranslate('Berhasil menambahkan progress') : getTranslate('Proyek berhasil diubah'))}
                                </div>
                            </Alert>
                        }
                    </Col>
                </Row>

                <div className="footer" style={{ fontSize: '14px' }}>
                    © Copyright 2020 Indonesia Green
                </div>
            </div>
        )
    }
}

const stateToProps = (state: AppState) => {
    return {
        user: state.user.user,
        media: state.media.medias,
        project: state.project.selectedProject
    } as MyProjectDetailPageProps;
};

const dispatchToProps = (dispatch: Dispatch) => {
    return {
        setMediaUpload: (media: Media[]) => dispatch(setUploadedMedia(media)),
        editProjectUser: (project_id: string, payload: ProjectUserBody) => editProjectUser(project_id, payload),
        mediaUpload: (data: FormData, id_user: string, file_type: string) => getUploadedMedia(data, id_user, file_type),
        clearMedia: () => dispatch(clearMedia()),
        submitProgress: (progress: ProgressBody) => submitProgress(progress)
    } as MyProjectDetailPageAction;
}

export default connect(stateToProps, dispatchToProps)(MyProjectDetailPage);
