import Redux, { AnyAction } from 'redux';

import { Progress } from '../model/Progress';
import { ProgressBody } from '../model/payload/ProgressBody';
import ProgressApi from '../api/Progress';

export const SET_PROGRESS_BY_PROJECT = `PROGRESS.SET_PROGRESS_BY_PROJECT`

export const setProgressByProject: Redux.ActionCreator<Redux.AnyAction> = (payload: Progress[]) => {
    return { type: SET_PROGRESS_BY_PROJECT, payload: payload }
}

export const getProgress = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, type?: string, status?: string) {
    try {
        const result: Progress[] = await ProgressApi.getProgress(user_id, starting_after, order_by, limit, project_id, type, status);
        return result
    } catch (err: any) {
        throw err
    }
};
export const getPublicProgress = async function (user_id?: string, starting_after?: string, order_by?: string, limit?: number, project_id?: string, type?: string, status?: string) {
    try {
        const result: Progress[] = await ProgressApi.getPublicProgress(user_id, starting_after, order_by, limit, project_id, type, status);
        return result
    } catch (err: any) {
        throw err
    }
};

export const submitProgress = async function (payload: ProgressBody) {
    try {
        await ProgressApi.submitProgress(payload);
    } catch (err: any) {
        throw err
    }
}

export const submitProgressPmt = async function (payload: ProgressBody) {
    try {
        await ProgressApi.submitProgress(payload);
    } catch (err: any) {
        throw err
    }
}

export interface ProgressState {
    progressByProject: Progress[] | undefined
}

export const initialProgressState: ProgressState = {
    progressByProject: undefined
}

const progressReducers = (state: ProgressState = initialProgressState, action: any): ProgressState => {
    switch (action.type) {
        case SET_PROGRESS_BY_PROJECT:
            return {
                ...state,
                progressByProject: action.payload
            }
        default: {
            return state;
        }
    }
}

export default progressReducers
